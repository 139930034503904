import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { Controller, useForm } from 'react-hook-form';

import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputSwitch } from 'primereact/inputswitch';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';

import InputErrorMessage from 'components/UI/InputErrorMessage/InputErrorMessage';
import CustomFields from 'modules/lead/AddLead/components/ContactForm/CustomFields';
import { PI_PROVIDERS_TYPE } from 'constants/index';
import { titles } from 'common/dropdownOptions';
import { countriesList, stateList } from 'constants/stateList';
import { getNestedProperty, makeListUnique } from 'utils/utils';
import { updateContactDetails } from 'services/ContactService';
import { useToast } from 'context/ToastContext';
import { toastConstant } from 'constants/toastmessage';
import { validatePhoneNumber } from 'modules/lead/helpers/helpers';
import { useUserDetailsContext } from 'context/userDetailsContext';
import TextSnippetWrapper from 'components/TextSnippetWrapper';
import { useCaseDetailsCustomField } from 'modules/cases/hooks/useCaseDetailsCustomField';
import LeadContactCustomField from './LeadContactCustomField';
import CustomCalendar from 'components/CustomCalendar/CustomCalendar';

const state_list = stateList.map((item) => {
  return {
    label: item.abbreviation + ' - ' + item.name,
    value: item.abbreviation + ' - ' + item.name,
  };
});

export default function PersonalInformation({ leadDetails, loadLeadDetails }) {
  const { addToast } = useToast();
  const { checkSubPermission } = useUserDetailsContext();
  const [apiLoading, setApiLoading] = useState(false);
  const clientStatus = leadDetails?.main_client?.some((item) => item?.status === 'ARCHIVED');
  const { contactCustomFieldList } = useCaseDetailsCustomField();

  const {
    control,
    formState: { errors, defaultValues },
    watch,
    handleSubmit,
    reset,
    setValue,
    trigger,
  } = useForm({
    client: leadDetails?.main_client?.[0],
  });

  useEffect(() => {
    if (leadDetails) {
      let existingCustomFieldList = leadDetails?.main_client?.[0]?.custom_field
        ?.filter((data) => data.value && data.custom_field_id)
        ?.map((c) => {
          let item2 = contactCustomFieldList?.find((item) => item?.custom_field_id === c?.custom_field_id);
          if (item2) {
            c.position = item2?.position;
          }
          return c;
        })
        ?.sort((a, b) => a?.position - b?.position);

      let assignedCustomField = [],
        unassignedCustomField = [];

      if (Array.isArray(contactCustomFieldList) && contactCustomFieldList?.length) {
        let custom_field_id = existingCustomFieldList?.map((item) => item.custom_field_id) || [];
        let remainingCustomFields = contactCustomFieldList?.filter((item) => !custom_field_id?.includes(item?.custom_field_id));

        let assignedLength = existingCustomFieldList?.length;
        let unassignedLength = remainingCustomFields?.length;

        let condition_1 = !assignedLength && unassignedLength; // no existing custom field
        let condition_2 = assignedLength && unassignedLength; // some + new custom field

        if (condition_1) {
          unassignedCustomField = remainingCustomFields;
        } else if (condition_2) {
          assignedCustomField = existingCustomFieldList;
          unassignedCustomField = remainingCustomFields;
        } else {
          assignedCustomField = existingCustomFieldList;
        }
      }
      reset({
        unassigned_custom_field: unassignedCustomField,
        client: {
          ...leadDetails?.main_client?.[0],
          custom_field: assignedCustomField,
        },
      });
    }
  }, [leadDetails, contactCustomFieldList]);

  const submit = (formData) => {
    let existingCustomField = formData?.client?.custom_field ?? [];
    let remainingCustomFields = formData?.unassigned_custom_field ?? [];

    let finalCustomfield = [...remainingCustomFields, ...existingCustomField];
    finalCustomfield = makeListUnique(finalCustomfield, 'custom_field_id');

    let assignedCustomField = [],
      unassignedCustomField = [];

    finalCustomfield.map((item) => {
      if ([0, null, undefined, '', 'undefined'].includes(item.value)) {
        unassignedCustomField = [...unassignedCustomField, item];
      } else {
        assignedCustomField = [...assignedCustomField, item];
      }
      return true;
    });
    formData.client.custom_field = assignedCustomField;
    delete formData.client.unassigned_custom_field;
    setValue('toggled_custom_field', true);
    setApiLoading(true);
    updateContactDetails(formData?.client, true)
      .then(() => {
        loadLeadDetails();
        addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.LEAD_CONTACT_SAVED);
        setApiLoading(false);
      })
      .catch(() => {
        addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, toastConstant.message.LEAD_CONTACT_SAVE_FAILED);
        setApiLoading(false);
      });
  };

  const validatePhone = async (value) => {
    if (!value) return true;
    return validatePhoneNumber(value, leadDetails?.main_client?.[0]?.contact_id);
  };

  const RequiredValidation = ({ field, message = 'Field Required' }) => {
    return getNestedProperty(errors, field) ? (
      <InputErrorMessage>{getNestedProperty(errors, field)?.message || message}</InputErrorMessage>
    ) : null;
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <h5 className="caption-bold">PERSONAL INFORMATION</h5>
      <div className="shadow-middle bg-white lead-data-box p-3">
        <div className="row">
          <div className="col-lg-6 col-12 ps-lg-4 ps-0 pe-0">
            <div className="col-md-6 col-12 p-medium ps-3 ps-lg-0">
              <span>Client Details</span>
            </div>
            <hr></hr>
            <div className="pe-3 ps-lg-0 ps-3">
              <div className="d-flex align-items-center py-2 flex-wrap">
                <div className="col-md-4 col-12 p-medium">
                  Title
                  <span className="danger-text"> *</span>
                </div>
                <div className="col-md-8 col-12">
                  {defaultValues?.client?.contact_type === 'business' || PI_PROVIDERS_TYPE.includes(defaultValues?.client?.contact_type) ? (
                    <Controller
                      name="client.title"
                      control={control}
                      rules={{ required: 'Title is required.' }}
                      render={({ field }) => (
                        <TextSnippetWrapper
                          type="text"
                          disabled={!leadDetails?.is_open || clientStatus}
                          placeholder="Select"
                          id={field.name}
                          {...field}
                          className="w-100 new-task-popup input-shadow"
                        />
                      )}
                    />
                  ) : (
                    <Controller
                      name="client.title"
                      control={control}
                      rules={{ required: 'Title is Required' }}
                      render={({ field }) => (
                        <Dropdown
                          id={field.name}
                          options={titles}
                          className="w-100"
                          onChange={(e) => field.onChange(e.value)}
                          value={field.value}
                          placeholder="Select"
                          disabled={!leadDetails?.is_open || clientStatus}
                        />
                      )}
                    />
                  )}
                  <RequiredValidation field={'client.title'} />
                </div>
              </div>
              {!Boolean(
                defaultValues?.client?.contact_type === 'business' || PI_PROVIDERS_TYPE.includes(defaultValues?.client?.contact_type)
              ) && (
                <>
                  <div className="d-flex align-items-center py-2 flex-wrap">
                    <div className="col-md-4 col-12 p-medium">
                      First Name
                      <span className="danger-text"> *</span>
                    </div>
                    <div className="col-md-8 col-12">
                      <Controller
                        name="client.first_name"
                        control={control}
                        rules={{ required: 'First Name is required.' }}
                        render={({ field, fieldState }) => (
                          <TextSnippetWrapper
                            type="text"
                            disabled={!leadDetails?.is_open || clientStatus}
                            placeholder="Type in"
                            id={field.name}
                            {...field}
                            className={classNames({ 'p-invalid': fieldState.invalid, 'w-100 new-task-popup input-shadow': true })}
                          />
                        )}
                      />
                      <RequiredValidation field={'client.first_name'} />
                    </div>
                  </div>
                  <div className="d-flex align-items-center py-2 flex-wrap">
                    <div className="col-md-4 col-12 p-medium">Middle Name</div>
                    <div className="col-md-8 col-12">
                      <Controller
                        name="client.middle_name"
                        control={control}
                        render={({ field }) => (
                          <TextSnippetWrapper
                            type="text"
                            disabled={!leadDetails?.is_open || clientStatus}
                            placeholder="Type in"
                            id={field.name}
                            {...field}
                            className="w-100 new-task-popup input-shadow"
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className="d-flex align-items-center py-2 flex-wrap">
                    <div className="col-md-4 col-12 p-medium">
                      Last Name
                      <span className="danger-text"> *</span>
                    </div>
                    <div className="col-md-8 col-12">
                      <Controller
                        name="client.last_name"
                        control={control}
                        rules={{ required: 'Last Name is required.' }}
                        render={({ field, fieldState }) => (
                          <TextSnippetWrapper
                            type="text"
                            disabled={!leadDetails?.is_open || clientStatus}
                            placeholder="Type in"
                            id={field.name}
                            {...field}
                            className={classNames({ 'p-invalid': fieldState.invalid, 'w-100 new-task-popup input-shadow': true })}
                          />
                        )}
                      />
                      <RequiredValidation field={'client.last_name'} />
                    </div>
                  </div>
                </>
              )}

              <div className="d-flex align-items-center py-2 flex-wrap">
                <div className="col-md-4 col-12 p-medium">Date Of Birth</div>
                <div className="col-md-8 col-12">
                  <Controller
                    name="client.dob"
                    control={control}
                    render={({ field }) => (
                      <CustomCalendar
                        placeholder="Select"
                        className="w-100 new-task-popup input-shadow"
                        monthNavigator
                        yearNavigator
                        yearRange="1920:3000"
                        id={field.name}
                        value={field.value}
                        onChange={(e) => field.onChange(e.value)}
                        maxDate={new Date()}
                        disabled={!leadDetails?.is_open || clientStatus}
                      />
                    )}
                  />
                </div>
              </div>

              <div className="d-flex align-items-center py-2 flex-wrap">
                <div className="col-md-4 col-12 p-medium">Phone Number</div>
                <div className="col-md-8 col-12">
                  <Controller
                    name="client.phone"
                    control={control}
                    rules={{ validate: { asyncValidation: validatePhone }, minLength: { value: 10, message: 'Min length should be 10' } }}
                    render={({ field }) => (
                      <NumberFormat
                        className="input-shadow w-100 p-2 border-0 number-format"
                        placeholder="Type in"
                        format="(###) ###-####"
                        mask="_"
                        name="phone"
                        keyfilter={'pnum'}
                        onValueChange={(e) => setValue('client.phone', e.value)}
                        onBlur={() => trigger('client.phone')}
                        value={field.value}
                        disabled={!leadDetails?.is_open || clientStatus}
                      />
                    )}
                  />
                  <RequiredValidation field={'client.phone'} />
                </div>
              </div>
              <div className="d-flex align-items-center py-2 flex-wrap">
                <div className="col-md-4 col-12 p-medium">Additional Number</div>
                <div className="col-md-8 col-12">
                  <Controller
                    name="client.additional_phone"
                    control={control}
                    rules={{ minLength: { value: 10, message: 'Min length should be 10' } }}
                    render={({ field }) => (
                      <NumberFormat
                        className="input-shadow w-100 p-2 border-0 number-format"
                        placeholder="Type in"
                        format="(###) ###-####"
                        mask="_"
                        name="phone"
                        keyfilter={'pnum'}
                        onValueChange={(e) => field.onChange(e.value)}
                        value={field.value}
                        disabled={!leadDetails?.is_open || clientStatus}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="d-flex align-items-center py-2 flex-wrap">
                <div className="col-md-4 col-12 p-medium">
                  Email {watch('client.is_create_client_portal') && <span className="danger-text"> *</span>}
                </div>
                <div className="col-md-8 col-12">
                  <Controller
                    name="client.email"
                    control={control}
                    rules={{
                      required: watch('client.is_create_client_portal') ? 'Email is required.' : false,
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: 'Entered value does not match email format',
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <InputText
                        disabled={!leadDetails?.is_open || clientStatus}
                        placeholder="Type in"
                        id={field.name}
                        {...field}
                        className={classNames({ 'p-invalid': fieldState.invalid, 'w-100 new-task-popup input-shadow': true })}
                      />
                    )}
                  />
                  <RequiredValidation field={'client.email'} />
                </div>
              </div>
              <div className="d-flex align-items-center py-2 flex-wrap">
                <div className="col-md-4 p-medium me-sm-0 me-3">Enable Lawft Access</div>
                <div className="col-md-8 col-4 d-flex">
                  <Controller
                    name="client.is_create_client_portal"
                    control={control}
                    render={({ field }) => (
                      <InputSwitch
                        disabled={!leadDetails?.is_open || clientStatus}
                        className="input-shadow input-swich"
                        inputId={field.name}
                        onChange={(e) => field.onChange(e.value)}
                        checked={field.value}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-12 ps-0 pe-0 pe-lg-4 mt-lg-0 mt-4">
            <div className="col-md-6 col-12 p-medium ps-3">
              <span>Address Details</span>
            </div>
            <hr></hr>
            <div className="ps-3 pe-lg-0 pe-3">
              <div className="d-flex align-items-center py-2 flex-wrap">
                <div className="col-md-4 col-12 p-medium">Street</div>
                <div className="col-md-8 col-12">
                  <Controller
                    name="client.street"
                    control={control}
                    render={({ field }) => (
                      <TextSnippetWrapper
                        type="text"
                        disabled={!leadDetails?.is_open || clientStatus}
                        placeholder="Type in"
                        id={field.name}
                        {...field}
                        className="w-100 new-task-popup input-shadow"
                      />
                    )}
                  />
                </div>
              </div>
              <div className="d-flex align-items-center py-2 flex-wrap">
                <div className="col-md-4 col-12 p-medium">City</div>
                <div className="col-md-8 col-12">
                  <Controller
                    name="client.city"
                    control={control}
                    render={({ field }) => (
                      <TextSnippetWrapper
                        type="text"
                        disabled={!leadDetails?.is_open || clientStatus}
                        placeholder="Type in"
                        id={field.name}
                        {...field}
                        className="w-100 new-task-popup input-shadow"
                      />
                    )}
                  />
                </div>
              </div>
              <div className="d-flex align-items-center py-2 flex-wrap">
                <div className="col-md-4 col-12 p-medium">State</div>
                <div className="col-md-8 col-12">
                  <Controller
                    name="client.state"
                    control={control}
                    render={({ field }) => (
                      <Dropdown
                        id={field.name}
                        options={state_list}
                        className="w-100"
                        onChange={(e) => field.onChange(e.value)}
                        value={field.value}
                        placeholder="Select"
                        optionLabel="label"
                        optionValue="value"
                        disabled={!leadDetails?.is_open || clientStatus}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="d-flex align-items-center py-2 flex-wrap">
                <div className="col-md-4 col-12 p-medium">Zip Code</div>
                <div className="col-md-8 col-12">
                  <Controller
                    name="client.zip"
                    control={control}
                    render={({ field }) => (
                      <InputText
                        disabled={!leadDetails?.is_open || clientStatus}
                        keyfilter={'pnum'}
                        maxLength={5}
                        placeholder="Type in"
                        id={field.name}
                        {...field}
                        className="w-100 new-task-popup input-shadow"
                      />
                    )}
                  />
                </div>
              </div>
              <div className="d-flex align-items-center py-2 flex-wrap">
                <div className="col-md-4 col-12 p-medium">Country</div>
                <div className="col-md-8 col-12">
                  <Controller
                    name="client.country"
                    control={control}
                    render={({ field }) => (
                      <Dropdown
                        id={field.name}
                        options={countriesList}
                        className="w-100"
                        onChange={(e) => field.onChange(e.value)}
                        value={'United States'}
                        placeholder="Select"
                        optionLabel="name"
                        optionValue="name"
                        disabled={clientStatus}
                      />
                    )}
                  />
                </div>
              </div>
              <LeadContactCustomField
                watch={watch}
                leadDetails={leadDetails}
                setValue={setValue}
                control={control}
                clientStatus={clientStatus}
                defaultValues={defaultValues}
                contactCustomFieldList={contactCustomFieldList}
              />
            </div>
          </div>
        </div>
        {leadDetails?.is_open && (
          <>
            <hr />
            <div className="d-flex justify-content-end">
              <Button
                className="p-button p-button-primary"
                label="Save Changes"
                onClick={handleSubmit(submit)}
                loading={apiLoading}
                disabled={clientStatus}
              />
            </div>
          </>
        )}
      </div>
    </form>
  );
}
