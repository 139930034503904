import { useState } from 'react';
import { postMail } from 'services/mailbox/mailboxService';
import { useToast } from 'context/ToastContext';
import { toastConstant } from 'constants/toastmessage';

const useSendMail = () => {
  const [loading, setLoading] = useState();
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const { addToast } = useToast();

  const sendMail = async (data) => {
    setLoading(true);
    setError(null);
    setSuccess(null);
    try {
      const response = await postMail(data);
      if (response.status === 200) {
        setSuccess(response);
        addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.MAIL_SENT_SUCCESS);
        return response;
      } else throw new Error('Unexpected Status Code ' + response.status);
    } catch (err) {
      addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, toastConstant.message.MAIL_SENT_FAILED);
      setError(err);
      throw err;
    } finally {
      setLoading(false);
    }
  };
  return { sendMail, loading, success, error };
};

export default useSendMail;
