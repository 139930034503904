export const validateExpiry = (value) => {
  if (!value || value.length !== 7) {
    return 'Expiry date is required';
  }
  const [monthStr, yearStr] = value.split('/');
  const month = parseInt(monthStr, 10);
  const year = parseInt(yearStr, 10);
  if (isNaN(month) || isNaN(year)) {
    return 'Invalid expiry date';
  }
  if (month < 1 || month > 12) {
    return 'Month must be between 01 and 12';
  }
  // Get current date details (using last two digits of the year)
  const now = new Date();
  const currentYear = now.getFullYear();
  const currentMonth = now.getMonth() + 1; // Months are 0-indexed

  if (year < currentYear || (year === currentYear && month < currentMonth)) {
    return 'Expiry date must be in the future';
  }
  return true;
};

export const validateCardNumber = (number) => {
  // Remove spaces from the card number
  const sanitized = number.replace(/\s+/g, '');

  let sum = 0;
  let shouldDouble = false;

  // Iterate through the number from right to left
  for (let i = sanitized.length - 1; i >= 0; i--) {
    let digit = parseInt(sanitized[i], 10);
    if (shouldDouble) {
      digit *= 2;
      if (digit > 9) {
        digit -= 9;
      }
    }
    sum += digit;
    shouldDouble = !shouldDouble;
  }

  // If the sum modulo 10 is zero, the card is valid
  return sum % 10 === 0;
};

export const buildPayload = (paymentDetails, formData, token, userDetails, case_id) => {
  const case_name = paymentDetails?.case_name || paymentDetails?.invoice?.case_name;
  const payment_ref_number = paymentDetails?.invoice_number || paymentDetails?.payment_request_number;
  const billing_contact_id = paymentDetails?.billing_contact_id || paymentDetails?.invoice?.contact_id;
  const billing_contact_name = paymentDetails?.billing_contact_name || paymentDetails?.invoice?.contact_name;
  const isInvoice = paymentDetails?.res_type === 'invoice';

  return {
    token,
    save_card: token && !!formData?.save_card,
    card_on_file: formData?.selectedCard?.id,
    amount: formData?.amount,
    billing_contact_id,
    billing_contact_name,
    case_id,
    case_name,
    deposit_account_id: paymentDetails?.account_id || paymentDetails?.deposit_account_id,
    deposit_account_name: paymentDetails?.deposit_account_name,
    deposit_into: paymentDetails?.account_type || paymentDetails?.deposit_account_type || paymentDetails?.deposit_into,
    description: 'Payment made by client via intuit',
    due_amount: paymentDetails?.due_amount,
    paid_amount: String(formData?.amount),
    payment_for: isInvoice ? 'invoice' : 'payment_request',
    payment_method: 'Credit Card',
    payment_record_amount: Number(formData?.amount),
    payment_record_date: new Date(),
    payment_record_status: Number(formData?.amount) === Number(paymentDetails?.due_amount) ? 'FULL' : 'PARTIAL',
    payment_record_type: 'ONLINE',
    payment_ref_id: paymentDetails?.pk?.split('#')?.[1],
    payment_ref_number,
    payment_ref_qb_id: userDetails?.qb_id,
  };
};
