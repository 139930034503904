import { toastConstant } from 'constants/toastmessage';
import { useToast } from 'context/ToastContext';
import { useEffect, useMemo, useState } from 'react';
import { listclientIntake, shareClientIntakeService } from 'services/intakeforms/clientIntakeService';
import { convertObjectToQuery, fallbackCopyTextToClipboard } from 'utils/utils';
import { getClientUserList } from 'services/case/caseServices';
import { generateFullName } from 'utils/generateFullNameUtils';
import { usePracticeAreaList } from 'hooks/usePracticeAreaList';
// import { getCaseList } from 'services/case/caseServices';

export default function useShareIntakeForms(case_id, pa) {
  const { addToast } = useToast();
  const [clientData, setClientData] = useState([]);
  const { practiceAreaList } = usePracticeAreaList();

  const paSK = useMemo(() => practiceAreaList.filter((v) => v.practice_area_name === pa).map((v) => v?.sk), [practiceAreaList, pa]);

  const getContactList = (caseId) => {
    getClientUserList({
      caseId,
      is_mandatory_lawft_access: false,
      is_team_member_list: false,
      all_contacts: true,
      is_active: true,
      is_include_groups: false,
      active_groups: true,
    })
      .then((response) => setClientData(response.data?.map((v) => ({ fullName: generateFullName(v), ...v }))))
      .catch((error) => console.log(error));
  };

  const shareIntakeFrom = async (params) => {
    try {
      const res = await shareClientIntakeService(convertObjectToQuery(params));
      const lawftLink = res?.data?.lawft_link;

      if (!res?.data?.recipient || res?.data?.recipient === 'no recipient/invalid email') {
        try {
          setTimeout(() => {
            navigator.clipboard.writeText(lawftLink);
          }, 0);
          addToast(false, toastConstant.toasterType.WARNING, toastConstant.api.SUCCESS, toastConstant.message.CLIPBOARD_COPY_SUCCCESS);
        } catch (err) {
          console.log(err);
          fallbackCopyTextToClipboard(lawftLink);
          addToast(false, toastConstant.toasterType.WARNING, toastConstant.api.SUCCESS, toastConstant.message.CLIPBOARD_COPY_SUCCCESS);
        }
      } else {
        addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.SHARE_INTAKE_SUCCESS);
      }
    } catch (err) {
      addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, toastConstant.message.SHARE_INTAKE_FAILED);
    }
  };

  const IntakeSearch = async (keyword = '') => {
    const qs = {
      limit: 20,
      form_type: 'contact_form',
      keyword,
    };
    const list = await listclientIntake(convertObjectToQuery(qs), { practice_area: paSK, within_case: true });
    return list?.data?.contact_forms || [];
  };

  useEffect(() => {
    if (case_id) {
      getContactList(case_id);
    }
  }, []);

  return { clientData, practiceAreaList, IntakeSearch, shareIntakeFrom, getContactList };
}
