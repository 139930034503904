import { postActivityLog } from 'services/generalServices';

export function useActivityLog() {
  const addActivity = (data) => {
    console.log('Activity Log', data);
    postActivityLog(data).catch((error) => console.error(error));
  };

  return { addActivity };
}
