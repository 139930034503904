import { phoneValidation } from 'services/ContactService';
import { contingencyFee, evergreenRetainer, standardRetainer, subscription } from 'constants/index';
import moment from 'moment';

export const formatPaymentStructure = (data) => {
  if ((data?.billing_preferences?.payment_structure).toLowerCase() === subscription) {
    return {
      ...data,
      billing_preferences: {
        ...data.billing_preferences,
        subscriptionDates: [
          data.billing_preferences.start_date ? data.billing_preferences.start_date : null,
          data.billing_preferences.end_date ? data.billing_preferences.end_date : null,
        ],
        frequency_of_payment: 'monthly',
      },
    };
  } else return data;
};

export const nextDate = (frequency, date) => {
  switch (frequency) {
    case 'weekly':
      return new Date(date.setDate(date.getDate() + 7));
    case 'biweekly':
      return new Date(date.setDate(date.getDate() + 14));
    case 'monthly':
      return new Date(date.setMonth(date.getMonth() + 1));
    case 'quarterly':
      return new Date(date.setMonth(date.getMonth() + 3));
    case 'yearly':
      return new Date(date.setFullYear(date.getFullYear() + 1));
    default:
      return new Date();
  }
};

export const validatePhoneNumber = async (value, contact_id) => {
  return new Promise((resolve) => {
    phoneValidation(value, true)
      .then((res) => {
        if (res?.data?.filter((v) => v.contact_id !== contact_id).length > 0) {
          resolve('Phone Number Already Exists');
        } else resolve(true);
      })
      .catch(() => resolve('Invalid Phone Number'));
  });
};

export const prepareBillingData = (formData) => {
  let data = {
    billing_preferences: {
      payment_structure: formData?.payment_structure,
      rate: formData?.rate,
      payment_plan: formData?.payment_plan,
      number_of_payments: formData?.number_of_payments,
      frequency_of_payment: formData?.frequency_of_payment,
      notes: formData?.notes,
      retainer_amount: formData?.retainer_amount,
      custom_rates: formData?.custom_rates,
      contingency_conditions: [
        {
          type: '',
          stage_name: '',
          contingency_at: '',
        },
      ],
    },
  };
  if (formData?.payment_plan) {
    data.billing_preferences.payment_plan_details = {
      payment_starting_date: formData?.payment_plan_details?.payment_starting_date,
      first_payment_amount: formData?.payment_plan_details?.first_payment_amount?.toString(),
      payment_dates: formData?.payment_plan_details?.payment_dates,
    };
  }

  if (formData?.payment_structure?.toLowerCase() === contingencyFee) {
    data.billing_preferences.is_expense = formData?.is_expense;
    data.billing_preferences.litigation = formData?.litigation;
    data.billing_preferences.pre_litigation = formData?.pre_litigation;
  }
  if (formData?.payment_structure?.toLowerCase() === evergreenRetainer || formData?.payment_structure === standardRetainer) {
    data.billing_preferences.retainer_amount = formData?.retainer_amount;
    data.billing_preferences.retainer_date = formData?.retainer_date;
  }

  if (formData?.payment_structure?.toLowerCase() === subscription) {
    data.billing_preferences.start_date = moment(formData?.subscriptionDates?.[0], 'MM/dd/yyyy');
    data.billing_preferences.end_date = moment(formData?.subscriptionDates?.[1], 'MM/dd/yyyy');
    data.billing_preferences.frequency_of_payment = 'monthly';
  }

  return data.billing_preferences;
};
