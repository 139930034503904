import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { TabPanel, TabView } from 'primereact/tabview';
import { Button } from 'primereact/button';
import { AutoComplete } from 'primereact/autocomplete';
import MailList from './Components/MailList';
import MailPreview from './Components/MailPreview';
import MailCreateModal from './Components/MailCreateModal';
import useEmails from './hooks/useEmail';
import useEmailDetails from './hooks/useEmailDetails';
import './mailbox.scss';
import { fetchFiles } from 'services/fileManager/fileManagerServices';
import { useDebounce } from 'hooks/useDebounce';
import CaseMappingModal from './reconciliation/Components/CaseMappingModal';
import ReconcileHeader from './reconciliation/Components/ReconcileHeader';
import { convertToTimezoneFormat } from 'utils/utility_functions/timezone';
import moment from 'moment';
import { UserDetailsContext } from 'context/userDetailsContext';

const MailBox = ({ caseDetails }) => {
  const caseId = caseDetails?.case_id;
  const caseName = caseDetails?.case_description?.case_name;
  const isLead = caseDetails?.is_lead ? caseDetails?.is_lead : false;
  const [activeIndex, setActiveIndex] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [selectedEmailThreadPkSk, setSelectedEmailThreadPkSk] = useState({
    pk: null,
    sk: null,
  });
  const [show, setShow] = useState(false);
  const [readEmails, setReadEmails] = useState({});
  const [existingFiles, setExistingFiles] = useState([]);
  const [query, setQuery] = useState('');
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [showEmailMapping, setShowEmailMapping] = useState(false);
  const [dateFilter, setDateFilter] = useState('');

  const userContext = useContext(UserDetailsContext);
  const { emailDetails, setEmailDetails } = useEmailDetails(selectedEmailThreadPkSk, setReadEmails);

  const pickExtractedDate = (dateArray) => {
    return {
      mail_date_lower: dateArray?.[0]
        ? convertToTimezoneFormat(moment(dateArray[0]).startOf('day'), userContext?.userDetails.timezone)
        : '',
      mail_date_upper: dateArray?.[1]
        ? convertToTimezoneFormat(moment(dateArray[1] ? dateArray[1] : dateArray[0]).endOf('day'), userContext?.userDetails.timezone)
        : '',
    };
  };

  const { mail_date_lower, mail_date_upper } = pickExtractedDate(dateFilter);

  const {
    emails,
    setEmails,
    handleLoadMore,
    loading: emailLoading,
    hasNext,
    paginationLoading,
    refreshEmails,
  } = useEmails(caseId, mail_date_lower, mail_date_upper, searchKeyword, setReadEmails, activeIndex === 1);

  const handleSearchKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      setSearchKeyword(e.target.value);
    }
  };

  const handleEmailData = (data) => {
    setEmailDetails((prev) => [...prev, data]);
  };

  const handleSentEmailList = (data) => {
    if (activeIndex === 1) {
      setEmails((prev) => [data, ...prev]);
    }
  };

  const tabs = [
    { header: 'Inbox', filter: (email) => email },
    { header: 'Sent', filter: (email) => email?.is_sent },
  ];

  const debouncedQuery = useDebounce(query, 500);

  const getFiles = (obj) => {
    fetchFiles(obj)
      .then(async (response) => {
        let arr = response?.data?.data;
        const filesData = arr
          ?.filter((data) => data.entry_type === 'file')
          .map((item) => {
            return {
              ...item,
              label: item.display_name,
              value: {
                file_path: item.file_path,
                size: item.size,
                display_name: item.display_name,
                file_name: item.file_name,
                file_type: item.file_type,
                created_by: item.created_by_details,
                is_new_file: true,
                sk: item.sk,
                pk: item.pk,
              },
            };
          });
        // setExistingFiles(filesData);
        setExistingFiles((prev) => {
          if (prev.length > 0 && prev[0]?.case_id !== obj.case_id) {
            return filesData;
          }

          const nonListedItems = [];
          for (const data of filesData) {
            const ifNotAdded = prev.find((prv) => prv?.sk === data?.sk);
            if (!ifNotAdded) {
              nonListedItems.push(data);
            }
          }
          return [...prev, ...nonListedItems];
        });
      })
      .catch((error) => {});
  };

  useEffect(() => {
    if (!caseDetails?.case_id) return;

    const obj = {
      case_id: caseDetails.case_id,
      selectedFolder: '',
      selectedType: '',
      tag: '',
      query: debouncedQuery,
      pageLimit: 10,
      pagination_direction: '',
      is_read: '',
    };

    getFiles(obj);
  }, [caseDetails?.case_id, debouncedQuery]);

  useEffect(() => {
    const dropdownClasses = document?.querySelectorAll('.p-multiselect-token');
    for (const dropdownElement of dropdownClasses) {
      const dropLabelElement = dropdownElement?.querySelector('.p-multiselect-token-label')?.innerHTML;

      if (dropLabelElement?.trim()?.length === 0) {
        dropdownElement.style.display = 'none';
      }
    }
  }, [existingFiles]);

  return (
    <>
      {/* <div className="d-flex py-2">
        <Button
          type="button"
          className="btn-outline p-button-primary ms-2 mb-2"
          label="Map to Case/Lead"
          disabled={!selectedEmails?.length > 0}
          onClick={() => setShowEmailMapping(true)}
        />
        <Button
          label="Compose"
          type="button"
          className="btn-outline p-button-primary ms-auto mb-2"
          icon="pi pi-plus"
          onClick={() => setShow(true)}
        />
      </div> */}
      <ReconcileHeader
        isGlobalMail={false}
        caseValue={caseId}
        dateFilter={dateFilter}
        onDateChange={setDateFilter}
        refreshEmails={refreshEmails}
        setShowComposeModal={setShow}
        selectedEmails={selectedEmails}
        setSelectedEmails={setSelectedEmails}
        setShow={setShowEmailMapping}
        setSelectedEmailThreadPkSk={setSelectedEmailThreadPkSk}
      />

      <div className="row mail-list-tab-view mb-3 max-height-90">
        <div className="col-lg-4 col-12 p-0 shadow-middle">
          <TabView
            activeIndex={activeIndex}
            onTabChange={(e) => {
              setActiveIndex(e.index);
              setSelectedEmailThreadPkSk({ pk: null, sk: null });
            }}
          >
            {tabs.map((tab) => (
              <TabPanel header={tab.header} key={`tab-${tab.header}`}>
                <div className="p-3">
                  <span className="p-input-icon-left input-shadow w-100">
                    <AutoComplete
                      inputClassName="email-search w-100"
                      className="input-shadow w-100"
                      value={searchKeyword}
                      onChange={(e) => {
                        setSearchKeyword(e.target.value);
                        setSelectedEmailThreadPkSk({});
                      }}
                      placeholder="Search"
                      onKeyPress={(e) => handleSearchKeyPress(e)}
                    />
                    {/* #todo: add reconciliation button for mapping wrongly mapped emails */}
                  </span>
                </div>
                {emailLoading ? (
                  <div className="d-flex justify-content-center align-items-center">
                    <i className="pi pi-spin pi-spinner" />
                    Loading {tab.header} Emails...
                  </div>
                ) : (
                  <MailList
                    allMails={emails}
                    selectedEmailThreadPk={selectedEmailThreadPkSk?.pk}
                    setSelectedEmailThreadPkSk={setSelectedEmailThreadPkSk}
                    handleLoadMore={handleLoadMore}
                    hasNext={hasNext}
                    paginationLoading={paginationLoading}
                    readEmails={readEmails}
                    setReadEmails={setReadEmails}
                    multiple
                    loading={false}
                    selectedEmails={selectedEmails}
                    setSelectedEmails={setSelectedEmails}
                  />
                )}
              </TabPanel>
            ))}
          </TabView>
        </div>

        <div className="col-lg-8 col-12">
          {show && (
            <MailCreateModal
              show={show}
              setShow={setShow}
              caseId={caseId}
              isLead={isLead}
              caseName={caseName}
              handleSentEmailList={handleSentEmailList}
            />
          )}
          {selectedEmailThreadPkSk?.pk ? (
            <MailPreview
              previewMail={emailDetails}
              caseDetails={caseDetails}
              existingFiles={existingFiles}
              setQuery={setQuery}
              handleEmailData={handleEmailData}
            />
          ) : (
            <div className="shadow-middle p-3 h-100">
              <div className="d-flex flex-column align-items-center justify-content-center h-100 F-size16 start-conversation">
                {/* <i className="icon-chat" /> */}
                <i className="bi bi-envelope" />
                <span>Select an Email</span>
              </div>
            </div>
          )}
        </div>
      </div>
      <CaseMappingModal
        show={showEmailMapping}
        setShow={setShowEmailMapping}
        selectedEmails={selectedEmails}
        setSelectedEmails={setSelectedEmails}
        setSelectedEmailThreadPkSk={setSelectedEmailThreadPkSk}
        refreshEmails={refreshEmails}
        handleReconcile={refreshEmails}
      />
    </>
  );
};

MailBox.propTypes = {
  caseDetails: PropTypes.shape({
    case_id: PropTypes.string.isRequired,
  }).isRequired,
};

export default MailBox;
