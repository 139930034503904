import { useContext, useEffect, useState } from 'react';

import { Dropdown } from 'primereact/dropdown';

import { listUsers } from 'services/calendarServices';
import { getActivityLogs } from 'services/activitylogServices';

import { generateFullName } from 'utils/generateFullNameUtils';

import ActivityLogTable from './ActivityLogTable';
import GlobalLoader from 'components/GlobalLoader/GlobalLoader';
import { sortByKey } from 'utils/utils';
import moment from 'moment';
import { UserDetailsContext } from 'context/userDetailsContext';
import { getTimestampDate } from 'utils/utility_functions/timezone';

const ActivityLog = (props) => {
  const userContext = useContext(UserDetailsContext);

  const [activityLog, setActivityLog] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [selectedUserList, setSelectedUserList] = useState(null);
  const [endDate, setEndDate] = useState(moment().tz(userContext?.userDetails?.timezone));

  const [currentDateToDisplay, setCurrentDateToDisplay] = useState({
    start: moment().tz(userContext?.userDetails?.timezone),
    end: moment().tz(userContext?.userDetails?.timezone),
  });

  const isCaseSpecific = props?.isCaseSpecific ?? false;
  const isFromTeamManagement = props?.isFromTeamManagement ?? false;
  const isFromDashBoard = props?.isFromDashBoard ?? false;
  const isFromReports = props?.isFromReports ?? false;
  const userId = props?.userDetails?.client_cognito_username;

  let todayDate = moment().tz(userContext?.userDetails?.timezone);

  useEffect(() => {
    loadUsers();
  }, []); // eslint-disable-line

  const loadUsers = (case_id = props?.caseDetails?.case_id) => {
    listUsers(case_id)
      .then((response) => setUsersList(sortByKey(response.data, 'first_name')))
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getDataOfActivityLog('initial');
  }, [selectedUserList]);

  const fetchActivityLogData = (backward = false, forward = false) => {
    if (backward) {
      getDataOfActivityLog('backward');
    } else if (forward) {
      let currentDate = moment().tz(userContext?.userDetails?.timezone);

      if (!endDate.isSameOrAfter(currentDate, 'day')) {
        getDataOfActivityLog('forward');
      }
    }
  };

  function getDataOfActivityLog(type) {
    let tempStartDate = endDate.clone();
    let tempEndDate = endDate.clone();

    if (type === 'forward') {
      tempStartDate.add(1, 'days');
      tempEndDate.add(2, 'days');
    } else if (type === 'backward') {
      tempStartDate.subtract(3, 'days');
      tempEndDate.subtract(2, 'days');
    } else if (type === 'initial') {
      tempStartDate.subtract(1, 'days');
    }

    let tempFormattedStartDate = getTimestampDate(tempStartDate, userContext?.userDetails?.timezone, true);
    let tempFormattedEndDate = getTimestampDate(tempStartDate, userContext?.userDetails?.timezone, true);

    setEndDate(tempEndDate);
    setCurrentDateToDisplay({
      start: moment(tempStartDate).tz(userContext?.userDetails?.timezone),
      end: moment(tempEndDate).tz(userContext?.userDetails?.timezone),
    });
    setActivityLog([]);

    let data = {
      key: '',
      status: '',
      type: '',
      sub_type: '',
      user_id: isFromTeamManagement ? userId : selectedUserList?.client_cognito_username ? selectedUserList?.client_cognito_username : '',
      case_id: isCaseSpecific ? props?.caseDetails?.case_id : '',
      start_timestamp: tempFormattedStartDate,
      end_timestamp: tempFormattedEndDate,
      pagination_limit: 1000,
    };

    getActivityLogData(data);
  }

  function getActivityLogData(data) {
    getActivityLogs(data)
      .then((response) => {
        setActivityLog([...response.data]);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const assignToItemTemplate = (option) => {
    return (
      <>
        {option?.firm_user_id && <i className="fas fa-user-tie me-2"></i>}
        {generateFullName(option)}
      </>
    );
  };

  return (
    <div>
      {!isFromDashBoard && <GlobalLoader />}
      <div className="activity-log-wrap bg-white">
        {!isFromTeamManagement && !isFromReports && <h5 className="F-size14 mb-3 black-600 text-bold">Activity Log</h5>}
        {isFromReports && <h5 className="F-size14 mb-3 black-600 text-bold">Activity Log</h5>}
        <div className="d-flex justify-content-between align-items-center flex-wrap">
          <div className="d-flex align-items-center">
            <div className="me-3 black-800 text-bold">
              {currentDateToDisplay.end.format('MMM')} {currentDateToDisplay.start.format('DD')}-{currentDateToDisplay.end.format('DD')}
            </div>

            <div
              className="rounded me-1 pointer slider-ctrl"
              onClick={(e) => {
                fetchActivityLogData(true, false);
              }}
            >
              <i className="pi pi-angle-left p-1"></i>
            </div>
            <div
              className={
                currentDateToDisplay.end.isSameOrAfter(todayDate, 'day')
                  ? 'rounded ms-1 pointer slider-ctrl disabled-element '
                  : 'rounded ms-1 pointer slider-ctrl'
              }
              onClick={(e) => fetchActivityLogData(false, true)}
            >
              <i className="pi pi-angle-right p-1"></i>
            </div>
          </div>
          {!isFromTeamManagement && (
            <div className="d-flex flex-column my-2  datatable-responsive">
              <Dropdown
                placeholder={props?.caseDetails?.case_id ? 'By Case User' : 'By User'}
                optionLabel={(v) => generateFullName(v)}
                onChange={(e) => setSelectedUserList(e.value)}
                options={usersList}
                value={selectedUserList}
                className="mx-1 p-datatable-responsive"
                filter
                showClear
                style={{ minWidth: 160 }}
                itemTemplate={assignToItemTemplate}
              />
            </div>
          )}
        </div>
        <ActivityLogTable
          activityLog={activityLog}
          isCaseSpecific={isCaseSpecific}
          isLead={props?.isLead}
          isFromDashBoard={props?.isFromDashBoard}
        />
      </div>
    </div>
  );
};

export default ActivityLog;
