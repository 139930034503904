import { useEffect, useState } from 'react';
import { confirmDialog } from 'primereact/confirmdialog';

import { deleteClientCardService, listClientCardsService, makeIntuitPaymentService } from 'services/Billing/BillingService';
import { axiosInstance } from 'common/apiHandler';
import { toastConstant } from 'constants/toastmessage';
import { useToast } from 'context/ToastContext';
import { useUserDetailsContext } from 'context/userDetailsContext';
import { buildPayload } from '../helpers';

export default function useIntuitPayment(paymentDetails, isFromFirm) {
  const { addToast } = useToast();
  const { userDetails } = useUserDetailsContext();

  const [cards, setCards] = useState([]);
  const [showCardList, setShowCardList] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);

  const case_id = paymentDetails?.case_id || paymentDetails?.invoice?.case_id;

  const getCardList = () => {
    listClientCardsService(userDetails?.qb_id, userDetails?.contact_id)
      .then((res) => {
        setCards(res?.data);
        setShowCardList(res?.data?.length > 0);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const generateCardToken = async (data) => {
    try {
      const response = await axiosInstance.post(process.env.REACT_APP_INTUIT_URL, data);
      return { isError: false, data: response?.data };
    } catch (error) {
      return { isError: true, error };
    }
  };

  const executePayment = (payload, sk, callBack) => {
    makeIntuitPaymentService(payload, sk)
      .then((res) => {
        if (res?.data?.body === 'Payment Successful') {
          typeof callBack === 'function' && callBack();
          addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.PAYMENT_SUCCESS);
        } else {
          addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, toastConstant.message.PAYMENT_FAILED);
        }
        console.log(res);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const makePayment = async (formData, callBack) => {
    // If a saved card is selected, no token generation is needed.
    if (formData?.selectedCard) {
      const payload = buildPayload(paymentDetails, formData, undefined, userDetails, case_id);
      executePayment(payload, paymentDetails?.sk, callBack);
      return;
    }

    // Process a new card: extract expiry details and generate a token.
    const [month, year] = formData?.expiry?.split('/')?.map((part) => part?.trim());
    const card = {
      ...(formData?.card || {}),
      expMonth: month,
      expYear: year,
      number: formData?.card?.number?.replace(/\s/g, ''),
    };

    const response = await generateCardToken({ card });
    if (response.isError) {
      console.error(response.error);
      addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, response?.error?.response?.data?.errors?.[0]?.message);
      return;
    }

    const token = response?.data?.value;
    const payload = buildPayload(paymentDetails, formData, token, userDetails, case_id);
    executePayment(payload, paymentDetails?.sk, callBack);
  };

  const deleteCard = (card_id) => {
    confirmDialog({
      message: 'Are you sure you want to delete this card?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        deleteClientCardService(userDetails?.qb_id, card_id)
          .then((res) => {
            if (res?.data?.body === 'Client Card Removed Successfully') {
              getCardList();
              addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.DELETE_CARD_SUCCESS);
            } else {
              addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, toastConstant.message.DELETE_CARD_FAILED);
            }
          })
          .catch((err) => {
            console.log(err);
            addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, toastConstant.message.DELETE_CARD_FAILED);
          });
      },
      reject: () => null,
      closable: false,
    });
  };

  useEffect(() => {
    !isFromFirm && getCardList();
  }, []);

  return {
    cards,
    showCardList,
    selectedCard,
    deleteCard,
    makePayment,
    setSelectedCard,
    setShowCardList,
  };
}
