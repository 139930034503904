import React, { useState, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import { createPaymentRefund, getPaymentRecord } from 'services/billingServices';
import { format } from 'date-fns';
import { useToast } from 'context/ToastContext';
import { toastConstant } from 'constants/toastmessage';
import { Dropdown } from 'primereact/dropdown';
import { paymentMethods } from 'constants/dropdownOptions';
import { ModalHeader } from 'shared/ModalHeader';
import TextSnippetWrapper from 'components/TextSnippetWrapper';
import CustomCalendar from 'components/CustomCalendar/CustomCalendar';

export const RefundPayment = (props) => {
  const [amount, setAmount] = useState();
  const [fullRefund, setFullRefund] = useState(false);
  const [refundDate, setRefundDate] = useState();
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState();
  const [notes, setNotes] = useState();
  let [formErrors, setFormError] = useState({
    amount: '',
    refundDate: '',
  });
  const [loading, setLoading] = useState(false);

  const [recordDetails, setRecordDetails] = useState();
  const { addToast } = useToast();

  useEffect(() => {
    if (props.selectedRecord?.case_id) {
      setAmount();
      setRefundDate();
      getPaymentRecord(props.selectedRecord.sk)
        .then((response) => {
          if (response?.data) {
            setRecordDetails({ ...response.data });
          }
        })
        .catch((err) => {});
    }
  }, [props.selectedRecord]);

  const onChangeRefundFull = (e) => {
    setFullRefund(e.target.checked);
    if (e.target.checked) {
      let payment_record_amount = recordDetails?.payment_record_amount;
      payment_record_amount = payment_record_amount ? parseFloat(payment_record_amount) : 0;
      setAmount(payment_record_amount);
    }
  };

  const onSubmit = () => {
    if (validateForm()) {
      setLoading(true);
      let data = {
        case_id: recordDetails.case_id,
        case_name: recordDetails.case_name,
        billing_contact_id: recordDetails.billing_contact_id,
        billing_contact_name: recordDetails.billing_contact_name,
        description: notes ? notes : '',
        deposit_into: recordDetails.deposit_into,
        deposit_account_name: recordDetails.deposit_account_name,
        paid_amount: recordDetails.paid_amount,
        payment_record_amount: amount,
        due_amount: recordDetails.due_amount,
        amount: recordDetails.amount,
        payment_record_date: refundDate ? format(refundDate, 'yyyy-MM-dd') : '',
        payment_method: selectedPaymentMethod,
        payment_ref_id: recordDetails.payment_ref_id,
        payment_ref_number: recordDetails.payment_ref_number,
        payment_record_status: recordDetails.payment_record_status,
        payment_record_type: 'REFUND',
        transaction_details: recordDetails.transaction_details,
        payment_record_ref: recordDetails.payment_record_id,
        payment_for: recordDetails?.payment_for,
      };
      createPaymentRefund(data, recordDetails.sk, props.isLeadSpecific ? props?.isLeadSpecific : recordDetails?.is_lead)
        .then((response) => {
          setLoading(false);
          addToast(
            false,
            toastConstant.toasterType.SUCCESS,
            toastConstant.api.SUCCESS,
            toastConstant.message.REFUND_REQUEST_CREATE_SUCCESS
          );
          props.onSuccess();
        })
        .catch((err) => {
          setLoading(false);
          addToast(
            false,
            toastConstant.toasterType.ERROR,
            toastConstant.api.FAILED,
            err?.response?.data || toastConstant.message.REFUND_REQUEST_CREATE_FAILED
          );
        });
    }
  };

  const validateForm = () => {
    let payment_record_amount = recordDetails?.payment_record_amount;
    payment_record_amount = payment_record_amount ? parseFloat(payment_record_amount) : 0;
    let isValid = true;
    if (!amount || (amount && amount < 0)) {
      formErrors.amount = 'Required Valid Amount';
      isValid = false;
    }
    if (amount && amount > payment_record_amount) {
      formErrors.amount = "Refund amount can't be greater than payment record amount";
      isValid = false;
    }
    if (!refundDate) {
      formErrors.refundDate = 'Required';
      isValid = false;
    }
    if (!selectedPaymentMethod) {
      isValid = false;
      formErrors.payment_method = 'Required';
    }
    setFormError({ ...formErrors });
    return isValid;
  };
  return (
    <Dialog header={ModalHeader} visible={props.show} onHide={props.onHide} className="create-msg-popup" closable={false}>
      <div className="row">
        <div className="d-flex justify-content-between align-items-center mt-3 mb-3">
          <div className="modal-heading F-size16 text-bold">Request Refund</div>
        </div>
        <div className="d-flex align-items-top py-2 flex-wrap">
          <div className="col-12 col-sm-4 pt-2">
            Refund Amount
            <span className="text-danger">*</span>
          </div>

          <div className="col-12 col-sm-8">
            <InputNumber
              value={amount}
              onValueChange={(e) => {
                setAmount(e.target.value);
                formErrors.amount = '';
                setFormError({ ...formErrors });
              }}
              className="input-shadow w-100"
              mode="currency"
              currency="USD"
              locale="en-US"
            />
            {formErrors && formErrors.amount && <span className="text-danger">{formErrors.amount}</span>}
            <div className="mt-3">
              <label className="switch">
                <input type="checkbox" checked={fullRefund} name="weekends" onChange={onChangeRefundFull} />{' '}
                <span className="slider round"></span>
              </label>
              <span className="ms-2">Refund full amount</span>
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center py-2">
          <div className="col-12 col-sm-4 p-medium">
            Payment Method
            <span className="text-danger">*</span>
          </div>
          <div className="col-12 col-sm-8">
            <Dropdown
              className="input-shadow w-100 border-0 p-0"
              options={paymentMethods}
              value={selectedPaymentMethod}
              onChange={(e) => {
                setSelectedPaymentMethod(e.value);
                formErrors.payment_method = '';
                setFormError({ ...formErrors });
              }}
              filter
            />
            {formErrors && formErrors.payment_method && <span className="text-danger">{formErrors.payment_method}</span>}
          </div>
        </div>
        <div className="d-flex align-items-center py-2">
          <div className="col-12 col-sm-4">
            Date
            <span className="text-danger">*</span>
          </div>
          <div className="col-12 col-sm-8">
            <CustomCalendar
              value={refundDate}
              onChange={(e) => {
                setRefundDate(e.value);
                formErrors.refundDate = '';
                setFormError({ ...formErrors });
              }}
              className="input-shadow w-100 border-0"
            />
            {formErrors && formErrors.refundDate && <span className="text-danger">{formErrors.refundDate}</span>}
          </div>
        </div>
        <div className="d-flex align-item-center py-2">
          <div className="col-12 col-sm-4">Notes</div>
          <div className="col-12 col-sm-8">
            <TextSnippetWrapper
              type="textarea"
              value={notes}
              onChange={(e) => {
                setNotes(e.target.value);
              }}
              className="input-shadow w-100 border-0 p-2"
            />
          </div>
        </div>
      </div>
      <hr></hr>
      <div className="row">
        <div className="col-12 d-flex justify-content-end mt-2">
          <Button
            className="p-button-secondary outline"
            label="Cancel"
            onClick={() => {
              props.onHide();
            }}
          />
          <Button className="p-button-secondary ms-2" label={'Refund'} onClick={onSubmit} loading={loading} />
        </div>
      </div>
    </Dialog>
  );
};
