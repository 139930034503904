import React from 'react';

import ViewChanger from './ViewChanger';
import CreateDocument from './CreateDocument';
import UploadFileButton from './UploadFileButton';
import CreateFolderButton from './CreateFolderButton';

import { useUserDetailsContext } from 'context/userDetailsContext';
import constants from 'constants/index';
import { useFileManagerContext } from 'modules/file_manager/context/FileManagerContext';
import Discrepancy from './Discrepancy';
import ExtractEntities from './ExtractEntities';
import CreateTimeline from './CreateTimeline';

function Menu() {
  const { isGlobalFileManager, isCaseFileManager, isLeadFileManager, isClientFileManager } = useFileManagerContext();
  const { getPermission, checkSubPermission } = useUserDetailsContext();

  return (
    <div className="d-flex align-items-center justify-content-between">
      <ViewChanger />
      {isCaseFileManager || isLeadFileManager ? <Discrepancy /> : null}
      {getPermission('cases') === constants.permissions.WRITE && (
        <div className="font-bold d-flex align-items-center justify-content-end">
          {checkSubPermission('document_assembly') && <CreateDocument />}
          {!isGlobalFileManager && <CreateFolderButton />}
        </div>
      )}
      <UploadFileButton />
      {!isGlobalFileManager && !isClientFileManager && <ExtractEntities />}
      {!isGlobalFileManager && !isClientFileManager && <CreateTimeline />}
    </div>
  );
}

export default Menu;
