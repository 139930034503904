import GlobalTooltip from 'components/GlobalTooltip';
import React from 'react';

export default function CardList(props) {
  const { cards, setSelectedCard, selectedCard, deleteCard } = props;
  return (
    <React.Fragment>
      <div className="row">
        {cards?.map((card, index) => (
          <div className="col-md-6 mb-3">
            <div
              className={`input-shadow bg-white position-relative p-3 pointer payment-card ${
                selectedCard?.id === card?.id ? 'selected-card' : ''
              }`}
              key={index}
              onClick={() => setSelectedCard(card)}
            >
              <div className="d-flex flex-row align-items-center">
                <i className="pi pi-credit-card ps-2 pe-3" style={{ fontSize: '1.3rem' }} />
                <div>
                  <div className="d-flex flex-row gap-10">
                    <p className="mb-0">{card?.cardType}</p>
                    <p className="mb-0">{card?.number}</p>
                  </div>
                  <p className="card-text">
                    Expires {card?.expMonth}/{card?.expYear}
                  </p>
                </div>
                <GlobalTooltip
                  tooltip="Delete card"
                  style={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                  }}
                >
                  <i
                    className="icon-delete F-size18 cursor-pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedCard(null);
                      deleteCard(card?.id);
                    }}
                  />
                </GlobalTooltip>
              </div>
            </div>
          </div>
        ))}
        {cards?.length === 0 && <p>No saved cards.</p>}
      </div>
    </React.Fragment>
  );
}
