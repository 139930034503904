import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import NumberFormat from 'react-number-format';

import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputSwitch } from 'primereact/inputswitch';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';

import InputErrorMessage from 'components/UI/InputErrorMessage/InputErrorMessage';
import CustomFields from 'modules/lead/AddLead/components/ContactForm/CustomFields';
import { PI_PROVIDERS_TYPE } from 'constants/index';
import { titles } from 'constants/dropdownOptions';
import { getNestedProperty } from 'utils/utils';
import { updateContactDetails } from 'services/ContactService';
import { useToast } from 'context/ToastContext';
import { toastConstant } from 'constants/toastmessage';
import { validatePhoneNumber } from 'modules/lead/helpers/helpers';
import { useUserDetailsContext } from 'context/userDetailsContext';
import TextSnippetWrapper from 'components/TextSnippetWrapper';
import CustomCalendar from 'components/CustomCalendar/CustomCalendar';

export default function ContactDetails(props) {
  const { businessRole, relationRole, leadDetails, loadLeadDetails } = props;
  const {
    control,
    formState: { errors, defaultValues },
    watch,
    reset,
    handleSubmit,
    setValue,
  } = useForm();

  const { addToast } = useToast();
  const { checkSubPermission } = useUserDetailsContext();
  const [apiLoading, setApiLoading] = useState(false);
  const contactStatus = leadDetails?.contact?.some((item) => item?.status === 'ARCHIVED');

  useEffect(() => {
    if (leadDetails) {
      reset({ contact: leadDetails?.contact?.[0] });
    }
  }, [leadDetails]);

  const RequiredValidation = ({ field, message = 'Field Required' }) => {
    return getNestedProperty(errors, field) ? (
      <InputErrorMessage>{getNestedProperty(errors, field)?.message || message}</InputErrorMessage>
    ) : null;
  };

  const validatePhone = async (value) => {
    return validatePhoneNumber(value, leadDetails?.contact?.[0]?.contact_id);
  };

  const updateContactData = (formData) => {
    setApiLoading(true);
    updateContactDetails(formData?.contact, true)
      .then(() => {
        loadLeadDetails();
        addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.LEAD_CONTACT_SAVED);
      })
      .catch(() => {
        addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, toastConstant.message.LEAD_CONTACT_SAVE_FAILED);
      })
      .finally(() => setApiLoading(false));
  };

  return (
    <>
      <h5 className="caption-bold">Person Who Made First Contact</h5>
      <div className="shadow-middle bg-white lead-data-box p-3">
        <div className="d-flex align-items-center py-2 flex-wrap">
          <div className="col-md-4 col-12 p-medium">
            Title
            <span className="danger-text"> *</span>
          </div>
          <div className="col-md-8 col-12">
            {defaultValues?.contact?.contact_type === 'business' || PI_PROVIDERS_TYPE.includes(defaultValues?.contact?.contact_type) ? (
              <Controller
                name="contact.title"
                control={control}
                rules={{ required: 'Title is required.' }}
                render={({ field }) => (
                  <TextSnippetWrapper
                    type="text"
                    disabled={!leadDetails?.is_open || contactStatus}
                    placeholder="Select"
                    id={field.name}
                    {...field}
                    className="w-100 new-task-popup input-shadow"
                  />
                )}
              />
            ) : (
              <Controller
                name="contact.title"
                control={control}
                rules={{ required: 'Title is Required' }}
                render={({ field }) => (
                  <Dropdown
                    id={field.name}
                    options={titles}
                    className="w-100"
                    onChange={(e) => field.onChange(e.value)}
                    value={field.value}
                    placeholder="Select"
                    disabled={!leadDetails?.is_open || contactStatus}
                  />
                )}
              />
            )}
            <RequiredValidation field={'contact.title'} />
          </div>
        </div>

        {!Boolean(
          defaultValues?.contact?.contact_type === 'business' || PI_PROVIDERS_TYPE.includes(defaultValues?.contact?.contact_type)
        ) && (
          <>
            <div className="d-flex align-items-center py-2 flex-wrap">
              <div className="col-md-4 col-12 p-medium">
                First Name
                <span className="danger-text"> *</span>
              </div>
              <div className="col-md-8 col-12">
                <Controller
                  name="contact.first_name"
                  control={control}
                  rules={{ required: 'First Name is required.' }}
                  render={({ field, fieldState }) => (
                    <TextSnippetWrapper
                      type="text"
                      disabled={!leadDetails?.is_open || contactStatus}
                      placeholder="Type in"
                      id={field.name}
                      {...field}
                      className={classNames({ 'p-invalid': fieldState.invalid, 'w-100 new-task-popup input-shadow': true })}
                    />
                  )}
                />
                <RequiredValidation field={'contact.first_name'} />
              </div>
              <></>
            </div>
            <div className="d-flex align-items-center py-2 flex-wrap">
              <div className="col-md-4 col-12 p-medium">Middle Name</div>
              <div className="col-md-8 col-12">
                <Controller
                  name="contact.middle_name"
                  control={control}
                  render={({ field }) => (
                    <TextSnippetWrapper
                      type="text"
                      disabled={!leadDetails?.is_open || contactStatus}
                      placeholder="Type in"
                      id={field.name}
                      {...field}
                      className="w-100 new-task-popup input-shadow"
                    />
                  )}
                />
              </div>
            </div>
            <div className="d-flex align-items-center py-2 flex-wrap">
              <div className="col-md-4 col-12 p-medium">
                Last Name
                <span className="danger-text"> *</span>
              </div>
              <div className="col-md-8 col-12">
                <Controller
                  name="contact.last_name"
                  control={control}
                  rules={{ required: 'Last Name is required.' }}
                  render={({ field, fieldState }) => (
                    <TextSnippetWrapper
                      type="text"
                      disabled={!leadDetails?.is_open || contactStatus}
                      placeholder="Type in"
                      id={field.name}
                      {...field}
                      className={classNames({ 'p-invalid': fieldState.invalid, 'w-100 new-task-popup input-shadow': true })}
                    />
                  )}
                />
                <RequiredValidation field={'contact.last_name'} />
              </div>
            </div>
          </>
        )}

        <div className="d-flex align-items-center py-2 flex-wrap">
          <div className="col-md-4 col-12 p-medium">Date Of Birth</div>
          <div className="col-md-8 col-12">
            <Controller
              name="contact.dob"
              control={control}
              render={({ field }) => (
                <CustomCalendar
                  placeholder="Select"
                  className="w-100 new-task-popup input-shadow"
                  monthNavigator
                  yearNavigator
                  yearRange="1920:3000"
                  id={field.name}
                  value={field.value}
                  onChange={(e) => field.onChange(e.value)}
                  maxDate={new Date()}
                  disabled={!leadDetails?.is_open || contactStatus}
                />
              )}
            />
          </div>
        </div>
        <div className="d-flex align-items-center py-2 flex-wrap">
          <div className="col-md-4 col-12 p-medium">Phone Number</div>
          <div className="col-md-8 col-12">
            <Controller
              name="contact.phone"
              control={control}
              rules={{ validate: { asyncValidation: validatePhone }, minLength: { value: 10, message: 'Min length should be 10' } }}
              render={({ field }) => (
                <NumberFormat
                  className="input-shadow w-100 p-2 border-0 number-format"
                  placeholder="Type in"
                  format="(###) ###-####"
                  mask="_"
                  name="phone"
                  keyfilter={'pnum'}
                  onValueChange={(e) => setValue('contact.phone', e.value)}
                  value={field.value}
                  disabled={!leadDetails?.is_open || contactStatus}
                />
              )}
            />
            <RequiredValidation field={'contact.phone'} />
          </div>
        </div>
        <div className="d-flex align-items-center py-2 flex-wrap">
          <div className="col-md-4 col-12 p-medium">Additional Number</div>
          <div className="col-md-8 col-12">
            <Controller
              name="contact.additional_phone"
              control={control}
              rules={{ minLength: { value: 10, message: 'Min length should be 10' } }}
              render={({ field }) => (
                <NumberFormat
                  className="input-shadow w-100 p-2 border-0 number-format"
                  placeholder="Type in"
                  format="(###) ###-####"
                  mask="_"
                  name="phone"
                  keyfilter={'pnum'}
                  onValueChange={(e) => field.onChange(e.value)}
                  value={field.value}
                  disabled={!leadDetails?.is_open || contactStatus}
                />
              )}
            />
          </div>
        </div>
        <div className="d-flex align-items-center py-2 flex-wrap">
          <div className="col-md-4 col-12 p-medium">
            Email {watch('contact.is_create_client_portal') && <span className="danger-text"> *</span>}
          </div>
          <div className="col-md-8 col-12">
            <Controller
              name="contact.email"
              control={control}
              rules={{
                required: watch('contact.is_create_client_portal') ? 'Email is required.' : false,
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'Entered value does not match email format',
                },
              }}
              render={({ field, fieldState }) => (
                <InputText
                  disabled={!leadDetails?.is_open || contactStatus}
                  placeholder="Type in"
                  id={field.name}
                  {...field}
                  className={classNames({ 'p-invalid': fieldState.invalid, 'w-100 new-task-popup input-shadow': true })}
                />
              )}
            />
          </div>
        </div>
        <div className="d-flex align-items-center py-2 flex-wrap">
          <div className="col-md-4 col-12 p-medium">Enable Lawft Access</div>
          <div className="col-md-8 col-12">
            <Controller
              name="contact.is_create_client_portal"
              control={control}
              render={({ field }) => (
                <InputSwitch
                  disabled={!leadDetails?.is_open || contactStatus}
                  className="input-shadow input-swich"
                  inputId={field.name}
                  onChange={(e) => field.onChange(e.value)}
                  checked={field.value}
                />
              )}
            />
          </div>
        </div>
        <div className="d-flex align-items-center py-2 flex-wrap">
          <div className="col-md-4 col-12 p-medium">
            Role
            <span className="danger-text"> *</span>
          </div>
          <div className="col-md-8 col-12">
            {defaultValues?.contact?.contact_type === 'business' || PI_PROVIDERS_TYPE.includes(defaultValues?.contact?.contact_type) ? (
              <Controller
                name="contact.role"
                control={control}
                rules={{ required: 'Role is Required' }}
                render={({ field }) => (
                  <Dropdown
                    id={field.name}
                    onChange={(e) => field.onChange(e.value)}
                    value={field.value}
                    options={businessRole}
                    optionLabel="name"
                    optionValue="name"
                    placeholder="Select"
                    filterPlaceholder="Search"
                    className="w-100"
                    disabled={!leadDetails?.is_open || contactStatus}
                  />
                )}
              />
            ) : (
              <Controller
                name="contact.role"
                control={control}
                rules={{ required: 'Role is Required' }}
                render={({ field }) => (
                  <Dropdown
                    id={field.name}
                    onChange={(e) => field.onChange(e.value)}
                    value={field.value}
                    options={relationRole}
                    optionLabel="relation_role"
                    optionValue={(v) => v.relation_role?.toLowerCase()}
                    placeholder="Select"
                    filterPlaceholder="Search"
                    className="w-100"
                    disabled={!leadDetails?.is_open || contactStatus}
                  />
                )}
              />
            )}
            <RequiredValidation field={'contact.role'} />
          </div>
        </div>
        {checkSubPermission('custom_fields') && (
          <>
            <div className="row d-flex align-items-center mt-3">
              <div className="col-12 col-sm-7">
                <span className="caption-bold mb-3">
                  <span>Custom Fields</span>
                </span>
              </div>
            </div>
            {!leadDetails?.contact?.[0]?.custom_field?.length && (
              <div className="row mb-2">
                <div className="col-12 small">
                  <i className="fas fa-info-circle mx-2 mt-1"></i>
                  <span className="text-hint">No Custom fields found.</span>
                </div>
              </div>
            )}
            <CustomFields
              field="contact.custom_field"
              control={control}
              setValue={setValue}
              fieldDisable={!leadDetails?.is_open || contactStatus}
            />
          </>
        )}
        {leadDetails?.is_open && (
          <>
            <hr />
            <div className="d-flex mt-3 justify-content-end">
              <Button
                className="p-button p-button-primary"
                label="Save Changes"
                onClick={handleSubmit(updateContactData)}
                loading={apiLoading}
                disabled={contactStatus}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
}
