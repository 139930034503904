import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import React, { useContext } from 'react';
import { fixToTwoDecimal, formatNumbersToCurrencyString } from 'utils/utils';
import { billableExpenseBody } from './helpers/expensesDataTableHelpers';
import TextSnippetWrapper from 'components/TextSnippetWrapper';
import CustomCalendar from 'components/CustomCalendar/CustomCalendar';
import { handleDateTimeOffset } from 'utils/utility_functions/timezone';
import { UserDetailsContext } from 'context/userDetailsContext';
import constants from 'constants/index';

const Expenses = (props) => {
  const {
    isContigencyFee,
    expenses,
    setExpenses,
    expenseTypes,
    expenseError,
    setExpenseError,
    isChanged,
    setIsChanged,
    addExpense,
    teamMembers,
    deleteExpense,
    expenseTotal,
    caseDetails,
  } = props || {};

  const disableExpense = isContigencyFee && !caseDetails?.billing_preferences?.is_expense;

  const userContext = useContext(UserDetailsContext);

  const onChangeBillable = (e, index) => {
    expenses[index].billable = e.target.checked;
    isChanged.expenses = true;
    let expenseErrors = { ...expenseError };
    for (let key in expenseErrors) {
      if (key.includes(index.toString())) {
        expenseErrors[key] = '';
      }
    }

    setExpenseError(expenseErrors);
    setExpenses([...expenses]);
    setIsChanged({ ...isChanged });
  };

  return (
    <div className="mt-4">
      <div className="caption-bold mb-3">Expenses</div>
      <div className="shadow-small">
        <div className="row mt-3">
          <div className="col-12 datatable-responsive">
            <DataTable
              className="p-datatable-responsive"
              value={expenses}
              emptyMessage={() => <div onClick={addExpense}>No records found</div>}
            >
              <Column
                header="Date"
                body={(rowData, props) => {
                  return (
                    <>
                      <span className="p-column-title text-break">Date</span>
                      <span className="text-break">
                        {rowData.expense_date ? (
                          handleDateTimeOffset(userContext?.userDetails?.timezone, rowData?.expense_date, constants.month_date_year_format)
                        ) : (
                          <i>Please Enter</i>
                        )}
                      </span>
                      <br></br>
                      {expenses && expenses[props?.rowIndex]?.billable && expenseError && expenseError[`${props.rowIndex}_date`] && (
                        <span className="text-danger">{expenseError[`${props.rowIndex}_date`]}</span>
                      )}
                    </>
                  );
                }}
                editor={(props) => {
                  return (
                    <div className="table-input">
                      <CustomCalendar
                        value={props.rowData?.expense_date}
                        onChange={(e) => {
                          let index = props.rowIndex;
                          expenses[index].expense_date = e.value;
                          setExpenses([...expenses]);
                          expenseError[`${props.rowIndex}_date`] = '';
                          setExpenseError({ ...expenseError });
                          isChanged.expenses = true;
                          setIsChanged({ ...isChanged });
                        }}
                      />
                      {expenses && expenses[props?.rowIndex]?.billable && expenseError && expenseError[`${props.rowIndex}_date`] && (
                        <span className="text-danger">{expenseError[`${props.rowIndex}_date`]}</span>
                      )}
                    </div>
                  );
                }}
              />
              <Column
                header="Members"
                body={(rowData, props) => {
                  return (
                    <>
                      <span className="p-column-title text-break">Members</span>
                      <span className="text-break">{rowData.team_member_name ? rowData.team_member_name : <i>Please Enter</i>}</span>
                      <br></br>
                      {expenses && expenses[props?.rowIndex]?.billable && expenseError && expenseError[`${props.rowIndex}_team_member`] && (
                        <span className="text-danger">{expenseError[`${props.rowIndex}_team_member`]}</span>
                      )}
                    </>
                  );
                }}
                editor={(props) => {
                  return (
                    <div>
                      <Dropdown
                        options={teamMembers}
                        optionLabel="name"
                        value={{
                          name: props?.rowData?.team_member_name,
                          contact_id: props?.rowData?.team_member_id,
                        }}
                        placeholder="Select"
                        onChange={(e) => {
                          let index = props.rowIndex;
                          let name = e.target.value?.name;
                          let contact_id = e.target.value?.contact_id;
                          expenses[index].team_member_name = name;
                          expenses[index].team_member_id = contact_id;
                          setExpenses([...expenses]);
                          expenseError[`${props.rowIndex}_team_member`] = '';
                          setExpenseError({ ...expenseError });
                          isChanged.expenses = true;
                          setIsChanged({ ...isChanged });
                        }}
                      />
                      {expenses && expenses[props?.rowIndex]?.billable && expenseError && expenseError[`${props.rowIndex}_team_member`] && (
                        <span className="text-danger">{expenseError[`${props.rowIndex}_team_member`]}</span>
                      )}
                    </div>
                  );
                }}
              />
              {/*   <Column header="Case" field="case_name" /> */}
              <Column
                header="Expense Type"
                body={(rowData, props) => {
                  return (
                    <>
                      <span className="p-column-title text-break">Expense Type</span>
                      <span className="text-break">{rowData.expense_type ? rowData.expense_type : <i>Please Enter</i>}</span>
                      <br></br>
                      {expenses &&
                        expenses[props?.rowIndex]?.billable &&
                        expenseError &&
                        expenseError[`${props.rowIndex}_expense_type`] && (
                          <span className="text-danger">{expenseError[`${props.rowIndex}_expense_type`]}</span>
                        )}
                    </>
                  );
                }}
                editor={(props) => {
                  return (
                    <div>
                      <Dropdown
                        value={{
                          expense_type: props?.rowData?.expense_type,
                          expense_type_id: props?.rowData?.expense_type_id,
                        }}
                        options={expenseTypes}
                        onChange={(e) => {
                          let expense_type = e.target?.value?.expense_type;
                          let expense_type_id = e.target?.value?.expense_type_id;
                          let index = props.rowIndex;
                          expenses[index].expense_type = expense_type;
                          expenses[index].expense_type_id = expense_type_id;
                          setExpenses([...expenses]);
                          expenseError[`${props.rowIndex}_expense_type`] = '';
                          setExpenseError({ ...expenseError });
                          isChanged.expenses = true;
                          setIsChanged({ ...isChanged });
                        }}
                        optionLabel="expense_type"
                        filter
                        placeholder="Select"
                      />
                      {expenses &&
                        expenses[props?.rowIndex]?.billable &&
                        expenseError &&
                        expenseError[`${props.rowIndex}_expense_type`] && (
                          <span className="text-danger">{expenseError[`${props.rowIndex}_expense_type`]}</span>
                        )}
                    </div>
                  );
                }}
              />
              <Column
                header="Description"
                field="description"
                body={(rowData) => {
                  return (
                    <>
                      <span className="p-column-title text-break">Description</span>
                      <span className="text-break">{rowData.description ? rowData.description : <i>Please Enter</i>}</span>
                    </>
                  );
                }}
                editor={(props) => {
                  return (
                    <TextSnippetWrapper
                      type="text"
                      value={props.rowData?.description}
                      onChange={(e) => {
                        let index = props.rowIndex;
                        expenses[index].description = e.target.value;
                        setExpenses([...expenses]);
                        isChanged.expenses = true;
                        setIsChanged({ ...isChanged });
                      }}
                      className="w-100 input-shadow"
                    />
                  );
                }}
              />
              <Column
                header="Fee"
                body={(rowData, props) => {
                  return (
                    <>
                      <span className="p-column-title text-break">Fee</span>
                      <span className="text-break">
                        {rowData.rate_per_unit ? formatNumbersToCurrencyString(rowData.rate_per_unit) : <i>Please Enter</i>}
                      </span>
                      <br></br>
                      {expenses &&
                        expenses[props?.rowIndex]?.billable &&
                        expenseError &&
                        expenseError[`${props.rowIndex}_rate_per_unit`] && (
                          <span className="text-danger">{expenseError[`${props.rowIndex}_rate_per_unit`]}</span>
                        )}
                    </>
                  );
                }}
                editor={(props) => {
                  return (
                    <div>
                      <InputText
                        value={props.rowData?.rate_per_unit}
                        onChange={(e) => {
                          let index = props.rowIndex;
                          let quantity = expenses[index].quantity;
                          let rate = e.target.value;
                          quantity = quantity ? parseFloat(quantity) : 0;
                          rate = rate ? parseFloat(rate) : 0;
                          let total = quantity * rate;
                          expenses[index].rate_per_unit = fixToTwoDecimal(e.target.value);
                          expenses[index].total_amount = fixToTwoDecimal(total);
                          setExpenses([...expenses]);
                          expenseError[`${props.rowIndex}_rate_per_unit`] = '';
                          setExpenseError({ ...expenseError });
                          isChanged.expenses = true;
                          setIsChanged({ ...isChanged });
                        }}
                        className="w-100 input-shadow"
                        type="number"
                        min={0}
                        keyfilter={'pnum'}
                      />
                      {expenses &&
                        expenses[props?.rowIndex]?.billable &&
                        expenseError &&
                        expenseError[`${props.rowIndex}_rate_per_unit`] && (
                          <span className="text-danger">{expenseError[`${props.rowIndex}_rate_per_unit`]}</span>
                        )}
                    </div>
                  );
                }}
              />
              <Column
                header="Quantity"
                body={(rowData, props) => {
                  return (
                    <>
                      <span className="p-column-title text-break">Quantity</span>
                      <span className="text-break">{rowData.quantity ? rowData.quantity : <i>Please Enter</i>}</span>
                      <br></br>
                      {expenses && expenses[props?.rowIndex]?.billable && expenseError && expenseError[`${props.rowIndex}_quantity`] && (
                        <span className="text-danger">{expenseError[`${props.rowIndex}_quantity`]}</span>
                      )}
                    </>
                  );
                }}
                editor={(props) => {
                  return (
                    <div>
                      <InputText
                        value={props.rowData?.quantity}
                        onChange={(e) => {
                          let index = props.rowIndex;
                          let quantity = e.target.value;
                          let rate = expenses[index].rate_per_unit;
                          quantity = quantity ? parseFloat(quantity) : 0;
                          rate = rate ? parseFloat(rate) : 0;
                          let total = quantity * rate;
                          expenses[index].quantity = fixToTwoDecimal(e.target.value);
                          expenses[index].total_amount = fixToTwoDecimal(total);
                          setExpenses([...expenses]);
                          expenseError[`${props.rowIndex}_quantity`] = '';
                          setExpenseError({ ...expenseError });
                          isChanged.expenses = true;
                          setIsChanged({ ...isChanged });
                        }}
                        className="w-100 input-shadow"
                        min={0}
                        keyfilter={'pnum'}
                        type="number"
                        placeholder="Please Enter"
                      />
                      {expenses && expenses[props?.rowIndex]?.billable && expenseError && expenseError[`${props.rowIndex}_quantity`] && (
                        <span className="text-danger">{expenseError[`${props.rowIndex}_quantity`]}</span>
                      )}
                    </div>
                  );
                }}
              />
              <Column
                header="Total"
                body={(rowData) => {
                  return (
                    <>
                      <span className="p-column-title text-break">Total</span>
                      <span className="text-break">{formatNumbersToCurrencyString(rowData?.total_amount)}</span>
                    </>
                  );
                }}
              />

              <Column
                header="Billable"
                body={(rowData, props) => {
                  return billableExpenseBody({ rowData, props, onChangeBillable, disableExpense });
                }}
              />
              <Column
                body={(rowData, props) => {
                  return (
                    <>
                      <span className="p-column-title text-break">Actions</span>
                      <i
                        className="icon-delete icon-red ms-2 mt-2"
                        onClick={(e) => {
                          deleteExpense(props);
                        }}
                      />
                    </>
                  );
                }}
              />
            </DataTable>
          </div>
        </div>
        <div>
          <div className="d-flex mt-2 p-2">
            <div className="col col-md-8 text-bold">Total:</div>
            <div className="col col-md-4 text-bold">{formatNumbersToCurrencyString(expenseTotal)}</div>
          </div>
        </div>
        <div className="col-12 p-2">
          <span className="mt-3 addfield" role="button" onClick={addExpense}>
            + Add Expense
          </span>
        </div>
      </div>
    </div>
  );
};

export default Expenses;
