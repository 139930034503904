import React, { useEffect } from 'react';
import AsyncSelect from 'react-select/async';
import { Tooltip } from 'primereact/tooltip';

import { useCaseListFilter } from 'hooks/useCaseListFilter';

export default function CaseListDropDown(props) {
  const {
    isClient = false,
    selectFirstOption = false,
    caseOnly,
    disabled = false,
    onChange,
    placeholder = 'By Case',
    noOptionsMessage = 'No Cases Found',
    ref,
    paFilter,
    ...rest
  } = props || {};
  const { initialCaseList, onSearchChange } = useCaseListFilter(isClient, caseOnly, paFilter);

  // Directly select the first valid option (skip the tip)
  const defaultOption = initialCaseList && initialCaseList.length > 1 ? initialCaseList[1] : null;

  // Manually trigger onChange with the default value after mount
  useEffect(() => {
    if (defaultOption && selectFirstOption && !rest?.value) {
      onChange(defaultOption); // Trigger onChange with the default value
    }
  }, [defaultOption]);

  return (
    <>
      <Tooltip target=".case-tooltip" position="top" />
      <div className="case-tooltip" data-pr-tooltip={rest?.value?.label || ''} data-pr-showdelay="500">
        <AsyncSelect
          {...rest}
          styles={{
            control: (baseStyles) => ({
              ...baseStyles,
              borderRadius: 12,
              borderStyle: 'none',
              borderWidth: 'none',
              minWidth: 250,
            }),
            menu: (baseStyles) => ({
              ...baseStyles,
              zIndex: 9999,
            }),
            ...rest?.styles,
          }}
          onChange={onChange}
          getOptionValue={(option) => option.id}
          isOptionDisabled={(option) => option.value === 'tip'}
          getOptionLabel={(option) => {
            if (isClient) {
              return initialCaseList?.find((item) => item?.id === option?.id)?.label;
            } else return option?.label;
          }}
          // placeholder="By Case"
          placeholder={placeholder}
          className="input-shadow w-100"
          cacheOptions
          defaultOptions={initialCaseList}
          loadOptions={onSearchChange}
          // noOptionsMessage={() => 'No Cases Found'}
          noOptionsMessage={() => noOptionsMessage}
          isDisabled={disabled}
          isSearchable={!isClient}
        />
        <input type="hidden" ref={ref} />
      </div>
    </>
  );
}
