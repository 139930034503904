import { Tooltip } from 'primereact/tooltip';
import { useRef, useMemo } from 'react';

const GlobalTooltip = ({ children, tooltip, position = 'top', style = {}, className }) => {
  const uniqueId = useRef(useMemo(() => `tooltip-${Math.random().toString(36).substr(2, 9)}`, [])).current;

  if (!tooltip) return children; // Render without tooltip if not provided

  return (
    <>
      <span id={uniqueId} data-pr-tooltip={tooltip} data-pr-position={position} style={style} className={className}>
        {children}
      </span>
      <Tooltip target={`#${uniqueId}`} />
    </>
  );
};

export default GlobalTooltip;
