/**
 * This is a React component that renders a header and a task board within a container, wrapped in a
 * context provider.
 * @returns The `Tasks` component is being returned, which contains the `Header` and `TaskBoard`
 * components wrapped in a `DashboardDataHandler` context provider.
 */
import React, { useEffect } from 'react';
import { classNames } from 'primereact/utils';

import { Header, TaskBoard } from './components';
import DashboardDataHandler from './Context/TaskContext';
import GlobalLoader from 'components/GlobalLoader/GlobalLoader';
import useComponentLifecycle from 'hooks/useComponentLifecycle';

export default function Tasks({ isFromCase, caseDetails, isFromLead, isLeadClosed, isCaseClosed, onMountUnMount }) {
  useComponentLifecycle('task-activity', onMountUnMount);
  useEffect(() => {
    // Add the class to change navbar background
    document.body.classList.add('task-details-page');

    // Cleanup function to remove the class when leaving the page
    return () => {
      document.body.classList.remove('task-details-page');
    };
  }, []);
  return (
    <DashboardDataHandler {...{ isLeadClosed, isFromCase, caseDetails, isFromLead, isCaseClosed }}>
      <div className={classNames({ 'container-fluid  task-main-container': !Boolean(isFromCase), 'mt-0': true })}>
        <Header />
        <TaskBoard />
        <GlobalLoader />
      </div>
    </DashboardDataHandler>
  );
}
