import React, { useContext, useEffect, useRef, useState } from 'react';
import { Controller, useFieldArray } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import { MultiSelect } from 'primereact/multiselect';
import { Button } from 'primereact/button';

import { generateFullName } from 'utils/generateFullNameUtils';
import { UserDetailsContext } from 'context/userDetailsContext';
import LabelName from 'components/UI/LabelName/LabelName';
import useEventFreeTime from 'hooks/useEventFreeTime';
import { convertToTimezoneFormat, currentBrowserTimeZone } from 'utils/utility_functions/timezone';
import { getClientUserList } from 'services/users/userServices';
import SelectedGroups from 'modules/calendar/Components/CreateEventModal/Components/EventAttendees/SelectedGroups';
import SelectedFirmUsers from 'modules/calendar/Components/CreateEventModal/Components/EventAttendees/SelectedFirmUsers';
import SelectedAttendees from 'modules/calendar/Components/CreateEventModal/Components/EventAttendees/SelectedAttendees';
import SuggestedTime from 'modules/calendar/Components/SuggestedTime/SuggestedTime';

function CourtEventAttendees({ control, getValues, setValue, trigger, watch, selectedItem, handleShowReminder, showReminder }) {
  const op = useRef(null);
  const userDetails = useContext(UserDetailsContext);
  const { eventFreeTime, loadFreeTime, loading } = useEventFreeTime();
  const history = useHistory();
  const [eventReminder, setEventReminder] = useState({ users: false, contacts: false, groups: false });
  const [userList, setUserList] = useState([]);
  const [selectedAttendeeList, setSelectedAttendeeList] = useState([]);

  const { fields, insert, remove } = useFieldArray({
    control,
    name: 'contacts',
  });

  const {
    fields: groupsFields,
    insert: groupsInsert,
    remove: groupsRemove,
  } = useFieldArray({
    control,
    name: 'groups',
  });

  const {
    fields: firm_UsersFields,
    insert: firm_UsersInsert,
    remove: firm_UsersRemove,
  } = useFieldArray({
    control,
    name: 'firm_users',
  });

  function getCaseUserClientData(case_id = '') {
    let obj = {
      caseId: case_id,
      is_contact_list: false,
      is_team_member_list: true,
      all_contacts: true,
      contact_id: false,
      is_lead: selectedItem?.is_lead ?? false,
      is_mandatory_lawft_access: false,
      is_active: true,
      is_include_groups: true,
      active_groups: false,
    };
    getClientUserList(obj)
      .then((response) => {
        let finalUserList = response?.data;

        let combinedData = combineUserAndGroup(finalUserList);
        setUserList(combinedData);
      })
      .catch((err) => console.error(err));
  }

  function combineUserAndGroup(response) {
    const groupedContacts = {};

    if (response?.length > 0) {
      response?.forEach((element) => {
        const contactType = element?.res_type === 'user-group' ? 'Groups' : !element.contact_type ? 'Firm Users' : 'Contacts';
        function generateLabel(element) {
          if (element.contact_type === 'person' || element.first_name || element.middle_name || element.last_name) {
            return generateFullName(element);
          }
          return element.title;
        }

        if (!groupedContacts[contactType]) {
          groupedContacts[contactType] = {
            label: contactType,
            items: [],
          };
        }

        if (element?.res_type === 'user-group') {
          groupedContacts[contactType].items.push({
            label: element.group_name,
            value: element.user_group_id,
            ...element,
          });
        } else if (element?.access_level) {
          groupedContacts[contactType]?.items.push({
            label: generateLabel(element),
            value: element.client_cognito_username ? element.client_cognito_username : element.contact_id,
            access_level: element.access_level,
            ...element,
          });
        } else {
          const label = generateLabel(element);
          groupedContacts[contactType].items.push({
            label: label,
            value: element.client_cognito_username ? element.client_cognito_username : element.contact_id,
            email: element.email,
            is_create_client_portal: element.is_create_client_portal,
            access_level: element.access_level,
            ...element,
          });
        }
      });
    }
    return Object.values(groupedContacts);
  }

  useEffect(() => {
    if (selectedItem?.case_id && selectedItem?.case_id.trim() !== 'undefined') {
      getCaseUserClientData(selectedItem?.case_id);
    } else {
      getCaseUserClientData();
    }
  }, [selectedItem]);

  useEffect(() => {
    const dropdownClasses = document?.querySelectorAll('.p-multiselect-token');
    for (const dropdownElement of dropdownClasses) {
      const dropLabelElement = dropdownElement?.querySelector('.p-multiselect-token-label')?.innerHTML;

      if (dropLabelElement?.trim()?.length === 0) {
        dropdownElement.style.display = 'none';
      }
    }
    if (selectedItem?.case_id) {
      handleAttendeesChange([]);
      setValue('selected_attendees_list', []);
    }
  }, [userList]);

  useEffect(() => {
    setEventReminder((prevReminder) => ({
      users: firm_UsersFields?.some((field) => field.send_reminder),
      contacts: fields?.some((field) => field.send_reminder),
      groups: groupsFields?.some((field) => field.send_reminder),
    }));
  }, [fields, groupsFields, firm_UsersFields]);

  useEffect(() => {
    const isAnyReminderTrue = Object.values(eventReminder).some((value) => value === true);
    if (isAnyReminderTrue) {
      handleShowReminder(true);
    } else {
      handleShowReminder(false);
    }
  }, [eventReminder]);

  useEffect(() => {
    if (!showReminder) {
      trigger('reminder');
    }
  }, [showReminder]);

  const updateReminder = (type, value) => {
    setEventReminder((prevState) => ({
      ...prevState,
      [type]: value,
    }));
  };

  function handleAttendeesChange(value) {
    const usersArray = userList?.find((item) => item.label === 'Contacts')?.items;
    const groupsArray = userList?.find((item) => item.label === 'Groups')?.items;
    const firm_usersArray = userList?.find((item) => item.label === 'Firm Users')?.items;

    const selectedFirmUsersArray = firm_usersArray?.filter((item) => value.includes(item.client_cognito_username ?? item.contact_id));

    const selectedUsersArray = usersArray?.filter((item) => value.includes(item.client_cognito_username ?? item.contact_id));

    const selectedGroupsArray = groupsArray?.filter((item) => value.includes(item.user_group_id));

    if (selectedUsersArray?.length > 0) {
      selectedUsersArray?.forEach((currentSelection, index) => {
        const userExists = fields.some(
          (field) =>
            field.cognito_username ===
            (currentSelection.client_cognito_username ? currentSelection.client_cognito_username : currentSelection.cognito_username)
        );

        if (!userExists) {
          insert(fields.length + index, {
            name: generateFullName(currentSelection),
            email: currentSelection.email,
            is_attendance_required: 'optional',
            send_reminder: true,
            cognito_username: currentSelection.client_cognito_username
              ? currentSelection.client_cognito_username
              : currentSelection.cognito_username,
            contact_id: currentSelection.contact_id ? currentSelection.contact_id : '',
            access_level: currentSelection?.access_level,
            is_individual_user: true,
            profile_image: currentSelection?.profile_image,
          });
        }
      });

      // Remove users from the userList
      fields.forEach((field, index) => {
        const userExists = selectedUsersArray.some(
          (currentSelection) =>
            field.cognito_username ===
            (currentSelection.client_cognito_username ? currentSelection.client_cognito_username : currentSelection.cognito_username)
        );

        if (!userExists) {
          remove(index);
        }
      });
    } else if (selectedUsersArray?.length === 0) {
      remove();
    }

    if (selectedFirmUsersArray?.length > 0) {
      // Add new users to the userList
      selectedFirmUsersArray?.forEach((currentSelection, index) => {
        const firm_userExists = firm_UsersFields.some(
          (field) =>
            field.cognito_username ===
            (currentSelection.client_cognito_username ? currentSelection.client_cognito_username : currentSelection.cognito_username)
        );

        if (!firm_userExists) {
          firm_UsersInsert(firm_UsersFields.length + index, {
            name: generateFullName(currentSelection),
            email: currentSelection.email,
            is_attendance_required: 'optional',
            send_reminder: true,
            cognito_username: currentSelection.client_cognito_username
              ? currentSelection.client_cognito_username
              : currentSelection.cognito_username,
            contact_id: currentSelection.contact_id ? currentSelection.contact_id : '',
            access_level: currentSelection?.access_level,
            is_individual_user: true,
            profile_image: currentSelection?.profile_image,
            firm_user_id: currentSelection.firm_user_id ? currentSelection.firm_user_id : '',
          });
        }
      });

      // Remove users from the userList
      firm_UsersFields.forEach((field, index) => {
        const firm_userExists = selectedFirmUsersArray.some(
          (currentSelection) =>
            field.cognito_username ===
            (currentSelection.client_cognito_username ? currentSelection.client_cognito_username : currentSelection.cognito_username)
        );

        if (!firm_userExists) {
          firm_UsersRemove(index);
        }
      });
    } else if (selectedFirmUsersArray?.length === 0) {
      firm_UsersRemove();
    }

    if (selectedGroupsArray?.length > 0) {
      selectedGroupsArray.forEach((currentSelection, index) => {
        const groupExists = groupsFields.some((field) => field.group_id === currentSelection.user_group_id);
        if (!groupExists) {
          groupsInsert(groupsFields.length + index, {
            group_id: currentSelection?.user_group_id,
            group_name: currentSelection?.group_name,
            sk: currentSelection?.sk,
            is_attendance_required: 'optional',
            send_reminder: true,
          });
        }
      });
      // Remove users from the userList
      groupsFields.forEach((field, index) => {
        const groupExists = selectedGroupsArray.some((currentSelection) => field.group_id === currentSelection.user_group_id);
        if (!groupExists) {
          groupsRemove(index);
        }
      });
    }
    if (selectedGroupsArray?.length === 0) {
      groupsRemove();
    }
    if (selectedFirmUsersArray?.length === 0 || selectedGroupsArray?.length === 0 || selectedUsersArray?.length === 0) {
      handleShowReminder(false);
    }
  }

  const assignToItemTemplate = (v) => {
    if (v?.status === 'ARCHIVED' && (v?.firm_user_id || !v.contact_id)) {
      return (
        <>
          {v?.firm_user_id && <i className="fas fa-user-tie me-2"></i>}
          {`${v?.label} (Inactive)`}
        </>
      );
    }
    if ((v?.firm_user_id || !v.contact_id) && v?.is_confirmed === false) {
      return (
        <>
          {v?.firm_user_id && <i className="fas fa-user-tie me-2"></i>}
          {`${v?.label} (Unconfirmed)`}
        </>
      );
    }
    if ((v?.access_level === 'client' || v?.access_level === undefined || v.contact_id) && v?.res_type !== 'user-group') {
      if (!Boolean(v?.email)) {
        return (
          <>
            {v?.firm_user_id && <i className="fas fa-user-tie me-2"></i>}
            {`${v?.label} (Email does not exist)`}
          </>
        );
      }
      if (v?.is_create_client_portal === false) {
        return (
          <>
            {v?.firm_user_id && <i className="fas fa-user-tie me-2"></i>}
            {`${v?.label} (Disabled Lawft Access)`}
          </>
        );
      }
      if (v?.is_confirmed === false) {
        return (
          <>
            {v?.firm_user_id && <i className="fas fa-user-tie me-2"></i>}
            {`${v?.label} (Unconfirmed)`}
          </>
        );
      }
    }
    return (
      <>
        {v?.firm_user_id && <i className="fas fa-user-tie me-2"></i>}
        {v?.label}
      </>
    );
  };

  const checkOptionDisabled = (v) => {
    if (v?.status === 'ARCHIVED' && (v?.firm_user_id || !v.contact_id)) {
      return true;
    }
    if ((v?.firm_user_id || !v.contact_id) && v?.is_confirmed === false) {
      return true;
    }
    if ((Boolean(v?.access_level) === false || v?.access_level === 'client' || v.contact_id) && v?.res_type !== 'user-group') {
      return !Boolean(v.email) || v.is_create_client_portal === false || v?.is_confirmed === false ? true : false;
    }
    return false;
  };

  const selecteduserList = getValues('selected_attendees_list');

  const getSuggestedTime = async (e) => {
    const startDate = getValues('when');
    const meeting_start_time = getValues('meeting_start_time');
    const meeting_end_time = getValues('meeting_end_time');

    let start_date;

    const today = moment().tz(userDetails?.userDetails?.timezone).startOf('day');
    if (moment(startDate).isSame(today, 'day')) {
      start_date = moment().tz(userDetails?.userDetails?.timezone);
    } else {
      start_date = moment(startDate).startOf('day').toDate();
    }

    await loadFreeTime({
      // user_list: selecteduserList,
      user_list: selectedAttendeeList.flatMap((attendee) =>
        attendee.res_type === 'user-group' ? attendee.user_accessible_list : [attendee.client_cognito_username]
      ),
      start_date: convertToTimezoneFormat(start_date, userDetails?.userDetails?.timezone),
      end_date: convertToTimezoneFormat(moment(start_date).add(2, 'days').endOf('day'), userDetails?.userDetails?.timezone),
      meeting_start_time: convertToTimezoneFormat(meeting_start_time, userDetails?.userDetails?.timezone),
      meeting_end_time: convertToTimezoneFormat(meeting_end_time, userDetails?.userDetails?.timezone),
      response_limit: 5,
    });
    if (op.current) op.current.toggle(e);
  };

  const handleSelectTime = (value) => {
    const offset1 = moment.tz(value, currentBrowserTimeZone).utcOffset();
    const offset2 = moment.tz(value, userDetails?.userDetails?.timezone).utcOffset();
    const differenceInMinutes = offset1 - offset2;
    const date = moment(value).subtract(differenceInMinutes, 'minutes').toDate();

    setValue('when', date);
    setValue('meeting_start_time', date);
    setValue('meeting_end_time', moment(date).tz(userDetails?.userDetails?.timezone).add(1, 'hour').toDate());
    trigger('when');
    trigger('meeting_start_time');
    trigger('meeting_end_time');
    op.current?.hide();
  };

  return (
    <>
      <div className="d-flex align-items-center py-2 flex-wrap">
        <div className="col-md-4 col-12">
          <LabelName required={false} htmlFor="selected_attendees_list">
            Add Attendees
          </LabelName>
        </div>
        <div className="col-md-8 col-12 add-attendees">
          <Controller
            name="selected_attendees_list"
            control={control}
            defaultValue={[]}
            render={({ field }) => (
              <MultiSelect
                options={userList ?? []}
                optionLabel="label"
                optionGroupLabel="label"
                optionGroupChildren="items"
                display="chip"
                className="input-shadow w-100"
                filter
                id={field?.name}
                value={userList?.length > 0 && field?.value}
                placeholder="Select Attendees"
                onChange={(e) => {
                  const selectedAttendeesList = e.value.map((selected) =>
                    userList
                      .flatMap((group) => group.items)
                      .find((item) =>
                        item?.client_cognito_username ? item?.client_cognito_username === selected : item?.user_group_id === selected
                      )
                  );

                  setSelectedAttendeeList(selectedAttendeesList);
                  field.onChange(e.value);
                  handleAttendeesChange(e.value);
                }}
                emptyFilterMessage="No records found"
                optionDisabled={checkOptionDisabled}
                itemTemplate={assignToItemTemplate}
                showSelectAll={false}
              />
            )}
          />
        </div>
      </div>
      <div className="d-flex justify-content-end">
        <Button
          type="button"
          label="Suggest Time"
          className="btn-outline p-button-secondary outline"
          onClick={getSuggestedTime}
          aria-controls="popup_menu"
          aria-haspopup
          loading={loading}
          disabled={selecteduserList?.length < 2}
          tooltip="Suggested time will be within business hours, in 1-hour intervals."
          tooltipOptions={{ position: 'top', showDelay: 500 }}
        />
        <SuggestedTime {...{ handleSelectTime, eventFreeTime, op, timezone: userDetails?.userDetails?.timezone }} />
      </div>
      {userList?.length > 0 && groupsFields?.length > 0 && (
        <SelectedGroups
          selectedGroups={groupsFields}
          control={control}
          history={history}
          watch={watch}
          updateReminder={updateReminder}
          setValue={setValue}
        />
      )}
      {userList?.length > 0 && firm_UsersFields?.length > 0 && (
        <SelectedFirmUsers
          selectedAttendees={firm_UsersFields}
          control={control}
          history={history}
          watch={watch}
          updateReminder={updateReminder}
          setValue={setValue}
        />
      )}
      {userList?.length > 0 && fields?.length > 0 && (
        <SelectedAttendees
          selectedAttendees={fields}
          control={control}
          history={history}
          watch={watch}
          updateReminder={updateReminder}
          setValue={setValue}
        />
      )}
    </>
  );
}

export default CourtEventAttendees;
