import { Calendar } from 'primereact/calendar';
import React from 'react';
import { handleDateTimeOffset } from 'utils/utility_functions/timezone';
import { useContext } from 'react';
import { UserDetailsContext } from 'context/userDetailsContext';
import moment from 'moment';

function CustomCalendar(props) {
  const userContext = useContext(UserDetailsContext);

  const {
    selectionMode = 'single',
    value = '',
    onChange = (e) => {},
    timezone = userContext?.userDetails?.timezone,
    minDate = null,
    maxdDate = null,
    showTime = false,
    timeOnly = false,
    ...rest
  } = props || {};

  const convertToTimezoneFormatWithoutOffset = (date, timezone) => {
    return moment(date)?.tz(timezone, true)?.format();
  };

  const adjustedValue =
    selectionMode === 'single'
      ? value
        ? new Date(handleDateTimeOffset(timezone, value))
        : null
      : value
      ? value?.map((val) => {
          return val ? new Date(handleDateTimeOffset(timezone, val)) : null;
        })
      : null;

  const handleChange = (e) => {
    let updatedValue = e;
    console.log('Customcalendar\nInput value e:', updatedValue.value);
    updatedValue.value =
      selectionMode === 'single'
        ? updatedValue?.value
          ? convertToTimezoneFormatWithoutOffset(updatedValue.value, timezone)
          : null
        : updatedValue?.value?.map((val) => {
            return val ? convertToTimezoneFormatWithoutOffset(val, timezone) : null;
          });
    updatedValue.target.value = updatedValue.value;

    onChange(updatedValue);
  };
  // const handleOnTodayButtonClicked = () => {
  //   console.log('on today');
  //   //setIsTodayButtonClicked(true);
  //   // const todayWithTimezone = moment().tz(timezone).format();
  //   // console.log('Today button clicked:', todayWithTimezone);

  //   // // Update the testValue state and trigger the parent onChange
  //   // const updatedValue = {
  //   //   value: todayWithTimezone,
  //   //   target: { value: todayWithTimezone },
  //   // };

  //   // setTestValue(todayWithTimezone);
  //   // onChange(updatedValue);
  //   // let sourceMoment = moment().startOf('day').tz(timezone);

  //   // let updatedValue = sourceMoment;
  //   // updatedValue =
  //   //   selectionMode === 'single'
  //   //     ? updatedValue
  //   //       ? sourceMoment.format()
  //   //       : null
  //   //     : updatedValue?.map((val) => {
  //   //         return val ? sourceMoment.format() : null;
  //   //       });

  //   //console.log('Customcalendar\nupdated Value after target\n', sourceMoment);

  //   //console.log('Customcalendar\nValue\n', e);
  //   //console.log(updatedValue.value = moment(updatedValue.value).set({ hour: e.value.hour, minute: e.value.minute, second: 0, millisecond: 0 }) .tz(timezone))
  //   // console.log('Customcalendar\nupdatedValuemoment\n', moment(updatedValue.value).tz(timezone).format());
  //   //console.log('to_time 1',moment().tz(timezone).format())
  //   //console.log('to_time',moment().add(30, 'minutes').tz(timezone).format())
  //   // setTestValue(updatedValue);
  //   // onChange(updatedValue);

  //   setNewEditedValue(
  //     new Date().toLocaleString('en-US', {
  //       timeZone: userContext?.userDetails?.timezone,
  //     })
  //   );

  //   onChange(moment().tz(timezone).format());
  //   // updatedValue.target.value = updatedValue.value;
  //   // setTestValue(updatedValue.value);
  //   // onChange(updatedValue);
  //   setCalendarKey((prevKey) => prevKey + 1);
  //   // setNewEditedValue(null)
  //   // setTimeout(() => {
  //   //   setNewEditedValue(new Date(new Date().toLocaleString('en-US', {
  //   //     timeZone: userContext?.userDetails?.timezone,
  //   //     }))) // Set today's date after resetting
  //   // }, 1);
  // };

  const adjustedMinDate = minDate ? new Date(handleDateTimeOffset(timezone, minDate)) : null;

  const adjustedMaxDate = maxdDate ? new Date(handleDateTimeOffset(timezone, maxdDate)) : null;

  return (
    <>
      <Calendar
        // key={calendarKey}
        selectionMode={selectionMode}
        value={adjustedValue}
        // onTodayButtonClick={handleOnTodayButtonClicked}
        onChange={handleChange}
        minDate={adjustedMinDate}
        maxDate={adjustedMaxDate}
        showTime={showTime}
        timeOnly={timeOnly}
        {...rest}
      />
    </>
  );
}

export default CustomCalendar;
