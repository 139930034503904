import { toastConstant } from 'constants/toastmessage';
import { useToast } from 'context/ToastContext';
import { addTeamMember, deleteCaseTeamMember, deleteCaseTeamGroup } from 'services/CaseServices';
import { updateTeamMember } from 'services/teamManagement.service';

export default function useTeamMember({ caseDetails, isLeadSpecific, getDetails }) {
  const { addToast } = useToast();

  const handleTeamMemberLawftAcces = (data) => {
    let team_members = caseDetails?.team_member || [];
    let groups = caseDetails?.groups || [];
    if (data?.firm_user_id) {
      team_members = team_members.map((member) => {
        if (member?.firm_user_id === data?.firm_user_id) {
          member.notify_case_updates = !member?.notify_case_updates;
        }
        return member;
      });
    } else {
      groups = groups.map((group) => {
        if (group?.group_id === data?.group_id) {
          group.notify_case_updates = !group?.notify_case_updates;
        }
        return group;
      });
    }
    return updateTeamMemberService({ team_member: team_members, groups });
  };

  const updateTeamMemberService = async (sendData) => {
    //Automated Time entry code
    return updateTeamMember(sendData, isLeadSpecific, caseDetails?.case_id)
      .then(() => {
        typeof getDetails === 'function' && getDetails();
        addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.UPDATE_TEAM_MEMBER_SUCCESS);
      })
      .catch(() => {
        addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, toastConstant.message.UPDATE_TEAM_MEMBER_FAILURE);
      });
  };

  const deleteTeamMember = async (teamMember) => {
    if (teamMember?.firm_user_id) {
      return deleteCaseTeamMember(teamMember?.sk, teamMember?.pk, isLeadSpecific)
        .then(() => {
          typeof getDetails === 'function' && getDetails();
          addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.TEAM_MEMBER_DELETE_SUCCESS);
        })
        .catch(() => {
          addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, toastConstant.message.TEAM_MEMBER_DELETE_FAILED);
        });
    } else {
      return deleteCaseTeamGroup(teamMember?.sk, teamMember?.group_name, caseDetails?.case_id)
        .then(() => {
          typeof getDetails === 'function' && getDetails();
          addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.TEAM_MEMBER_DELETE_SUCCESS);
        })
        .catch(() => {
          addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, toastConstant.message.TEAM_MEMBER_DELETE_FAILED);
        });
    }
  };

  const addTeamMembers = async (selectedMembers) => {
    let old_team_member = null;
    if (isLeadSpecific && caseDetails?.lead_assign_to?.[0]) {
      old_team_member = caseDetails?.lead_assign_to?.[0];
    }

    return addTeamMember({ team_member: selectedMembers, old_team_member }, '', caseDetails?.case_id, isLeadSpecific)
      .then((response) => {
        typeof getDetails === 'function' && getDetails();
        addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.TEAM_MEMBER_CASE_ADD_SUCCESS);
      })
      .catch(() => {
        addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, toastConstant.message.TEAM_MEMBER_CASE_ADD_FAILED);
      });
  };

  const updateTeamMembers = async (data) => {
    return updateTeamMember(data, isLeadSpecific, caseDetails?.case_id)
      .then((response) => {
        typeof getDetails === 'function' && getDetails();
        addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.TEAM_MEMBER_CASE_ADD_SUCCESS);
      })
      .catch(() => {
        addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, toastConstant.message.TEAM_MEMBER_CASE_ADD_FAILED);
      });
  };

  return { handleTeamMemberLawftAcces, addTeamMembers, deleteTeamMember, updateTeamMembers };
}
