import React from 'react';

import { Controller } from 'react-hook-form';

import InputErrorMessage from 'components/UI/InputErrorMessage/InputErrorMessage';
import LabelName from 'components/UI/LabelName/LabelName';

import { useUserDetailsContext } from 'context/userDetailsContext';
import CustomCalendar from 'components/CustomCalendar/CustomCalendar';
import { roundToNextHalfHour_withoutNewDate } from 'utils/utility_functions/timezone';

function CourtEventDate({ control, trigger, errors, setValue }) {
  const { userDetails } = useUserDetailsContext();

  function changeTimeValues(time) {
    if (time) {
      let startTime = roundToNextHalfHour_withoutNewDate(time, userDetails?.timezone);
      setValue('meeting_start_time', startTime.format());
      let updatedTime = startTime;
      updatedTime.add(30, 'minutes').seconds(0).milliseconds(0);
      setValue('meeting_end_time', updatedTime.format());
    }
  }

  return (
    <div className="d-flex align-items-center py-2 flex-wrap">
      <div className="col-md-4 col-12">
        <LabelName required={true} htmlFor="when">
          When
        </LabelName>
      </div>
      <div className="col-md-8 col-12">
        <Controller
          name="when"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <CustomCalendar
              id={field.name}
              value={field.value}
              onChange={(e) => {
                field.onChange(e.value);
                changeTimeValues(e.value);
                trigger('when');
              }}
              minDate={new Date()}
              placeholder="Date"
              monthNavigator
              yearNavigator
              yearRange="1800:2150"
              className="input-shadow w-100"
              inputRef={field.ref}
            />
          )}
        />
      </div>
      <div className="offset-md-4">
        {errors?.when?.type === 'required' ? <InputErrorMessage>Please select event date</InputErrorMessage> : ''}
      </div>
    </div>
  );
}

export default CourtEventDate;
