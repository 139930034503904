import React, { useState } from 'react';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import moment from 'moment';

import constants from 'constants/index';
import GlobalLoader from 'components/GlobalLoader/GlobalLoader';
import ShareClientFormModal from 'modules/cases/Modal/ShareClientFormModal';
import { useCaseIntake } from 'modules/cases/hooks/useCaseIntake';
import { getPropByString } from 'utils/utils';
import { convertToSpecificTimeZoneFormat, convertToUTC, handleDateTimeOffset } from 'utils/utility_functions/timezone';
import { useUserDetailsContext } from 'context/userDetailsContext';

const statusStyles = {
  expired: {
    backgroundColor: '#FDECF2',
    color: '#83264A',
  },
  viewed: {
    backgroundColor: '#d7e8f6',
    color: '#006cc6',
  },
  submitted: {
    backgroundColor: '#D4ECD5',
    color: '#357B38',
  },
  sent: {
    backgroundColor: '#f0f0f0',
    color: '#000',
  },
};

const getStatusStyle = (rowData) => {
  const isExpired = moment(rowData?.lawft_link_expiry).isBefore(moment()) && !rowData?.is_submitted;

  if (isExpired) return statusStyles.expired;
  if (rowData?.form_status === 'viewed') return statusStyles.viewed;

  // Default style for statuses not explicitly mapped
  return statusStyles[rowData?.form_status] || { backgroundColor: '#f0f0f0', color: '#000' };
};

export default function Intake({ caseDetails, isLead }) {
  const [showShare, setShowShare] = useState(false);
  const { caseForms, paginatorTemplate, resendCaseForm, deleteIntake, viewCaseForm, copyToClipboard, loadList } = useCaseIntake(
    caseDetails?.case_id
  );
  const { userDetails } = useUserDetailsContext();

  const handleViewCaseForm = (item) => {
    viewCaseForm(item?.pk, item?.sk);
  };

  const actionBody = (item) => {
    const isSubmitted = Boolean(item?.is_submitted);
    const isExpired = moment(item?.lawft_link_expiry).isBefore(moment()) && !item?.is_submitted;
    return (
      <>
        <span className="p-column-title text-break">Action</span>
        <div className="d-inline-table">
          <a
            className={`text-decoration-none pointer tooltip-intake ${!isSubmitted ? 'disabled-icon' : ''}`}
            data-pr-position="bottom"
            data-pr-tooltip="Download"
            onClick={() => isSubmitted && handleViewCaseForm(item)}
          >
            <span className="pi pi-download" style={{ fontSize: 14, marginbottom: 1.5, marginRight: 12, color: '#7F8F9F' }}></span>
          </a>
          <a
            className={`text-decoration-none pointer tooltip-intake ${isSubmitted ? 'disabled-icon' : ''}`}
            data-pr-position="bottom"
            data-pr-tooltip="Resend Form"
            onClick={() => !isSubmitted && resendCaseForm(item?.sk, 'true')}
          >
            <i className="pi pi-envelope me-3" />
          </a>
          <a
            className={`text-decoration-none pointer tooltip-intake ${isSubmitted || isExpired ? 'disabled-icon' : ''}`}
            data-pr-position="bottom"
            data-pr-tooltip="Copy Link"
            onClick={() => !(isSubmitted || isExpired) && copyToClipboard(item?.lawft_link_endpoint)}
          >
            <i className="pi pi-link me-3" />
          </a>
          <a
            className="text-decoration-none pointer tooltip-intake"
            data-pr-tooltip="Delete"
            data-pr-position="bottom"
            style={{ fontSize: 17 }}
            onClick={() => deleteIntake(item)}
          >
            <i className="icon-delete" />
          </a>
        </div>
      </>
    );
  };

  const DateCell = (row, ...rest) => {
    const { header, field } = rest?.[0];
    return (
      <React.Fragment>
        <span className="p-column-title text-break">{header}</span>
        <span
          className="text-break"
          title={
            getPropByString(row, field)
              ? handleDateTimeOffset(userDetails?.timezone, getPropByString(row, field), constants?.month_date_year_time_12_format_2)
              : undefined
          }
        >
          {getPropByString(row, field)
            ? handleDateTimeOffset(userDetails?.timezone, getPropByString(row, field), constants?.month_date_year_time_12_format_2)
            : '-'}
        </span>
      </React.Fragment>
    );
  };

  const statusBody = (rowData) => {
    const statusStyle = getStatusStyle(rowData);

    return (
      <React.Fragment>
        <span className="p-column-title text-break">Status</span>
        <span
          className="text-break text-capitalize"
          style={{
            ...statusStyle,
            padding: '5px',
            borderRadius: '5px',
          }}
        >
          {rowData?.form_status}
        </span>
      </React.Fragment>
    );
  };

  const getExpiryStatus = (rowData) => {
    const isSubmitted = Boolean(rowData?.is_submitted);
    const expiryDate = moment(rowData?.lawft_link_expiry);
    const currentDateUTC = moment(convertToUTC(userDetails?.timezone));
    const expiryUTC = moment.utc(expiryDate);

    const currentDateString = currentDateUTC.toISOString();
    const expiryDateString = expiryUTC.toISOString();

    if (expiryDateString <= currentDateString) {
      return <span className="text-expire">Expired</span>;
    }

    const duration = moment.duration(expiryUTC.diff(currentDateUTC));
    const minutes = Math.ceil(duration.asMinutes());
    const hours = Math.ceil(duration.asHours());
    if (isSubmitted) {
      return (
        <>
          <span className="p-column-title text-break">Status</span>
          <span>-</span>
        </>
      );
    }

    if (minutes <= 0) {
      return <span className="text-expire">Expired</span>;
    } else if (minutes <= 1440 && hours <= 1) {
      return (
        <span className="text-expire">
          Expires in {minutes} {minutes === 1 ? 'minute' : 'minutes'}
        </span>
      );
    } else if (!minutes <= 1440 && hours >= 1 && hours <= 24) {
      return (
        <span className="text-expire">
          Expires in {hours} {hours === 1 ? 'hour' : 'hours'}
        </span>
      );
    } else {
      return (
        <>
          <span className="F-size12 black-500">Expires on</span>
          <br />
          <span>{convertToSpecificTimeZoneFormat(expiryUTC, userDetails?.timezone)}</span>
        </>
      );
    }
  };

  return (
    <div className="container-fluid input-shadow bg-white p-3">
      {caseForms?.length > 0 && <Tooltip target=".tooltip-intake" style={{ bottom: 0, left: 0 }} />}
      <GlobalLoader />
      <div className="col-12">
        <div className="d-flex align-items-center justify-content-center">
          <div className="d-flex justify-content-center align-items-center">
            <span className="caption-bold mb-0">Forms</span>
          </div>
          <Button
            label="Share form"
            icon="pi pi-share-alt"
            className="button p-button p-button-primary ms-auto"
            onClick={() => setShowShare(true)}
          />
        </div>
      </div>
      <div className="col-12 datatable-responsive">
        <DataTable
          value={caseForms || []}
          responsiveLayout="scroll"
          className="p-datatable-responsive"
          emptyMessage="No Data Found."
          paginator
          rowHover
          paginatorTemplate={paginatorTemplate}
          rows={10}
        >
          <Column
            field="form_data.form_name"
            header="Name"
            body={(rowData) => <span title={rowData.form_data.form_name}>{rowData.form_data.form_name}</span>}
            sortable
            bodyClassName="ellipsis-text"
          />
          <Column field="time" header="Created" body={DateCell} bodyClassName="ellipsis-text" />
          <Column field="form_status" header="Status" body={statusBody} sortable style={{ width: '180px' }} />
          <Column field="form_status" header="Expiry" body={getExpiryStatus} sortable className="forms" />
          <Column header="Actions" body={actionBody} style={{ width: '150px' }} />
        </DataTable>
      </div>
      {showShare && (
        <ShareClientFormModal
          callBack={() => loadList({})}
          pa={caseDetails?.case_description?.case_practice_area}
          case_id={caseDetails?.case_id}
          onHide={() => setShowShare(false)}
          isLead={isLead}
        />
      )}
    </div>
  );
}
