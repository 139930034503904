import React, { useState, useEffect, useContext } from 'react';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { listExpenses, deleteExpense } from 'services/billingServices';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { useToast } from 'context/ToastContext';
import { toastConstant } from 'constants/toastmessage';
import useSpinner from 'hooks/useSpinner';
import { Dropdown } from 'primereact/dropdown';
import { dollarFormat, getTenantDetails, paginationDropdownOptions } from 'utils/utils';
import ImageComponent from 'shared/ImageComponent';
import { MultiSelect } from 'primereact/multiselect';
import { useDispatch, useSelector } from 'react-redux';
import { getPracticeAreaAction } from 'redux/actions/PracticeAreaAction';
import { totalCountEvent } from 'services/generalServices';
import { Tooltip } from 'primereact/tooltip';
import {
  convertToTimezoneWithoutOffset_EndOf,
  convertToTimezoneWithoutOffset_StartOf,
  handleDateTimeOffset,
} from 'utils/utility_functions/timezone';
import constants from 'constants/index';
import { UserDetailsContext } from 'context/userDetailsContext';
import { CreateExpense } from './CreateExpense/CreateExpense';
import { handlePageNumberInPagination } from 'common/pagination';
import { expenseStatusOptions } from 'constants/dropdownOptions';
import TextSnippetWrapper from 'components/TextSnippetWrapper';
import CustomCalendar from 'components/CustomCalendar/CustomCalendar';

const Expenses = (props) => {
  const {
    isLeadSpecific,
    isLeadClosed,
    isFromCaseSpecificModule,
    handleExpenseTableRedirection,
    getTimeEntrySummarty,
    isFromGlobalBilling,
  } = props;
  const userContext = useContext(UserDetailsContext);
  const [modal, setModal] = useState(false);
  const [expenseList, setExpenseList] = useState([]);
  const [selectedExpense, setSelectedExpense] = useState();
  const [confirmDelete, setConfirmDelete] = useState(false);
  const { addToast } = useToast();
  const dispatch = useDispatch();
  const [spinner, showSpinner, hideSpinner] = useSpinner(true);
  const [searchKey, setSearchKey] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedStatus, setSelectedStatus] = useState();
  const [selectedPractice, setSelectedPractice] = useState(null);
  const [pageLimit, setPageLimit] = useState(getPageLimit() ?? 10);
  const [isForward, setIsForward] = useState(true);
  const practiceData = useSelector((state) => state.practiceArea.practiceAreaList['practice-area']);
  const [practiceAreaOptions, setPracticeAreaOptions] = useState([]);
  const next_Token = expenseList.next_token;
  const transition_Token = expenseList.transition_token;
  const [tenantId] = getTenantDetails();

  function getPageLimit() {
    if (isLeadSpecific && userContext) {
      return userContext?.firmDetails?.pagination_preference?.leads?.billings?.expenses;
    } else if (isFromCaseSpecificModule && userContext) {
      return userContext?.firmDetails?.pagination_preference?.case?.billings?.expenses;
    } else if (userContext) {
      return userContext?.firmDetails?.pagination_preference?.billings?.expenses;
    }
  }

  function getFilePathForPagination() {
    if (isLeadSpecific && userContext) {
      return { module: 'leads', subModule: 'billings', listName: 'expenses' };
    } else if (isFromCaseSpecificModule && userContext) {
      return { module: 'case', subModule: 'billings', listName: 'expenses' };
    } else {
      return { module: 'billings', subModule: '', listName: 'expenses' };
    }
  }

  const loadExpenseData = (filter, pageLimit, pagination_token, pagination_direction) => {
    showSpinner();
    if (props.caseView) {
      filter.case_id = props?.caseDetails?.case_id;
    }
    listExpenses(filter, pageLimit, pagination_token, pagination_direction)
      .then((response) => {
        if (response?.data) {
          let expenses = response.data;
          setExpenseList(expenses);
          hideSpinner();
        }
      })
      .catch((err) => {
        hideSpinner();
      });
  };

  const caseBody = (rowData, ...rest) => {
    const { case_id, is_lead } = rowData;
    const mainModuleName = is_lead ? '/leads' : '/cases';
    let route = case_id ? `${mainModuleName}/${case_id}` : mainModuleName;

    return (
      <React.Fragment>
        <span className="p-column-title text-break">Case</span>
        <span
          onClick={(e) => {
            handleExpenseTableRedirection(e, rowData, route);
          }}
          // className="text-break pointer F-size14 text-bold call-back text-decoration-none"
          className="redirection-link-no-text-transform"
          title={rowData.case_name ? rowData.case_name : undefined}
        >
          {rowData.case_name ? rowData.case_name : '-'}
        </span>
      </React.Fragment>
    );
  };
  const typeBody = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title text-break">Type</span>
        <span className="text-break" title={rowData.expense_type}>
          {rowData.expense_type}
        </span>
      </React.Fragment>
    );
  };

  const teamMemberBody = (rowData) => {
    const firmUserId = rowData?.team_member_id || rowData?.firm_user_id;
    return (
      <React.Fragment>
        <div
          className="d-inline-flex"
          onClick={(e) => {
            const route = firmUserId ? `/settings/team-management/${firmUserId}` : '/settings/team-management';
            handleExpenseTableRedirection(e, rowData, route);
          }}
        >
          <span className="p-column-title text-break">Team Member</span>
          <span className="d-flex">
            <ImageComponent filePath={`${tenantId}/${firmUserId}/profile_image`} fileName={rowData.team_member_name} fileSize="small.jpg" />
            <span
              className="redirection-link-no-text-transform"
              title={rowData.team_member_name}
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                width: '200px',
              }}
            >
              {firmUserId && <i className="fas fa-user-tie me-2"></i>}
              {rowData.team_member_name}
            </span>
          </span>

          {/* <Link
          to={`/settings/team-management/${rowData?.team_member_id}`}
          className="text-break pointer F-size14 text-bold call-back text-decoration-none"
        >
          {rowData?.team_member_name}
        </Link> */}
        </div>
      </React.Fragment>
    );
  };
  const statusBody = (rowData) => {
    let status = '';
    if (rowData?.expense_status === 'UNINVOICED') {
      status = 'Uninvoiced';
    } else {
      status = 'Invoiced';
    }
    return (
      <React.Fragment>
        <span className="p-column-title text-break">Status</span>
        <span className="text-break" title={status}>
          {status}
        </span>
      </React.Fragment>
    );
  };

  const totalAmountBody = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title text-break">Total Amount</span>
        <span className="text-break" title={dollarFormat(rowData.total_amount)}>
          {dollarFormat(rowData.total_amount)}
        </span>
      </React.Fragment>
    );
  };

  const dateBody = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title text-break">Date</span>
        {rowData.expense_date ? (
          <span
            className="text-break"
            title={handleDateTimeOffset(userContext?.userDetails?.timezone, rowData.expense_date, constants.month_date_year_format)}
          >
            {handleDateTimeOffset(userContext?.userDetails?.timezone, rowData.expense_date, constants.month_date_year_format)}
          </span>
        ) : (
          'N/A'
        )}
      </React.Fragment>
    );
  };
  const actionBody = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title text-break">Action</span>

        <i
          className={'icon-edit cursor-pointer' + (props.isCaseClosed || (isLeadSpecific && isLeadClosed) ? ' pe-none opacity-50' : '')}
          onClick={() => {
            setSelectedExpense({ ...rowData });
            setModal(true);
          }}
          id="edit"
        ></i>
        <i
          className={
            'icon-delete ms-2 cursor-pointer ms-4 ' + (props.isCaseClosed || (isLeadSpecific && isLeadClosed) ? 'pe-none opacity-50' : '')
          }
          onClick={() => {
            setSelectedExpense({ ...rowData });
            setConfirmDelete(true);
          }}
          id="delete"
        ></i>
      </React.Fragment>
    );
  };

  const onCreateSuccess = () => {
    setModal(false);
    loadExpenseData(
      {
        case_id: '',
        expense_status: selectedStatus ? selectedStatus : '',
        expense_date_lower: '',
        expense_date_upper: '',
        expense_filter: 0,
        search_key: searchKey,
        practice_area: selectedPractice,
      },
      pageLimit,
      '',
      'forward'
    );
    typeof getTimeEntrySummarty === 'function' && getTimeEntrySummarty();
  };

  const pickExtractedDate = (dateArray) => {
    return {
      expense_date_lower: dateArray?.[0] ? convertToTimezoneWithoutOffset_StartOf(dateArray[0], userContext?.userDetails.timezone) : '',
      expense_date_upper: dateArray?.[0]
        ? convertToTimezoneWithoutOffset_EndOf(dateArray[1] ? dateArray[1] : dateArray[0], userContext?.userDetails.timezone)
        : '',
    };
  };

  const onDeleteExpense = () => {
    let sk = selectedExpense?.sk;
    sk = sk ? sk.replaceAll('#', '%23') : '';
    deleteExpense(sk, props.isLeadSpecific ? props.isLeadSpecific : selectedExpense?.is_lead)
      .then((response) => {
        addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.EXPENSE_DELETE_SUCCESS);
        loadExpenseData(
          {
            case_id: '',
            expense_status: selectedStatus ? selectedStatus : '',
            ...pickExtractedDate(selectedDate),
            expense_filter: 0,
            search_key: searchKey,
            practice_area: selectedPractice,
          },
          pageLimit,
          '',
          'forward'
        );
        typeof getTimeEntrySummarty === 'function' && getTimeEntrySummarty();
      })
      .catch((err) => {
        addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, toastConstant.message.EXPENSE_DELETE_FAILURE);
      });
  };

  const onChangeCalendar = (e) => {
    setSelectedDate(e.value);
    loadExpenseData(
      {
        case_id: '',
        expense_status: selectedStatus ? selectedStatus : '',
        ...pickExtractedDate(e.value),
        expense_filter: 0,
        search_key: searchKey,
        practice_area: selectedPractice,
      },
      pageLimit,
      '',
      'forward'
    );
  };

  const onSearchKeyDown = (e) => {
    let code = e.keyCode ? e.keyCode : e.which;
    if (code === 13) {
      loadExpenseData(
        {
          case_id: '',
          expense_status: selectedStatus ? selectedStatus : '',
          ...pickExtractedDate(selectedDate),
          expense_filter: 0,
          search_key: searchKey,
          practice_area: selectedPractice,
        },
        pageLimit,
        '',
        'forward'
      );
    }
  };

  const onChangeSearch = (e) => {
    let value = e.target.value;
    setSearchKey(value);
    if (!value) {
      loadExpenseData(
        {
          case_id: '',
          expense_status: selectedStatus ? selectedStatus : '',
          ...pickExtractedDate(selectedDate),
          expense_filter: 0,
          search_key: '',
          practice_area: selectedPractice,
        },
        pageLimit,
        '',
        'forward'
      );
    }
  };

  const onChangeStatus = (e) => {
    setSelectedStatus(e.value);
    loadExpenseData(
      {
        case_id: '',
        expense_status: e.value ? e.value : '',
        ...pickExtractedDate(selectedDate),
        expense_filter: 0,
        search_key: searchKey,
        practice_area: selectedPractice,
      },
      pageLimit,
      '',
      'forward'
    );
  };
  const onChangePractice = (e) => {
    setSelectedPractice([...e.value]);
    loadExpenseData(
      {
        case_id: '',
        expense_status: selectedStatus,
        ...pickExtractedDate(selectedDate),
        expense_filter: 0,
        practice_area: [...e.value],
        search_key: searchKey,
      },
      pageLimit,
      '',
      'forward'
    );
  };
  const refreshCase = () => {
    setSelectedStatus();
    setSearchKey('');
    setSelectedDate('');
    setSelectedPractice('');
    loadExpenseData(
      {
        case_id: '',
        expense_status: '',
        expense_date_lower: '',
        expense_date_upper: '',
        expense_date: '',
        expense_filter: 0,
        search_key: '',
        practice_area: '',
      },
      10,
      '',
      'forward'
    );
  };

  const paginatorTemplate = {
    layout: 'RowsPerPageDropdown PrevPageLink NextPageLink',
    RowsPerPageDropdown: (options) => {
      return (
        <React.Fragment>
          <span className="mx-1" style={{ color: 'var(--text-color)', userSelect: 'none' }}>
            Item Per Page
          </span>
          <Dropdown value={options.value} options={paginationDropdownOptions} onChange={onPageChange} />
        </React.Fragment>
      );
    },
    NextPageLink: ({ iconClassName }) => {
      return (
        <Button
          onClick={onNextPageClick}
          disabled={next_Token === null && isForward}
          className={'p-paginator-next p-paginator-element p-link'}
          icon={iconClassName}
        />
      );
    },
    PrevPageLink: ({ iconClassName }) => {
      return (
        <Button
          onClick={onPrevPageClick}
          disabled={transition_Token === null || (transition_Token && next_Token === null && !isForward)}
          className={'p-paginator-next p-paginator-element p-link'}
          icon={iconClassName}
        />
      );
    },
  };
  const onNextPageClick = () => {
    loadExpenseData(
      {
        case_id: '',
        expense_status: '',
        expense_date_lower: '',
        expense_date_upper: '',
        expense_date: '',
        expense_filter: 0,
        search_key: '',
        practice_area: '',
      },
      pageLimit,
      !isForward ? transition_Token : next_Token,
      'forward'
    );
    setIsForward(true);
  };
  const onPrevPageClick = () => {
    loadExpenseData(
      {
        case_id: '',
        expense_status: '',
        expense_date_lower: '',
        expense_date_upper: '',
        expense_date: '',
        expense_filter: 0,
        search_key: '',
        practice_area: '',
      },
      pageLimit,
      isForward ? transition_Token : next_Token,
      'reverse'
    );
    setIsForward(false);
  };
  const onPageChange = (event) => {
    setPageLimit(event.value);
    let path = getFilePathForPagination();
    handlePageNumberInPagination(userContext, path?.module, path?.subModule, path?.listName, event.value);
    loadExpenseData(
      {
        case_id: '',
        expense_status: '',
        expense_date_lower: '',
        expense_date_upper: '',
        expense_date: '',
        expense_filter: 0,
        search_key: '',
        practice_area: '',
      },
      event.value,
      '',
      'forward'
    );
  };

  useEffect(() => {
    loadExpenseData(
      {
        expense_status: selectedStatus ? selectedStatus : '',
        ...pickExtractedDate(selectedDate),
        search_key: searchKey,
        practice_area: selectedPractice,
      },
      pageLimit,
      '',
      'forward'
    );
    totalCountEvent('practice-area')
      .then((response) => {
        dispatch(getPracticeAreaAction(response.data));
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    if (practiceData && practiceData.length) {
      let practice_options = practiceData.map((val) => {
        return {
          label: val.practice_area_name,
          value: val.practice_area_name,
        };
      });
      setPracticeAreaOptions(practice_options);
    } else {
      setPracticeAreaOptions([]);
    }
  }, [practiceData]);

  return (
    <div>
      {spinner}
      <ConfirmDialog
        className="confirm-delete-popup"
        visible={confirmDelete}
        onHide={() => setConfirmDelete(false)}
        header="Confirm Delete"
        rejectClassName="p-button-secondary outline"
        message={' Do you want to delete the expense data?'}
        icon="pi pi-exclamation-triangle"
        accept={() => {
          onDeleteExpense();
        }}
        reject={() => setConfirmDelete(false)}
      />
      {modal && (
        <CreateExpense
          modal={modal}
          setModal={setModal}
          onCreateSuccess={onCreateSuccess}
          selectedExpense={selectedExpense}
          caseView={props.caseView}
          caseDetails={props.caseDetails}
          isLeadSpecific={props.isLeadSpecific}
        />
      )}
      <div className="container-fluid mx-0 p-0">
        <div className="">
          <div className="col-12">
            <div className="d-flex  align-items-center mb-3">
              {/*  <div className="d-flex justify-content-center align-items-center">
                <span className="font-bold me-2">Filters : </span>

                <Calendar id="icon" value={selectedDate} onChange={(e) => {onChangeCalendar(e)}} showIcon />
              </div> */}
              <Button
                label="Add Expense"
                icon="pi pi-plus"
                className="p-button p-button-primary ms-auto"
                onClick={() => {
                  setSelectedExpense();
                  setModal(true);
                }}
                disabled={props.isCaseClosed || (isLeadSpecific && isLeadClosed)}
              ></Button>
            </div>
          </div>
        </div>
        <div className="">
          <div className="d-flex flex-wrap align-items-center filter-wrap mb-3 p-lg-0 p-2 w-100 align-items-center justify-content-between">
            <div className="p-2 filter-wraper">
              <span className="p-input-icon-left input-shadow">
                <i className="icon-search F-size16"></i>
                <TextSnippetWrapper
                  type="text"
                  placeholder="Search"
                  onChange={(e) => {
                    onChangeSearch(e);
                  }}
                  value={searchKey}
                  onKeyDown={onSearchKeyDown}
                  className="p-inputtext p-component input-search"
                />
              </span>
            </div>
            <div className="d-flex flex-wrap align-items-center p-2 expense-filter filter-wraper">
              <label className="text-bold black-600 filter-label">Filters:</label>
              <div className="d-flex flex-column mx-lg-2 mx-0 filter-wraper">
                <CustomCalendar
                  selectionMode="range"
                  className="input-shadow filter-calender"
                  id="icon"
                  value={selectedDate}
                  onChange={(e) => {
                    onChangeCalendar(e);
                  }}
                  showIcon
                  placeholder="Expense Date"
                />
              </div>
              <div className="d-flex flex-column me-2 filter-wraper">
                <Dropdown
                  options={expenseStatusOptions}
                  onChange={onChangeStatus}
                  value={selectedStatus}
                  placeholder="Invoice Status"
                  className="searchable-dropdown"
                />
              </div>
              {!isFromCaseSpecificModule && (
                <div className="d-flex flex-column me-2 filter-wraper">
                  <MultiSelect
                    value={selectedPractice}
                    options={practiceAreaOptions}
                    onChange={onChangePractice}
                    placeholder="Practice Area"
                    maxSelectedLabels={1}
                    display="chip"
                    filter
                    className="input-shadow w-100 F-size14 searchable-dropdown"
                    style={{ height: '40px' }}
                  />
                </div>
              )}

              <div className="d-flex flex-column mx-2 filter-wraper text-center">
                <div className="pointer ms-0">
                  {/* <label className="font-bold mx-1 d-block">&nbsp;</label> */}
                  <i className="fas fa-sync-alt" onClick={refreshCase}></i>
                  <Tooltip content="Clear Filter" position="top" target=".fa-sync-alt" showDelay={500} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="">
          <div className="col-12 p-0 datatable-responsive">
            <DataTable
              value={expenseList.expenses}
              className="p-datatable-responsive"
              paginator
              paginatorTemplate={paginatorTemplate}
              rows={pageLimit}
            >
              {isFromGlobalBilling && (
                <Column header="Case/Lead" body={caseBody} clickable={true} clickableKey={'case_id'} bodyClassName="ellipsis-text" />
              )}
              <Column header="Type" body={typeBody} bodyClassName="ellipsis-text" />
              <Column header="Team Member" body={teamMemberBody} bodyClassName="ellipsis-text" />
              <Column header="Status" body={statusBody} bodyClassName="ellipsis-text" style={{ width: '180px' }} />
              <Column header="Total Amount" body={totalAmountBody} bodyClassName="ellipsis-text" />
              <Column header="Date" body={dateBody} bodyClassName="ellipsis-text" style={{ width: '180px' }} />
              <Column body={actionBody} style={{ width: '120px' }} />
            </DataTable>
            <Tooltip target={'#edit'} position={'top'} showDelay={500} className="case-tooltip mb-3">
              Edit
            </Tooltip>
            <Tooltip target={'#delete'} position={'top'} showDelay={500} className="case-tooltip mb-3">
              Delete
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Expenses;
