import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Storage, Auth } from 'aws-amplify';
import { FILEUPLOAD_S3, FILEUPLOAD_S3_LEVEL, SetS3Config } from 'config/amplify_config';
import { useToast } from 'context/ToastContext';
import PdfViewerComponent from 'modules/file_manager/PdfViewerComponent';
import { useFileManagerContext } from 'modules/file_manager/context/FileManagerContext';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Tooltip } from 'primereact/tooltip';
import { sendFaxRequest } from 'services/faxService';
import { toTitleCase } from 'utils/utils';
import { toastConstant } from 'constants/toastmessage';
import CustomHeader from './../CustomHeader';
import { useHistory, useLocation } from 'react-router-dom';
import TextSnippetWrapper from 'components/TextSnippetWrapper';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

function SendFaxModal() {
  const { modals, selectedItem, caseDetails, isCaseFileManager, isLeadFileManager, handleGetFiles, pageLimit, fileManagerFilters } =
    useFileManagerContext();

  const faxReqScheme = Yup.object().shape({
    recipientName: Yup.string().required('Recipient name is required'),
    recipientPhone: Yup.string().required('Recipient phone number is required'),
    message: Yup.string(),
    subject: Yup.string(),
    retryAttempts: Yup.number().required('Please select retry attempts'),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(faxReqScheme),
    defaultValues: {
      recipientName: '',
      recipientPhone: '',
      message: '',
      subject: '',
      retryAttempts: 1,
    },
  });

  const { showSendFaxModal, setShowSendFaxModal } = modals;

  const { addToast } = useToast();
  const history = useHistory();
  const location = useLocation();

  const [instance, setInstance] = useState(null);
  const [PSPDFKit, setPSPDFKit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [url, setUrl] = useState('');
  const [previewUrl, setPreviewUrl] = useState('');

  // Retry attempts options
  const retryOptions = [
    { label: '1 Attempt', value: 1 },
    { label: '2 Attempts', value: 2 },
    { label: '3 Attempts', value: 3 },
  ];

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);

      // Prepare the fax request data
      const faxData = {
        file_url: selectedItem.file_url,
        recipient_name: data.recipientName,
        recipient: data.recipientPhone,
        message: data.message,
        subject: data.subject,
        retry_count: data.retryAttempts,
        case_id: selectedItem.case_id || caseDetails?.case_id,
        file_path: selectedItem.file_path,
      };

      // Call the API to send the fax
      const response = await sendFaxRequest(faxData);

      if (response.status === 200) {
        addToast(false, toastConstant.toasterType.SUCCESS, 'Success', 'Fax sent successfully');
        setShowSendFaxModal(false);
        reset();
      } else {
        addToast(false, toastConstant.toasterType.ERROR, 'Error', 'Failed to send fax');
      }

      setIsLoading(false);
    } catch (error) {
      console.error('Error sending fax:', error);
      addToast(false, toastConstant.toasterType.ERROR, 'Error', 'Failed to send fax');
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setShowSendFaxModal(false);
    reset();
  };

  const renderFooter = (
    <div>
      <Button label="Cancel" icon="pi pi-times" onClick={handleClose} className="p-button-text" />
      <Button label="Send Fax" icon="pi pi-send" onClick={handleSubmit(onSubmit)} loading={isLoading} />
    </div>
  );

  return (
    <Dialog
      className="common-popup-style"
      header={<CustomHeader onHide={() => setShowSendFaxModal(false)} title="Send Fax" />}
      // header="Send Fax"
      visible={showSendFaxModal}
      style={{ width: '50vw' }}
      onHide={handleClose}
      footer={renderFooter}
      closeOnEscape={false}
      closable={true}
      modal
    >
      <div className="send-fax-container">
        <div className="d-flex justify-content-center">
          {/* <div className="col-md-6">
            <div className="file-preview-container mb-3">
              <h5>File Preview</h5>
              {isLoading ? (
                <div className="text-center p-4">
                  <i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem' }}></i>
                  <p>Loading preview...</p>
                </div>
              ) : previewUrl ? (
                <div className="preview-frame">
                  {selectedItem.file_name.toLowerCase().endsWith('.pdf') ? (
                    <iframe src={previewUrl} width="100%" height="400px" title="PDF Preview"></iframe>
                  ) : (
                    <div className="text-center p-4">
                      <img src={previewUrl} alt="File Preview" style={{ maxWidth: '100%', maxHeight: '400px' }} />
                    </div>
                  )}
                </div>
              ) : (
                <div className="text-center p-4">
                  <p>No preview available</p>
                </div>
              )}
              <div className="file-info mt-2">
                <p><strong>File Name:</strong> {selectedItem?.file_name}</p>
              </div>
            </div>
          </div> */}

          <div className="col-12">
            <div className="fax-form">
              <div className="mb-3">
                <label htmlFor="recipientName" className="form-label">
                  Recipient Name*
                </label>
                <Controller
                  name="recipientName"
                  control={control}
                  render={({ field }) => (
                    <InputText id="recipientName" className={`form-control ${errors.recipientName ? 'p-invalid' : ''}`} {...field} />
                  )}
                />
                {errors.recipientName && <small className="p-error">{errors.recipientName.message}</small>}
              </div>

              <div className="mb-3">
                <label htmlFor="recipientPhone" className="form-label">
                  Recipient Phone Number*
                </label>
                <Controller
                  name="recipientPhone"
                  control={control}
                  render={({ field }) => (
                    <InputText id="recipientPhone" className={`form-control ${errors.recipientPhone ? 'p-invalid' : ''}`} {...field} />
                  )}
                />
                {errors.recipientPhone && <small className="p-error">{errors.recipientPhone.message}</small>}
              </div>

              <div className="mb-3">
                <label htmlFor="retryAttempts" className="form-label">
                  Retry Attempts*
                </label>
                <Controller
                  name="retryAttempts"
                  control={control}
                  render={({ field }) => (
                    <Dropdown
                      id="retryAttempts"
                      options={retryOptions}
                      optionLabel="label"
                      className={`w-100 ${errors.retryAttempts ? 'p-invalid' : ''}`}
                      {...field}
                    />
                  )}
                />
                {errors.retryAttempts && <small className="p-error">{errors.retryAttempts.message}</small>}
              </div>

              <div className="mb-3">
                <label htmlFor="message" className="form-label">
                  Subject
                </label>
                <Controller
                  name="subject"
                  control={control}
                  render={({ field }) => <InputText id="subject" className={`form-control `} {...field} />}
                />
              </div>

              <div className="mb-3">
                <label htmlFor="message" className="form-label">
                  Message
                </label>
                <Controller
                  name="message"
                  control={control}
                  render={({ field }) => (
                    <InputTextarea id="message" rows={5} className="w-100" placeholder="Enter cover letter text (optional)" {...field} />
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export default SendFaxModal;
