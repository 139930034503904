/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useUserDetailsContext } from 'context/userDetailsContext';
import CustomFields from 'modules/lead/AddLead/components/ContactForm/CustomFields';

const LeadContactCustomField = ({ watch, leadDetails, setValue, control, clientStatus, defaultValues, contactCustomFieldList }) => {
  const [showCustomFields, setShowCustomFields] = useState(false);
  const { checkSubPermission } = useUserDetailsContext();

  useEffect(() => {
    if (watch('toggled_custom_field') === true) setShowCustomFields(false);
  }, [watch('toggled_custom_field')]);

  return (
    <>
      {checkSubPermission('custom_fields') && (
        <>
          {watch('client.custom_field')?.length > 0 && (
            <div className="row d-flex align-items-center mt-3">
              <div className="col-12 col-sm-7">
                <span className="caption-bold">Custom Fields</span>
              </div>
            </div>
          )}
          {contactCustomFieldList?.length === 0 ||
            (showCustomFields && watch('unassigned_custom_field')?.length === 0 && (
              <div className="row mb-2">
                <div className="col-12 small">
                  <i className="fas fa-info-circle mx-2 mt-1"></i>
                  <span className="text-hint">No Custom fields found.</span>
                </div>
              </div>
            ))}
          {watch('client.custom_field')?.length > 0 && (
            <CustomFields field="client.custom_field" control={control} fieldDisable={!leadDetails?.is_open || clientStatus} />
          )}
          {defaultValues?.client?.contact_type === 'location' || watch('unassigned_custom_field')?.length === 0 ? (
            ''
          ) : (
            <div className="d-flex align-items-center py-2">
              <div className="col-sm-12 addfield">
                <Button
                  disabled={!leadDetails?.is_open || defaultValues?.client?.contact_type === undefined || clientStatus}
                  type="button"
                  className="p-button button-text text-medium addfield ps-0"
                  onClick={(e) => {
                    e.preventDefault();
                    setShowCustomFields(!showCustomFields);
                  }}
                >
                  <i className={showCustomFields ? 'pi pi-minus F-size12 me-1' : 'icon-add F-size14 me-1 '}></i>Add Custom Field
                </Button>
              </div>
            </div>
          )}

          {watch('unassigned_custom_field')?.length > 0 && showCustomFields && (
            <CustomFields field="unassigned_custom_field" control={control} setValue={setValue} watch={watch} />
          )}
        </>
      )}
    </>
  );
};

export default LeadContactCustomField;
