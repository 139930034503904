import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';

import CustomHeader from './CustomHeader';
import LabelName from 'components/UI/LabelName/LabelName';
import InputErrorMessage from 'components/UI/InputErrorMessage/InputErrorMessage';

import { useFileManagerContext } from 'modules/file_manager/context/FileManagerContext';
import { createTemplate, getTemplates } from 'services/fileManager/fileManagerServices';
import { uuidv4 } from 'common/uuidGenerator';
import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { openLinkInBlank } from 'utils/utils';
import Cookies from 'universal-cookie';
import TextSnippetWrapper from 'components/TextSnippetWrapper';
const cookie = new Cookies();

function CreateDocumentModal() {
  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    clearErrors,
  } = useForm();
  const history = useHistory();

  const { modals, fileManagerFilters, caseDetails } = useFileManagerContext();
  const { showCreateDocumentModal, setShowCreateDocumentModal } = modals;

  const [templateList, setTemplateList] = useState([]);

  const createDocumentFromTemplateWatcher = watch('createDocumentFromTemplate');

  useEffect(() => {
    getTemplatesFn();
  }, []);

  const getTemplatesFn = () => {
    // console.log(caseDetails, 'JJJJ')
    // let params = {
    //   practice_area:
    // }
    let params = `&practice_area=${caseDetails?.case_description?.case_practice_area}&page=case`;
    getTemplates(params)
      .then(async (response) => {
        let arr = response.data.data;
        let templates = arr.map((item) => {
          return {
            label: item.display_name,
            value: `${item.pk} ${item.sk}`,
          };
        });
        let fromScratch = {
          label: 'Start with a blank document',
          value: '0',
        };
        setTemplateList([fromScratch, ...templates]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const createDocument = async (data) => {
    const tenantId = (await cookie.get('tenantId')) || null;
    let caseID;
    let caseName;
    if (history.location.pathname === '/file-manager') {
      caseID = fileManagerFilters.case?.id;
      caseName = fileManagerFilters.case?.case_description?.case_name;
    } else {
      caseID = caseDetails?.case_id;
      caseName = caseDetails?.case_description?.case_name;
    }
    // console.log(caseName,fileManagerFilters?.case, caseDetails, "CASEEEEEEE" )

    if (data.selectedTemplate && data.selectedTemplate !== '0') {
      let ids = data.selectedTemplate.split(' ');
      let file_s3_name = `${uuidv4()}.docx`;
      let file_location = `${tenantId}/root/${file_s3_name}`;
      let url = 'public/' + file_location;

      let selectedItem = {
        pk: ids[0],
        sk: ids[1],
        file_name: data.newFileName,
        file_path: url,
        folder_id: fileManagerFilters.selectedFolder,
        case_id: caseID,
        display_name: data.newFileName,
      };
      localStorage.setItem('selectedTemplate', JSON.stringify(selectedItem));

      history.push({
        pathname: '/templates/generate',
        state: {
          selectedItem,
          selectedCase: caseID || '',
          createDocFromTemplate: data.selectedTemplate !== '0',
          from: history.location?.pathname,
          caseName,
        },
      });
      return;
    }
    localStorage.removeItem('file_url');
    localStorage.removeItem('file_name');
    localStorage.removeItem('file_size');
    localStorage.removeItem('file_type');
    localStorage.setItem('mode', 'blank');
    let new_id = uuidv4();
    localStorage.setItem('file_url', `${new_id}.docx`);
    let user = await Auth.currentAuthenticatedUser();
    let url_docx = `public/${tenantId}/root/templates/${new_id}.docx`;

    let file_details = {
      file_name: data.newFileName + '.docx',
      display_name: data.newFileName + '.docx',
      size: '',
      file_path: url_docx,
      file_type: 'docx',
      created_by: user.attributes,
      version_id: '',
      file_path_docx: url_docx,
      conditional_fields: [],
    };
    let attachments = [];
    attachments.push(file_details);

    let paylaod = {
      type: 'file',
      files: attachments,
      case_id: caseID || 'undefined',
      mode: 'blank',
    };
    let response = await createTemplate(paylaod);
    if (response?.data) {
      localStorage.setItem('file_url', response.data.data[0].file_path);
      localStorage.setItem('pk', response.data.data[0].pk);
      localStorage.setItem('sk', response.data.data[0].sk);
      localStorage.setItem('file_name', data.newFileName);

      let pathname = '/templates/builder';
      let state = { type: 'blank', save_as: 'file', case_id: caseID, from: history.location?.pathname, caseName };
      if (!openLinkInBlank(pathname, state)) {
        history.push({
          pathname,
          state,
        });
      }
    }
  };

  const footer = (
    <div>
      <Button label="Cancel" onClick={() => setShowCreateDocumentModal(false)} className="p-button p-button-secondary outline mx-2" />
      <Button label="Create" type="submit" onClick={handleSubmit(createDocument)} className="p-button p-button-secondary" />
    </div>
  );

  return (
    <Dialog
      className="common-popup-style"
      header={<CustomHeader onHide={() => setShowCreateDocumentModal(false)} title="Create Document" />}
      footer={footer}
      visible={showCreateDocumentModal}
      style={{ width: '40vw' }}
      modal
      onHide={() => {
        setShowCreateDocumentModal(false);
      }}
      closable={false}
    >
      <div className="d-flex align-items-center py-2 flex-wrap">
        <div className="col-md-4 col-12">
          <LabelName required={true} htmlFor="newFileName">
            File Name
          </LabelName>
        </div>
        <div className="col-md-8 col-12">
          <Controller
            name="newFileName"
            control={control}
            defaultValue={''}
            rules={{ required: true }}
            render={({ field }) => (
              <TextSnippetWrapper
                type="text"
                {...field}
                onChange={(e) => {
                  field.onChange(e);
                }}
                placeholder="Document Name"
                className="mt-2 w-100 input-shadow"
              />
            )}
          />
          {errors.newFileName && <InputErrorMessage>Required</InputErrorMessage>}
        </div>
      </div>

      {/* <div className="d-flex align-items-center py-2 flex-wrap">
        <div className="col-md-4 col-12"></div>
        <div className="col-md-8 col-12">
          <Controller
            name="createDocumentFromTemplate"
            control={control}
            defaultValue={false}
            render={({ field }) => (
              <Checkbox
                id={field.name}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e.checked);
                  e.target.checked && clearErrors('selectedTemplate');
                }}
                checked={field.value}
              />
            )}
          />
          <LabelName className="ms-2">Create Document from a template</LabelName>
        </div>
      </div> */}

      <div className="d-flex align-items-center py-2 flex-wrap">
        <div className="col-md-4 col-12">
          <LabelName required={createDocumentFromTemplateWatcher ? true : false} htmlFor="selectedTemplate">
            Select a template
          </LabelName>
        </div>
        <div className="col-md-8 col-12">
          <Controller
            name="selectedTemplate"
            control={control}
            rules={{ required: createDocumentFromTemplateWatcher ? true : false }}
            render={({ field }) => (
              <Dropdown
                filter
                className="w-100 "
                placeholder="Select a template"
                id={field.name}
                value={field.value}
                onChange={(e) => field.onChange(e.value)}
                options={templateList}
                optionLabel="label"
              />
            )}
          />
          {errors.selectedTemplate && createDocumentFromTemplateWatcher && <InputErrorMessage>Required</InputErrorMessage>}
        </div>
      </div>
    </Dialog>
  );
}

export default CreateDocumentModal;
