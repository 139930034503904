import React, { useContext } from 'react';

import LabelName from 'components/UI/LabelName/LabelName';

import { UserDetailsContext } from 'context/userDetailsContext';
import { handleDateTimeOffset } from 'utils/utility_functions/timezone';
import constants from 'constants/index';

function GooglePreview({ eventDetails }) {
  const userContext = useContext(UserDetailsContext);

  let conference_url = eventDetails?.conference_url;

  function navigateTo(e) {
    e.preventDefault();
    let url = btoa(eventDetails?.google_event_id + ' ' + eventDetails?.calendar_id);
    url = url.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
    window.open(`https://calendar.google.com/calendar/event?eid=${url}`, '_blank');
  }

  return (
    <form>
      {/* form heading */}
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div className="modal-heading F-size16 text-bold">Google Meet</div>
      </div>
      {/* title */}
      <div className="d-flex align-items-center py-2 flex-wrap">
        <div className="col-md-4 col-12 ">
          <LabelName required={false} htmlFor="title">
            Event Title
          </LabelName>
        </div>
        <div className="col-md-8 col-12 input-style">{eventDetails?.title}</div>
      </div>
      <div className="d-flex align-items-center py-2 flex-wrap">
        <div className="col-md-4 col-12">
          <LabelName required={false} htmlFor="title">
            Date
          </LabelName>
        </div>
        <div className="col-md-8 col-12 input-style">
          {handleDateTimeOffset(userContext.userDetails.timezone, eventDetails?.meeting_start_time, constants.month_date_year_format)}
        </div>
      </div>
      <div className="d-flex align-items-center py-2 flex-wrap">
        <div className="col-md-4 col-12">
          <LabelName required={false} htmlFor="title">
            Time
          </LabelName>
        </div>
        <div className="col-md-8 col-12 d-flex gap-3 align-items-center">
          <div className="w-100 input-style">
            {handleDateTimeOffset(userContext.userDetails.timezone, eventDetails?.meeting_start_time, constants.hour_minute_12_format)}
          </div>
          <div className="text-bold">-</div>
          <div className="w-100 input-style">
            {handleDateTimeOffset(userContext.userDetails.timezone, eventDetails?.meeting_end_time, constants.hour_minute_12_format)}
          </div>
        </div>
      </div>
      {eventDetails?.location && (
        <div className="d-flex align-items-center py-2 flex-wrap">
          <div className="col-md-4 col-12 ">
            <LabelName required={false} htmlFor="title">
              Location
            </LabelName>
          </div>
          <div className="col-md-8 col-12 input-style">{eventDetails?.location}</div>
        </div>
      )}
      {eventDetails?.guests?.length > 0 && (
        <div className="d-flex align-items-center py-2 flex-wrap">
          <div className="col-md-4 col-12 ">
            <LabelName required={false} htmlFor="title">
              Attendees
            </LabelName>
          </div>
          <div className="col-md-8 col-12">
            {eventDetails?.guests?.map((v, i) => {
              return (
                <div className="input-style mb-2" key={i}>
                  {v?.email}
                </div>
              );
            })}
          </div>
        </div>
      )}
      {eventDetails?.description && (
        <div className="d-flex align-items-center py-2 flex-wrap">
          <div className="col-md-4 col-12 ">
            <LabelName required={false} htmlFor="title">
              Description
            </LabelName>
          </div>
          <div className="col-md-8 col-12 input-style">{eventDetails?.description}</div>
        </div>
      )}
      {conference_url && (
        <div className="d-flex align-items-center py-2 flex-wrap">
          <div className="col-md-4 col-12">
            <LabelName required={false} htmlFor="title">
              Meet link
            </LabelName>
          </div>
          <div className="col-md-8 col-12 d-flex gap-3 align-content-center">
            <a className="btn btn-primary" href={conference_url} target="_blank">
              Join With Google Meet
            </a>
          </div>
        </div>
      )}
      <div className="d-flex align-items-center py-2 flex-wrap">
        <div className="col-md-4 col-12">
          <LabelName required={false} htmlFor="title">
            {/* Calendar link */}
          </LabelName>
        </div>
        <div className="col-md-8 col-12 d-flex gap-3 align-content-center">
          <a href="http://" target="_blank" rel="noopener noreferrer" onClick={(e) => navigateTo(e)}>
            More Details
          </a>
        </div>
      </div>
    </form>
  );
}

export default GooglePreview;
