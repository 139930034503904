import React from 'react';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'modules/Tasks/components/TaskBoard/Board/Column';
import { formatNumbersToCurrencyString } from 'utils/utils';
import { InputText } from 'primereact/inputtext';
import { tableCell } from '../BillingDetails/helpers/dataTableCommonHelpers';

const AvailableTrustFund = (props) => {
  const { selectedInvoice, trustBalance, trustAccountData, setTrustAccountData, formErrors, setFormErrors, accountList } = props || {};
  return (
    <div>
      {/* TODO:doubt two tables are there */}
      {/* TODO:must fix direct state mutation */}
      {/* TODO:body and editor templates */}

      {!selectedInvoice && trustBalance?.trust_data?.length > 0 && trustAccountData && (
        <div className="mt-3">
          <div className="col-12 fw-bold">Available Trust Funds</div>
          <div className="col-12 shadow-small datatable-responsive">
            <DataTable value={[trustAccountData]} className="p-datatable-responsive">
              <Column header="Client" field="client_name" body={tableCell} />
              <Column
                header="Account"
                body={(rowData, props) => {
                  return (
                    <>
                      <span className="p-column-title text-break">Account</span>
                      <span>
                        <Dropdown
                          options={trustBalance.trust_data}
                          optionLabel="trust_account_name"
                          value={{
                            trust_account_id: rowData?.debit_account_id,
                            trust_account_name: rowData?.debit_account_name,
                            trust_balance: rowData?.amount_available,
                          }}
                          onChange={(e) => {
                            trustAccountData.debit_account_id = e?.target?.value?.trust_account_id;
                            trustAccountData.debit_account_name = e?.target?.value?.trust_account_name;
                            trustAccountData.amount_available = e?.target?.value?.trust_balance;
                            trustAccountData.amount_paid = 0;
                            setTrustAccountData({ ...trustAccountData });
                          }}
                          className="input-shadow w-100"
                          filter
                        />
                      </span>
                    </>
                  );
                }}
              />
              <Column
                header="Amount Available"
                body={(rowData) => {
                  return (
                    <>
                      <span className="p-column-title text-break">Amount Available</span>
                      <span className="text-break">{formatNumbersToCurrencyString(rowData?.amount_available)}</span>
                    </>
                  );
                }}
              />
              <Column
                header="Amount To Apply"
                body={(rowData) => {
                  return (
                    <>
                      <span className="p-column-title text-break">Amount To Apply</span>
                      <span className="text-break">{formatNumbersToCurrencyString(rowData?.amount_paid)}</span>
                    </>
                  );
                }}
                editor={(props) => {
                  return (
                    <div>
                      <InputText
                        className="input-shadow w-50"
                        placeholder="amount"
                        min={0}
                        max={props?.rowData?.trust_balance ? parseFloat(props?.rowData?.trust_balance) : 0}
                        type="number"
                        value={props?.rowData?.amount_paid}
                        onChange={(e) => {
                          let amount_paid = e.target.value;
                          amount_paid = parseFloat(amount_paid);
                          trustAccountData.amount_paid = amount_paid;
                          setTrustAccountData({ ...trustAccountData });
                          formErrors.amount_paid = '';
                          setFormErrors({ ...formErrors });
                        }}
                      />
                      {formErrors?.amount_paid && <span className="text-danger">{formErrors?.amount_paid}</span>}
                    </div>
                  );
                }}
              />
              <Column
                header="Balance"
                body={(rowData) => {
                  let amount_paid = rowData?.amount_paid;
                  let amount_available = rowData?.amount_available;
                  if (amount_paid && amount_available) {
                    let d = parseFloat(amount_available) - parseFloat(amount_paid);
                    return (
                      <>
                        <span className="p-column-title text-break">Balance</span>
                        <span className="text-break">{formatNumbersToCurrencyString(d)}</span>
                      </>
                    );
                  } else {
                    return (
                      <>
                        <span className="p-column-title text-break">Balance</span>
                        <span className="text-break">-</span>
                      </>
                    );
                  }
                }}
              />
              <Column
                header="Deposit Into"
                body={(rowData) => {
                  return (
                    <>
                      <span className="p-column-title text-break">Deposit Into</span>
                      <span>
                        <Dropdown
                          className="input-shadow w-100"
                          options={accountList}
                          optionLabel="account_name"
                          optionValue={(v) => ({ account_name: v?.account_name, account_id: v?.account_id })}
                          value={{
                            account_name: rowData?.credit_account_name,
                            account_id: rowData?.credit_account_id,
                          }}
                          onChange={(e) => {
                            let account_name = e?.target?.value?.account_name;
                            let account_id = e.target?.value?.account_id;
                            setTrustAccountData({ ...trustAccountData, credit_account_name: account_name, credit_account_id: account_id });
                            formErrors.credit_account_name = '';
                            setFormErrors({ ...formErrors });
                          }}
                          filter
                        />
                        {formErrors?.credit_account_name && <span className="text-danger">{formErrors?.credit_account_name}</span>}
                      </span>
                    </>
                  );
                }}
              />
            </DataTable>
          </div>
        </div>
      )}

      {selectedInvoice && trustAccountData?.amount_paid && (
        <div className="d-flex py-2 flex-wrap time-entries mt-3">
          <div className="col-12 caption-bold mb-3">Available Trust Funds</div>
          <div className="col-12 shadow-small">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Client Name</th>
                  <th>Account</th>
                  <th>Amount Paid</th>
                  <th>Deposit Into</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{trustAccountData?.client_name}</td>
                  <td>{trustAccountData?.debit_account_name}</td>
                  <td>{trustAccountData?.amount_paid}</td>
                  <td>{trustAccountData?.credit_account_name}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default AvailableTrustFund;
