export const dateFilterOptions = [
  { label: '3 months before and after', value: '3-months' },
  { label: 'All time', value: 'All time' },
  { label: 'Past due', value: 'past-due' },
];

export const priorityList = [
  { label: 'No Priority', value: 'No Priority' },
  { label: 'Low', value: 'Low' },
  { label: 'Medium', value: 'Medium' },
  { label: 'High', value: 'High' },
];

export const prioritySortList = [
  { label: 'High - Low', value: 'high' },
  { label: 'Low - High', value: 'low' },
];

export const timeIntervals = [
  { label: '1 day before', value: '1 day before' },
  { label: '3 days before', value: '3 days before' },
  { label: '7 days before', value: '7 days before' },
];

export const timeIntervalsWithServerValue = [
  { label: '1 day before', value: '1#days#', days: 1 },
  { label: '3 days before', value: '3#days#', days: 3 },
  { label: '7 days before', value: '7#days#', days: 7 },
];

// Constants from Contact Module
export const types = [
  { label: 'Person', value: 'person' },
  { label: 'Business', value: 'business' },
  { label: 'Location', value: 'location' },
];

export const businessTypes = [
  { label: 'Vendor', value: 'vendor' },
  { label: 'Medical Provider', value: 'medical_provider' },
  { label: 'Expert', value: 'expert' },
];

export const titles = [
  { label: 'Mr.', value: 'Mr.' },
  { label: 'Ms.', value: 'Ms.' },
  { label: 'Mrs.', value: 'Mrs.' },
  { label: 'Miss.', value: 'Miss.' },
];

export const contactTypes = [
  { label: 'Show All', value: 'All' },
  { label: 'Person', value: 'person' },
  { label: 'Location', value: 'location' },
  { label: 'Business', value: 'business' },
  { label: 'Vendor', value: 'vendor' },
  { label: 'Medical Provider', value: 'medical_provider' },
  { label: 'Expert', value: 'expert' },
];

export const status = [
  { label: 'Active', value: 'ACTIVE' },
  { label: 'Inactive', value: 'ARCHIVED' },
]; // for contact filter page

export const paginationDropdownOptions = [
  { label: 10, value: 10 },
  { label: 20, value: 20 },
  { label: 50, value: 50 },
  { label: 100, value: 100 },
];

export const leadsPagesOptions = [
  {
    label: 'Overview',
    value: 'dashboard',
    icon: 'icon-overview',
    icon_active: 'icon-overview-filled',
    path: '',
  },
  {
    label: 'Information',
    value: 'information',
    icon: 'icon-information',
    icon_active: 'icon-information-filled',
    path: 'info',
  },
  {
    label: 'Files',
    value: 'files',
    icon: 'icon-files',
    icon_active: 'icon-files-filled',
    path: 'files',
  },
  {
    label: 'Contacts',
    value: 'contacts',
    icon: 'icon-contacts',
    icon_active: 'icon-contacts-filled',
    path: 'contacts',
  },
  {
    label: 'Calendar',
    value: 'calendar',
    icon: 'icon-calendar',
    icon_active: 'icon-calendar-filled',
    path: 'calendar',
  },
  {
    label: 'Tasks',
    value: 'tasks',
    icon: 'icon-tasks',
    icon_active: 'icon-tasks-filled',
    path: 'task',
  },
  {
    label: 'Mail',
    value: 'mails',
    icon: 'bi bi-envelope',
    icon_active: 'bi bi-envelope',
    path: 'mails',
  },
  {
    label: 'Messages',
    value: 'messages',
    icon: 'icon-messages',
    icon_active: 'icon-messages-filled',
    path: 'messages',
  },
  {
    label: 'Text Messages',
    value: 'text-messages',
    icon: 'icon-textmessage',
    icon_active: 'icon-textmessage-filled',
    path: 'text-message',
  },
  {
    label: 'Forms',
    value: 'intake',
    icon: 'icon-documentPrepare',
    icon_active: 'icon-documentPrepare-filled',
    path: 'intake',
  },
  {
    label: 'Notes/Call Logs',
    value: 'notes',
    icon: 'icon-notes',
    icon_active: 'icon-notes-filled',
    path: 'note',
  },
  {
    label: 'Billing',
    value: 'billing',
    icon: 'icon-money',
    icon_active: 'icon-money-filled',
    path: 'billing',
  },
  {
    label: 'Activity Log',
    value: 'activitylog',
    icon: 'icon-activity',
    icon_active: 'icon-activity-filled',
    path: 'activity',
  },
];

export const casePageOptions = [
  {
    label: 'Overview',
    value: 'dashboard',
    icon: 'icon-overview',
    icon_active: 'icon-overview-filled',
    path: '',
  },
  {
    label: 'Information',
    value: 'information',
    icon: 'icon-information',
    icon_active: 'icon-information-filled',
    path: 'info',
  },
  {
    label: 'Files',
    value: 'files',
    icon: 'icon-files',
    icon_active: 'icon-files-filled',
    path: 'files',
  },
  {
    label: 'Contacts',
    value: 'contacts',
    icon: 'icon-contacts',
    icon_active: 'icon-contacts-filled',
    path: 'contacts',
  },
  {
    label: 'Calendar',
    value: 'calendar',
    icon: 'icon-calendar',
    icon_active: 'icon-calendar-filled',
    path: 'calendar',
  },
  {
    label: 'Tasks',
    value: 'tasks',
    icon: 'icon-tasks',
    icon_active: 'icon-tasks-filled',
    path: 'tasks',
  },
  {
    label: 'Mail',
    value: 'mails',
    icon: 'bi bi-envelope',
    icon_active: 'bi bi-envelope',
    path: 'mails',
  },
  {
    label: 'Messages',
    value: 'messages',
    icon: 'icon-messages',
    icon_active: 'icon-messages-filled',
    path: 'messages',
  },
  {
    label: 'Text Messages',
    value: 'text-messages',
    icon: 'icon-textmessage',
    icon_active: 'icon-textmessage-filled',
    path: 'text-messages',
  },
  {
    label: 'Forms',
    value: 'intake',
    icon: 'icon-documentPrepare',
    icon_active: 'icon-documentPrepare-filled',
    path: 'intake',
  },
  {
    label: 'Notes/Call Logs',
    value: 'notes',
    icon: 'icon-notes',
    icon_active: 'icon-notes-filled',
    path: 'notes',
  },
  {
    label: 'Medical / Case Expenses',
    value: 'expenses',
    icon: 'icon-expenses',
    icon_active: 'icon-expenses-filled',
    path: 'expenses',
  },
  {
    label: 'Billing',
    value: 'billing',
    icon: 'icon-money',
    icon_active: 'icon-money-filled',
    path: 'billing',
  },
  // { label: 'Timeline', value: "timeline", icon: 'icon-clock',icon_active: "icon-clock-filled" },
  {
    label: 'Activity Log',
    value: 'activitylog',
    icon: 'icon-activity',
    icon_active: 'icon-activity-filled',
    path: 'activity',
  },
];

export const npsSendingInterval = [
  { label: 'Monthly', value: 'monthly' },
  { label: 'Quarterly', value: 'quarterly' },
  { label: 'Bi-annually', value: 'bi-annually' },
  { label: 'Annually', value: 'annually' },
];

export const paymentFrequency = [
  { label: 'Weekly', value: 'weekly' },
  { label: 'Bi-weekly', value: 'biweekly' },
  { label: 'Monthly', value: 'monthly' },
  { label: 'Quarterly', value: 'quarterly' },
  { label: 'Yearly', value: 'yearly' },
];
export const reminderOptions = [
  { label: '30 minutes', value: '30#minutes#' },
  { label: '1 day', value: '1#days#' },
  { label: '2 days', value: '2#days#' },
  { label: '1 week', value: '1#weeks#' },
  { label: 'Custom', value: 'custom' },
];

export const invoiceReminderOptions = [
  { label: '30 minutes before due date', value: '30#minutes#' },
  { label: '1 day before due date', value: '1#days#' },
  { label: '2 days before due date', value: '2#days#' },
  { label: '1 week before due date', value: '1#weeks#' },
  { label: '30 minutes after due date', value: '30#minutes#past_due' },
  { label: '1 day after due date', value: '1#days#past_due' },
  { label: '2 days after due date', value: '2#days#past_due' },
  { label: '1 week after due date', value: '1#weeks#past_due' },
  { label: 'Custom', value: 'custom' },
];

export const reminderChannels = [
  { label: 'Lawft Notification', value: 'inapp_notification' },
  { label: 'Secure Message', value: 'secure_message' },
  { label: 'Text Message', value: 'text_message' },
];

export const reminderCustomOptions = [
  { label: 'Minutes', value: 'minutes' },
  { label: 'Hours', value: 'hours' },
  { label: 'Days', value: 'days' },
  { label: 'weeks', value: 'weeks' },
];

export const reminderInvoiceCustomOption = [
  { label: 'Before due date', value: '' },
  { label: 'After due date', value: 'past_due' },
];

export const goalFrequency = [
  { label: 'Weekly', value: 'weekly' },
  { label: 'Monthly', value: 'monthly' },
];

export const reminderCountOptions = Array.from({ length: 60 }, (_, i) => i + 1).map((index) => {
  return {
    label: index,
    value: index,
  };
});

export const calendarCategories = [
  { label: 'Show All', value: 'All' },
  {
    label: 'Event',
    value: 'event',
  },
  {
    label: 'Date of Birth',
    value: 'dob',
  },
  {
    label: 'Statute of Limitation',
    value: 'case',
  },
  {
    label: 'Task',
    value: 'task',
  },
];

export const expenseStatusOptions = [
  // { label: 'Invoiced/Uninvoiced', value: '' },
  { label: 'All', value: '' },
  { label: 'Invoiced', value: 'INVOICED' },
  { label: 'Uninvoiced', value: 'UNINVOICED' },
];
export const caseStatusOptions = [
  { label: 'Open', value: 'open' },
  { label: 'Closed', value: 'closed' },
  { label: 'All', value: 'all' },
];

export const caseStatusOptionsReport = [
  { label: 'Open', value: 'Open' },
  { label: 'Closed', value: 'Closed' },
  { label: 'All', value: '' },
];

export const leadStatusOptionsReport = [
  { label: 'All', value: 'both' },
  { label: 'Open', value: 'special' },
  { label: 'Closed', value: 'closed' },
  { label: 'Converted', value: 'open' },
];

export const refferalStatusReportOptions = [
  { label: 'All', value: '' },
  { label: 'Open', value: 'special' },
  { label: 'Closed', value: 'closed' },
];

export const timePastDueReportOptions = [
  { label: '0 - 30 days', value: 30 },
  { label: '31 - 60 days', value: 60 },
  { label: '61 - 90 days', value: 90 },
  { label: 'Over 90 days', value: 100 },
];

export const caseValueFilter = [
  { label: '0-5000', value: { price_range_lower: -1, price_range_upper: 5000 } },
  { label: '5001-10000', value: { price_range_lower: 5001, price_range_upper: 10000 } },
  { label: '10001-20000', value: { price_range_lower: 10001, price_range_upper: 20000 } },
  { label: '20001-50000', value: { price_range_lower: 20001, price_range_upper: 50000 } },
  { label: '50001-100,000', value: { price_range_lower: 50001, price_range_upper: 100000 } },
  { label: '100k-250k', value: { price_range_lower: 100001, price_range_upper: 250000 } },
  { label: '250k-500k', value: { price_range_lower: 250001, price_range_upper: 500000 } },
  { label: '500k-1M', value: { price_range_lower: 500001, price_range_upper: 1000000 } },
  { label: '1M-2M', value: { price_range_lower: 1000001, price_range_upper: 2000000 } },
  { label: '2M-5M', value: { price_range_lower: 1000001, price_range_upper: 5000000 } },
  { label: '5M-10M', value: { price_range_lower: 5000001, price_range_upper: 10000000 } },
  { label: '10M-25M', value: { price_range_lower: 10000001, price_range_upper: 25000000 } },
  { label: '25M+', value: { price_range_lower: 25000000, price_range_upper: 9999999999 } },
];

export const financeReportPaymentOptions = [
  { label: 'Payment Request', value: 'payment_request' },
  { label: 'Invoice', value: 'invoice' },
];

export const callTypeOptions = [
  { label: 'Incoming', value: 'incoming' },
  { label: 'Outgoing', value: 'outgoing' },
];

export const noteTypeOptions = [
  { name: 'Case Note', value: 'case_note' },
  { name: 'Call Log', value: 'call_log' },
];

export const notifyUpdateOptions = [
  {
    label: 'Daily',
    value: 'daily',
  },
  { label: 'Event Based', value: 'event_based' },
];

export const caseNoteCallLogFilterOptions = [
  { label: 'All', value: '' },
  { label: 'Notes', value: 'case_note' },
  { label: 'Call Log', value: 'call_log' },
];

export const paymentStructure = {
  FLAT_FEE: 'Flat Fee',
  SUBSCIPTION: 'Subscription',
  CONTINGENCY_FEE: 'Contingency Fee',
  EVERGREEN_RETAINER: 'Evergreen Retainer',
  STANDARD_RETAINER: 'Standard Retainer',
};
export const paymentMethods = [
  {
    label: 'Credit Card',
    value: 'Credit Card',
  },
  { label: 'Check', value: 'Check' },
  { label: 'Cash', value: 'Cash' },
  { label: 'Other', value: 'other' },
];

export const accountOptions = [
  { label: 'Trust', value: 'trust' },
  { label: 'Operating', value: 'operating' },
];

export const trackedTimeOptions = [
  { label: 'Today', value: 'day' },
  {
    label: 'This Week',
    value: 'week',
  },
  { label: 'This Month', value: 'month' },
  { label: 'This Quarter', value: 'quarterly' },
];

export const agendaOptions = [
  { label: 'Events', value: 'event' },
  { label: 'Tasks', value: 'task' },
];

export const taskOptions = [
  { label: 'Today', value: 'today' },
  { label: 'Upcoming', value: 'upcoming' },
  { label: 'Overdue', value: 'overdue' },
];

export const statusOptions = [
  { label: 'All', value: 'all' },
  { label: 'Invoiced', value: 'INVOICED' },
  { label: 'Uninvoiced', value: 'OPEN' },
];

export const billingOptions = [
  { label: 'All', value: '' },
  { label: 'Billable', value: 'billable' },
  { label: 'Non-Billable', value: 'nonbillable' },
];

export const msgStatusOptions = [
  { label: 'Show All', value: 'All' },
  { label: 'Read', value: true },
  { label: 'Unread', value: false },
];

export const practiceAreaStatusOptions = [
  { label: 'All', value: '' },
  { label: 'Archive', value: 'ARCHIVED' },
  { label: 'Unarchive', value: 'ACTIVE' },
];

export const teamMemberRoles = [
  { label: 'Lead Associate', value: 'is_lead_associate' },
  { label: 'Lead Attorney', value: 'is_lead_attorney' },
  { label: 'Lead Paralegal', value: 'is_lead_paralegal' },
  { label: 'Lead Case Manager', value: 'is_lead_case_manager' },
  { label: 'Attorney', value: '' },
];

export const portalOptions = [
  { label: 'Enable', value: 'enable' },
  { label: 'Disable', value: 'disable' },
];

export const sentimentOptions = [
  { label: 'Positive', value: 'POSITIVE' },
  { label: 'Negative', value: 'NEGATIVE' },
  { label: 'Mixed', value: 'MIXED' },
  { label: 'Neutral', value: 'NEUTRAL' },
];

export const participantOptions = [
  { label: 'Within Firm', value: 'within_firm' },
  { label: 'Client-Involved', value: 'client_involved' },
];

export const gratitudeOptions = [
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' },
];

export const generalFields = [
  {
    name: 'Short text',
    icon: 'bi bi-text-paragraph',
    type: 'short',
    field_type: 'general',
    label: 'Short text',
  },
  {
    name: 'Long text',
    icon: 'bi bi-text-paragraph',
    type: 'long',
    field_type: 'general',
    label: 'Long text',
  },
  {
    name: 'Dropdown',
    icon: 'bi bi-caret-down',
    type: 'dropdown',
    field_type: 'general',
    label: 'Dropdown',
  },
  {
    name: 'Dropdown (Multiselect)',
    icon: 'bi bi-caret-down',
    type: 'multiselect',
    field_type: 'general',
    label: 'Dropdown (Multiselect)',
  },
  {
    name: 'Single choice',
    icon: 'bi bi-record2',
    type: 'singlechoice',
    field_type: 'general',
    label: 'Single choice',
  },
  {
    name: 'Multiple choice',
    icon: 'bi bi-check-square',
    type: 'multiplechoice',
    field_type: 'general',
    label: 'Multiple choice',
  },
  {
    name: 'Number',
    icon: 'bi bi-123',
    type: 'number',
    field_type: 'general',
    label: 'Number',
  },
  {
    name: 'Date',
    icon: 'bi bi-calendar3-event',
    type: 'date',
    field_type: 'general',
    label: 'Date',
    dateFormat: 'mm/dd/yy',
  },
  {
    name: 'Time',
    icon: 'bi bi-clock',
    type: 'time',
    field_type: 'general',
    label: 'Time',
    period: 'AM',
  },
  {
    name: 'Phone number',
    icon: 'bi bi-telephone',
    type: 'phone',
    field_type: 'general',
    label: 'Phone number',
  },
  {
    name: 'Link',
    icon: 'bi bi-link-45deg',
    type: 'link',
    field_type: 'general',
    label: 'Link',
  },
  {
    name: 'Email',
    icon: 'bi bi-envelope',
    type: 'email',
    field_type: 'general',
    label: 'Email',
  },
  // {
  //   name: 'File upload',
  //   icon: 'bi bi-cloud-upload',
  //   type: 'file',
  //   field_type: 'general',
  //   label: 'File upload',
  // },
];

export const pageElements = [
  {
    name: 'Heading',
    icon: 'bi bi-type',
    type: 'heading',
    field_type: 'design-element',
  },
  {
    name: 'Paragraph',
    icon: 'bi bi-alphabet-uppercase',
    type: 'paragraph',
    field_type: 'general',
    label: 'Paragraph',
    alignment: 'left',
  },
  {
    name: 'Divider',
    icon: 'bi bi-dash-lg',
    type: 'divider',
    field_type: 'design-element',
  },
];

export const nameField = {
  name: 'Name',
  icon: 'bi bi-briefcase',
  type: 'name',
  field_type: 'contact',
  first_name_show: true,
  middle_name_show: true,
  last_name_show: true,
  required: true,
  relationFieldName: '',
};
export const generalContactFields = [
  nameField,
  {
    name: 'Date of birth',
    label: 'Date of birth',
    icon: 'bi bi-calendar3-event',
    type: 'date',
    field_type: 'contact',
    dateFormat: 'mm/dd/yy',
    field_name: 'contact_data.dob',
    relationFieldName: 'dob',
  },
  {
    name: 'Email',
    label: 'Email',
    icon: 'bi bi-envelope',
    type: 'email',
    field_type: 'contact',
    field_name: 'contact_data.email',
    relationFieldName: 'email',
  },
  {
    name: 'Phone number',
    icon: 'bi bi-telephone',
    type: 'phone',
    field_type: 'contact',
    label: 'Phone number',
    field_name: 'contact_data.phone',
    relationFieldName: 'phone',
  },
  {
    name: 'Additional phone number',
    icon: 'bi bi-telephone',
    type: 'phone',
    field_type: 'contact',
    label: 'Additional phone number',
    field_name: 'contact_data.additional_phone',
    relationFieldName: 'additional_phone',
  },
  {
    name: 'Address',
    icon: 'bi bi-geo-alt',
    type: 'address',
    field_type: 'contact',
    relationFieldName: '',
  },
];

export const roleField = {
  name: 'Role',
  label: 'Role',
  icon: 'bi bi-people',
  type: 'role',
  field_type: 'contact',
  required: true,
  relationFieldName: 'role',
};

export const generalContactFieldWithRole = [...generalContactFields, roleField];

export const practice_area_option = {
  name: 'Practice area',
  label: 'Practice area',
  type: 'practice_area',
  field_type: 'practice_area',
};

export const relationField = {
  name: 'Relation',
  label: 'Contact',
  type: 'relation',
  field_type: 'relation',
  subFields: [nameField, roleField],
};

export const DATE_FORMATS = [
  { label: 'Month/Day/Year (MM/DD/YYYY)', value: 'mm/dd/yy' },
  { label: 'Day/Month/Year (DD/MM/YYYY)', value: 'dd/mm/yy' },
  { label: 'Year-Month-Day (YYYY-MM-DD)', value: 'yy-mm-dd' },
  { label: 'Full Month Name, Day Year (e.g., January 1, 2024)', value: 'MM d, yy' },
  { label: 'Day Full Month Name, Year (e.g., 1 January, 2024)', value: 'd MM, yy' },
];

export const dateFormatMap = new Map([
  ['mm/dd/yy', 'MM/DD/YYYY'],
  ['dd/mm/yy', 'DD/MM/YYYY'],
  ['yy-mm-dd', 'YYYY-MM-DD'],
  ['MM d, yy', 'MMMM D, YYYY'],
  ['d MM, yy', 'D MMMM, YYYY'],
]);

export const periods = [
  { label: 'AM', value: 'AM' },
  { label: 'PM', value: 'PM' },
];

export const booleanOptions = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

export const justifyOptions = [
  { icon: 'pi pi-align-left', value: 'left' },
  { icon: 'pi pi-align-right', value: 'Right' },
  { icon: 'pi pi-align-center', value: 'Center' },
  { icon: 'pi pi-align-justify', value: 'Justify' },
];

export const conditionOptions = [
  { label: 'equals', value: 'equals' },
  { label: 'does not equal', value: 'does-not-equal' },
  { label: 'is empty', value: 'is-empty' },
  { label: 'is not empty', value: 'is-not-empty' },
  { label: 'contains', value: 'contains' },
  { label: 'does not contains', value: 'does-not-contains' },
];

export const conditionOptionForNumber = [
  { label: 'equals', value: 'equals' },
  { label: 'greater than', value: 'greater-than' },
  { label: 'less than', value: 'less-than' },
  { label: 'does not equal', value: 'does-not-equal' },
  { label: 'is empty', value: 'is-empty' },
  { label: 'is not empty', value: 'is-not-empty' },
  { label: 'contains', value: 'contains' },
  { label: 'does not contains', value: 'does-not-contains' },
];

export const conditionOptionForDate = [
  { label: 'equals', value: 'equals' },
  { label: 'greater than', value: 'greater-than' },
  { label: 'less than', value: 'less-than' },
  { label: 'does not equal', value: 'does-not-equal' },
  { label: 'is empty', value: 'is-empty' },
  { label: 'is not empty', value: 'is-not-empty' },
];

export const viewOptions = [
  { label: 'show', value: 'show' },
  { label: 'hide', value: 'hide' },
];

export const recurringEventFrequency = [
  { label: 'Daily', value: 'daily' },
  { label: 'Weekdays', value: 'weekdays' },
  { label: 'Weekly', value: 'weekly' },
  { label: 'Bi-weekly', value: 'bi-weekly' },
  { label: 'Monthly', value: 'monthly' },
  { label: 'Yearly', value: 'yearly' },
  { label: 'Nth Weekday Of Month', value: 'nth_weekday' },
];

export const recurringWeekOccurrence = [
  { label: '1st', value: '1' },
  { label: '2nd', value: '2' },
  { label: '3rd', value: '3' },
  { label: '4th', value: '4' },
  { label: '5th', value: '5' },
  { label: 'Last', value: 'last' },
];

export const weekdaysList = [
  { label: 'Monday', value: 'monday' },
  { label: 'Tuesday', value: 'tuesday' },
  { label: 'Wednesday', value: 'wednesday' },
  { label: 'Thursday', value: 'thursday' },
  { label: 'Friday', value: 'friday' },
  { label: 'Saturday', value: 'saturday' },
  { label: 'Sunday', value: 'sunday' },
];

export const stateOptions = [
  { label: 'Alaska', value: 'AK' },
  { label: 'Alabama', value: 'AL' },
  { label: 'Arkansas', value: 'AR' },
  { label: 'Arizona', value: 'AZ' },
  { label: 'California', value: 'CA' },
  { label: 'Colorado', value: 'CO' },
  { label: 'Connecticut', value: 'CT' },
  { label: 'District of Columbia', value: 'DC' },
  { label: 'Delaware', value: 'DE' },
  { label: 'Florida', value: 'FL' },
  { label: 'Georgia', value: 'GA' },
  { label: 'Hawaii', value: 'HI' },
  { label: 'Iowa', value: 'IA' },
  { label: 'Idaho', value: 'ID' },
  { label: 'Illinois', value: 'IL' },
  { label: 'Indiana', value: 'IN' },
  { label: 'Kansas', value: 'KS' },
  { label: 'Kentucky', value: 'KY' },
  { label: 'Louisiana', value: 'LA' },
  { label: 'Massachusetts', value: 'MA' },
  { label: 'Maryland', value: 'MD' },
  { label: 'Maine', value: 'ME' },
  { label: 'Michigan', value: 'MI' },
  { label: 'Minnesota', value: 'MN' },
  { label: 'Missouri', value: 'MO' },
  { label: 'Mississippi', value: 'MS' },
  { label: 'Montana', value: 'MT' },
  { label: 'North Carolina', value: 'NC' },
  { label: 'North Dakota', value: 'ND' },
  { label: 'Nebraska', value: 'NE' },
  { label: 'New Hampshire', value: 'NH' },
  { label: 'New Jersey', value: 'NJ' },
  { label: 'New Mexico', value: 'NM' },
  { label: 'Nevada', value: 'NV' },
  { label: 'New York', value: 'NY' },
  { label: 'Ohio', value: 'OH' },
  { label: 'Oklahoma', value: 'OK' },
  { label: 'Oregon', value: 'OR' },
  { label: 'Pennsylvania', value: 'PA' },
  { label: 'Puerto Rico', value: 'PR' },
  { label: 'Rhode Island', value: 'RI' },
  { label: 'South Carolina', value: 'SC' },
  { label: 'South Dakota', value: 'SD' },
  { label: 'Tennessee', value: 'TN' },
  { label: 'Texas', value: 'TX' },
  { label: 'Utah', value: 'UT' },
  { label: 'Virginia', value: 'VA' },
  { label: 'Vermont', value: 'VT' },
  { label: 'Washington', value: 'WA' },
  { label: 'Wisconsin', value: 'WI' },
  { label: 'West Virginia', value: 'WV' },
  { label: 'Wyoming', value: 'WY' },
];

export const faxPageOptions = [
  {
    label: 'Home',
    value: 'home',
    icon: 'icon-overview',
    icon_active: 'icon-overview-filled',
    path: '/fax',
  },
  {
    label: 'Sent',
    value: 'sent',
    icon: 'pi pi-inbox',
    icon_active: 'pi pi-inbox',
    path: '/fax/sent',
  },
  {
    label: 'Outbox',
    value: 'files',
    icon: 'pi pi-send',
    icon_active: 'pi pi-send',
    path: '/fax/outbox',
  },
];
