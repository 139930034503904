import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';

import { Button } from 'primereact/button';
import { Chips } from 'primereact/chips';
import { Card } from 'primereact/card';
import { Dropdown } from 'primereact/dropdown';
import { Tooltip } from 'primereact/tooltip';

import { useToast } from 'context/ToastContext';
import AppConstants from 'constants/index';
import { toastConstant } from 'constants/toastmessage';
import { createVenue } from 'services/Settings';
import { createContact } from 'services/contact';
import { useGlobalTagList } from 'hooks/useGlobalTags';
import { usePracticeAreaList } from 'hooks/usePracticeAreaList';
import { useVenueList } from 'hooks/useVenueList';
import { useOfficeLocation } from 'hooks/useOfficeLocation';
import { useCaseMatter } from 'modules/cases/hooks/useCaseMatter';
import TextSnippetWrapper from 'components/TextSnippetWrapper';
import { getLocalStorageItem } from 'utils/localStorageUtils';
import { totalCountEvent } from 'services/generalServices';
import { getPracticeAreas } from 'services/CaseServices';
import CustomCalendar from 'components/CustomCalendar/CustomCalendar';

const Summary = ({
  control,
  userDetails,
  caseDetails,
  isCaseClosed,
  watch,
  setValue,
  fetchCaseStage,
  caseStageList,
  loadCourtContacts,
  courtList,
  isLeadSpecific,
}) => {
  //----------- custom hook ----------
  const { addToast } = useToast();
  const { formattedGlobalTag } = useGlobalTagList();
  // const { practiceAreaList } = usePracticeAreaList(true);

  const { caseMatterList } = useCaseMatter();
  const { modifiedOfficeLocationList } = useOfficeLocation();
  const { venueOptions, loadVenue } = useVenueList();

  // -----------------useState ------------------------
  const [isAddCourtLoading, setIsAddCourtLoading] = useState(false);
  const [toggleCourt, setToggleCourt] = useState(false);

  const [toggleVenue, setToggleVenue] = useState(false);
  const [isAddVenueLoading, setIsAddVenueLoading] = useState(false);

  const [showGlobalTags, setShowGlobalTags] = useState(false);

  const [practiceAreaList, setPracticeAreaList] = useState([]);

  // ---------------custom function----------------------------

  const handleAddCaseCourt = () => {
    if (watch('case_description.court') && toggleCourt) {
      setIsAddCourtLoading(true);
      let data = {};
      data = {
        phone: '',
        title: watch('case_description.court'),
        contact_type: 'location',
        is_court_location: true,
        email: '',
        country_code: '+1',
      };
      createContact(data)
        .then((response) => {
          setToggleCourt(false);
          addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.CASE_COURT_CREATED);
          setIsAddCourtLoading(false);
          loadCourtContacts();
          setValue('case_description.court', data?.title);
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            addToast(false, toastConstant.toasterType.WARN, toastConstant.api.FAILED, error.response.data);
          }
          setIsAddCourtLoading(false);
        });
    } else {
      addToast(false, toastConstant.toasterType.INFO, toastConstant.api.FAILED, toastConstant.message.CASE_COURT_NAME_VALIDATION);
    }
  };

  const handleAddVenue = () => {
    if (watch('case_description.venue') && toggleVenue) {
      setIsAddVenueLoading(true);
      let data = {};
      data = {
        venue_name: watch('case_description.venue'),
        // is_lead :false
      };
      createVenue(data)
        .then((response) => {
          setToggleVenue(false);
          addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.VENUE_CREATED);
          setIsAddVenueLoading(false);
          loadVenue();
          setValue('case_description.venue', watch('case_description.venue'));
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            addToast(false, toastConstant.toasterType.WARN, toastConstant.api.FAILED, error.response.data);
          }
          setIsAddVenueLoading(false);
        });
    } else {
      addToast(false, toastConstant.toasterType.INFO, toastConstant.api.FAILED, toastConstant.message.VENUE_NAME_VALIDATION);
    }
  };

  const chipTemplate = (e) => {
    if (e) {
      try {
        let formattedTagData = e?.replaceAll('#', '');
        return <div>{'#' + formattedTagData}</div>;
      } catch (error) {
        return <div>{e}</div>;
      }
    }
  };

  const assignToItemTemplate = (option) => {
    if (option?.status === 'ARCHIVED') {
      return `${option?.practice_area_name} (Archived)`;
    }
    return option?.practice_area_name;
  };

  useEffect(() => {
    totalCountEvent('practice-area')
      .then((response) => {
        const tenantId = getLocalStorageItem('tenantId') || null;
        const tenant_sk = getLocalStorageItem('tenantSk') || null;
        let tenantSk = tenant_sk ? tenant_sk.replace('#', '%23') : '';
        const filters = {
          status: 'all',
        };
        // getPracticeAreas(tenantId, tenantSk, response?.data, '', 'forward')
        getPracticeAreas(tenantId, tenantSk, response?.data, '', 'forward', filters)
          .then((response) => {
            const practiceAreaOptions = response?.data?.['practice-area'] || [];

            // Apply filtering logic
            const filteredOptions = practiceAreaOptions.filter(
              (option) =>
                caseDetails?.case_description?.case_practice_area?.trim() === option?.practice_area_name.trim() ||
                option?.status === 'ACTIVE'
            );

            // Set the filtered practice areas
            setPracticeAreaList(filteredOptions);
          })
          .catch((err) => {});
      })
      .catch();
  }, [caseDetails]);

  // ------------------------------------------------ here start jsx code --------------------------------

  return (
    <>
      <div className="caption-bold mb-3">{isLeadSpecific ? 'Potential Case Details' : 'Summary'}</div>
      {/* summary---------------------------------------------------------------  */}
      <Card className="mb-4 shadow-middle F-size14">
        <div className="row align-items-center py-2 flex-wrap">
          <div className="col-md-4 col-12 text-muted p-medium">Case Name</div>
          <div className="col-md-8 col-12 d-flex align-items-center edit-field">
            <Controller
              name="case_description.case_name"
              control={control}
              render={({ field }) => (
                <TextSnippetWrapper
                  type="text"
                  className="input-shadow w-100 border-0"
                  placeholder="Enter Case Name"
                  {...field}
                  disabled={isCaseClosed || userDetails?.getPermission('cases') !== AppConstants.permissions.WRITE}
                />
              )}
            />
          </div>
        </div>
        <div className="row align-items-center py-2 flex-wrap">
          <div className="col-md-4 col-12 text-muted p-medium">Practice Area</div>
          <div className="col-md-8 col-12 d-flex align-items-center edit-field">
            <Controller
              name="case_description.case_practice_area"
              control={control}
              render={({ field }) => (
                <Dropdown
                  value={field.value}
                  // options={practiceAreaList}
                  options={practiceAreaList}
                  onChange={(e) => {
                    field.onChange(e.value);
                    fetchCaseStage(e.value);
                    let dat = caseStageList.find((val) => val.label === 'Open');
                    if (dat?.label && dat?.value) {
                      setValue('case_description.case_stage', dat?.value);
                    } else {
                      setValue('case_description.case_stage', 'Open');
                    }
                    const sk = practiceAreaList.find((val) => val?.practice_area_name === e.value)?.sk;
                    setValue('case_practice_area_sk', sk);
                  }}
                  disabled={isCaseClosed || userDetails?.getPermission('cases') !== AppConstants.permissions.WRITE}
                  optionLabel="practice_area_name"
                  optionValue="practice_area_name"
                  placeholder="Select Practice Area"
                  className="input-shadow w-100"
                  itemTemplate={assignToItemTemplate}
                  filter
                />
              )}
            />
          </div>
        </div>
        <div className="row align-items-center py-2 flex-wrap">
          <div className="col-md-4 col-12 text-muted p-medium">Case Matter</div>
          <div className="col-md-8 col-12 d-flex align-items-center edit-field">
            <Controller
              name="case_description.case_matter"
              control={control}
              render={({ field }) => (
                <Dropdown
                  name="case_matter"
                  value={field.value}
                  onChange={(event) => field.onChange(event.value)}
                  options={caseMatterList}
                  optionLabel="matter_name"
                  optionValue="matter_name"
                  placeholder="Select Case Matter"
                  disabled={isCaseClosed || userDetails?.getPermission('cases') !== AppConstants.permissions.WRITE}
                  className="flex-grow-1 w-75 border-0 input-shadow"
                />
              )}
            />
          </div>
        </div>

        <div className="row align-items-start py-2 flex-wrap">
          <div className="col-md-4 col-12 text-muted p-medium">Description</div>
          <div className="col-md-8 col-12 edit-field d-flex align-items-center">
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <TextSnippetWrapper
                  type="textarea"
                  rows={16}
                  cols={30}
                  value={field.value}
                  placeholder="Enter Description"
                  onChange={(e) => field.onChange(e.target.value)}
                  className="input-shadow w-100"
                  disabled={isCaseClosed || userDetails?.getPermission('cases') !== AppConstants.permissions.WRITE}
                />
              )}
            />
          </div>
        </div>
        <div className="row align-items-center py-2 flex-wrap">
          <div className="col-md-4 col-12 p-medium">Office Location</div>
          <div className="col-md-8 col-12">
            <Controller
              name="case_description.office_location"
              control={control}
              render={({ field }) => (
                <Dropdown
                  placeholder="Select Office Location"
                  value={field.value}
                  options={modifiedOfficeLocationList}
                  onChange={(e) => {
                    field.onChange(e.value);
                  }}
                  disabled={isCaseClosed}
                  optionValue="office_name"
                  optionLabel="office_nameLabel"
                  className="input-shadow border-0 w-100"
                  filter
                ></Dropdown>
              )}
            />
          </div>
        </div>
        <div className="row align-items-center py-2 flex-wrap">
          <div className="col-md-4 col-12 p-medium">
            <label className="p-medium">Court</label>
          </div>
          <div className="d-flex col-md-8 col-12">
            {toggleCourt ? (
              <div className="p-inputgroup align-items-center">
                <Controller
                  name="case_description.court"
                  control={control}
                  render={({ field }) => (
                    <TextSnippetWrapper
                      type="text"
                      value={field.value}
                      placeholder="Enter New Court"
                      onChange={(e) => field.onChange(e.target.value.trimStart())}
                      className="input-shadow w-100"
                    />
                  )}
                />
                <Button
                  icon="pi pi-check"
                  className="p-button-secondary mx-2"
                  type="button"
                  onClick={handleAddCaseCourt}
                  loading={isAddCourtLoading}
                />
                <Button
                  icon="pi pi-times"
                  className="p-button-secondary outline"
                  type="button"
                  onClick={() => {
                    setToggleCourt(false);
                    setValue('case_description.court', watch('case_description.court'));
                  }}
                  disabled={isAddCourtLoading}
                />
              </div>
            ) : (
              <>
                <Controller
                  name="case_description.court"
                  control={control}
                  render={({ field }) => (
                    <Dropdown
                      name="case_court"
                      value={field.value}
                      options={courtList}
                      optionLabel="title"
                      optionValue="title"
                      onChange={(e) => field.onChange(e.value)}
                      placeholder="Select Court"
                      className="input-shadow w-100"
                      filter
                      disabled={isCaseClosed}
                    />
                  )}
                />
              </>
            )}
            {userDetails.permissions.settings.add_court_location && !isCaseClosed && (
              <div className="col-1 col-md-1 px-1 d-flex align-items-center ms-1 w-auto">
                <span className="pointer">
                  <i
                    className="fas fa-plus-circle add-court"
                    onClick={() => {
                      setToggleCourt(true);
                      setValue('case_description.court', '');
                    }}
                  ></i>
                  <Tooltip content="Add Court" position="top" target=".add-court" showDelay={500} />
                </span>
              </div>
            )}
          </div>
        </div>
        <div className="row align-items-center py-2 flex-wrap">
          <div className="col-md-4 col-12 p-medium">
            <label className="p-medium">Venue</label>
          </div>
          <div className="d-flex col-md-8 col-12">
            {toggleVenue ? (
              <div className="p-inputgroup align-items-center">
                <Controller
                  name="case_description.venue"
                  control={control}
                  render={({ field }) => (
                    <TextSnippetWrapper
                      type="text"
                      value={field.value}
                      placeholder="Enter New Venue"
                      onChange={(e) => field.onChange(e.target.value.trimStart())}
                      className="input-shadow w-100"
                    />
                  )}
                />
                <Button
                  icon="pi pi-check"
                  className="p-button-secondary mx-2"
                  type="button"
                  onClick={handleAddVenue}
                  loading={isAddVenueLoading}
                />
                <Button
                  icon="pi pi-times"
                  className="p-button-secondary outline"
                  type="button"
                  onClick={() => {
                    setToggleVenue(false);
                    setValue('case_description.venue', watch('case_description.venue'));
                  }}
                  disabled={isAddVenueLoading}
                />
              </div>
            ) : (
              <>
                <Controller
                  name="case_description.venue"
                  control={control}
                  render={({ field }) => (
                    <Dropdown
                      name="venue"
                      value={field.value}
                      options={venueOptions}
                      optionLabel="venue_name"
                      optionValue="venue_name"
                      onChange={(e) => field.onChange(e.value)}
                      placeholder="Select Venue"
                      className="input-shadow w-100"
                      filter
                      disabled={isCaseClosed}
                    />
                  )}
                />
              </>
            )}
            {!isCaseClosed && (
              <div className="col-1 col-md-1 px-1 d-flex align-items-center ms-1 w-auto">
                <span className="pointer">
                  <i
                    className="fas fa-plus-circle add-venue"
                    onClick={() => {
                      setToggleVenue(true);
                      setValue('case_description.venue', '');
                    }}
                  ></i>
                  <Tooltip content="Add Venue" position="top" target=".add-venue" showDelay={500} />
                </span>
              </div>
            )}
          </div>
        </div>

        <div className="row align-items-center py-2 flex-wrap">
          <div className="col-md-4 col-12 text-muted p-medium">Date of Loss/Event</div>
          <div className="col-md-8 col-12 edit-field">
            <Controller
              name="case_description.date_of_loss_event"
              control={control}
              render={({ field: { value, onChange } }) => (
                <CustomCalendar
                  monthNavigator
                  yearNavigator
                  yearRange="1900:2100"
                  showButtonBar
                  dateFormat={AppConstants.date_format}
                  value={value}
                  onChange={(e) => onChange(e.value)}
                  className="rounded flex-grow-1 w-100 input-shadow"
                  disabled={isCaseClosed}
                  placeholder="Select"
                />
              )}
            />
          </div>
        </div>

        <div className="row align-items-center py-2 flex-wrap">
          <div className="col-md-4 col-12 text-muted p-medium">Tags</div>
          <div className="col-md-8 col-12 edit-field">
            <Controller
              name="tags"
              control={control}
              defaultValue={[]}
              render={({ field }) => (
                <Chips
                  itemTemplate={chipTemplate}
                  placeholder="Select Tags"
                  value={field.value}
                  onChange={(e) => {
                    let arr = [];
                    if (e?.value?.length) {
                      arr = e.value.map((val) => val.replace(/\s+/g, ''));
                    }
                    field.onChange([...new Set(arr || e.target.value)]);
                  }}
                  className="input-shadow w-100 taging lead-tags"
                  disabled={isCaseClosed || (caseDetails?.is_lead && !caseDetails?.is_open)}
                />
              )}
            />
          </div>
        </div>

        {userDetails.checkSubPermission('tags') && (
          <div className="row align-items-center py-2 flex-wrap">
            <div className="col-12 text-muted p-medium">
              <Button
                type="button"
                className="p-button button-text text-medium addfield ps-0"
                onClick={(e) => {
                  e.preventDefault();
                  setShowGlobalTags(!showGlobalTags);
                }}
                disabled={isCaseClosed || (caseDetails.is_lead && !caseDetails?.is_open)}
              >
                {<i className={showGlobalTags ? 'pi pi-minus F-size12 me-1' : 'icon-add F-size14 me-1 '} />}Add Global Tags
              </Button>
            </div>
            <div className="col-12 edit-field">
              {showGlobalTags && (
                <>
                  {formattedGlobalTag?.length > 0 ? (
                    <div className="global-tag-wrapper input-shadow lead-tags">
                      {formattedGlobalTag?.map((element, index) => {
                        let hashReplacedTag = element.value.replace('#', '');
                        return (
                          <span
                            key={index}
                            className={`tag-highlight ${watch('tags')?.includes(hashReplacedTag) ? 'selected-global-tag' : ''}`}
                            onClick={(e) => {
                              let existingTags = watch('tags');
                              existingTags?.push(hashReplacedTag);
                              setValue('tags', [...new Set(existingTags)]);
                            }}
                          >
                            {element.value}
                          </span>
                        );
                      })}
                    </div>
                  ) : (
                    <div className="row mb-2">
                      <div className="col-12 small">
                        <i className="fas fa-info-circle mx-2 mt-1"></i>
                        <span className="text-hint">No Global Tags Found.</span>{' '}
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        )}
      </Card>
    </>
  );
};

export default Summary;
