import moment from 'moment';

import constants from 'constants';

import { adjustColor, adjustColorToLightOrDark } from 'utils/colorUtils';
import { handleDateTimeOffset } from 'utils/utility_functions/timezone';

export default function eventContentFunction(arg, timezone, showBusinessTime) {
  let elementDiv = document.createElement('div');
  elementDiv.style.width = '100%';
  elementDiv.classList.add('badgeevent-wrapper');

  let startDate = arg?.event?.start;
  let endDate = arg?.event?.end;

  let hideEvent = false;
  let respType = arg?.event?._def?.extendedProps?.res_type;
  let source = arg?.event?._def?.extendedProps?.source;

  let eventBgColor = '';
  let textColor = '';
  let borderColor = '';
  let iconBgColor = '';
  let yearViewDotColor = '';

  let fallbackIcon = '';

  if (source === 'google_calendar') {
    fallbackIcon = 'fab fa-google';
  } else if (respType === 'user') {
    fallbackIcon = 'fas fa-birthday-cake';
  } else if (respType === 'case') {
    fallbackIcon = 'fas fa-briefcase';
  } else if (respType === 'task') {
    fallbackIcon = 'fas fa-tasks';
  } else if (respType === 'event') {
    fallbackIcon = 'far fa-calendar-alt';
  } else if (respType === 'outlook') {
    fallbackIcon = 'fab fa-microsoft';
  } else {
    fallbackIcon = 'far fa-calendar-alt';
  }

  let icon = arg?.event?.extendedProps?.icon ? arg?.event?.extendedProps?.icon : fallbackIcon;

  let calendarColor = arg?.event?._def?.extendedProps?.created_by_details?.calendar_color;
  if (calendarColor && source !== 'google_calendar') {
    borderColor = calendarColor;
    iconBgColor = calendarColor;
    eventBgColor = adjustColor(calendarColor, 90); //positive value for lighten the color and negative value for darken the color
    yearViewDotColor = calendarColor;
    textColor = adjustColorToLightOrDark(eventBgColor);
  } else if (respType === 'user' || respType === 'case') {
    eventBgColor = '#F0F2F5';
    borderColor = '#F0F2F5';
    textColor = '#7F8F9F';
    iconBgColor = '#7F8F9F';
    yearViewDotColor = '#2655b9';
  } else {
    borderColor = '#2655b9';
    textColor = '#2655b9';
    eventBgColor = '#f5f5fa';
    iconBgColor = '#2655b9';
    yearViewDotColor = '#2655b9';
  }

  let leftBorder = `4px solid ${borderColor}`;
  if (startDate !== 'Invalid Date' && endDate !== 'Invalid Date') {
    startDate = handleDateTimeOffset(timezone, arg?.event?.startStr, constants.hour_minute_12_format);
    endDate = handleDateTimeOffset(timezone, arg?.event?.endStr, constants.hour_minute_12_format);

    let beforeTime = moment('09:00:00', 'hh:mm A');
    let afterTime = moment('17:00:00', 'hh:mm A');
    let _startDate = moment(startDate, 'hh:mm A');
    if (showBusinessTime && !_startDate.isBetween(beforeTime, afterTime) && respType === 'event') {
      hideEvent = true;
      arg?.event?.setProp && arg?.event?.setProp('display', 'none');
    } else if (arg?.event?.display !== 'auto') {
      arg?.event?.setProp && arg?.event?.setProp('display', 'auto');
    }
  }

  if (!hideEvent) {
    elementDiv.innerHTML = `<div class="badge-events d-flex" style="background:${eventBgColor}; border-left:${leftBorder};color:${textColor};">
      <div class="badge-event-icon me-2" style="background-color:${iconBgColor}">
        <i class="${icon}" style="color:${textColor}"></i>
      </div>
      <div class="badge-event-details" title="${startDate} - ${endDate}">
        <div class="time" title="${startDate} - ${endDate}">
          <span style="display:${
            respType !== 'event' && respType !== 'google' && respType !== 'outlook' ? 'none' : 'inline'
          }"> ${startDate}-${endDate} </span>
        </div>
        <div class="title" title="${arg?.event?.title}">
            <span class="text-break title">${arg?.event?.title}</span>
        </div>
      </div>
     </div>`;
  }

  let arrayOfDomNodes = [elementDiv];

  return { domNodes: arrayOfDomNodes };
}
