import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { ConfirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import { UserDetailsContext } from 'context/userDetailsContext';
import { useCalendarContext } from 'modules/calendar/Context/CalendarContext';
import { useToast } from 'context/ToastContext';

import { deleteEvent, getEvent } from 'services/Calender/calendarServices';

import constants from 'constants/index';
import { toastConstant } from 'constants/toastmessage';

import BirthdayAndSOLPreview from './BirthdayAndSOLPreview';
import EventAndTaskPreview from './EventAndTaskPreview';
import GoogleAndOutlookPreview from './GoogleAndOutlookPreview';
import GooglePreview from './GooglePreview';
import { ModalHeader } from 'shared/ModalHeader';
import { useNavbarContext } from 'context/NavbarContext';

function EventPreviewModal({ showEventPreviewModal }) {
  const {
    setShowEventPreviewModal,
    selectedEvent,
    setShowCreateEventModal,
    setEditEventModal,
    editAllRecurringEvent,
    hidePreviewModal,
    isFromCaseSpecificModule,
    isFromLeadSpecificModule,
    eventDetails,
    setEventDetails,
    getEventList,
    isClosed,
    setEditAllRecurringEvent,
  } = useCalendarContext();

  const [birthdaySOLModal, setBirthdaySOLModal] = useState(false);
  const [eventTaskModal, setEventTaskModal] = useState(false);
  const [outlookModal, setOutlookModal] = useState(false);
  const [googleModal, setGoogleModal] = useState(false);
  const location = useLocation();
  const { showConfirmDialog } = useNavbarContext();

  useEffect(() => {
    //Email Redirection logic
    const queryParams = new URLSearchParams(location?.search);
    const event_id = queryParams.get('id');
    if (selectedEvent || event_id) {
      const res_type = selectedEvent?.extendedProps?.res_type;
      const source = selectedEvent?.extendedProps?.source;

      if (source === 'google_calendar') {
        setEventDetails({ ...(selectedEvent || {}), ...(selectedEvent?.extendedProps || {}) });
        getEventDetails(event_id, selectedEvent ?? '');
        setEventTaskModal(false);
        setBirthdaySOLModal(false);
        setOutlookModal(false);
        setGoogleModal(true);
      } else if (res_type === 'user' || res_type === 'case') {
        setEventDetails(selectedEvent);
        setBirthdaySOLModal(true);
        setEventTaskModal(false);
        setGoogleModal(false);
        setOutlookModal(false);
      } else if (res_type === 'event' || res_type === 'task' || event_id) {
        getEventDetails(event_id, selectedEvent ?? '');
        setEventTaskModal(true);
        setBirthdaySOLModal(false);
        setOutlookModal(false);
        setGoogleModal(false);
      } else if (res_type === 'google' || res_type === 'outlook') {
        setEventDetails(selectedEvent);
        setEventTaskModal(false);
        setBirthdaySOLModal(false);
        setOutlookModal(true);
        setGoogleModal(false);
      }
    }
  }, []); // eslint-disable-line

  const handleEdit = () => {
    setShowCreateEventModal(true);
    setEditEventModal(true);
    setShowEventPreviewModal(false);
  };

  function getEventDetails(event_id, selectedEvent) {
    getEvent(event_id, selectedEvent)
      .then((res) => {
        setEventDetails(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const userContext = useContext(UserDetailsContext);
  const { addToast } = useToast();

  const [cancelEvent, setCancelEvent] = useState(false);

  let cancelEventPermission =
    userContext.getPermission('cases') === constants.permissions.WRITE && userContext.getFirmPermission(constants.permissions.DELETE_ITEMS);

  let editEventPermission = userContext.getPermission('cases') === constants.permissions.WRITE;

  function cancelCurrentEvent() {
    let newData = eventDetails;
    let obj = {};

    if (newData?.is_recurring) {
      obj = {
        pk: newData.pk,
        is_lead: isFromLeadSpecificModule ? isFromLeadSpecificModule : newData?.is_lead,
        recurrence_scope: editAllRecurringEvent,
        overriden_start_date: eventDetails?.meeting_start_time || '', //to pass previous date of selected event
        data: newData,
      };
    } else {
      obj = {
        pk: newData.pk,
        is_lead: isFromLeadSpecificModule ? isFromLeadSpecificModule : newData?.is_lead,
      };
    }
    deleteEvent(obj)
      .then((response) => {
        hidePreviewModal();
        getEventList();
        addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.EVENT_DELETE_SUCCESS);
        // todo: delete google and outlook event
      })
      .catch((err) => {
        console.log(err);
        addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, toastConstant.message.EVENT_DELETE_FAILURE);
      });
  }
  const handleDeleteRecurringEvent = async () => {
    const result = await showConfirmDialog({
      footer: {
        cancelLable: 'Cancel',
        okLable: 'Delete',
      },
      formFields: [
        {
          type: 'title',
          label: 'Delete Event',
        },
        {
          name: 'selection',
          type: 'radiobutton',
          label: 'Option',
          required: true,
          categories: [
            { key: 'current ', label: 'This event' },
            { key: 'futures', label: 'This and following events' },
            { key: 'all', label: 'All recurring events' },
          ],
        },
      ],
    })?.catch((error) => console.error('Dialog closed or error:', error));
    if (!result) return;
    setEditAllRecurringEvent(result?.selection);
    setCancelEvent(true);
  };

  const customFooter = () => {
    return (
      <>
        {eventDetails?.res_type === 'event' ? (
          <div></div>
        ) : (
          <div>
            {/* footer buttons */}
            {eventDetails?.pk && eventDetails?.res_type === 'event' && eventDetails?.upcoming_event?.type !== 'SOL' && !isClosed && (
              <div className="footer-btn">
                {cancelEventPermission && (
                  <Button label="Cancel Event" className="btn p-button-secondary outline me-2" onClick={() => setCancelEvent(true)} />
                )}
                {editEventPermission && (
                  <Button
                    label="Edit Event"
                    type="button"
                    className="p-button-secondary"
                    onClick={() => {
                      setShowCreateEventModal(true);
                      setEditEventModal(true);
                      setShowEventPreviewModal(false);
                    }}
                  />
                )}
              </div>
            )}
          </div>
        )}
      </>
    );
  };
  return (
    <>
      <ConfirmDialog
        className="confirm-delete-popup"
        header="Confirm Delete"
        rejectClassName="p-button-secondary outline"
        icon="pi pi-exclamation-triangle"
        visible={cancelEvent}
        message="Are you sure you want to delete this event?"
        accept={() => {
          cancelCurrentEvent();
        }}
        reject={() => {
          setCancelEvent(false);
          setEditAllRecurringEvent('');
        }}
        onHide={() => {
          setCancelEvent(false);
          setEditAllRecurringEvent('');
        }}
      />
      <Dialog
        visible={showEventPreviewModal}
        modal
        onHide={hidePreviewModal}
        className="new-task-popup media-popup z-5"
        resizable={false}
        header={ModalHeader}
        footer={customFooter}
      >
        {birthdaySOLModal && <BirthdayAndSOLPreview eventDetails={eventDetails} />}{' '}
        {eventTaskModal && (
          <EventAndTaskPreview
            eventDetails={eventDetails}
            setCancelEvent={setCancelEvent}
            handleDeleteRecurringEvent={handleDeleteRecurringEvent}
            handleEdit={handleEdit}
            cancelEventPermission={cancelEventPermission}
            editEventPermission={editEventPermission}
            isClosed={isClosed}
            isFromCaseSpecificModule={isFromCaseSpecificModule}
            isFromLeadSpecificModule={isFromLeadSpecificModule}
          />
        )}
        {outlookModal && <GoogleAndOutlookPreview eventDetails={eventDetails} />}
        {googleModal && <GooglePreview eventDetails={eventDetails} />}
      </Dialog>
    </>
  );
}

export default EventPreviewModal;
