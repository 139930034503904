import React, { useContext, useEffect, useState } from 'react';

import { UserDetailsContext } from 'context/userDetailsContext';

import EventTitle from 'modules/calendar/Components/CreateEventModal/Components/EventTitle';
import EventVideoConferenceUrl from 'modules/calendar/Components/CreateEventModal/Components/EventVideoConferenceUrl';
import EventDescription from 'modules/calendar/Components/CreateEventModal/Components/EventDescription';
import EventReminders from 'modules/calendar/Components/CreateEventModal/Components/EventReminders';
import EventRecurring from 'modules/calendar/Components/CreateEventModal/Components/EventRecurring';
import { useFileManagerContext } from 'modules/file_manager/context/FileManagerContext';
import EventPracticeArea from 'modules/calendar/Components/CreateEventModal/Components/EventPracticeArea';
import CourtEventType from './Components/CourtEventType';
import CourtEventDate from './Components/CourtEventDate';
import CourtEventTime from './Components/CourtEventTime';
import CourtEventLocation from './Components/CourtEventLocations';
import CourtEventAttendees from './Components/CourtEventAttendees';
import CourtEventAttachments from './Components/CourtEventAttachments';
import { getCurrentDateInTimezone, roundToNextHalfHour_withoutNewDate } from 'utils/utility_functions/timezone';
import moment from 'moment';

function CreateCourtEventForm({
  selectedItem,
  control,
  resetField,
  setValue,
  getValues,
  watch,
  errors,
  trigger,
  handleUploadAttachements,
  uploadedAttachments,
  courtEventExtractedData,
  locationsList,
  hadleAddNewLocation,
}) {
  const { isGlobalFileManager } = useFileManagerContext();
  const userContext = useContext(UserDetailsContext);

  const [showReminder, setShowReminder] = useState(false);

  const startTimeWatcher = watch('meeting_start_time');

  useEffect(() => {
    if (courtEventExtractedData) {
      if (courtEventExtractedData?.details?.title !== -1) {
        setValue('title', courtEventExtractedData?.details?.title);
      }
      if (courtEventExtractedData?.details?.court_date !== -1) {
        setValue('when', courtEventExtractedData?.details?.court_date);
      } else {
        setValue('when', getCurrentDateInTimezone(userContext?.userDetails?.timezone));
      }
      if (courtEventExtractedData?.details?.court_time !== -1) {
        setValue('meeting_start_time', courtEventExtractedData?.details?.court_time);
        addTimeHandler(
          roundToNextHalfHour_withoutNewDate(courtEventExtractedData?.details?.court_time, userContext?.userDetails?.timezone).format(),
          30,
          'meeting_end_time'
        );
      } else {
        let startTime = roundToNextHalfHour_withoutNewDate(
          getCurrentDateInTimezone(userContext?.userDetails?.timezone),
          userContext?.userDetails?.timezone
        );
        setValue('meeting_start_time', startTime.format());
        addTimeHandler(startTime.format(), 30, 'meeting_end_time');
      }
    }
  }, [courtEventExtractedData]);

  function addTimeHandler(time, extraTime, field) {
    if (time) {
      let new_time = moment.tz(time, userContext?.userDetails?.timezone);
      new_time.add(extraTime, 'minutes').seconds(0).milliseconds(0);
      setValue(field, new_time.format());
      trigger(field);
    }
  }

  const handleShowReminder = (show) => {
    if (!show) {
      setShowReminder(false);
    } else {
      setShowReminder(true);
    }
  };

  return (
    <form>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div className="modal-heading F-size16 text-bold">{'Court Event Details'}</div>
      </div>
      <div className="court-form-contents pe-2">
        <EventTitle control={control} errors={errors} watch={watch} />
        <CourtEventType control={control} setValue={setValue} resetField={resetField} watch={watch} />
        <CourtEventDate control={control} trigger={trigger} errors={errors} setValue={setValue} watch={watch} />
        <CourtEventTime
          control={control}
          trigger={trigger}
          errors={errors}
          setValue={setValue}
          getValues={getValues}
          startTimeWatcher={startTimeWatcher}
          watch={watch}
        />
        <EventRecurring
          control={control}
          resetField={resetField}
          setValue={setValue}
          trigger={trigger}
          watch={watch}
          isGlobal={isGlobalFileManager}
          errors={errors}
        />
        <EventVideoConferenceUrl control={control} watch={watch} />
        <CourtEventLocation
          control={control}
          setValue={setValue}
          locationsList={locationsList}
          hadleAddNewLocation={hadleAddNewLocation}
          courtEventExtractedData={courtEventExtractedData}
          watch={watch}
        />
        {(!selectedItem?.case_id || selectedItem?.case_id.trim() === 'undefined') && (
          <EventPracticeArea key={selectedItem?.case_id} control={control} setValue={setValue} watch={watch} />
        )}
        <CourtEventAttendees
          control={control}
          getValues={getValues}
          setValue={setValue}
          trigger={trigger}
          watch={watch}
          selectedItem={selectedItem}
          handleShowReminder={handleShowReminder}
          showReminder={showReminder}
        />
        <EventDescription control={control} watch={watch} />
        <CourtEventAttachments
          control={control}
          setValue={setValue}
          getValues={getValues}
          handleUploadAttachements={handleUploadAttachements}
          uploadedAttachments={uploadedAttachments}
          watch={watch}
        />
        <EventReminders
          control={control}
          setValue={setValue}
          watch={watch}
          isGlobal={isGlobalFileManager}
          errors={errors}
          isRequired={showReminder}
          optionText="before event start time"
        />
      </div>
    </form>
  );
}

export default CreateCourtEventForm;
