import { useForm } from 'react-hook-form';
import { Dialog } from 'primereact/dialog';
import MailCreateForm from './MailCreateForm';
import useSendMail from '../hooks/useSendMail';
import { UserDetailsContext, useUserDetailsContext } from 'context/userDetailsContext';
import { useLoaderDispatch } from 'context/LoaderContext';
import { uploadFiles } from 'utils/filesUtils';
import { useContext } from 'react';

const MailCreateModal = ({ show, setShow, caseId, caseName, isLead, handleSentEmailList }) => {
  const {
    handleSubmit,
    control,
    resetField,
    setError,
    formState: { errors },
    reset,
  } = useForm();

  const { sendMail, loading: sendingMail } = useSendMail();

  const { userDetails } = useUserDetailsContext();
  const handleClose = () => {
    reset({ recipient_list: '', subject: '', html_body: '', cc_list: '', bcc_list: '', reply_to: '', case: null, attachments: [] });
    setShow(false);
  };
  const setLoader = useLoaderDispatch();
  const userContext = useContext(UserDetailsContext);
  const userId = userContext?.firmDetails?.current_user;

  const onSubmit = async (data) => {
    if (data?.attachments?.length > 0) {
      setLoader(true);
      await uploadFiles(data?.attachments, 'email/outbound', caseId ? caseId : data?.case?.id, userId)
        .then((res) => {
          setLoader(false);
          data.attachments = res;
        })
        .catch((err) => {
          setLoader(false);
          console.log(err);
          return;
        });
    }

    const formattedData = {
      // recipient_list: data.recipient_list.split(',').map((email) => email.trim()),
      recipient_list: data?.recipient_list,
      subject: data.subject,
      email_content: data.html_body,
      // cc_list: data.cc_list ? data.cc_list.split(',').map((email) => email.trim()) : [],
      cc_list: data?.cc_list,
      bcc_list: data.bcc_list ? data.bcc_list.split(',').map((email) => email.trim()) : [],
      reply_to: data.reply_to || undefined,
      case_id: caseId ? caseId : data?.case?.id,
      case_name: caseName ? caseName : data?.case?.case_name,
      is_lead: isLead !== null ? isLead : data?.case?.is_lead,
      attachments: data?.attachments,
    };
    try {
      const response = await sendMail(formattedData);
      if (response.status === 200) {
        const formattedDataForSentList = {
          pk: response?.data?.pk,
          sk: response?.data?.sk,
          subject: data.subject,
          is_sent: true,
          email_content: data.html_body,
          email_from: {
            user_email: userDetails?.business_email,
            user_name: userDetails?.first_name + ' ' + userDetails?.last_name,
          },
          time: new Date().toISOString(),
        };
        handleClose();
        reset();
        handleSentEmailList(formattedDataForSentList);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const headerComponent = (data) => {
    return (
      <div>
        <i className="icon-window-filled F-size14 pointer" onClick={data?.onHide}></i>
        {/* <i className="icon-window-filled F-size14 icon-minimize"></i> */}
      </div>
    );
  };
  return (
    <Dialog
      header={headerComponent}
      visible={show}
      onHide={handleClose}
      className="create-msg-popup"
      closable={false}
      style={{ width: '50vw' }}
    >
      <MailCreateForm
        onSubmit={onSubmit}
        handleSubmit={handleSubmit}
        handleClose={handleClose}
        caseId={caseId}
        control={control}
        errors={errors}
        resetField={resetField}
        setError={setError}
      />
      {sendingMail && <p className="text-center mt-3">Sending mail...</p>}
    </Dialog>
  );
};

export default MailCreateModal;
