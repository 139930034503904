import React, { useEffect } from 'react';
import SettingsLayout from '../SettingsLayout/SettingsLayout';
import { Card } from 'primereact/card';
import { Controller, useForm } from 'react-hook-form';
import { InputSwitch } from 'primereact/inputswitch';
import GlobalLoader from 'components/GlobalLoader/GlobalLoader';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { npsSendingInterval } from 'constants/dropdownOptions';
import { handleRedirection } from 'utils/utils';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { getNpsSettings, npsSettings } from 'services/nps/npsService';
import { useToast } from 'context/ToastContext';
import { toastConstant } from 'constants/toastmessage';

const NpsSettings = () => {
  const { control, handleSubmit, setValue, watch } = useForm();
  const history = useHistory();
  const { addToast } = useToast();

  const onSubmit = async (formData) => {
    if (formData) {
      try {
        const response = await npsSettings(formData);
        if (response?.data) {
          addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.NPS_SETTINGS_ADDED);
        }
      } catch (error) {
        addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, toastConstant.message.NPS_SETTINGS_FAILED);
      }
    }
  };

  useEffect(() => {
    getNpsSettings()
      .then((response) => {
        if (response?.data?.nps_setting) {
          setValue('is_enabled', response?.data?.nps_setting?.is_enabled);
          setValue('interval', response?.data?.nps_setting?.interval);
          setValue('case_closure', response?.data?.nps_setting?.case_closure);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div>
      <GlobalLoader />
      <div className="d-flex">
        <div className="col-12">
          <h1 className="title gray-800">NPS Settings</h1>
        </div>
      </div>
      <form>
        <Card className="shadow-middle col-lg-6 col-md-10 col-12">
          <div className="row align-items-center">
            <div className="col-md-3 col-sm-4 col-auto p-medium text-bold">Enable NPS</div>
            <div className="col-md-2 col-sm-2 col-auto align-items-center d-flex">
              <Controller
                name="is_enabled"
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <InputSwitch
                    id="is_enabled"
                    {...field}
                    checked={field.value}
                    onChange={(e) => {
                      if (!e.value) {
                        setValue('case_closure', false);
                        setValue('interval', '');
                      }
                      field.onChange(e.value);
                    }}
                    className="input-switch"
                  />
                )}
              />
            </div>
            <div className="col-md-7 col-sm-6 col-auto d-flex justify-content-end">
              <Button
                className="p-button p-button-primary outline ms-2"
                onClick={(e) => {
                  e.preventDefault();
                  const url = '#/nps?isPreview=true';
                  window.open(url, '_blank');
                }}
                label={'Preview'}
              />
              <Button
                className="p-button p-button-primary outline ms-2"
                onClick={(event) => {
                  const url = '/reports/nps';
                  handleRedirection(history, url);
                }}
                label={'View Report'}
              />
            </div>
          </div>

          <div className="row align-items-center pt-4">
            <div className="col-md-3 col-sm-4 col-12 p-medium align-self-start mt-2">
              Interval
              <span style={{ color: 'red' }}>*</span>
            </div>
            <div className="col-md-9 col-sm-8 col-12">
              <Controller
                name="interval"
                control={control}
                required={true}
                render={({ field }) => (
                  <Dropdown
                    value={field.value}
                    onChange={(e) => field.onChange(e.value)}
                    name="interval"
                    className="input-shadow w-100"
                    optionLabel="label"
                    optionValue="value"
                    options={npsSendingInterval}
                    placeholder="Set Interval"
                    filter
                    disabled={!watch('is_enabled')}
                  />
                )}
              />
              <div className="d-flex mt-2 align-items-center">
                <div className="w-auto p-0">
                  <Controller
                    name="case_closure"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <InputSwitch
                        id="case_closure"
                        {...field}
                        checked={field.value}
                        onChange={(e) => {
                          field.onChange(e.value);
                        }}
                        className="input-swich"
                        disabled={!watch('is_enabled')}
                      />
                    )}
                  />
                </div>
                <div className="F-size12 ms-2  align-items-center black-600 w-auto">Send NPS survey upon case closure.</div>
              </div>
            </div>
          </div>
          <div className="footer-btn">
            <Button className="p-button p-button-secondary" type="submit" onClick={handleSubmit(onSubmit)}>
              Save
            </Button>
          </div>
        </Card>
      </form>
    </div>
  );
};

const NpsSettingsWrapper = () => {
  return (
    <SettingsLayout>
      <NpsSettings />
    </SettingsLayout>
  );
};

export default NpsSettingsWrapper;
