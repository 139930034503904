import React from 'react';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { tableCell } from '../helpers/dataTableCommonHelpers';
import { handleDateTimeOffset } from 'utils/utility_functions/timezone';
import { useUserDetailsContext } from 'context/userDetailsContext';
import constants from 'constants/index';

const SubscriptionBillingDetails = (props) => {
  const { caseDetails, formErrors, subscriptionItems } = props || {};
  const userContext = useUserDetailsContext();

  const dateTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title text-break">Date</span>
        <span
          className="text-break"
          title={
            handleDateTimeOffset(userContext?.userDetails?.timezone, rowData?.invoice_date, constants.month_date_year_time_12_format) ??
            null
          }
        >
          {handleDateTimeOffset(userContext?.userDetails?.timezone, rowData?.invoice_date, constants.month_date_year_time_12_format0) ||
            '-'}
        </span>
      </React.Fragment>
    );
  };

  const dueDateTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title text-break">Due Date</span>
        <span
          className="text-break"
          title={
            handleDateTimeOffset(userContext?.userDetails?.timezone, rowData?.due_date, constants.month_date_year_time_12_format) ?? null
          }
        >
          {handleDateTimeOffset(userContext?.userDetails?.timezone, rowData?.due_date, constants.month_date_year_time_12_format0) || '-'}
        </span>
      </React.Fragment>
    );
  };

  return (
    <div className="mt-4">
      <div className="caption-bold mb-3">Subscription Details</div>
      <div className="col-12 datatable-responsive shadow-small p-2">
        <DataTable className="p-datatable-responsive" value={[subscriptionItems]}>
          <Column header="Type" field="frequency_of_payment" body={tableCell} />
          <Column header="Date" field="invoice_date" body={dateTemplate} />
          <Column header="Notes" field="description" body={tableCell} />
          <Column header="Due Date" field="due_date" body={dueDateTemplate} />
          <Column header="Actual Amount" field="rate" currency={true} body={tableCell} />
          {caseDetails?.billing_preferences?.amount_with_tax && (
            <Column header="Amount (with Tax)" field="amount_with_tax" currency={true} body={tableCell} />
          )}
        </DataTable>
      </div>
      {formErrors && formErrors.subscription && (
        <div className="col-12 d-flex justify-content-center mt-3">
          <span className="text-danger">{formErrors.subscription}</span>{' '}
        </div>
      )}
    </div>
  );
};

export default SubscriptionBillingDetails;
