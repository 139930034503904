import React, { useCallback } from 'react';
import { Controller, useFieldArray } from 'react-hook-form';

import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputSwitch } from 'primereact/inputswitch';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';

import { paymentFrequency } from 'constants/dropdownOptions';
import { getNestedProperty } from 'utils/utils';
import InputErrorMessage from 'components/UI/InputErrorMessage/InputErrorMessage';
import { generateFullName } from 'utils/generateFullNameUtils';
import { flatFee, contingencyFee, evergreenRetainer, standardRetainer, subscription } from 'constants/index';
import { nextDate } from 'modules/lead/helpers/helpers';
import TextSnippetWrapper from 'components/TextSnippetWrapper';
import BigNumber from 'bignumber.js';
import CustomCalendar from 'components/CustomCalendar/CustomCalendar';

const FeeAndPayments = (props) => {
  const {
    getValues,
    trigger,
    control,
    watch,
    formState: { errors },
    setValue,
    paymentStructures,
    isFromConvertToCase = false,
    hideCustomRate = false,
  } = props;

  const { fields, append, remove, replace } = useFieldArray({
    control, // control props comes from useForm
    name: 'billing_preferences.payment_plan_details.payment_dates',
  });

  const {
    fields: custom_rate,
    append: append_custom_rate,
    remove: remove_custom_rate,
  } = useFieldArray({
    control, // control props comes from useForm
    name: 'billing_preferences.custom_rates',
  });

  const selectedPaymentType = watch('billing_preferences.payment_structure');
  const paymentPlan = watch('billing_preferences.payment_plan');
  const practiceArea = watch('case_description.case_practice_area');
  const lead_assigned_to = watch('lead_assigned_to') ? [watch('lead_assigned_to')] : [];

  const getPaymentStructure = useCallback(() => {
    return paymentStructures.filter((ps) => {
      if (practiceArea?.toLowerCase() === 'personal injury') {
        if (ps?.payment_structure?.toLowerCase() === contingencyFee) {
          return true;
        } else return false;
      } else return true;
    });
  }, [practiceArea, paymentStructures]);

  const generatePlan = (billing_preferences) => {
    const {
      rate,
      number_of_payments,
      payment_plan_details: { first_payment_amount: first_amount, payment_starting_date },
      frequency_of_payment,
    } = billing_preferences || {};
    let payment_dates = [];
    let numberofpayments = number_of_payments ? parseInt(number_of_payments) : 0;

    if (numberofpayments >= 1) {
      if (first_amount > 0) numberofpayments = numberofpayments - 1;
      let totalM = new BigNumber(rate - first_amount).multipliedBy(100);
      let divValue = totalM.dividedToIntegerBy(numberofpayments);
      let reminder = totalM.modulo(numberofpayments);

      let date = payment_starting_date;
      if (!first_amount || first_amount === 0) {
        let first_payment = divValue
          .plus(reminder > 0 ? 1 : 0)
          .div(100)
          .toNumber();
        payment_dates.push({ date: payment_starting_date, amount: first_payment });
        numberofpayments = numberofpayments - 1;
        reminder = reminder.minus(1);
      } else if (first_amount > 0) {
        payment_dates.push({
          date: payment_starting_date,
          amount: first_amount,
        });
      }

      for (let i = 0; i < numberofpayments; i++) {
        payment_dates.push({
          date: date,
          amount: divValue
            .plus(i < reminder ? 1 : 0)
            .div(100)
            .toNumber(),
        });
        date = nextDate(frequency_of_payment, date);
      }
      replace(payment_dates);
    }
  };

  const validateTotal = () => {
    let planDetails = getValues('billing_preferences.payment_plan_details');
    let totalFee = getValues('billing_preferences.rate');
    let total = new BigNumber(planDetails?.payment_dates?.reduce((total, val) => BigNumber.sum(total, val.amount), 0));
    if (total.isLessThan(totalFee)) return 'Total amount is less than fee amount';
    else if (total.isGreaterThan(totalFee)) return 'Total amount is more than fee amount';
    else return true;

    // let total = planDetails?.payment_dates?.reduce((total, val) => total + parseFloat(val.amount), 0);
    // let totalFeeRounded = Math.round(parseFloat(totalFee) * 10) / 10
    // let totalRounded = Math.round(parseFloat(total) * 10) / 10
    // if (total<totalFee)
    //   return "Total amount is less than fee amount"
    // else if (total>totalFee)
    //   return "Total amount is more than fee amount"
    // else
    //   return true
  };
  const RequiredValidation = ({ field, message = 'Field Required' }) => {
    return getNestedProperty(errors, field) ? (
      <InputErrorMessage>{getNestedProperty(errors, field)?.message || message}</InputErrorMessage>
    ) : null;
  };
  const validateAndGetData = async () => {
    const isValid = await trigger([
      'billing_preferences.rate',
      'billing_preferences.number_of_payments',
      'billing_preferences.frequency_of_payment',
      'billing_preferences.payment_plan_details.payment_starting_date',
      'billing_preferences.payment_plan_details.first_payment_amount',
    ]);
    if (isValid) {
      generatePlan(getValues('billing_preferences'));
      trigger('billing_preferences.payment_plan_details.totalValueValidationError');
    }
  };
  return (
    <div className={!props?.paymentData && !isFromConvertToCase ? 'mt-5' : ''}>
      <div className="col-12">
        <div className="row mb-3">
          <div className={isFromConvertToCase ? 'col-lg-12' : ''}>
            {!props?.paymentData && !isFromConvertToCase && <div className="caption-bold mb-3">Billing Preferences</div>}
            <Card className="shadow-middle billing-preferences F-size14">
              <div className="d-flex align-items-center py-2 flex-wrap">
                <div className="col-lg-4 col-12">
                  <div className="p-medium">
                    Payment Structure
                    {!props?.paymentData && <span className="text-danger">*</span>}
                  </div>
                </div>
                <div className="col-lg-8 col-12">
                  <Controller
                    name="billing_preferences.payment_structure"
                    control={control}
                    rules={{ required: 'Payment Structure is Required' }}
                    render={({ field }) => (
                      <Dropdown
                        id={field.name}
                        onChange={(e) => setValue('billing_preferences', { payment_structure: e.value }, { shouldValidate: true })}
                        value={field.value}
                        className="w-50 autowidth"
                        options={getPaymentStructure()}
                        optionLabel="payment_structure"
                        optionValue="payment_structure"
                        placeholder="Add Payment"
                        optionDisabled={(item) => item?.payment_structure_status !== 'ACTIVE'}
                        filter
                      />
                    )}
                  />
                  <RequiredValidation field={'billing_preferences.payment_structure'} />
                </div>
              </div>
              {selectedPaymentType?.toLowerCase() === flatFee && (
                <>
                  <div className="d-flex align-items-center py-2 flex-wrap">
                    <div className="col-lg-4 col-12">
                      <div className="p-medium">
                        Fee
                        {!props?.paymentData && <span className="text-danger">*</span>}
                      </div>
                    </div>
                    <div className="col-lg-8 col-12">
                      <Controller
                        name="billing_preferences.rate"
                        control={control}
                        rules={{ required: 'Fee is Required' }}
                        render={({ field }) => (
                          <InputNumber
                            className="input-shadow col-md-6 col-12"
                            placeholder="Fee"
                            mode="currency"
                            currency="USD"
                            locale="en-US"
                            id={field.name}
                            onChange={(e) => field.onChange(e.value)}
                            value={field.value}
                          />
                        )}
                      />
                      <RequiredValidation field={'billing_preferences.rate'} />
                    </div>
                  </div>
                  <div className="d-flex align-items-center py-2 flex-wrap">
                    <div className="col-lg-4 col-12">
                      <div className="p-medium">Payment Plan</div>
                    </div>
                    <div className="col-lg-8 col-12">
                      <Controller
                        name="billing_preferences.payment_plan"
                        control={control}
                        defaultValue={false}
                        render={({ field, fieldState }) => (
                          <InputSwitch
                            inputId={field.name}
                            ref={field.ref}
                            onChange={(e) => {
                              field.onChange(e.value);
                            }}
                            checked={field.value}
                            className={{ 'p-invalid': fieldState.invalid }}
                          />
                        )}
                      />
                    </div>
                  </div>
                  {paymentPlan && (
                    <>
                      <div className="d-flex align-items-center py-2 flex-wrap">
                        <div className="col-md-4 col-12">
                          <div className="p-medium">
                            Number of Payments
                            {!props?.paymentData && <span className="text-danger">*</span>}
                          </div>
                        </div>
                        <div className="col-md-8 col-12">
                          <Controller
                            name="billing_preferences.number_of_payments"
                            defaultValue={''}
                            control={control}
                            rules={{
                              required: 'Payment Number is Required',
                              validate: (value) => (value >= 1 ? true : 'Payment Number is Required'),
                            }}
                            render={({ field }) => (
                              <InputText
                                type="number"
                                placeholder="Number of Payments"
                                id={field.name}
                                {...field}
                                className="input-shadow col-md-6 col-12 "
                              />
                            )}
                          />
                          <RequiredValidation field={'billing_preferences.number_of_payments'} />
                        </div>
                      </div>

                      <div className="d-flex align-items-center py-2 flex-wrap">
                        <div className="col-md-4 col-12">
                          <div className="p-medium">
                            Frequency of Payments
                            {!props?.paymentData && <span className="text-danger">*</span>}
                          </div>
                        </div>
                        <div className="col-md-8 col-12">
                          <Controller
                            name="billing_preferences.frequency_of_payment"
                            control={control}
                            rules={{ required: 'Payment Structure is Required' }}
                            render={({ field }) => (
                              <Dropdown
                                id={field.name}
                                onChange={(e) => field.onChange(e.value)}
                                value={field.value}
                                className="col-md-6 col-12 "
                                options={paymentFrequency}
                                placeholder="Please Select"
                                filter
                              />
                            )}
                          />
                          <RequiredValidation field={'billing_preferences.frequency_of_payment'} />
                        </div>
                      </div>
                      <div className="d-flex align-items-center py-2 flex-wrap">
                        <div className="col-md-4 col-12 p-medium">
                          Payment Starting Date
                          {!props?.paymentData && <span className="text-danger">*</span>}
                        </div>
                        <div className="col-md-8 col-12">
                          <Controller
                            name="billing_preferences.payment_plan_details.payment_starting_date"
                            control={control}
                            rules={{ required: true }}
                            render={({ field }) => (
                              <CustomCalendar
                                className="input-shadow col-md-6 col-12"
                                minDate={new Date()}
                                id={field.name}
                                value={field.value}
                                onChange={(e) => field.onChange(e.value)}
                              />
                            )}
                          />
                          <RequiredValidation field={'billing_preferences.payment_plan_details.payment_starting_date'} />
                        </div>
                      </div>
                      <div className="d-flex align-items-center py-2 flex-wrap">
                        <div className="col-md-4 col-12 p-medium">First Payment Amount</div>
                        <div className="col-md-8 col-12">
                          <div className=" d-flex align-items-center">
                            <Controller
                              name="billing_preferences.payment_plan_details.first_payment_amount"
                              control={control}
                              defaultValue={0}
                              render={({ field }) => (
                                <div className="col-md-7 col-sm-6 col-6">
                                  <InputNumber
                                    className="input-shadow "
                                    placeholder="Amount"
                                    min={0}
                                    keyfilter={'pnum'}
                                    mode="currency"
                                    currency="USD"
                                    locale="en-US"
                                    id={field.name}
                                    onChange={(e) => field.onChange(e.value)}
                                    value={field.value}
                                  />
                                </div>
                              )}
                            />
                            {!props?.isLeadDetails && (
                              <div className="col-md-5 col-sm-6 col-6">
                                <Button className="p-button-secondary ms-2" label="Generate Plan" onClick={validateAndGetData} />
                              </div>
                            )}
                          </div>
                          <RequiredValidation field={'billing_preferences.payment_plan_details.first_payment_amount'} />
                          <Controller
                            name="billing_preferences.payment_plan_details.totalValueValidationError"
                            control={control}
                            rules={{ validate: validateTotal }}
                            render={() => null}
                          />
                          <RequiredValidation field={'billing_preferences.payment_plan_details.totalValueValidationError'} />
                        </div>
                      </div>
                      <div className="d-flex align-items-center py-2 flex-wrap">
                        <div className="col-md-4 col-12"></div>
                        <div className="col-md-8 col-12 time-entries">
                          {fields?.length > 0 && (
                            <table className="table table-striped">
                              <thead>
                                <tr>
                                  <th>Due Date</th>
                                  <th>Amount</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                {fields.map((item, i) => {
                                  return (
                                    <tr key={item.id}>
                                      <td>
                                        <Controller
                                          name={`billing_preferences.payment_plan_details.payment_dates.${i}.date`}
                                          control={control}
                                          defaultValue={item.date}
                                          rules={{ required: true }}
                                          render={({ field }) => (
                                            <CustomCalendar
                                              className="input-shadow w-100"
                                              value={field.value}
                                              placeholder="Date"
                                              onChange={(e) => field.onChange(e.value)}
                                            />
                                          )}
                                        />
                                        <RequiredValidation field={`billing_preferences.payment_plan_details.payment_dates.${i}.date`} />
                                      </td>
                                      <td>
                                        <Controller
                                          name={`billing_preferences.payment_plan_details.payment_dates.${i}.amount`}
                                          defaultValue={item.amount}
                                          control={control}
                                          rules={{ required: true }}
                                          render={({ field }) => (
                                            <InputNumber
                                              className="input-shadow "
                                              placeholder="Amount"
                                              mode="currency"
                                              currency="USD"
                                              locale="en-US"
                                              value={field.value}
                                              onChange={(e) => field.onChange(e.value)}
                                            />
                                          )}
                                        />
                                        <RequiredValidation field={`billing_preferences.payment_plan_details.payment_dates.${i}.amount`} />
                                      </td>
                                      <td className="align-middle">
                                        <i className="icon-delete pointer" onClick={() => remove(i)} />
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          )}
                          {paymentPlan && (
                            <span role="button" className="addfield" onClick={() => append({ date: '', amount: '' })}>
                              <i className="icon-add"></i> Add date
                            </span>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}

              {selectedPaymentType?.toLowerCase() === contingencyFee && (
                <>
                  <div className="d-flex align-items-center py-2 flex-wrap">
                    <div className="col-md-4 col-12 p-medium">
                      If the case is resolved pre-litigation,
                      <br />
                      the contingency fee is set at
                    </div>
                    <div className="col-md-7 col-12 ps-1 d-flex pe-0 align-items-center">
                      <Controller
                        name="billing_preferences.pre_litigation"
                        defaultValue={''}
                        control={control}
                        render={({ field }) => (
                          <InputText
                            type="number"
                            min={0}
                            keyfilter={'pnum'}
                            {...field}
                            className="input-shadow col-sm-11 col-md-6 col-12 pe-0 me-2"
                          />
                        )}
                      />
                      %.
                    </div>
                  </div>
                  <div className="d-flex align-items-center py-2 flex-wrap">
                    <div className="col-md-4 col-12 p-medium">
                      If the case is resolved through litigation,
                      <br />
                      the contingency fee is set at
                    </div>
                    <div className="col-md-7 col-12 pe-0 d-flex align-items-center">
                      <Controller
                        name="billing_preferences.litigation"
                        defaultValue={''}
                        control={control}
                        render={({ field }) => (
                          <InputText
                            type="number"
                            min={0}
                            keyfilter={'pnum'}
                            {...field}
                            className="input-shadow col-sm-11 col-md-6 col-12 pe-0 me-2"
                          />
                        )}
                      />
                      %.
                    </div>
                  </div>
                  <div className="d-flex align-items-center py-2 flex-wrap">
                    <div className="col-md-4 col-12 p-medium pe-2">Expenses will be deducted from the settlement amount</div>
                    <div className="col-md-8 col-12 ">
                      <Controller
                        name="billing_preferences.is_expense"
                        control={control}
                        defaultValue={false}
                        render={({ field }) => (
                          <InputSwitch
                            inputId={field.name}
                            ref={field.ref}
                            onChange={(e) => field.onChange(e.value)}
                            checked={field.value}
                          />
                        )}
                      />
                    </div>
                  </div>
                </>
              )}

              {(selectedPaymentType?.toLowerCase() === evergreenRetainer || selectedPaymentType?.toLowerCase() === standardRetainer) && (
                <>
                  <div className="d-flex align-items-center py-2 flex-wrap">
                    <div className="col-md-4 col-12 p-medium">
                      Retainer amount
                      {!props?.paymentData && selectedPaymentType?.toLowerCase() === evergreenRetainer && (
                        <span className="text-danger">*</span>
                      )}
                    </div>
                    <div className="col-md-8 col-12">
                      <Controller
                        name="billing_preferences.retainer_amount"
                        control={control}
                        rules={{ required: selectedPaymentType?.toLowerCase() === evergreenRetainer ? 'Fee is Required' : false }}
                        render={({ field }) => (
                          <InputNumber
                            className="input-shadow w-50 autowidth F-size14"
                            placeholder="Amount"
                            mode="currency"
                            currency="USD"
                            locale="en-US"
                            min={0}
                            keyfilter={'pnum'}
                            id={field.name}
                            onChange={(e) => field.onChange(e.value)}
                            value={field.value}
                          />
                        )}
                      />
                      <RequiredValidation field={'billing_preferences.retainer_amount'} />
                    </div>
                  </div>
                  <div className="d-flex align-items-center py-2 flex-wrap">
                    <div className="col-md-4 col-12 p-medium">
                      Retainer date
                      {!props?.paymentData && selectedPaymentType?.toLowerCase() === evergreenRetainer && (
                        <span className="text-danger">*</span>
                      )}
                    </div>
                    <div className="col-md-8 col-12">
                      <Controller
                        name="billing_preferences.retainer_date"
                        control={control}
                        rules={{ required: selectedPaymentType?.toLowerCase() === evergreenRetainer ? 'Fee is Required' : false }}
                        render={({ field }) => (
                          <CustomCalendar
                            className="input-shadow w-50 autowidth F-size14"
                            placeholder="Date"
                            id={field.name}
                            value={field.value}
                            onChange={(e) => field.onChange(e.value)}
                          />
                        )}
                      />
                      <RequiredValidation field={'billing_preferences.retainer_date'} />
                    </div>
                  </div>
                </>
              )}
              {selectedPaymentType?.toLowerCase() === subscription && (
                <>
                  <div className="d-flex align-items-center py-2 flex-wrap">
                    <div className="col-lg-4 col-12">
                      <div className="p-medium">
                        Fee
                        {!props?.paymentData && <span className="text-danger">*</span>}
                      </div>
                    </div>
                    <div className="col-lg-8 col-12">
                      <Controller
                        name="billing_preferences.rate"
                        control={control}
                        rules={{ required: 'Fee is Required' }}
                        render={({ field }) => (
                          <InputNumber
                            className="input-shadow w-50 autowidth"
                            placeholder="Fee"
                            mode="currency"
                            currency="USD"
                            locale="en-US"
                            id={field.name}
                            onChange={(e) => field.onChange(e.value)}
                            value={field.value}
                          />
                        )}
                      />
                      <RequiredValidation field={'billing_preferences.rate'} />
                    </div>
                  </div>
                  <div className="d-flex align-items-center py-2 flex-wrap">
                    <div className="col-lg-4 col-12">
                      <div className="p-medium">
                        Frequency of Payment
                        {!props?.paymentData && <span className="text-danger">*</span>}
                      </div>
                    </div>
                    <div className="col-lg-8 col-12">Monthly</div>
                  </div>
                  <div className="d-flex align-items-center py-2 flex-wrap">
                    <div className="col-lg-4 col-12">
                      <div className="p-medium">
                        Subscription Period
                        {!props?.paymentData && <span className="text-danger">*</span>}
                      </div>
                    </div>
                    <div className="col-lg-8 col-12">
                      <Controller
                        name="billing_preferences.subscriptionDates"
                        control={control}
                        rules={{
                          required: true,
                          validate: (value) => {
                            if (!value || value.length !== 2 || value.indexOf(null) !== -1) {
                              return 'Date range required';
                            }
                            return true;
                          },
                        }}
                        render={({ field }) => (
                          <CustomCalendar
                            className="input-shadow w-50 autowidth"
                            selectionMode="range"
                            minDate={new Date()}
                            id={field.name}
                            value={field.value}
                            onChange={(e) => field.onChange(e.value)}
                          />
                        )}
                      />
                      <RequiredValidation field={'billing_preferences.subscriptionDates'} />
                    </div>
                  </div>
                </>
              )}
              <div className="d-flex align-items-start py-2 flex-wrap">
                <div className="col-lg-4 col-12">
                  <div className="p-medium">Payment Notes</div>
                </div>
                <div className="col-lg-8 col-12 p-0">
                  <Controller
                    name="billing_preferences.notes"
                    control={control}
                    defaultValue={''}
                    render={({ field }) => (
                      <TextSnippetWrapper
                        type="textarea"
                        rows={5}
                        cols={30}
                        className="input-shadow w-50 autowidth"
                        placeholder="Notes..."
                        autoResize
                        {...field}
                      />
                    )}
                  />
                </div>
              </div>
              {lead_assigned_to.length > 0 && !hideCustomRate && (
                <div className="d-flex align-items-center py-2">
                  <div className="addfield">
                    <span role="button" onClick={() => append_custom_rate({ team_member: null, rate: null, error: '' })}>
                      <i className="icon-add"></i> Add Custom Hourly Rate
                    </span>
                  </div>
                </div>
              )}
              {custom_rate?.map((val, i) => {
                return (
                  <div className="row align-items-center py-2 flex-wrap" key={val.id}>
                    <div className="col-12 col-md-4 pe-2">
                      <label className="p-medium" htmlFor="role">
                        Team Member Name
                      </label>
                      <div className="d-flex align-items-center autowidth">
                        <Controller
                          name={`billing_preferences.custom_rates.${i}.team_member`}
                          control={control}
                          rules={{ required: 'Team Member is Required' }}
                          render={({ field }) => (
                            <Dropdown
                              id={field.name}
                              onChange={(e) => {
                                field.onChange(e.value);
                                setValue(`billing_preferences.custom_rates.${i}.rate`, e.value.rate);
                              }}
                              value={field.value}
                              options={lead_assigned_to}
                              optionLabel={(item) => generateFullName(item)}
                              optionValue={(v) => v}
                              className="col-12"
                              placeholder="Select"
                              filter
                              optionDisabled={(v1) =>
                                custom_rate.find((v2) => {
                                  return v2?.contact_id === v1.contact_id;
                                })
                              }
                            />
                          )}
                        />
                      </div>
                      <RequiredValidation field={`billing_preferences.custom_rates.${i}.team_member`} />
                    </div>
                    <div className="col-12 col-md-4">
                      <label className="p-medium" htmlFor="role">
                        Fee
                      </label>
                      <div className="d-flex align-items-center">
                        <Controller
                          name={`billing_preferences.custom_rates.${i}.rate`}
                          control={control}
                          rules={{ required: 'Rate is Required' }}
                          render={({ field }) => (
                            <InputNumber
                              className="input-shadow w-100 autowidth"
                              placeholder="Fee"
                              mode="currency"
                              currency="USD"
                              locale="en-US"
                              id={field.name}
                              onChange={(e) => field.onChange(e.value)}
                              value={field.value}
                            />
                          )}
                        />
                      </div>
                      <RequiredValidation field={`billing_preferences.custom_rates.${i}.rate`} />
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="d-flex align-items-center">
                        <i className="icon-delete pointer mt-4 ms-2" onClick={() => remove_custom_rate(i)} />
                      </div>
                    </div>
                  </div>
                );
              })}
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};
export default FeeAndPayments;
