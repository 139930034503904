import apiEndPoints from 'common/apiEndPoints';
import { performRequestSecond, performRequestThird } from 'common/apiHandler';
import { getTenantDetails } from 'utils/utils';

export const listExpenses = ({ filter, pageLimit = 10, pagination_token = '', pagination_direction = 'forward', next = '' }) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.listExpenses}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&next=${next}&limit=${pageLimit}&pagination_token=${pagination_token}&pagination_direction=${pagination_direction}`;
  return performRequestSecond(apiEndPoints.methodType.POST, url, filter);
};

export const listExpenseType = ({ pageLimit = 10, pagination_token = '', pagination_direction = 'forward', next = '' }) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.listExpenseType}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&limit=${pageLimit}&next=${next}&pagination_token=${pagination_token}&pagination_direction=${pagination_direction}`;
  return performRequestSecond(apiEndPoints.methodType.POST, url, {});
};

export const deleteExpense = (expense_sk, is_lead = false) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.deleteExpense}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&expense_sk=${encodeURIComponent(expense_sk)}${
    is_lead ? `&is_lead=${is_lead}` : ''
  }`;
  return performRequestSecond(apiEndPoints.methodType.DELETE, url);
};

export const makeIntuitPaymentService = (data, sk) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.intuitPayment}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&payment_sk=${encodeURIComponent(sk)}`;
  return performRequestThird(apiEndPoints.methodType.POST, url, data);
};

export const listClientCardsService = (client_qb_id, contact_id) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.listClientCards}?tenant_id=${tenantId}&tenant_sk=${tenantSk}${
    client_qb_id ? `&client_qb_id=${client_qb_id}` : `&contact_id=${contact_id}`
  }`;
  return performRequestThird(apiEndPoints.methodType.GET, url);
};

export const deleteClientCardService = (client_qb_id, card_id) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.deleteClientCard}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&client_qb_id=${client_qb_id}&card_id=${card_id}`;
  return performRequestThird(apiEndPoints.methodType.DELETE, url);
};
