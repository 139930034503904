import React, { useState } from 'react';
import { Calendar } from 'primereact/calendar';
import { Tooltip } from 'primereact/tooltip';
import { MultiSelect } from 'primereact/multiselect';

export default function Filter({ categoryList, dateFilter, categoryFilter, handleFilterChange, resetFilter }) {
  const formattedCategoryList = categoryList?.map((category) => ({
    label: category,
    value: category,
  }));

  return (
    <div className="mb-3 report-filters-wrap">
      <div className="d-flex filter-wrap autowidth">
        <div className="d-flex align-items-center autowidth p-2 gap-8">
          <div className="d-flex flex-column filter-wraper">
            <Calendar
              id="icon"
              name="date"
              className="input-shadow filter-calender"
              value={dateFilter}
              onChange={(e) => {
                handleFilterChange(e);
              }}
              selectionMode="range"
              showIcon
              placeholder="Date"
              disabled={!categoryList.length}
            />
          </div>
          <div className="d-flex flex-column filter-wraper">
            <MultiSelect
              name="category"
              value={categoryFilter}
              options={formattedCategoryList}
              onChange={handleFilterChange}
              placeholder="Category"
              maxSelectedLabels={1}
              display="chip"
              resetFilterOnHide
              filter
              className="searchable-dropdown input-shadow"
              disabled={!categoryList.length}
            />
          </div>
          <div className="d-flex flex-column text-center">
            <div className="pointer ms-0">
              <i className={`fas fa-sync-alt ${!categoryList?.length ? 'disabled-icon' : ''}`} onClick={resetFilter}></i>
              <Tooltip content="Clear Filter" position="top" target=".fa-sync-alt" showDelay={500} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
