import FileSelection from 'components/FileSelection/FileSelection';
import GlobalLoader from 'components/GlobalLoader/GlobalLoader';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { downloadCsvFile, handleRedirection } from 'utils/utils';
import { Button } from 'primereact/button';
import { createTimeline, getTimeline } from 'services/fileManager/fileManagerServices';
import useFetchFiles from 'hooks/useFetchFiles';
import { WebSocketConnectionContext } from 'context/WebSocketConnectionContext';
import { Menu } from 'primereact/menu';
import TimelineTable from './TimelineTable';
import { convertToTimezoneFormat } from 'utils/utility_functions/timezone';
import moment from 'moment';
import { UserDetailsContext } from 'context/userDetailsContext';
import { useToast } from 'context/ToastContext';
import { toastConstant } from 'constants/toastmessage';

export default function AiTimeline() {
  let { type, id } = useParams();
  const history = useHistory();
  const location = useLocation();
  const caseData = location.state || JSON.parse(localStorage.getItem('caseData'));

  const { files, getFiles, handleSetFiles } = useFetchFiles();
  const websocketContext = useContext(WebSocketConnectionContext);
  const userContext = useContext(UserDetailsContext);

  const [selectedFiles, setSelectedFiles] = useState({});
  const [timelineData, setTimelineData] = useState({});
  const [confirmedSelection, setConfirmedSelection] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [dateFilter, setDateFilter] = useState('');
  const [categoryFilter, setCategoryFilter] = useState([]);
  const menu = useRef(null);
  const { addToast } = useToast();

  const saveOptions = [
    {
      label: 'as PDF',
      command: () => {
        let filters = {
          ...pickExtractedDate(dateFilter),
          category: categoryFilter?.length ? categoryFilter : undefined,
        };
        listTimeline({ download: false, saveAs: 'pdf', filters });
      },
    },
    {
      label: 'as DOCX',
      command: () => {
        let filters = {
          ...pickExtractedDate(dateFilter),
          category: categoryFilter?.length ? categoryFilter : undefined,
        };
        listTimeline({ download: false, saveAs: 'docx', filters });
      },
    },
  ];

  const pickExtractedDate = (dateArray) => {
    return {
      start_date: dateArray?.[0] ? convertToTimezoneFormat(moment(dateArray[0]).startOf('day'), userContext?.userDetails.timezone) : '',
      end_date: dateArray?.[0]
        ? convertToTimezoneFormat(moment(dateArray[1] ? dateArray[1] : dateArray[0]).endOf('day'), userContext?.userDetails.timezone)
        : '',
    };
  };
  const resetFilter = () => {
    setDateFilter('');
    setCategoryFilter([]);
    let filters = {
      start_date: '',
      end_date: '',
      category: [],
    };
    listTimeline({ download: false, filters });
  };
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    let filters = {};
    if (name === 'date') {
      setDateFilter(value);
      filters = {
        ...pickExtractedDate(value),
        category: categoryFilter?.length ? [...categoryFilter] : [],
      };
    } else if (name === 'category') {
      if (value?.length) {
        setCategoryFilter([...value]);
      } else setCategoryFilter([]);
      filters = {
        ...pickExtractedDate(dateFilter),
        category: value?.length ? [...value] : [],
      };
    }
    listTimeline({ download: false, filters });
  };

  const handleGetFiles = () => {
    let obj = {
      case_id: id,
    };
    getFiles(obj);
  };

  const toggleSelect = (id) => {
    setConfirmedSelection(true);
    handleSetFiles((prevFiles) => {
      const updatedFiles = JSON.parse(JSON.stringify(prevFiles));

      const updateNode = (nodes) => {
        Object.keys(nodes)?.forEach((key) => {
          if (nodes[key]?.entry_type === 'file' && nodes[key]?.file_id === id) {
            nodes[key].is_checked_timeline = !nodes[key].is_checked_timeline;
          } else if (nodes[key]?.entry_type === 'folder' && nodes[key]?.sub_items) {
            updateNode(nodes[key]?.sub_items);
          }
        });
      };
      updateNode(updatedFiles);
      return updatedFiles;
    });
    setSelectedFiles((prevSelected) => {
      const newSelectedFiles = { ...prevSelected };
      let found = false;
      Object.keys(prevSelected)?.forEach((key) => {
        if (prevSelected[key]?.file_id === id) {
          delete newSelectedFiles[key];
          found = true;
        }
      });
      if (!found) {
        const findFile = (nodes) => {
          Object.keys(nodes)?.forEach((key) => {
            if (nodes[key]?.entry_type === 'file' && nodes[key]?.file_id === id) {
              newSelectedFiles[key] = nodes[key];
              return;
            } else if (nodes[key]?.entry_type === 'folder' && nodes[key]?.sub_items) {
              findFile(nodes[key]?.sub_items);
            }
          });
        };
        findFile(files);
      }

      return newSelectedFiles;
    });
  };

  const handleCreateTimeline = () => {
    setCategoryFilter([]);
    setDateFilter('');
    setTimelineData({});
    setCategoryList([]);
    const folderDetailsSet = new Set();
    const validFiles = Object.fromEntries(
      Object.entries(selectedFiles)?.filter(([key, file]) => file.timeline_invalid_document_flag !== true)
    );

    Object.values(selectedFiles)?.forEach((file) => {
      const findFolder = (nodes, parentFolders = []) => {
        Object.keys(nodes)?.forEach((key) => {
          const node = nodes[key];

          if (node?.entry_type === 'file' && node?.file_id === file.file_id) {
            parentFolders?.forEach((folder) => {
              folderDetailsSet.add(JSON.stringify({ pk: folder.pk, sk: folder.sk }));
            });
          } else if (node?.entry_type === 'folder' && node?.sub_items) {
            findFolder(node?.sub_items, [...parentFolders, node]);
          }
        });
      };

      findFolder(files);
    });

    const folderDetails = Array.from(folderDetailsSet)?.map((item) => JSON.parse(item));

    const data = {
      file_details: validFiles,
      case_id: id,
      is_lead: type !== 'cases',
    };
    if (type === 'cases' && caseData?.case_name) {
      data.case_name = caseData?.case_name;
    } else if (type === 'leads' && caseData?.main_client_name) {
      data.main_client_name = caseData?.main_client_name;
    }
    if (folderDetails?.length > 0) {
      data.folder_details = folderDetails;
    }
    createTimeline(data)
      .then(async (response) => {
        let arr = response.data;
        setTimelineData(arr);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const listTimeline = ({ download = false, saveAs = '', filters = {} }) => {
    const filterList = {
      start_date: filters?.start_date,
      end_date: filters?.end_date,
      category: filters?.category,
    };
    const obj = {
      case_id: id,
      download_flag: download ? 1 : 0,
      ...filterList,
    };
    if (saveAs === 'pdf') obj.save_as_pdf = 1;
    if (saveAs === 'docx') obj.save_as_docx = 1;
    if (saveAs) obj.timezone = userContext?.userDetails.timezone;

    getTimeline(obj)
      .then(async (response) => {
        let arr = response?.data;
        if (!download && !saveAs) {
          setTimelineData(arr);
          if (arr?.task_status !== 'In Progress' && arr?.category_list) {
            setCategoryList(arr?.category_list);
          }
        } else if (download) {
          downloadCsvFile(arr, 'Timeline');
        }
        if (saveAs && arr) {
          addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.FILE_SAVE_SUCCESS);
          handleGetFiles();
        }
      })
      .catch((error) => {
        console.log(error);
        if (saveAs) {
          addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.FILE_SAVE_FAILED);
        }
      });
  };

  useEffect(() => {
    const extractSelectedFiles = (nodes) => {
      let selected = {};

      Object.keys(nodes)?.forEach((key) => {
        if (nodes[key]?.entry_type === 'file' && nodes[key]?.is_checked_timeline) {
          selected[key] = nodes[key];
        } else if (nodes[key]?.entry_type === 'folder' && nodes[key]?.sub_items) {
          selected = { ...selected, ...extractSelectedFiles(nodes[key]?.sub_items) };
        }
      });
      return selected;
    };
    setSelectedFiles(extractSelectedFiles(files));
  }, [files]);

  useEffect(() => {
    if (!confirmedSelection && Object.keys(selectedFiles)?.length > 0) {
      listTimeline({ download: false });
    }
  }, [selectedFiles]);

  useEffect(() => {
    if (
      websocketContext?.aiTimelineData?.body &&
      websocketContext?.aiTimelineData?.body?.timeline?.task_status === 'Success' &&
      websocketContext?.aiTimelineData?.body?.timeline?.case_id === id
    ) {
      if (
        websocketContext?.aiTimelineData?.body?.timeline?.timeline &&
        websocketContext?.aiTimelineData?.body?.timeline?.timeline?.length
      ) {
        setTimelineData(websocketContext?.aiTimelineData?.body?.timeline);
        setCategoryList(websocketContext?.aiTimelineData?.body?.timeline?.category_list);
        if (websocketContext?.aiTimelineData?.body?.timeline?.error_documents) {
          handleGetFiles();
        }
      } else {
        setTimelineData({ message: 'No Data Found' });
      }
    } else if (
      websocketContext?.aiTimelineData?.body &&
      websocketContext?.aiTimelineData?.body?.timeline?.task_status === 'Failed' &&
      websocketContext?.aiTimelineData?.body?.timeline?.case_id === id
    ) {
      setTimelineData(websocketContext?.aiTimelineData?.body?.timeline);
      if (websocketContext?.aiTimelineData?.body?.timeline?.error_documents) {
        handleGetFiles();
      }
    }
  }, [websocketContext?.aiTimelineData]);

  useEffect(() => {
    handleGetFiles();
    return () => {
      localStorage.removeItem('caseData');
    };
  }, []);

  return (
    <>
      <GlobalLoader />
      <div className="container-fluid">
        <div className="d-flex justify-content-between align-items-center py-2 my-3">
          <div className="pointer">
            <i
              onClick={() => {
                setTimelineData({});
                const url = `/${type}/${id}/files`;
                handleRedirection(history, url);
              }}
              className="icon-back icon-box me-3 pointer"
            ></i>
            <label className="sub-title">Timeline</label>
          </div>
        </div>
        <div className="row" style={{ maxHeight: '100%' }}>
          <div className="d-flex mt-2 align-items-center mb-0">
            <span className="d-flex p-0" style={{ color: 'rgba(0, 0, 0, 0.6)', lineHeight: '18px', fontSize: '12px' }}>
              <i className="pi pi-info-circle me-2"></i>
              AI-generated responses may contain errors or inaccuracies. You should not rely on AI responses without independent
              verification.
            </span>
          </div>
          <div className="col-lg-4 col-12 p-3" style={{ height: '100%', overflowY: 'auto' }}>
            <div className="d-flex">
              <div className="col-md-12 col-12 d-flex justify-content-between align-items-center">
                <h1 className="caption-bold mb-0">File Selection</h1>
                <div className="d-flex justify-content-end">
                  <Button
                    label="Add to Timeline"
                    className="p-button-rounded p-button-primary"
                    onClick={() => {
                      setConfirmedSelection(true);
                      handleCreateTimeline();
                    }}
                    disabled={!(selectedFiles && Object.keys(selectedFiles)?.length) || timelineData?.task_status === 'In Progress'}
                  />
                </div>
              </div>
            </div>
            {Object.keys(files)?.length > 0 ? (
              <div className="mt-3">
                <div className="col-12">
                  <div className="bg-white shadow-middle py-3 ai-file-selection">
                    <FileSelection files={files} toggleSelect={toggleSelect} isTimelineEntry={true} />
                  </div>
                </div>
              </div>
            ) : (
              <div className="shadow-middle p-3 bg-white mt-3 no-entity-card">
                <div className="row">
                  <div className="col-12">No Files Found</div>
                </div>
              </div>
            )}
          </div>
          <div className="col-lg-8 col-12 p-3">
            <div className="d-flex">
              <div className="col-md-12 col-12 d-flex justify-content-between align-items-center">
                <h1 className="caption-bold mb-0">Timeline</h1>
                <div className="d-flex justify-content-end">
                  <Menu model={saveOptions} popup ref={menu} />
                  <Button
                    label="Save"
                    className="p-button-rounded p-button-primary ms-2"
                    aria-controls="popup_menu"
                    aria-haspopup
                    onClick={(event) => menu.current.toggle(event)}
                    disabled={!(timelineData?.timeline && timelineData?.timeline?.length) || timelineData?.task_status === 'In Progress'}
                  />
                  <Button
                    label="Download"
                    className="p-button-rounded p-button-primary ms-2"
                    onClick={() => {
                      let filters = {
                        ...pickExtractedDate(dateFilter),
                        category: categoryFilter?.length ? categoryFilter : undefined,
                      };
                      listTimeline({ download: true, filters });
                    }}
                    disabled={!(timelineData?.timeline && timelineData?.timeline?.length) || timelineData?.task_status === 'In Progress'}
                  />
                </div>
              </div>
            </div>
            <div className="mt-3 timeline-container">
              <TimelineTable {...{ timelineData, categoryList, dateFilter, categoryFilter, handleFilterChange, resetFilter }} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
