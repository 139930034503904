import React from 'react';
import { Button } from 'primereact/button';
import { useFileManagerContext } from 'modules/file_manager/context/FileManagerContext';
import { handleRedirection } from 'utils/utils';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { generateFullName } from 'utils/generateFullNameUtils';

function ExtractEntities() {
  const { isClosed, caseDetails, isCaseFileManager } = useFileManagerContext();
  const history = useHistory();

  return (
    <Button
      label="Extract Entities"
      className="p-button-primary outline ms-2"
      onClick={() => {
        const caseDataToPass = {};
        if (caseDetails?.is_lead) {
          caseDataToPass['main_client_name'] = generateFullName(caseDetails?.main_client[0]);
        } else {
          caseDataToPass['case_name'] = caseDetails?.case_description?.case_name;
        }
        if (caseDataToPass) {
          localStorage.setItem('caseData', JSON.stringify(caseDataToPass));
        }
        const url = `/entities/${isCaseFileManager ? 'cases' : 'leads'}/${caseDetails?.case_id}`;
        handleRedirection(history, url, caseDataToPass);
      }}
      disabled={isClosed}
    />
  );
}

export default ExtractEntities;
