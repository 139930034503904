import { useWebSocketContext } from 'context/WebSocketConnectionContext';
import { useCallback, useEffect, useState } from 'react';
import { listEmailThreads } from 'services/mailbox/mailboxService';

const useEmails = (caseId, mail_date_lower, mail_date_upper, searchKeyword, setReadEmails = () => {}, isSent = false) => {
  const [data, setData] = useState([]);
  const [emails, setEmails] = useState([]);
  const [unassignedEmails, setUnassignedEmails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [hasNext, setHasNext] = useState(false);
  const [paginationLoading, setPaginationLoading] = useState(false);
  const [paginationToken, setPaginationToken] = useState(null);

  const { caseEmails } = useWebSocketContext();

  const [isForward, setIsForward] = useState(true);

  const next_Token = data?.next_token;
  const transition_Token = data?.transition_token;

  const hasNextPage = next_Token === null && isForward;
  const hasPreviousPage = transition_Token === null || (transition_Token && next_Token === null && !isForward);

  const fetchNext = () => {
    setIsForward(true);
    setPaginationLoading(true);
    const token = !isForward ? transition_Token : next_Token;

    listEmailThreads(token, null, null, null, false, searchKeyword, 'forward')
      .then((res) => {
        setData(res?.data);
        const nextToken = res?.data?.next_token;
        setPaginationToken(res?.data?.next_token);
        setHasNext(!!nextToken);
        setUnassignedEmails(res?.data?.email_threads);
      })
      .catch((err) => {
        setError(err);
        console.error(err);
      })
      .finally(() => setPaginationLoading(false));
  };

  const fetchPrev = () => {
    setIsForward(false);
    setPaginationLoading(true);
    const token = isForward ? transition_Token : next_Token;
    listEmailThreads(token, null, null, null, false, searchKeyword, 'reverse')
      .then((res) => {
        setData(res?.data);
        const emailThreads = res?.data?.email_threads;
        setUnassignedEmails(emailThreads);
      })
      .catch((err) => {
        setError(err);
        console.error(err);
      })
      .finally(() => setPaginationLoading(false));
  };

  const fetchEmailThreads = useCallback(async () => {
    setLoading(true);
    setError(null);
    listEmailThreads(null, caseId, mail_date_lower, mail_date_upper, isSent, searchKeyword)
      .then((res) => {
        const emailThreads = res?.data?.email_threads;
        if (!caseId) {
          setUnassignedEmails(emailThreads);
        } else {
          setEmails(emailThreads);
        }
        const nextToken = res?.data?.next_token;

        setPaginationToken(nextToken);
        setHasNext(!!nextToken);
      })
      .catch((err) => {
        setError(err);
        console.error(err);
      })
      .finally(() => setLoading(false));
  }, [caseId, mail_date_lower, mail_date_upper, isSent, searchKeyword]);

  const fetchNextEmailThreads = async () => {
    if (!paginationToken || loading) return;
    setPaginationLoading(true);
    setHasNext(false);
    setError(null);
    listEmailThreads(paginationToken, caseId, mail_date_lower, mail_date_upper, isSent, searchKeyword)
      .then((res) => {
        const emailThreads = res?.data?.email_threads;
        if (!caseId) {
          setUnassignedEmails((prevEmails) => [...prevEmails, ...emailThreads]);
        } else {
          setEmails((prevEmails) => [...prevEmails, ...emailThreads]);
        }
        const nextToken = res?.data?.next_token;
        setPaginationToken(nextToken);
        setHasNext(!!nextToken);
      })
      .catch((err) => {
        setError(err);
        console.error(err);
      })
      .finally(() => setPaginationLoading(false));
  };

  const handleLoadMore = () => {
    fetchNextEmailThreads();
  };

  const refreshEmails = useCallback(() => {
    fetchEmailThreads();
  }, [fetchEmailThreads]);

  useEffect(() => {
    if (!!caseEmails?.body.case_id) {
      // if incoming email from ws has case id
      setEmails((prevEmails) => {
        const index = prevEmails.findIndex((email) => email.pk === caseEmails?.body.pk);
        if (index !== -1) {
          return prevEmails.map((email, i) => {
            setReadEmails((prev) => ({
              ...prev,
              [caseEmails?.body.pk]: false, // Ensure it remains unread
            }));
            return i === index ? { ...email, is_unread: true, subject: caseEmails?.body.subject } : email;
          });
        } else {
          const newEmail = { ...caseEmails?.body, is_unread: true };
          return [newEmail, ...prevEmails];
        }
      });
    } else if (hasPreviousPage) {
      // if reconciliation page is paginated, then don't need to add new emails from ws
      setUnassignedEmails((prevEmails) => {
        const index = prevEmails.findIndex((email) => email.pk === caseEmails?.body.pk);
        if (index !== -1) {
          return prevEmails.map((email, i) => (i === index ? { ...email, is_unread: true, subject: caseEmails?.body.subject } : email));
        }
        return [{ ...caseEmails?.body, is_unread: true }, ...prevEmails];
      });
    }
  }, [caseEmails]);

  useEffect(() => {
    if (caseId || mail_date_lower || mail_date_upper) fetchEmailThreads(); // for getting emails with caseId and date filter
    else fetchNext(); // use this for getting unassigned emails
  }, [isSent, mail_date_lower, mail_date_upper, searchKeyword, caseId]);

  return {
    emails,
    unassignedEmails,
    setEmails,
    handleLoadMore,
    refreshEmails,
    loading,
    error,
    hasNext,
    hasNextPage,
    hasPreviousPage,
    paginationLoading,
    fetchNext,
    fetchPrev,
  };
};

export default useEmails;
