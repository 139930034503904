import { useEffect } from 'react';
import { useState } from 'react';
import { listCasesAndLeads } from 'services/CaseServices';

export function useCaseAndLeadsList(case_id, exclude_pi_case) {
  const [List, setList] = useState([]);
  const loadCaseAndLeadsList = () => {
    listCasesAndLeads(false, case_id, exclude_pi_case)
      .then((response) => setList(response?.data))
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    loadCaseAndLeadsList();
  }, [case_id]);

  return { caseAndLeadsList: List };
}
