import React from 'react';
import { Button } from 'primereact/button';
import { useHistory } from 'react-router-dom';
import CaseListDropDown from 'components/CaseListDropDown/CaseListDropDown';
import LabelName from 'components/UI/LabelName/LabelName';
import { Tooltip } from 'primereact/tooltip';
import { Calendar } from 'primereact/calendar';

export default function ReconcileHeader({
  isGlobalMail,
  caseValue,
  onCaseChange,
  dateFilter,
  onDateChange,
  refreshEmails,
  setShowComposeModal,
  selectedEmails,
  setSelectedEmails,
  setShow,
  setSelectedEmailThreadPkSk,
}) {
  const history = useHistory();
  return (
    <div className="d-flex flex-wrap align-items-center justify-content-between p-2">
      <div className="d-flex flex-wrap align-items-center filter-wrap justify-content-between p-2">
        <LabelName className="pe-2">Filters:</LabelName>
        {isGlobalMail && (
          <div className="autowidth">
            <CaseListDropDown
              value={caseValue || null}
              onChange={(data) => {
                onCaseChange(data);
                setSelectedEmailThreadPkSk({});
              }}
              isClearable
              placeholder="By Case/Lead"
            />
          </div>
        )}
        <div className="d-flex flex-column ms-2 filter-wraper">
          <Calendar
            className="input-shadow filter-calender"
            id="mail-date-filter"
            value={dateFilter}
            onChange={(e) => {
              onDateChange(e.value);
              setSelectedEmailThreadPkSk({});
            }}
            selectionMode="range"
            showIcon
            placeholder="Date"
          />
        </div>
        <div className="d-flex flex-row pointer ms-0 p-2 gap-2">
          <div className="pointer ms-0 p-2">
            <i
              className="fas fa-sync-alt"
              onClick={() => {
                if (isGlobalMail) {
                  onCaseChange(null);
                  onDateChange('');
                  refreshEmails();
                  setSelectedEmailThreadPkSk({});
                } else {
                  onDateChange('');
                  refreshEmails();
                  setSelectedEmailThreadPkSk({});
                }
              }}
            ></i>
          </div>
          <Tooltip target=".fa-sync-alt" content="Clear Filters" position="bottom" showDelay={500} />
        </div>
      </div>
      <div className="d-flex py-2">
        <Button
          type="button"
          className="p-button p-component p-button-primary button outline ms-2 mb-2"
          label="Map to Case/Lead"
          disabled={!selectedEmails?.length > 0}
          onClick={() => setShow(true)}
        />
        {isGlobalMail && (
          <Button
            label="View Logs"
            type="button"
            className="btn-outline p-button-primary ms-2 mb-2"
            onClick={() => history.push('/mail-logs')}
          />
        )}
        <Button
          label="Compose"
          type="button"
          className="btn-outline p-button-primary ms-2 mb-2"
          icon="pi pi-plus"
          onClick={() => setShowComposeModal(true)}
        />
      </div>
    </div>
  );
}
