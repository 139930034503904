import React from 'react';
import Filter from './Filter';
import { tableCell } from 'modules/reports/components/TableCell/TableCell';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ProgressSpinner } from 'primereact/progressspinner';

export default function TimelineTable({ timelineData, categoryList, dateFilter, categoryFilter, handleFilterChange, resetFilter }) {
  return (
    <>
      <div className="container-fluid datatable-responsive" style={{ background: '#FAFAFA', maxHeight: '100%' }}>
        <div className="shadow-middle p-3 bg-white">
          <div className="d-flex justify-content-between align-items-center mb-2">
            <Filter {...{ categoryList, dateFilter, categoryFilter, handleFilterChange, resetFilter }} />
          </div>
          <div className="timeline-table">
            <DataTable
              className="p-datatable-responsive"
              value={timelineData?.timeline}
              responsiveLayout="scroll"
              emptyMessage={
                timelineData?.task_status === 'In Progress' || timelineData?.task_status === 'Failed' ? (
                  <div className="d-flex align-items-center gap-2 justify-content-center">
                    {timelineData?.task_status === 'In Progress' && (
                      <ProgressSpinner className="m-0" style={{ width: '20px', height: '20px' }} />
                    )}
                    {timelineData?.message}
                  </div>
                ) : timelineData?.task_status === 'Success' ? (
                  'No Data Found'
                ) : (
                  'Timeline not created'
                )
              }
              stripedRows
              sortField=""
            >
              <Column header="Date" field="date" bodyClassName="ellipsis-text" body={tableCell} style={{ width: '150px' }} />
              <Column
                sortable
                field="category"
                header="Category"
                body={tableCell}
                bodyClassName="ellipsis-text"
                style={{ width: '220px' }}
              />
              <Column sortable field="description" header="Description" body={tableCell} bodyClassName="ellipsis-text" />
              <Column sortable field="additional_info" header="Additional Information" body={tableCell} bodyClassName="ellipsis-text" />
            </DataTable>
          </div>
        </div>
      </div>
    </>
  );
}
