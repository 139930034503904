import apiEndPoints from 'common/apiEndPoints';
import { performRequestThird } from 'common/apiHandler';
import { getTenantDetails } from 'utils/utils';

export const listEmailThreads = (
  nextToken,
  caseId,
  mail_date_lower,
  mail_date_upper,
  isSent,
  searchKeyword,
  paginationDirection,
  is_email_log = false
) => {
  const [tenantId, tenantSk] = getTenantDetails();
  const url = `${apiEndPoints.listEmailThreads}?tenant_id=${tenantId}&tenant_sk=${tenantSk}
  ${caseId ? `&case_id=${caseId}` : ''}${mail_date_lower ? `&mail_date_lower=${mail_date_lower}` : ''}${
    mail_date_upper ? `&mail_date_upper=${mail_date_upper}` : ''
  }${isSent !== undefined ? `&is_sent=${isSent}` : ''}${nextToken ? `&pagination_token=${nextToken}` : ''}${
    searchKeyword ? '&search_keyword=' + encodeURIComponent(searchKeyword) : ''
  }${paginationDirection ? `&pagination_direction=${paginationDirection}` : ''}&limit=10${
    is_email_log ? `&is_email_log=${is_email_log}` : ''
  }`;
  return performRequestThird(apiEndPoints.methodType.GET, url);
};

export const viewEmailThreadDetails = (emailThreadPk, emailThreadSk) => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequestThird(
    apiEndPoints.methodType.GET,
    `${apiEndPoints.viewEmailThreadDetails}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&email_thread_pk=${emailThreadPk}&email_thread_sk=${emailThreadSk}`
  );
};

export const postMail = (data) => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequestThird(
    apiEndPoints.methodType.POST,
    `${apiEndPoints.sendOutboundMail}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`,
    data
  );
};

export const reconcileEmail = (data) => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequestThird(
    apiEndPoints.methodType.POST,
    `${apiEndPoints.reconcileEmail}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`,
    data
  );
};

export const listEmailLogs = (
  nextToken,
  caseId,
  mail_date_lower,
  mail_date_upper,
  isSent,
  searchKeyword,
  paginationDirection,
  limit,
  is_email_log = false
) => {
  const [tenantId, tenantSk] = getTenantDetails();
  const url = `${apiEndPoints.listEmailThreads}?tenant_id=${tenantId}&tenant_sk=${tenantSk}
  ${caseId ? `&case_id=${caseId}` : ''}${mail_date_lower ? `&mail_date_lower=${mail_date_lower}` : ''}${
    mail_date_upper ? `&mail_date_upper=${mail_date_upper}` : ''
  }${isSent !== undefined ? `&is_sent=${isSent}` : ''}${nextToken ? `&pagination_token=${nextToken}` : ''}${
    searchKeyword ? '&search_keyword=' + encodeURIComponent(searchKeyword) : ''
  }${paginationDirection ? `&pagination_direction=${paginationDirection}` : ''}${limit ? `&limit=${limit}` : ''}${
    is_email_log ? `&is_email_log=${is_email_log}` : ''
  }`;
  return performRequestThird(apiEndPoints.methodType.GET, url);
};
