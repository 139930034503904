import { postActivityLog } from 'services/generalServices';

export function useActivityLogPreview() {
  const addActivity = (data) => {
    console.log('Activity Log Preview', data);

    return postActivityLog(data) // Return the promise from postActivityLog
      .then((response) => {
        // Handle the successful response here if needed
        console.log('Activity log added successfully:', response);
      })
      .catch((error) => {
        // Handle the error
        console.error('Error posting activity log:', error);
      });
  };

  return { addActivity };
}
