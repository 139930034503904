import { phoneValidation } from 'services/contact';

export const phoneNumberDuplicationCheckFunc = async (phoneNumber, state, key = 'phone_number') => {
  phoneNumber = phoneNumber?.replaceAll(/[^0-9]/g, '');
  if (typeof state !== 'function') {
    console.warn('Need to pass a function (state function that updates the error) as a second parameter');
    return;
  }
  if (!phoneNumber) {
    return;
  }
  state((prev) => ({
    ...prev,
    [key]: 'Checking if phone number already exist...',
  }));
  try {
    let response = await phoneValidation(phoneNumber);
    if (response.data?.length) {
      state((prev) => ({
        ...prev,
        [key]: 'Phone number already exists.',
      }));
    } else {
      state((prev) => ({
        ...prev,
        [key]: '',
      }));
    }
  } catch (error) {
    console.log('error', error?.response);
    let errorMessage = error?.response?.data || 'Cannot add this phone number';
    state((prev) => ({
      ...prev,
      [key]: errorMessage,
    }));
  }
};
