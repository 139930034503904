import GlobalLoader from 'components/GlobalLoader/GlobalLoader';
import React from 'react';
import { Card } from 'primereact/card';

export default function ExtractedEntitiesTable(props) {
  const { selectedFiles, extractedEntitiesData } = props;

  const entities = [
    { label: 'Person', value: 'person' },
    { label: 'Location', value: 'locations' },
    { label: 'Email', value: 'email' },
    { label: 'Phone Number', value: 'phone_number' },
    { label: 'Organization', value: 'organisation' },
    { label: 'Title', value: 'tite' },
    { label: 'Police Badge Number', value: 'police_badge_number' },
  ];

  return (
    <>
      <GlobalLoader />
      <div className="container-fluid entities-container" style={{ background: '#FAFAFA', maxHeight: '100%' }}>
        {entities?.map(({ label, value }) => {
          const entityValues = extractedEntitiesData?.entities?.[value];

          return (
            entityValues?.length > 0 && (
              <div className="col-12 mt-3" style={{ maxHeight: '500px' }} key={value}>
                <div className="mb-2 entities-card input-shadow bg-white p-3">
                  <h6 className="card-title mb-0">{label}</h6>
                  <hr className="my-2" />
                  <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
                    <ul className="mb-1 custom-bullet custom-list gap-2">
                      {entityValues?.map((entity, index) => (
                        <li className="content" key={index}>
                          {entity}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            )
          );
        })}
      </div>
    </>
  );
}
