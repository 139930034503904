export const FLAT_FEE_SUB_TOTAL = 'Flat Fee Sub-total';
export const BILLED_HOUR_SUB_TOTAL = 'Billed Hour Sub-total';
export const EXPENSE_SUB_TOTAL = 'Expense Sub-total';
export const FORWARD_SUB_TOTAL = 'Expense Sub-total';
export const ADDITION = 'Addition';
export const DISCOUNT = 'Discount';
export const SETTLEMENT_AMOUNT = 'Settlement Amount';
export const TOTAL_BILLS_AND_EXPENSES = 'Total Bills And Expenses';
export const TOTAL_LEGAL_FEE = 'Total Legal Fee';
export const TOTAL_TO_CLIENT = 'Total to Client';
export const TOTAL_AMOUNT = 'Total Amount';
export const AMOUNT_EXCEEDS_TRUST_FUNDS_AVAILABLE = 'Amount exceeding Trust Funds Available';
export const PERSONAL_INJURY = 'Personal Injury';
export const INVOICE_TOTAL = 'INVOICE TOTAL';
export const FORWARDED_DUE_AMOUNT = 'Forwarded Due Amount';
