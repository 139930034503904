import CaseListDropDown from 'components/CaseListDropDown/CaseListDropDown';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { useReconcileEmail } from '../hooks/useReconcileEmail';
import { useState } from 'react';
import { useToast } from 'context/ToastContext';
import { toastConstant } from 'constants/toastmessage';

const CaseMappingModal = ({
  show,
  setShow,
  selectedEmails,
  setSelectedEmails,
  setSelectedEmailThreadPkSk,
  refreshEmails,
  isFromMailLogs,
  setSelectedRows,
  setSelectAll,
  setSelectedEmail,
  setShowMailDetailsModal,
}) => {
  const [caseId, setCaseId] = useState(null);
  const { changeEmailCase } = useReconcileEmail();
  const { addToast } = useToast();

  const handleCaseSelection = (data) => {
    setCaseId(data);
  };

  const handleAssign = async () => {
    if (!caseId) {
      console.warn('⚠️ Please select a case before assigning.');
      return;
    }

    const emailPkSkArray = selectedEmails.map((email) => ({
      pk: email.pk,
      sk: email.sk,
    }));

    try {
      const response = await changeEmailCase(emailPkSkArray, caseId.id, caseId?.case_name, caseId?.is_lead);
      addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.MAIL_RECONCILE_SUCCESS);
      setShow(false);
      setCaseId(null);
      refreshEmails();
      !isFromMailLogs && setSelectedEmailThreadPkSk({});
      !isFromMailLogs && setSelectedEmails([]);
      isFromMailLogs && setSelectedRows([]);
      isFromMailLogs && setSelectAll(false);
      isFromMailLogs && setSelectedEmail([]);
      isFromMailLogs && setShowMailDetailsModal(false);
    } catch (error) {
      console.error('❌ Error assigning emails:', error);
      addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, toastConstant.message.MAIL_RECONCILE_FAILED);
    }
  };

  const handleClose = () => {
    setCaseId(null);
    setShow(false);
  };

  const headerComponent = (data) => (
    <div>
      <i className="icon-window-filled F-size14 pointer" onClick={data?.onHide}></i>
    </div>
  );

  return (
    <Dialog
      id="caseMappingModal"
      className="reconcile-modal create-msg-popup"
      header={headerComponent}
      visible={show}
      onHide={handleClose}
      closable={false}
      style={{ width: '50vw' }}
    >
      <div className="pt-3">
        <div className="d-flex flex-column gap-2">
          <h5 className="sub-title mb-0">Select a case/lead to map</h5>
          <div className="d-flex gap-2 align-items-center w-100">
            <div className="p-medium">Case/Lead Name</div>
            <CaseListDropDown
              className="mail-case-list-dropdown"
              menuPortalTarget={document.body}
              styles={{
                menuPortal: (baseStyles) => ({
                  ...baseStyles,
                  zIndex: 99999,
                }),
              }}
              value={caseId}
              onChange={(data) => handleCaseSelection(data)}
              placeholder="Select Case/Lead"
              // caseOnly
            />
          </div>
        </div>
        <div className="d-flex mt-2 justify-content-end ">
          <Button label="Cancel" className="p-button-text" onClick={handleClose} />
          <Button label="Confirm" className="p-button-primary ml-2" onClick={handleAssign} disabled={!caseId} />
        </div>
      </div>
    </Dialog>
  );
};

export default CaseMappingModal;
