import apiEndPoints from 'common/apiEndPoints';
import { performRequestSecond, performRequestThird } from 'common/apiHandler';
import { getTenantDetails } from 'utils/utils';

export const listAllAccounts = (
  pageLimit,
  pagination_token = '',
  pagination_direction = 'forward',
  next = '',
  payloadData,
  qb_accounts = true
) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints?.listAccount}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&limit=${pageLimit}&next=${next}&pagination_token=${pagination_token}&pagination_direction=${pagination_direction}&qb_accounts=${qb_accounts}`;
  return performRequestSecond(apiEndPoints?.methodType?.POST, url, payloadData);
};

export const accountMapping = (data) => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequestThird(
    apiEndPoints.methodType.POST,
    `${apiEndPoints.accountMapping}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`,
    data
  );
};
