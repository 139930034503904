import { useState, useEffect } from 'react';
import { toastConstant } from 'constants/toastmessage';
import { useToast } from 'context/ToastContext';
import { useUserDetailsContext } from 'context/userDetailsContext';
import { disconnectCalendar, generateAuthURl, generateToken, getCalendarSettings, listEvents } from 'services/Calender/calendarServices';
import { getQueryParams } from 'utils/utils';

var pollOAuth = null;

export default function useGoogleCalendars() {
  const { addToast } = useToast();
  const { userDetails } = useUserDetailsContext();
  const [calendarSettings, setCalendarSettings] = useState();
  const [calendarList, setCalendarList] = useState([]);
  const [showLoader, setShowLoader] = useState(false);

  const getGoogleCalendarList = () => {
    listEvents({ list_google_calendars: true })
      .then((res) => {
        setCalendarList(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const disconnectGoogleCal = () => {
    return disconnectCalendar(userDetails?.firm_user_id).catch(() => {
      addToast(true, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, 'Failed to disconnect google calendar');
    });
  };

  const openGooglewindow = (url, callback) => {
    setShowLoader(true);
    // Launch Popup using the JS window Object
    var parameters = 'location=1,width=600,height=650';
    parameters += ',left=' + (window.screen.width - 800) / 2 + ',top=' + (window.screen.height - 650) / 2;
    var win = window.open(url, 'connectPopup', parameters);
    if (!win) {
      setShowLoader(false); // Hide spinner if window fails to open
      return;
    }
    pollOAuth = window.setInterval(function () {
      try {
        if (!win || win.closed) {
          window.clearInterval(pollOAuth);
          setShowLoader(false);
          return;
        }
        if (win.document.URL.indexOf('code') != -1) {
          window.clearInterval(pollOAuth);
          const params = getQueryParams(win.document.URL);
          setShowLoader(false);
          win.close();
          generateToken(userDetails?.firm_user_id, params?.code, params?.state)
            .then(() => {
              typeof callback === 'function' && callback(true);
            })
            .catch((err) => {
              console.log(err);
              addToast(true, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, 'Error occured. Please try gain.');
              typeof callback === 'function' && callback(false);
            });
        }
      } catch (e) {
        console.log(e);
      }
    }, 100);
  };

  const generateRedirectUrl = (callback) => {
    generateAuthURl(userDetails?.firm_user_id)
      .then((res) => {
        console.log(res?.data?.auth_url);
        openGooglewindow(res?.data?.auth_url, callback);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCalendarSetting = () => {
    return getCalendarSettings()
      .then((res) => {
        setCalendarSettings(res?.data?.calendar_settings);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getCalendarSetting();
  }, []);

  useEffect(() => {
    if (calendarSettings?.sync_with_google_calendar) {
      getGoogleCalendarList();
    }
  }, [calendarSettings]);

  return { showLoader, calendarSettings, calendarList, disconnectGoogleCal, getGoogleCalendarList, generateRedirectUrl };
}
