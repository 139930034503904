import React, { useMemo } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';
import { useUserDetailsContext } from 'context/userDetailsContext';
import constants from 'constants/index';
import { settingsDropdownOptions } from 'utils/utils';

const SettingsLayout = (props) => {
  const userDetails = useUserDetailsContext();
  const history = useHistory();
  const location = useLocation();

  const redirectTo = (e, path) => {
    e.preventDefault();
    history.push('/settings' + path);
  };

  const modifiedSettingsList = useMemo(
    () =>
      settingsDropdownOptions.filter((item) => {
        if (item.value === '/workflow-automation') {
          return userDetails.checkSubPermission('automated_repeatable_workflows');
        } else if (item.value === '/lead-sources') {
          return userDetails.checkSubPermission('lead_management');
        } else if (item.value === '/custom-fields') {
          return userDetails.checkSubPermission('custom_fields');
        } else if (item.value === '/subscription') {
          return !(userDetails?.access_level !== 'firm-admin' && item.value === '/subscription');
        } else if (item?.value === '/quickbooks') {
          return userDetails?.access_level === 'firm-admin' && item?.value === '/quickbooks';
        } else return true;
      }),
    [userDetails?.subscriptionAccess]
  );

  return (
    <div className="container-fluid">
      <div className="row">
        {userDetails.getFirmPermission(constants.permissions.MANAGE_FIRM_SETTINGS) && (
          <>
            <div className="col-lg-2 col-md-4 side-nav shadow-middle d-none height-full d-md-block">
              <div className="my-3 side-nav-inner">
                {modifiedSettingsList.map((v, i) => {
                  return (
                    <Link
                      to={`/settings${v.value}`}
                      key={i}
                      className={location.pathname === `/settings${v.value}` ? 'current-nav text-decoration-none' : ''}
                    >
                      <i className={location.pathname === `/settings${v.value}` ? v.icon_active : v.icon}></i> {v.label}
                    </Link>
                  );
                })}
              </div>
            </div>

            <Dropdown
              className="d-md-none mb-3"
              options={modifiedSettingsList}
              value={location?.pathname?.replace('/settings', '')}
              onChange={(e) => redirectTo(e, e.target.value)}
              filter
            />
            {/* this is for responsive screen for setting menus */}
          </>
        )}
        <div className="col-lg-10 col-md-8 ps-lg-4 ps-md-3 ps-0 pe-0 mt-1 settings-dm">{props.children}</div>
      </div>
    </div>
  );
};

export default SettingsLayout;
