import CustomCalendar from 'components/CustomCalendar/CustomCalendar';
import TextSnippetWrapper from 'components/TextSnippetWrapper';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import React, { useContext } from 'react';
import { addTax } from 'utils/utility_functions/commonCalculations';
import { handleDateTimeOffset } from 'utils/utility_functions/timezone';
import { fixToTwoDecimal, formatNumbersToCurrencyString } from 'utils/utils';
import { UserDetailsContext } from 'context/userDetailsContext';
import constants from 'constants/index';

const FlatFeeBillingDetails = (props) => {
  const {
    caseDetails,
    addFlatFee,
    flatFees,
    flatFeeError,
    onChangeFlatFeeDate,
    flatFeeActualTotal,
    flatFeeTotal,
    setIsChanged,
    isChanged,
    teamMembers,
    setFlatFeeError,
    setFlatFees,
    deleteFlatFee,
  } = props || {};

  const userContext = useContext(UserDetailsContext);

  //TODO:Move this to another folder

  const nonBillableFlatFeeBody = (rowData, props) => {
    return (
      <>
        <span className="p-column-title text-break">Billable</span>
        <label className="switch">
          <input
            type="checkbox"
            checked={rowData.billable}
            name="non_billable"
            onChange={(e) => {
              let index = props.rowIndex;
              flatFees[index].billable = e.target.checked;
              setFlatFees([...flatFees]);
              isChanged.flat_fees = true;
              setIsChanged({ ...isChanged });
            }}
          />{' '}
          <span className="slider round"></span>
        </label>
      </>
    );
  };

  return (
    <>
      <div>
        <div className="caption-bold mb-3 mt-3">Flat Fees</div>
        <div className="shadow-small">
          <div className="row mt-3 ">
            <div className="col-12 datatable-responsive">
              <DataTable
                className="p-datatable-responsive"
                value={flatFees}
                editMode="cell"
                emptyMessage={() => <div onClick={addFlatFee}>No records found</div>}
              >
                <Column
                  header="Date"
                  field="date"
                  body={(rowData, props) => {
                    return (
                      <>
                        <span className="p-column-title text-break">Date</span>
                        <span className="text-break">
                          {rowData?.flat_fee_date
                            ? handleDateTimeOffset(
                                userContext?.userDetails?.timezone,
                                rowData.flat_fee_date,
                                constants.month_date_year_format
                              )
                            : ''}
                        </span>
                        <br></br>
                        {/* TODO:ERROR HANDLING */}
                        {flatFeeError && flatFeeError[`${props.rowIndex}_date`] && (
                          <span className="text-danger">{flatFeeError[`${props.rowIndex}_date`]}</span>
                        )}
                      </>
                    );
                  }}
                  editor={(props) => {
                    return (
                      <div className="table-input">
                        <CustomCalendar
                          value={props.rowData?.flat_fee_date}
                          onChange={(e) => {
                            onChangeFlatFeeDate(e, props);
                            setIsChanged({ ...isChanged, flat_fees: true });
                          }}
                          className="w-100 border-0 p-2"
                        />
                        {/* TODO:ERROR HANDLING */}
                        {flatFeeError && flatFeeError[`${props.rowIndex}_date`] && (
                          <span className="text-danger">{flatFeeError[`${props.rowIndex}_date`]}</span>
                        )}
                      </div>
                    );
                  }}
                  sortable
                ></Column>
                <Column
                  header="Members"
                  field={'employee_name'}
                  body={(rowData) => {
                    return (
                      <>
                        <span className="p-column-title text-break">Members</span>
                        <span className="text-break">{rowData.employee_name ? rowData.employee_name : <i>Please Enter</i>}</span>
                      </>
                    );
                  }}
                  editor={(props) => {
                    return (
                      <Dropdown
                        options={teamMembers}
                        optionLabel={'name'}
                        value={{
                          name: props?.rowData?.employee_name,
                          contact_id: props?.rowData?.employee_id,
                        }}
                        onChange={(e) => {
                          flatFees[props.rowIndex].employee_name = e.target.value?.name;
                          flatFees[props.rowIndex].employee_id = e.target.value?.contact_id;
                          setFlatFees([...flatFees]);
                          isChanged.flat_fees = true;
                          setIsChanged({ ...isChanged });
                        }}
                        filter
                      />
                    );
                  }}
                  sortable
                ></Column>
                <Column
                  header="Item"
                  body={(rowData) => {
                    return (
                      <>
                        <span className="p-column-title text-break">Item</span>
                        <span className="text-break">{rowData.item_name}</span>
                      </>
                    );
                  }}
                />
                <Column
                  header="Flat Fee Notes"
                  field="notes"
                  body={(rowData) => {
                    return (
                      <>
                        <span className="p-column-title text-break">Flat Fee Notes</span>
                        <span className="text-break">{rowData.flat_fee_notes ? rowData.flat_fee_notes : <i>Please Enter</i>}</span>
                      </>
                    );
                  }}
                  editor={(props) => {
                    return (
                      <TextSnippetWrapper
                        type="textarea"
                        value={props.rowData?.flat_fee_notes}
                        onChange={(e) => {
                          let index = props.rowIndex;
                          flatFees[index].flat_fee_notes = e.target.value;
                          setFlatFees([...flatFees]);
                          isChanged.flat_fees = true;
                          setIsChanged({ ...isChanged });
                        }}
                        className="w-100 input-shadow"
                      />
                    );
                  }}
                  footer="Total"
                />
                <Column
                  header="Amount"
                  field="flat_fee_amount"
                  body={(rowData, props) => {
                    return (
                      <>
                        <span className="p-column-title text-break">Amount</span>
                        <span className="text-break">
                          {formatNumbersToCurrencyString(rowData.actual_amount ?? rowData.flat_fee_amount)}
                        </span>
                        {flatFeeError && flatFeeError[`${props.rowIndex}_amount`] && (
                          <span className="text-danger">{flatFeeError[`${props.rowIndex}_amount`]}</span>
                        )}
                      </>
                    );
                  }}
                  editor={(props) => {
                    return (
                      <div>
                        <InputText
                          type="number"
                          value={props.rowData?.actual_amount ?? props.rowData?.flat_fee_amount}
                          onChange={(e) => {
                            let index = props.rowIndex;
                            flatFees[index].actual_amount = fixToTwoDecimal(e.target.value);
                            if (caseDetails?.billing_preferences?.tax_percentage) {
                              flatFees[index].flat_fee_amount = addTax(
                                e.target.value || 0,
                                caseDetails?.billing_preferences?.tax_percentage || 0
                              );
                            } else {
                              flatFees[index].flat_fee_amount = fixToTwoDecimal(e.target.value);
                            }

                            flatFees[index].tax_applied = false;
                            flatFeeError[`${index}_amount`] = '';
                            setFlatFeeError({ ...flatFeeError });
                            setFlatFees([...flatFees]);
                            isChanged.flat_fees = true;
                            setIsChanged({ ...isChanged });
                          }}
                          className="w-100 input-shadow"
                          min={0}
                          keyfilter={'pnum'}
                        />
                        {flatFeeError && flatFeeError[`${props.rowIndex}_amount`] && (
                          <span className="text-danger">{flatFeeError[`${props.rowIndex}_amount`]}</span>
                        )}
                      </div>
                    );
                  }}
                  footer={formatNumbersToCurrencyString(flatFeeActualTotal)}
                  sortable
                ></Column>
                {caseDetails?.billing_preferences?.amount_with_tax && (
                  <Column
                    header="Amount (with Tax)"
                    field="flat_fee_amount"
                    body={(rowData, props) => {
                      return (
                        <>
                          <span className="p-column-title text-break">Amount</span>
                          <span className="text-break">{formatNumbersToCurrencyString(rowData.flat_fee_amount)}</span>
                        </>
                      );
                    }}
                    footer={formatNumbersToCurrencyString(flatFeeTotal)}
                    sortable
                  ></Column>
                )}
                <Column
                  header="Billable"
                  body={(rowData, props) => {
                    return nonBillableFlatFeeBody(rowData, props);
                  }}
                />
                <Column
                  body={(rowData, props) => {
                    return (
                      <>
                        <span className="p-column-title text-break">Actions</span>
                        <div className="d-flex">
                          <i
                            className="icon-delete icon-red ms-2 mt-2"
                            onClick={(e) => {
                              deleteFlatFee(props);
                            }}
                          />
                        </div>
                      </>
                    );
                  }}
                />
              </DataTable>
            </div>
            <div className="col-12"></div>
          </div>

          {caseDetails?.billing_preferences?.tax_percentage && (
            <div className="d-flex p-2">
              <div className="col col-md-8 text-bold disclaimer">Tax Rate @ {caseDetails?.billing_preferences?.tax_percentage || 0}%</div>
            </div>
          )}
          <div className="d-flex">
            <div className="col-12 p-2">
              <span className="addfield" role="button" onClick={addFlatFee}>
                + Add Flat Fee
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FlatFeeBillingDetails;
