import { DAYS } from 'constants/automationConstants';
import { toastConstant } from 'constants/toastmessage';
import { listEventTypes } from 'services/calendarServices';
import { createContact, listContacts } from 'services/contact';
import { totalCountEvent } from 'services/generalServices';
import { openLinkInBlank } from 'utils/utils';
import { calculateDateTimeCylce, getFilteredContacts } from 'modules/settings/workflow_automation/helpers/automationCommonHelpers';

export const reminderCountOptions = Array.from({ length: 60 }, (_, i) => i + 1).map((index) => {
  return {
    label: index,
    value: index,
  };
});

export const loadLocations = (setLocationsList) => {
  totalCountEvent('contact').then((response) => {
    listContacts('', '', '', '', false, true, '', response.data)
      .then((response) => {
        if (response?.data?.contacts?.length > 0) {
          let options = response.data.contacts.map((index) => {
            let locatonName = index.title ? index.title : index.office_name;
            return {
              label: locatonName,
              value: locatonName,
            };
          });
          setLocationsList([...options]);
        }
      })
      .catch((err) => {
        console.log('err', err);
      });
  });
};

export const loadEventTypesList = (setEventTypeList = () => {}) => {
  totalCountEvent('event-type').then((res) => {
    let pageLimit = res?.data || 10;
    let pagination_token = '';
    let pagination_direction = 'forward';

    listEventTypes(pageLimit, pagination_token, pagination_direction)
      .then((res) => {
        if (res?.data) {
          let evenTypeTempArr = [];
          let eventTypes = res?.data['event-type'] || [];

          eventTypes?.forEach((item, idx) => {
            const { event_type, event_type_icon } = item || {};
            evenTypeTempArr?.push({
              event_type,
              event_type_icon,
            });
          });

          setEventTypeList(evenTypeTempArr);
        }
      })
      .catch((err) => {
        console.log('err', err);
      });
  });
};

export const removeFirstSlash = (itemPath = '') => {
  if (itemPath.charAt(0) === '/') itemPath = itemPath?.slice(1);
  return itemPath;
};

export const handleCustomizeEventTypeLinkClick = (e, history) => {
  e?.preventDefault();
  let path = '/settings/custom-descriptors';
  localStorage?.setItem('stateData', JSON.stringify({ activeIndex: 3, currentGroupLabel: 2 }));
  if (!openLinkInBlank(path)) {
    history?.push(path);
  }
};

/** --- getEventToTime - Function to caluculate 'to' time for an event --- **/
export const getEventEndTime = (eventStartTime = new Date(), extraMinutes = 30) => {
  eventStartTime?.setMinutes(eventStartTime?.getMinutes() + extraMinutes);
  return new Date(eventStartTime);
};

export const getEventTimeDifferenceInMinutes = (eventEndTime, eventStartTime) => {
  eventEndTime = new Date(eventEndTime);
  eventStartTime = new Date(eventStartTime);
  let timeDifference = (eventEndTime?.getTime() - eventStartTime?.getTime()) / 1000;
  timeDifference /= 60;

  let absoluteTimeDifference = Math.abs(Math.round(timeDifference));
  return absoluteTimeDifference;
};

/** --- calculateEventMeetingCylce - Function to claculate meeting_cylce value --- **/
export const calculateEventMeetingCylce = (dateCount = 0, datePeriod = DAYS) => {
  return calculateDateTimeCylce(dateCount, datePeriod);
};

export const getFormattedAttendees = (params) => {
  const { attendeesArr = [], isBillingOrPrimary = false, isUserGroup = false, isFirmUser = true, isTeamMember } = params || {};
  let formatttedAttendeesArr = [];
  let filteredAttendees = [];

  //Filtering attendees selected
  filteredAttendees = getFilteredContacts({
    arrayToBeFiltered: attendeesArr,
    isBillingOrPrimary,
    isFirmUser,
    isUserGroup,
    isTeamMember,
  });

  formatttedAttendeesArr = filteredAttendees?.map((attendee, idx) => {
    if (attendee?.email) {
      // Firm users only
      // const { label: fullName, value: congnitoUserName, contact_id, email, is_attendance_required } = attendee;
      const {
        label: fullName,
        value,
        contact_id,
        email,
        is_attendance_required,
        client_cognito_username,
        firm_user_id,
        access_level,
        is_individual_user,
        profile_image,
      } = attendee;

      return {
        name: fullName,
        email,
        cognito_username: client_cognito_username,
        contact_id,
        firm_user_id,
        access_level,
        is_individual_user,
        profile_image,
        is_attendance_required,
      };
    } else if (attendee?.group_id) {
      // User group items only
      const { label: groupName, pk, group_id, sk } = attendee || {};
      return {
        group_name: groupName,
        sk: sk,
        group_id,
        pk,
      };
    } else if (attendee?.code && attendee?.code === 'TEAM_MEMBER_ROLE') {
      //Team member roles only
      return attendee?.value;
    }

    // primary and billing contacts only
    return attendee?.value;
  });
  return formatttedAttendeesArr;
};

/** --- generateFullAttendeesList - function generates full "Attendees" list including Primary and Billing Contacts--- **/
export const generateFullAttendeesList = (params = {}) => {
  const { firmUsersList = [], setFullAttendeesList = () => {}, getAllRequiredContactsList = () => {} } = params || {};
  let allAttendees = getAllRequiredContactsList(firmUsersList);
  setFullAttendeesList(allAttendees);
};
export const generateFullAttendeesListReverse = (params = {}) => {
  const { firmUsersList = [], setFullAttendeesList = () => {}, getAllRequiredContactsList = () => {} } = params || {};
  let allAttendees = getAllRequiredContactsList(firmUsersList);
  setFullAttendeesList(allAttendees.reverse());
};

/** --- Generating attendee details object array --- **/
export const generateAttendeesStatusOptions = (params) => {
  const { fullAttendeesList: fullAttendees, attendeesWatched: selectedAttendees } = params || {};

  let statusArr = [];
  let allContactList = [];

  if (fullAttendees?.length > 0) {
    fullAttendees?.forEach((element) => {
      if (element?.items?.length) {
        allContactList.push(...element?.items);
      }
    });
  }

  if (selectedAttendees?.length && allContactList?.length) {
    selectedAttendees?.forEach((att, idx) => {
      allContactList?.forEach((attendee, index) => {
        let condition = att === attendee?.value;
        if (condition) {
          statusArr.push(attendee);
        }
      });
    });
  }

  return statusArr;
};

/** ------------ Helpers for adding and removing new location starts --------------------- **/

//Canceling adding location
export const onCancelAddingNewLocation = (params) => {
  const { notifyFormDataChange, setToggleLocation, setValue } = params || {};

  notifyFormDataChange();
  setToggleLocation(false);
  setValue('newLocationName', '');
};

//Adding new location
export const handleAddLocation = (params) => {
  const { locationsList, setLocationsList, setToggleLocation, notifyFormDataChange, getValues, setValue, addToast } = params || {};

  notifyFormDataChange();
  let newLocation = getValues('newLocationName') || '';

  if (newLocation) {
    let data = {
      contact_type: 'location',
      title: newLocation,
      email: '',
      phone: '',
      country: 'United States',
      country_code: '+1',
    };
    createContact(data)
      .then((response) => {
        let allLocations = [...locationsList] || [];
        allLocations?.unshift({ label: newLocation, value: newLocation });
        setLocationsList(allLocations);
        setToggleLocation(false);
        addToast(false, toastConstant?.toasterType.SUCCESS, toastConstant?.api.SUCCESS, toastConstant?.message.LOCATION_CREATE_SUCCESS);
        setValue('newLocationName', '');
      })
      .catch((err) => {
        console.log('err', err);
        addToast(false, toastConstant?.toasterType.INFO, toastConstant?.api.FAILED, toastConstant?.message.LOCATION_CREATE_FAILURE);
      });
  }
};

/** --- handleReminderOptionsDisabling - Function for disabling already selected reminder options ------------ **/
export const handleReminderOptionsDisabling = (params) => {
  const { index, getValues, reminderOptions, setFilteredReminderOptions } = params || {};
  const selectedReminders = getValues('reminders');
  const selectedReminderLength = selectedReminders?.length;
  let allReminders = reminderOptions;
  allReminders[index]['disabled'] = false;

  if (selectedReminderLength > 1) {
    selectedReminders?.forEach((selectedRem, key) => {
      allReminders?.forEach((rem, idx) => {
        let condition = selectedRem?.value !== 'custom' && selectedRem?.value === rem?.value;

        if (condition) {
          rem['disabled'] = true;
        }
      });
    });
  }
  setFilteredReminderOptions(allReminders);
};

/** --- renderAttendeesStatusOptions - Function to add "required" or "optional" status to selected attendees array --- **/
export const renderAttendeesStatusOptions = (params) => {
  const { attendeesStatusOptions, onChangeAttendeesStatus } = params || {};

  if (attendeesStatusOptions?.length > 0) {
    return (
      <div className="my-2">
        <div className="col-12 col-md-4"></div>
        <div className="shadow-middle bg-white rounded p-2">
          <div className="permissions-table ">
            <table className="table table-striped event-permission-table">
              <thead>
                <tr>
                  <th> </th>
                  <th className="text-center">
                    <label className="text-muted">Required</label>
                  </th>
                  <th className="text-center">
                    <label className="text-muted">Optional</label>
                  </th>
                </tr>
              </thead>
              {attendeesStatusOptions?.map((attendee, idx) => {
                return (
                  <tbody key={idx}>
                    <tr>
                      <td className="text-break">{attendee.label} </td>
                      <td className="text-center">
                        <input
                          type="radio"
                          id={idx}
                          name={idx + 'status'}
                          value={'required'}
                          onChange={(e) => {
                            onChangeAttendeesStatus(e, idx);
                          }}
                          checked={attendee?.is_attendance_required === 'required'}
                        />{' '}
                      </td>
                      <td className="text-center">
                        <input
                          type="radio"
                          id={attendee}
                          name={idx + 'status'}
                          value={'optional'}
                          onChange={(e) => {
                            onChangeAttendeesStatus(e, idx);
                          }}
                          checked={attendee?.is_attendance_required === 'optional'}
                        />{' '}
                      </td>
                    </tr>
                  </tbody>
                );
              })}
            </table>
          </div>
        </div>
      </div>
    );
  }
};
