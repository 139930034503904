import GlobalLoader from 'components/GlobalLoader/GlobalLoader';
import React from 'react';
import FileDirectoryTree from './FileDirectoryTree';

export default function FileSelection(props) {
  const { files, toggleSelect, isTimelineEntry } = props;

  return (
    <>
      <div className="container-fluid" style={{ maxHeight: '100%' }}>
        <FileDirectoryTree files={files} toggleSelect={toggleSelect} isTimelineEntry={isTimelineEntry} />
      </div>
    </>
  );
}
