import { Tooltip } from 'primereact/tooltip';
import React, { useState } from 'react';

const FileTreeNode = ({ node, toggleSelect, isTimelineEntry = false }) => {
  const [expanded, setExpanded] = useState(isTimelineEntry ? !!node?.is_extension_persist_timeline : !!node?.is_extension_persist);

  const handleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} className={`${node?.entry_type === 'file' ? 'ms-2' : ''}`}>
        <div className="mb-3">
          {(isTimelineEntry ? node?.timeline_invalid_document_flag : node?.entities_invalid_document_flag) && (
            <>
              <i className="fas fa-exclamation-triangle" style={{ color: '#ff583e' }}></i>
              <Tooltip content="Cannot extract entities from this file." position="top" target=".fa-exclamation-triangle" showDelay={500} />
            </>
          )}
          {node?.entry_type === 'folder' && (
            <span onClick={handleExpand}>
              <i className={`pi ${expanded ? 'pi-chevron-down' : 'pi-chevron-right'} ms-2`}></i>
              <i className="icon-folder fa-lg ms-2"></i>
            </span>
          )}
          {node?.entry_type === 'file' && (
            <input
              className={(isTimelineEntry ? node?.timeline_invalid_document_flag : node?.entities_invalid_document_flag) ? 'ms-2' : ''}
              type="checkbox"
              checked={!!(isTimelineEntry ? node?.is_checked_timeline : node?.is_checked) || false}
              onChange={() => toggleSelect(node.file_id)}
              disabled={isTimelineEntry ? node?.timeline_invalid_document_flag : node?.entities_invalid_document_flag}
            />
          )}
          <span>
            {node?.entry_type === 'file' && <i className="fas fa-file-pdf ms-2"></i>}
            <span className="ms-2">{node?.display_name}</span>
          </span>
        </div>
      </div>
      {expanded && node?.entry_type === 'folder' && node?.sub_items && (
        <div style={{ marginLeft: 20 }}>
          {Object.entries(node?.sub_items)?.map(([key, child]) => (
            <FileTreeNode
              key={child.file_id || key}
              node={{ display_name: key, ...child }}
              toggleSelect={toggleSelect}
              isTimelineEntry={isTimelineEntry}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default FileTreeNode;
