import { Button } from 'primereact/button';
import { Controller } from 'react-hook-form';
// import Dropzone from 'react-dropzone';
import TextSnippetWrapper from 'components/TextSnippetWrapper';
import InputErrorMessage from 'components/UI/InputErrorMessage/InputErrorMessage';
import LabelName from 'components/UI/LabelName/LabelName';
import { useUserDetailsContext } from 'context/userDetailsContext';
import Dropzone from 'react-dropzone';
import CaseListDropDown from 'components/CaseListDropDown/CaseListDropDown';
import { Chips } from 'primereact/chips';

const MailCreateForm = ({ caseId, onSubmit, handleSubmit, handleClose, control, errors }) => {
  const { userDetails } = useUserDetailsContext();

  return (
    <div className="h-100">
      <form className="F-size14 message-create-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="justify-content-between align-items-center mb-3 mt-3">
          <div className="modal-heading F-size16 text-bold">Compose Mail</div>
          <div className="d-flex align-items-center py-2">
            <div className="col-12">
              <div>
                <div className="d-flex align-items-center py-2 flex-wrap ">
                  {/* To */}
                  <div className="col-md-4 col-12">
                    <LabelName required={true} htmlFor="recipient_list">
                      To
                    </LabelName>
                  </div>
                  <div className="col-md-8 col-12">
                    <Controller
                      name="recipient_list"
                      control={control}
                      rules={{
                        required: 'Required',
                        // validate: (value) => {
                        //   // Check if the recipient list contains the user's email
                        //   if (value.split(',').some((email) => email.trim() === userDetails?.business_email)) {
                        //     return 'You cannot send an email to your own address';
                        //   }

                        //   // Validate each email format using regex
                        //   const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                        //   const emails = value.split(',').map((email) => email.trim());
                        //   for (const email of emails) {
                        //     if (!emailRegex.test(email)) {
                        //       return `Invalid email format: ${email}`;
                        //     }
                        //   }

                        //   return true; // No error if all validations pass
                        // },
                        validate: (value) => {
                          // Check if the recipient list contains the user's email
                          if (value.some((email) => email.trim() === userDetails?.business_email)) {
                            return 'You cannot send an email to your own address';
                          }

                          // Check if the email domain ends with .lawft.com
                          const internalDomainRegex = /@.*\.lawft\.com$/i;

                          // Validate each email format using regex
                          const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                          for (const email of value) {
                            const trimmedEmail = email.trim();

                            // Check for internal user email domain
                            if (internalDomainRegex.test(trimmedEmail)) {
                              return 'You cannot send email to internal users';
                            }

                            if (!emailRegex.test(email.trim())) {
                              return `Invalid email format: ${email}`;
                            }
                          }

                          return true; // No error if all validations pass
                        },
                      }}
                      render={({ field }) => (
                        // <TextSnippetWrapper type="text" id={field.name} {...field} placeholder="To" className={'w-100 input-shadow'} />
                        <Chips
                          inputRef={field.ref}
                          id={field.name}
                          value={field.value}
                          onChange={(e) => field.onChange(e.value)}
                          placeholder={field.value ? '' : 'To'}
                          className={'w-100 input-shadow ps-2'}
                          separator=","
                        />
                      )}
                    />
                    {errors.recipient_list && <InputErrorMessage>{errors.recipient_list.message}</InputErrorMessage>}
                  </div>
                </div>
                <div className="d-flex align-items-center py-2 flex-wrap ">
                  {/* cc */}
                  <div className="col-md-4 col-12">
                    <LabelName htmlFor="cc_list">Cc</LabelName>
                  </div>
                  <div className="col-md-8 col-12">
                    <Controller
                      name="cc_list"
                      control={control}
                      rules={{
                        // validate: (value) => {
                        //   if (!value) {
                        //     return true; // No error for empty BCC field
                        //   }
                        //   // Check if the recipient list contains the user's email
                        //   if (value.split(',').some((email) => email.trim() === userDetails?.business_email)) {
                        //     return 'You cannot send an email to your own address';
                        //   }

                        //   // Validate each email format using regex
                        //   const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                        //   const emails = value.split(',').map((email) => email.trim());
                        //   for (const email of emails) {
                        //     if (!emailRegex.test(email)) {
                        //       return `Invalid email format`;
                        //     }
                        //   }

                        //   return true; // No error if all validations pass
                        // },
                        validate: (value) => {
                          if (value) {
                            // Check if the recipient list contains the user's email
                            if (value.some((email) => email.trim() === userDetails?.business_email)) {
                              return 'You cannot send an email to your own address';
                            }

                            // Check if the email domain ends with .lawft.com
                            const internalDomainRegex = /@.*\.lawft\.com$/i;
                            // Validate each email format using regex
                            const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                            for (const email of value) {
                              const trimmedEmail = email.trim();

                              // Check for internal user email domain
                              if (internalDomainRegex.test(trimmedEmail)) {
                                return 'You cannot send email to internal users';
                              }

                              if (!emailRegex.test(email.trim())) {
                                return `Invalid email format: ${email}`;
                              }
                            }

                            return true; // No error if all validations pass
                          }
                        },
                      }}
                      render={({ field }) => (
                        // <TextSnippetWrapper type="text" id={field.name} {...field} placeholder="cc" className={'w-100 input-shadow'} />
                        <Chips
                          inputRef={field.ref}
                          id={field.name}
                          value={field.value}
                          onChange={(e) => field.onChange(e.value)}
                          placeholder={field.value ? '' : 'cc'}
                          className={'w-100 input-shadow ps-2'}
                          separator=","
                        />
                      )}
                    />
                    {errors.cc_list && <InputErrorMessage>{errors.cc_list.message}</InputErrorMessage>}
                  </div>
                </div>
                {/* <div className="d-flex align-items-center py-2 flex-wrap ">
                  <div className="col-md-4 col-12">
                    <LabelName htmlFor="bcc_list">Bcc</LabelName>
                  </div>
                  <div className="col-md-8 col-12">
                    <Controller
                      name="bcc_list"
                      control={control}
                      rules={{
                        validate: (value) => {
                          if (!value) {
                            return true; // No error for empty CC field
                          }
                          // Check if the recipient list contains the user's email
                          if (value.split(',').some((email) => email.trim() === userDetails?.business_email)) {
                            return 'You cannot send an email to your own address';
                          }

                          // Validate each email format using regex
                          const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                          const emails = value.split(',').map((email) => email.trim());
                          for (const email of emails) {
                            if (!emailRegex.test(email)) {
                              return `Invalid email format: ${email}`;
                            }
                          }

                          return true; // No error if all validations pass
                        },
                      }}
                      render={({ field }) => (
                        <TextSnippetWrapper type="text" id={field.name} {...field} placeholder="bcc" className={'w-100 input-shadow'} />
                      )}
                    />
                    {errors.bcc_list && <InputErrorMessage>{errors.bcc_list.message}</InputErrorMessage>}
                  </div>
                </div> */}
              </div>
              <div className="d-flex align-items-center py-2 flex-wrap">
                <div className="col-md-4 col-12">
                  <LabelName required={true} htmlFor="subject">
                    Subject
                  </LabelName>
                </div>
                <div className="col-md-8 col-12">
                  <Controller
                    name="subject"
                    control={control}
                    rules={{ required: 'Required' }}
                    defaultValue=""
                    render={({ field }) => (
                      <TextSnippetWrapper type="text" id={field.name} {...field} placeholder="Subject" className={'w-100 input-shadow'} />
                    )}
                  />
                  {errors.subject && <InputErrorMessage>Required</InputErrorMessage>}
                </div>
              </div>
              {!caseId && (
                <div className="d-flex align-items-center py-2 flex-wrap">
                  <div className="col-md-4 col-12">
                    <LabelName required={true} htmlFor="subject">
                      Case
                    </LabelName>
                  </div>
                  <div className="col-md-8 col-12">
                    <Controller
                      name="case"
                      control={control}
                      defaultValue={null}
                      rules={{ required: 'Case is required' }} // Adding validation rule
                      render={({ field }) => (
                        <CaseListDropDown {...field} placeholder="Case/Lead Name" noOptionsMessage="No Cases/Leads Found" />
                      )}
                    />
                    {/* Display error for Case field */}
                    {errors.case && <InputErrorMessage>{errors?.case?.message}</InputErrorMessage>}
                  </div>
                </div>
              )}
              <div className="d-flex align-items-start py-2 flex-wrap">
                <div className="col-md-4 col-12">
                  <LabelName htmlFor="html_body">Body</LabelName>
                </div>
                <div className="col-md-8 col-12">
                  <Controller
                    control={control}
                    name="html_body"
                    className="shadow-small"
                    render={({ field: { onChange, value } }) => (
                      <TextSnippetWrapper className="email-quill-editor" type="editor" value={value} onChange={(e) => onChange(e)} />
                    )}
                  />
                  {errors?.html_body && <InputErrorMessage>Please enter your message</InputErrorMessage>}
                </div>
              </div>
              {/* Attachments Field */}
              <div className="d-flex align-items-start py-2 flex-wrap">
                <div className="col-md-4 col-12 mt-2">
                  <LabelName required={false} htmlFor="attachments">
                    Attachments
                  </LabelName>
                </div>
                <div className="col-md-8 col-12 mt-2">
                  <Controller
                    name="attachments"
                    control={control}
                    defaultValue={[]} // Initialize with an empty array
                    render={({ field }) => (
                      <Dropzone
                        onDrop={(acceptedFiles) => {
                          const updatedFiles = [...field.value, ...acceptedFiles];
                          field.onChange(updatedFiles); // Update react-hook-form state
                        }}
                        accept="application/pdf, application/msword, .docx, .doc, audio/*, video/*,image/*"
                      >
                        {({ getRootProps, getInputProps }) => (
                          <section>
                            <div {...getRootProps()}>
                              <input {...getInputProps()} />
                              <p className="text-center font-bold attachmnet-input input-shadow mb-0">
                                Click or drag file to this area to upload
                              </p>
                            </div>
                          </section>
                        )}
                      </Dropzone>
                    )}
                  />
                  <ul>
                    <Controller
                      name="attachments"
                      control={control}
                      render={({ field }) =>
                        field.value.map((file, index) => (
                          <div className="d-flex mt-2" key={file.path}>
                            <i className="fas fa-paperclip me-2 mt-1"></i>
                            <li className="text-break">{file.name}</li>
                            <i
                              className="icon-delete ms-2 mt-1 pointer"
                              onClick={() => {
                                const updatedFiles = field.value.filter((f, i) => i !== index);
                                field.onChange(updatedFiles); // Update react-hook-form state
                              }}
                            />
                          </div>
                        ))
                      }
                    />
                  </ul>
                </div>
              </div>
              <div className="footer-btn">
                <Button type="button" label="Cancel" className="p-button p-button-secondary outline me-2" onClick={handleClose} />
                <Button type="submit" label="Send" className="p-button p-button-secondary" />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default MailCreateForm;
