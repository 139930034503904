import React, { useState, forwardRef, useImperativeHandle } from 'react';
import ReactDOM from 'react-dom';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { useForm, Controller } from 'react-hook-form';
import { ModalHeader } from 'shared/ModalHeader';
import { RequiredValidation } from 'components/UI/RequiredValidation/RequiredValidation';
import TextSnippetWrapper from 'components/TextSnippetWrapper';
import { RadioButton } from 'primereact/radiobutton';

const ConfirmDialog = forwardRef((props, ref) => {
  const [visible, setVisible] = useState(false);
  const [resolveConfirm, setResolveConfirm] = useState(null);
  const [rejectCancel, setRejectCancel] = useState(null);
  const [dynamicContent, setDynamicContent] = useState(null);
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  useImperativeHandle(ref, () => ({
    open(content, onConfirm, onCancel) {
      setDynamicContent(content);
      setVisible(true);
      setResolveConfirm(() => onConfirm);
      setRejectCancel(() => onCancel);
    },
  }));

  const onSubmit = (data) => {
    setVisible(false);
    if (resolveConfirm) {
      resolveConfirm(data);
    }
    reset();
  };

  const handleCancel = () => {
    setVisible(false);
    if (rejectCancel) {
      rejectCancel(false);
    }
    reset();
  };

  const renderField = (fieldConfig) => {
    const validationRules = fieldConfig.required ? { required: `${fieldConfig.label} is required` } : {};

    switch (fieldConfig.type) {
      case 'title':
        return <div className="modal-heading F-size16 text-bold mb-3">{fieldConfig.label}</div>;
      case 'input':
        return (
          <>
            <label htmlFor={fieldConfig.name} className="p-medium">
              {fieldConfig.label} {fieldConfig?.required && <span className="p-error">*</span>}
            </label>
            <Controller
              name={fieldConfig.name}
              control={control}
              defaultValue=""
              rules={validationRules}
              render={({ field: controllerField }) => (
                <TextSnippetWrapper type="text" id={fieldConfig.name} {...controllerField} className="w-100 input-shadow" />
              )}
            />
          </>
        );
      case 'dropdown':
        const { name, value, options, onChange, placeholder, ...rest } = fieldConfig || {};
        return (
          <>
            <label htmlFor={fieldConfig.name} className="p-medium">
              {fieldConfig.label} {fieldConfig?.required && <span className="p-error">*</span>}
            </label>
            <Controller
              name={fieldConfig.name}
              control={control}
              defaultValue=""
              rules={validationRules}
              render={({ field: controllerField }) => (
                <Dropdown
                  {...rest}
                  className="w-100 input-shadow"
                  id={name}
                  value={controllerField.value}
                  options={options}
                  onChange={(e) => controllerField.onChange(e.value)}
                  placeholder={placeholder}
                />
              )}
            />
          </>
        );
      case 'multiselect':
        return (
          <>
            <label htmlFor={fieldConfig.name} className="p-medium">
              {fieldConfig.label} {fieldConfig?.required && <span className="p-error">*</span>}
            </label>
            <Controller
              name={fieldConfig.name}
              control={control}
              defaultValue=""
              rules={validationRules}
              render={({ field: controllerField }) => (
                <MultiSelect
                  className="w-100 input-shadow"
                  id={fieldConfig.name}
                  value={controllerField.value}
                  options={fieldConfig.options}
                  onChange={(e) => controllerField.onChange(e.value)}
                  placeholder={fieldConfig.placeholder}
                />
              )}
            />
          </>
        );
      case 'radiobutton':
        return (
          <div className="row">
            <Controller
              name={fieldConfig.name}
              control={control}
              defaultValue=""
              rules={validationRules}
              render={({ field: controllerField }) => (
                <>
                  {fieldConfig.categories.map((category) => {
                    return (
                      <div key={category.key} className="col-12 my-2 d-flex  align-items-center">
                        <div key={category.key} className="p-field-radiobutton">
                          <RadioButton
                            inputId={category.key}
                            name={fieldConfig.name}
                            value={category.key}
                            checked={controllerField.value === category.key}
                            onChange={(e) => controllerField.onChange(e.value)}
                          />
                          <label htmlFor={category.key} className="ms-2 p-medium">
                            {category.label}
                          </label>
                        </div>
                      </div>
                    );
                  })}
                </>
              )}
            />
          </div>
        );
      default:
        return null;
    }
  };

  return ReactDOM.createPortal(
    <Dialog visible={visible} onHide={handleCancel} header={ModalHeader} className="new-task-popup" footer={<></>}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {dynamicContent && (
          <div>
            {dynamicContent.formFields.map((fieldConfig) => (
              <div key={fieldConfig.name} className="p-field mb-2">
                {renderField(fieldConfig)}
                {fieldConfig.required && <RequiredValidation errors={errors} field={fieldConfig.name} />}
              </div>
            ))}
          </div>
        )}
        {dynamicContent?.footer ? (
          <div className="footer-btn">
            <Button
              label={dynamicContent.footer.cancelLable}
              type="reset"
              onClick={handleCancel}
              className="ms-2 btn p-button-secondary outline"
            />
            <Button label={dynamicContent.footer.okLable} type="submit" autoFocus className="p-button-secondary ms-2" />
          </div>
        ) : (
          <div className="footer-btn">
            <Button label="Cancel" type="reset" icon="pi pi-times" onClick={handleCancel} className="ms-2 btn p-button-secondary outline" />
            <Button label="Select" icon="pi pi-check" type="submit" autoFocus className="p-button-secondary ms-2" />
          </div>
        )}
      </form>
    </Dialog>,
    document.body
  );
});

export default ConfirmDialog;
