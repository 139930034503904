import React from 'react';
import FileTreeNode from './FileTreeNode';

const FileDirectoryTree = (props) => {
  const { files, toggleSelect, isTimelineEntry } = props;

  return (
    <div className="file-directory-tree">
      {Object.entries(files)?.map(([key, node]) => (
        <FileTreeNode
          key={node?.file_id || key}
          node={{ display_name: key, ...node }}
          toggleSelect={toggleSelect}
          isTimelineEntry={isTimelineEntry}
        />
      ))}
    </div>
  );
};

export default FileDirectoryTree;
