import React, { useState } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { useFileManagerContext } from 'modules/file_manager/context/FileManagerContext';
import { ModalHeader } from 'shared/ModalHeader';
import IdentifyDiscrepancy from './IdentifyDiscrepancy';
import { DiscrepancyHistory } from './DiscrepancyHistory';
import { detectDiscrepancy } from 'services/fileManager/fileManagerServices';
import { useToast } from 'context/ToastContext';
import { toastConstant } from 'constants/toastmessage';
import { useForm } from 'react-hook-form';
import { useUserDetailsContext } from 'context/userDetailsContext';

const DiscrepancyModal = () => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    setValue,
    reset,
  } = useForm();

  const { modals, fileManagerFilters, caseDetails, isGlobalFileManager, isCaseFileManager, isLeadFileManager, setBreadCrumbs } =
    useFileManagerContext();
  const userContext = useUserDetailsContext();
  const { addToast } = useToast();

  const { showDiscrepancyModal, setShowDiscrepancyModal } = modals;
  const [activeIndex, setActiveIndex] = useState(0);

  const onSubmit = (data) => {
    if (activeIndex === 0) {
      const sendData = {
        // task_name: data?.taskName,
        case_id: caseDetails?.case_id ? caseDetails?.case_id : data?.case?.id,
        case_name: caseDetails?.case_description?.case_name ? caseDetails?.case_description?.case_name : data?.case?.case_name,
        is_lead: caseDetails?.is_lead ? caseDetails?.is_lead : false,
        file_details: data?.selectedFiles?.reduce((acc, file) => {
          return { ...acc, [file?.display_name]: file?.file_path };
        }, {}),
        report_extension: data?.reportExtension,
        timezone: userContext?.userDetails?.timezone,
      };

      detectDiscrepancy(sendData)
        .then((response) => {
          setShowDiscrepancyModal(false);
          addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.DISCREPANCY_SUCCESS);
        })
        .catch((err) => {
          addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, toastConstant.message.DISCREPANCY_FAILED);
        });
    }
  };

  const onCancel = () => {
    reset();
    setShowDiscrepancyModal(false);
  };

  return (
    <Dialog
      className="new-task-popup"
      header={ModalHeader}
      visible={showDiscrepancyModal}
      style={{ width: '50vw' }}
      modal
      onHide={() => {
        setShowDiscrepancyModal(false);
      }}
      // closable={true}
      footer={<></>}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row mt-2">
          <div className="col-12 billing-wrap">
            <TabView
              className="curve-tab discrepancy-tab"
              activeIndex={activeIndex}
              onTabChange={(e) => setActiveIndex(e.index)}
              scrollable={true}
            >
              <TabPanel header="Identify Discrepancies">
                <IdentifyDiscrepancy
                  isGlobalFileManager={isGlobalFileManager}
                  isCaseFileManager={isCaseFileManager}
                  isLeadFileManager={isLeadFileManager}
                  caseDetails={caseDetails}
                  fileManagerFilters={fileManagerFilters}
                  setBreadCrumbs={setBreadCrumbs}
                  control={control}
                  watch={watch}
                  setValue={setValue}
                  errors={errors}
                  reset={reset}
                />
              </TabPanel>

              <TabPanel header="Task History">
                <DiscrepancyHistory
                  isCaseFileManager={isCaseFileManager}
                  isLeadFileManager={isLeadFileManager}
                  caseDetails={caseDetails}
                  setShowDiscrepancyModal={setShowDiscrepancyModal}
                />
              </TabPanel>
            </TabView>
          </div>
        </div>
        <div className="footer-btn">
          <Button
            className="p-button-secondary outline me-2"
            label="Cancel"
            onClick={() => {
              onCancel();
            }}
          />

          <Button className="p-button-secondary" label="Identify" type="submit" />
        </div>
      </form>
    </Dialog>
  );
};

export default DiscrepancyModal;
