import { performRequestThird } from 'common/apiHandler';
import { default as apiEndPoints } from 'common/apiEndPoints';
import { getTenantDetails } from 'utils/utils';

export const listAvailablePhoneNumbers = (region, area_code, limit, next) => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequestThird(
    apiEndPoints.methodType.GET,
    `${apiEndPoints.listAvailableFaxNumbers}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&region=${region}&area_code=${area_code}&limit=${limit}`
  );
};

export const listFaxAreaCodes = (incoming_phone_number, data = {}) => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequestThird(
    apiEndPoints.methodType.GET,
    `${apiEndPoints.listFaxAreaCodes}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&region=US`
    // data
  );
};

export const purchaseFaxNumber = (item_id, data = {}) => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequestThird(
    apiEndPoints.methodType.GET,
    `${apiEndPoints.purchaseFaxNumber}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&item_id=${item_id}`
    //   data
  );
};

export const sendFaxRequest = (data) => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequestThird(apiEndPoints.methodType.POST, `${apiEndPoints.sendFax}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`, data);
};

export const getOutgoingFaxNumbers = (params) => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequestThird(
    apiEndPoints.methodType.GET,
    `${apiEndPoints.listOutgoingFax}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&${params}`
    //   data
  );
};

export const getIncomingFaxNumbers = (params) => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequestThird(
    apiEndPoints.methodType.GET,
    `${apiEndPoints.IncomingFaxList}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&${params}`
    //   data
  );
};

export const assignFaxToCase = (data = {}) => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequestThird(
    apiEndPoints.methodType.POST,
    `${apiEndPoints.AssignFaxToCase}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`,
    data
  );
};

export const getFaxDetails = (params) => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequestThird(
    apiEndPoints.methodType.GET,
    `${apiEndPoints.getFaxDetails}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&${params}`
    //   data
  );
};
