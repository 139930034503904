import { useNavbarContext } from 'context/NavbarContext';
import { useUserDetailsContext } from 'context/userDetailsContext';
import { useWebSocketContext } from 'context/WebSocketConnectionContext';
import { useEffect, useState } from 'react';
import { viewEmailThreadDetails } from 'services/mailbox/mailboxService';

const useEmailDetails = (emailThreadPkSk, setReadEmails) => {
  const { pk: emailThreadPk, sk: emailThreadSk } = emailThreadPkSk || {};
  const [emailDetails, setEmailDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { userDetails } = useUserDetailsContext();

  const { caseEmails } = useWebSocketContext();
  const { notificationData } = useNavbarContext();
  const fetchEmailThreadDetails = async () => {
    setLoading(true);
    setError(null);
    viewEmailThreadDetails(emailThreadPk, emailThreadSk)
      .then((res) => {
        const emailThreads = res?.data?.email_thread_details;
        setEmailDetails(emailThreads.reverse());
      })
      .catch((err) => {
        setError(err);
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
        notificationData?.loadNotificationCount();
      });
  };

  useEffect(() => {
    if (!emailThreadPkSk.pk || !emailThreadPkSk.sk) return;
    fetchEmailThreadDetails();
  }, [emailThreadPkSk.pk, emailThreadPkSk.sk]);

  useEffect(() => {
    if (caseEmails) {
      if (caseEmails?.body?.pk === emailThreadPk) {
        const newEmailReply = {
          ...caseEmails?.body,
          is_unread: caseEmails?.body?.email_from?.user_email !== userDetails?.business_email,
        };
        setEmailDetails((prev) => [...prev, newEmailReply]);
        setReadEmails((prev) => ({
          ...prev,
          [newEmailReply.pk]: false, // Ensure it remains unread
        }));
      }
    }
  }, [caseEmails]);

  return { emailDetails, setEmailDetails, loading, error, fetchEmailThreadDetails };
};

export default useEmailDetails;
