import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import useQuickBooks from './hooks/useQuickBooks';
import SettingsLayout from '../SettingsLayout/SettingsLayout';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useUserDetailsContext } from 'context/userDetailsContext';
import GlobalLoader from 'components/GlobalLoader/GlobalLoader';
import { useToast } from 'context/ToastContext';
import { toastConstant } from 'constants/toastmessage';
import { accountMapping } from 'services/quickbookServices';
import { getOperatingAccountBalance, getTrustAccountBalance } from 'services/billingServices';
import { confirmDialog } from 'primereact/confirmdialog';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { SpinnerComponent } from 'shared/SpinnerComponent';

function QuickbooksAuthPage() {
  const { loadingURI, authdata, openQuickbooksWindow, listAccounts, getAccounts, showLoader } = useQuickBooks();
  const userContext = useUserDetailsContext();
  const { addToast } = useToast();
  const history = useHistory();

  const accountOptions = { label: '+ Add New Resource', value: 'add_new_resource' };

  const [rows, setRows] = useState([]);
  const [errors, setErrors] = useState({});
  const [showInfo, setShowInfo] = useState(true);

  useEffect(() => {
    if (userContext) {
      userContext?.loadUserDetails();
    }
  }, [authdata]);

  useEffect(() => {
    if (listAccounts?.account?.length || listAccounts?.qb_account?.length) {
      setRows([
        {
          selectedLawftAccount: null,
          selectedQbAccount: null,
          account: listAccounts?.account || [],
          qb_account: listAccounts?.qb_account || [],
        },
      ]);
    } else {
      setRows([
        {
          selectedLawftAccount: null,
          selectedQbAccount: null,
          account: [accountOptions],
          qb_account: [accountOptions],
        },
      ]);
    }
  }, [listAccounts]);

  const addNewRow = () => {
    setRows([
      ...rows,
      {
        selectedLawftAccount: null,
        selectedQbAccount: null,
        account: listAccounts?.account?.length ? listAccounts?.account : [],
        qb_account: listAccounts?.qb_account?.length ? listAccounts?.qb_account : [],
      },
    ]);
  };

  const handleLawftAccountChange = (e, rowIndex) => {
    const updatedRows = [...rows];
    updatedRows[rowIndex].selectedLawftAccount = e?.value;
    setRows(updatedRows);

    setErrors((prevErrors) => ({
      ...prevErrors,
      [`lawft-${rowIndex}`]: false,
    }));
  };

  const handleQbAccountChange = (e, rowIndex) => {
    const updatedRows = [...rows];
    updatedRows[rowIndex].selectedQbAccount = e?.value;
    setRows(updatedRows);

    // Clear validation error for this dropdown
    setErrors((prevErrors) => ({
      ...prevErrors,
      [`qb-${rowIndex}`]: false,
    }));
  };

  const validateRows = () => {
    const newErrors = {};

    rows.forEach((row, index) => {
      // Check if both dropdowns are selected
      if (!row?.selectedLawftAccount) {
        newErrors[`lawft-${index}`] = true;
      }
      if (!row?.selectedQbAccount) {
        newErrors[`qb-${index}`] = true;
      }

      // Add error for conflict between "Add New Resource" in both dropdowns
      if (row?.selectedLawftAccount === 'add_new_resource' && row?.selectedQbAccount === 'add_new_resource') {
        newErrors[`row-${index}`] = "Cannot select 'Add New Resource' in both Lawft and QuickBooks";
      }
    });

    setErrors(newErrors);
    return Object?.keys(newErrors)?.length === 0;
  };

  const getLawftAccountDetails = (item) => {
    const getAccountBalance =
      item?.selectedLawftAccount?.account_type === 'trust'
        ? getTrustAccountBalance(item?.selectedLawftAccount?.account_id)
        : getOperatingAccountBalance(item?.selectedLawftAccount?.account_id);

    return getAccountBalance
      .then((response) => {
        let available_balance = response?.data?.available_balance;
        return {
          ...item?.selectedLawftAccount,
          opening_balance: available_balance,
        };
      })
      .catch((err) => {
        console.log(err);
        return item?.selectedLawftAccount;
      });
  };

  const constructSendData = async (mappedData) => {
    const promises = mappedData.map(async (item) => {
      const lawftData = item?.selectedLawftAccount === 'add_new_resource' ? { create_resource: true } : await getLawftAccountDetails(item);
      return {
        qb_data: item?.selectedQbAccount === 'add_new_resource' ? { create_resource: true } : item?.selectedQbAccount,
        lawft_data: lawftData,
      };
    });
    return {
      map_data: await Promise.all(promises),
    };
  };

  const lawftQbaccountMapping = (mappedData) => {
    constructSendData(mappedData)
      .then((sendData) => {
        accountMapping(sendData)
          .then((response) => {
            addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.MAPPING_SUCCESS);
            getAccounts();
          })
          .catch((err) => {
            addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, toastConstant.message.MAPPING_FAILED);
          });
      })
      .catch((err) => {
        // Handle any errors in constructing sendData
        console.log('Error in constructing send data:', err);
      });
  };

  const handleMapSelected = () => {
    const isValid = validateRows();
    if (isValid) {
      const mappedData = rows?.map((row, index) => ({
        rowIndex: index,
        selectedLawftAccount: row?.selectedLawftAccount,
        selectedQbAccount: row?.selectedQbAccount,
      }));
      if (mappedData) {
        lawftQbaccountMapping(mappedData);
      }
    }
  };

  const getSelectedLawftAccounts = (currentRowIndex) => {
    const selectedAccounts = rows
      .filter((row, index) => index !== currentRowIndex)
      .map((row) => row?.selectedLawftAccount?.account_id)
      .filter(Boolean);
    return selectedAccounts;
  };

  const getSelectedQbAccounts = (currentRowIndex) => {
    const selectedAccounts = rows
      .filter((row, index) => index !== currentRowIndex)
      .map((row) => row?.selectedQbAccount?.Id)
      .filter(Boolean);
    return selectedAccounts;
  };

  const lawftBody = (rowData, { rowIndex }) => {
    const selectedLawftAccounts = getSelectedLawftAccounts(rowIndex);
    const lawftOptions = Array?.isArray(rowData?.account)
      ? rowData?.account
          .filter((acc) => !selectedLawftAccounts?.includes(acc?.account_id))
          .map((acc) => ({
            label: acc?.account_name,
            value: acc,
          }))
      : [];

    const finalLawftAccountOptions = [accountOptions, ...lawftOptions];
    return (
      <React.Fragment>
        <span className="p-column-title text-break">Subject</span>
        <Dropdown
          options={listAccounts ? finalLawftAccountOptions.filter((option) => option?.label && option?.value) : []}
          optionLabel="label"
          value={rowData?.selectedLawftAccount}
          onChange={(e) => handleLawftAccountChange(e, rowIndex)}
          placeholder="Select Lawft Account"
          style={{ width: '250px' }}
          itemTemplate={(option) => {
            if (option?.value === 'add_new_resource') {
              return <span className="text-break redirection-link-no-text-transform pointer text-muted">+ Add New Resource</span>;
            }
            return <span>{option?.label}</span>;
          }}
          valueTemplate={(option) => {
            if (!option?.value) {
              return <span>Select Lawft Account</span>;
            } else if (option?.value === 'add_new_resource') {
              return <span>Add New Resource</span>;
            } else return <span>{option?.label}</span>;
          }}
          filter
        />
      </React.Fragment>
    );
  };

  const quickbooksBody = (rowData, { rowIndex }) => {
    const selectedQbAccounts = getSelectedQbAccounts(rowIndex);
    const qbOptions = Array.isArray(rowData?.qb_account)
      ? rowData.qb_account
          .filter((qb) => !selectedQbAccounts?.includes(qb.Id))
          .map((qb) => ({
            label: qb.Name,
            value: qb,
          }))
      : [];

    const finalQbAccountOptions = [accountOptions, ...qbOptions];
    return (
      <React.Fragment>
        <span className="p-column-title text-break">QuickBooks</span>
        <Dropdown
          options={listAccounts ? finalQbAccountOptions.filter((option) => option?.label && option?.value) : []}
          optionLabel="label"
          style={{ width: '250px' }}
          value={rowData.selectedQbAccount || null}
          onChange={(e) => handleQbAccountChange(e, rowIndex)}
          placeholder="Select QuickBooks Account"
          itemTemplate={(option) => {
            if (option?.value === 'add_new_resource') {
              return <span className="text-break redirection-link-no-text-transform pointer text-muted">+ Add New Resource</span>;
            }
            return <span>{option?.label}</span>;
          }}
          valueTemplate={(option) => {
            if (!option?.value) {
              return <span>Select QuickBooks Account</span>;
            } else if (option?.value === 'add_new_resource') {
              return <span>Add New Resource</span>;
            } else return <span>{option?.label}</span>;
          }}
          filter
        />
      </React.Fragment>
    );
  };

  const deleteRow = (rowData) => {
    // if (rows.length > 1) {
    //   setRows(rows.filter((row) => row !== rowData));
    // }
    confirmDialog({
      message: 'Do you want to delete this row?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => setRows(rows.filter((row) => row !== rowData)), // Filter out the specific row
      reject: () => null,
      closable: false,
    });
  };

  const actionBody = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title text-break">Actions</span>
        <i
          // className={'icon-delete ms-2 cursor-pointer ms-4 ' + (rows?.length === 1 ? 'pe-none opacity-50' : '')}
          className={'icon-delete ms-2 cursor-pointer ms-4'}
          onClick={() => {
            deleteRow(rowData); // Prevent deletion if only one row remains
          }}
        ></i>
      </React.Fragment>
    );
  };

  const isFormValid = rows?.every(
    (row) =>
      row.selectedLawftAccount &&
      row.selectedQbAccount &&
      !(row?.selectedLawftAccount === 'add_new_resource' && row?.selectedQbAccount === 'add_new_resource')
  );

  return (
    <>
      <GlobalLoader />
      {showLoader && <SpinnerComponent />}
      {userContext?.userDetails && (
        <div className="row">
          <div className="col-lg-12">
            <div className="shadow-middle p-3 mb-3">
              <h1 className="title gray-800 mb-0">QuickBooks</h1>
              <br />
              <Button
                disabled={!authdata?.is_qb_connected && !authdata?.auth_url}
                label={authdata?.is_qb_connected ? 'Disconnect QuickBooks' : 'Connect to QuickBooks'}
                className="p-button p-button-primary mb-3"
                loading={loadingURI}
                onClick={openQuickbooksWindow}
              />
            </div>

            {userContext?.userDetails?.is_quickbook_connected === true && (
              <div>
                {showInfo &&
                  (listAccounts === undefined || (listAccounts?.account?.length === 0 && listAccounts?.qb_account?.length === 0)) && (
                    <div className="d-flex flex-wrap mt-2 align-items-center files-note mb-2">
                      <i className="pi pi-info-circle me-1"></i>
                      Create Account from&nbsp;
                      <span
                        className="text-break redirection-link-no-text-transform pointer text-muted"
                        onClick={() => {
                          history.push('/settings/accounts');
                        }}
                      >
                        here
                      </span>
                      <i className="pi pi-times cursor-pointer ms-3 me-1" onClick={() => setShowInfo(false)}></i>
                    </div>
                  )}
                <div className="d-flex justify-content-between mb-2">
                  <h1 className="title gray-800 mb-0">QuickBooks Mapping</h1>
                  <Button
                    label="Map Selected"
                    type="button"
                    className="btn-outline p-button-primary ms-2 mb-2"
                    onClick={handleMapSelected}
                    disabled={!isFormValid || rows?.length === 0} // Disable button if form is invalid
                  />
                </div>

                <div className="row">
                  <div className="col-lg-12">
                    <div className="shadow-middle p-3 bg-white">
                      <div className="d-flex justify-content-between align-items-center mb-2">
                        <h5 className="caption-bold mb-0">Mapping</h5>
                        <Button className="p-button p-button-secondary p-button-sm ml-2" icon="pi pi-plus" onClick={addNewRow} />
                      </div>
                      <div className="col-12 datatable-responsive">
                        <DataTable value={rows} className="p-datatable-responsive" emptyMessage="No data available. Please add a row.">
                          <Column header="Lawft" body={(rowData, rowIndex) => lawftBody(rowData, rowIndex)} />
                          <Column header="QuickBooks" body={(rowData, rowIndex) => quickbooksBody(rowData, rowIndex)} />
                          <Column header="Action" body={actionBody} />
                        </DataTable>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}

const QuickbooksAuth = () => {
  return (
    <SettingsLayout>
      <QuickbooksAuthPage />
    </SettingsLayout>
  );
};

export default QuickbooksAuth;
