import { useState } from 'react';

import { listGroupedFiles } from 'services/fileManager/fileManagerServices';

export default function useFetchFiles() {
  const [files, setFiles] = useState([]);

  const handleSetFiles = (updateFn) => {
    setFiles((prevFiles) => {
      const updatedFiles = typeof updateFn === 'function' ? updateFn(prevFiles) : updateFn;
      return { ...updatedFiles };
    });
  };

  const getFiles = (obj) => {
    listGroupedFiles(obj)
      .then(async (response) => {
        let arr = response.data;
        setFiles(arr);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return {
    files,
    getFiles,
    handleSetFiles,
  };
}
