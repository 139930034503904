import React from 'react';
import { Tooltip } from 'primereact/tooltip';
import { useFileManagerContext } from 'modules/file_manager/context/FileManagerContext';

function Discrepancy() {
  const { modals, isClosed } = useFileManagerContext();

  return (
    <span className={'discrepancy-button discrepancy me-2 pointer' + (isClosed ? ' opacity-25 pe-none' : '')}>
      <Tooltip content="Identify Discrepancies" position="bottom" target=".discrepancy" style={{ bottom: 0 }} showDelay={500} />
      <i className="bi bi-magic" onClick={() => modals.setShowDiscrepancyModal(true)}></i>
    </span>
  );
}

export default Discrepancy;
