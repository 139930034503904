import React, { useState } from 'react';
import NumberFormat from 'react-number-format';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { AutoComplete } from 'primereact/autocomplete';

import { getFormattedCountryList, getFormattedStateList } from '../../helpers/createInvoiceHelpers';
import TextSnippetWrapper from 'components/TextSnippetWrapper';
import { Tooltip } from 'primereact/tooltip';
import { InvoiceAddContactFormModal } from './facets/InvoiceAddContactFormModal';
import { EnableLawftAccessModal } from './EnableLawftAccessModal';

const InvoiceForForm = (props) => {
  const {
    isLeadSpecific,
    caseDetails,
    caseView,
    selectedInvoice,
    caseList,
    selectedCase,
    billingContacts,
    selectedBillingContact,
    contactDetails,
    onChangeCase,
    onChangeContact,
    setContactDetails,
    formErrors,
    setFormErrors,
    searchValue,
    setSearchValue,
    load,
    setLoad,
    showAddContactModal,
    setShowAddContactModal,
    setBillingContacts,
    showEnableLawftAccessModal,
    setShowEnableLawftAccessModal,
    enableLawftAccessBillingContact,
    caseDetailsState,
  } = props || {};

  const statesList = getFormattedStateList();
  const countriesList = getFormattedCountryList();

  const searchCase = (e) => {
    let query = e?.query;
    setSearchValue(query);
  };

  const assignToItemTemplate = (option) => {
    if (option?.is_create_client_portal === false || !Boolean(option?.email)) {
      return (
        <div className="d-flex justify-content-between">
          <span className="name">{option?.name}</span>
          <span className="text-nowrap">(No Lawft Access)</span>
        </div>
      );
    }
    return option?.name;
  };

  return (
    <>
      <div className="col-12 col-md-6 ">
        <div className="row">
          <div className="col-12 caption-bold">INVOICE FOR</div>
        </div>
        <div className="shadow-small p-3">
          <div className="d-flex align-items-center py-2 flex-wrap">
            <div className="col-12 col-md-6 p-medium">
              {!props?.caseView && !props?.isLeadSpecific ? 'Case/Lead' : 'Case'}
              <span className="text-danger">*</span>
            </div>
            <div className="col-12 col-md-6">
              {isLeadSpecific ? (
                <InputText
                  className="input-shadow w-100"
                  placeholder={!props?.caseView && !props?.isLeadSpecific ? 'Case/Lead Name' : 'Case Name'}
                  value={caseDetails?.case_description?.case_name}
                  disabled={caseView}
                />
              ) : (
                <>
                  <AutoComplete
                    value={selectedCase?.case_description?.case_name ? selectedCase?.case_description?.case_name : selectedCase}
                    suggestions={caseList}
                    completeMethod={searchCase}
                    field="label"
                    onChange={(e) => {
                      onChangeCase(e);
                      setFormErrors({ ...formErrors, case: '' });
                    }}
                    placeholder={!props?.caseView && !props?.isLeadSpecific ? 'Select or Search a Case/Lead' : 'Select or Search a Case'}
                    className="input-shadow w-100 border-0"
                    disabled={selectedInvoice || caseView}
                  />
                  {formErrors?.case && <span className="text-danger">Required</span>}
                </>
              )}
            </div>
          </div>

          {/* TODO:"Please select a case to list contacts"  Validation needs to be added*/}
          <div className="d-flex align-items-center py-2 flex-wrap">
            <div className="col-12 col-md-6 p-medium">
              Contact
              <span className="text-danger">*</span>
            </div>
            <div className="col-12 col-md-6 d-flex flex-column">
              {/* Changed to flex-column to stack elements */}
              <div className="d-flex align-items-center">
                {/* Wrapper for dropdown and icon */}
                <Dropdown
                  placeholder="Please select case contacts"
                  options={billingContacts}
                  optionLabel={'name'}
                  value={selectedBillingContact}
                  onChange={onChangeContact}
                  className="input-shadow w-100 border-0"
                  itemTemplate={assignToItemTemplate}
                  filter
                />
                <div
                  className={`px-2 d-flex align-items-center ms-2 ${
                    selectedCase && typeof selectedCase === 'object' ? '' : 'disabled-icon'
                  }`}
                  style={{
                    pointerEvents: selectedCase && typeof selectedCase === 'object' ? 'auto' : 'none', // Disable clicks if no case is selected
                    opacity: selectedCase && typeof selectedCase === 'object' ? 1 : 0.5, // Make button semi-transparent when disabled
                  }}
                >
                  <span
                    className="pointer"
                    onClick={() => selectedCase && typeof selectedCase === 'object' && setShowAddContactModal(true)}
                  >
                    <i className="fas fa-plus-circle add-contact"></i>
                    <Tooltip content="Add Contact" position="top" target=".add-contact" showDelay={500} />
                  </span>
                </div>
              </div>
              {formErrors?.contact && <span className="text-danger">Required</span>} {/* Moved below the dropdown */}
            </div>
          </div>

          <div className="d-flex align-items-center py-2 flex-wrap">
            <div className="col-12 col-md-6 p-medium">Street</div>
            <div className="col-12 col-md-6">
              <TextSnippetWrapper
                type="text"
                value={contactDetails?.street ? contactDetails?.street : ''}
                onChange={(e) => {
                  setContactDetails({ ...contactDetails, street: e.target.value });
                }}
                className="input-shadow w-100 border-0 p-2"
                placeholder="Please type in"
              />
            </div>
          </div>
          <div className="d-flex align-items-center py-2 flex-wrap">
            <div className="col-12 col-md-6 p-medium">City</div>
            <div className="col-12 col-md-6">
              <TextSnippetWrapper
                type="text"
                value={contactDetails?.city ? contactDetails?.city : ''}
                onChange={(e) => {
                  setContactDetails({ ...contactDetails, city: e.target.value });
                }}
                className="input-shadow w-100 border-0 p-2"
                placeholder="Please select a city"
              />
            </div>
          </div>
          <div className="d-flex align-items-center py-2 flex-wrap">
            <div className="col-12 col-md-6 p-medium">State</div>
            <div className="col-12 col-md-6">
              {contactDetails?.country === 'United States' ? (
                <Dropdown
                  options={statesList}
                  value={contactDetails?.state || ''}
                  onChange={(e) => {
                    setContactDetails({ ...contactDetails, state: e.target.value });
                  }}
                  className="input-shadow w-100 border-0"
                  placeholder="State"
                  filter
                />
              ) : (
                <TextSnippetWrapper
                  type="text"
                  value={contactDetails?.state ? contactDetails?.state : ''}
                  onChange={(e) => {
                    contactDetails.state = e.target.value;
                    setContactDetails({ ...contactDetails });
                  }}
                  className="input-shadow w-100 border-0 p-2"
                  placeholder="Please type in"
                />
              )}
            </div>
          </div>

          <div className="d-flex align-items-center py-2 flex-wrap">
            <div className="col-12 col-md-6 p-medium">Country</div>
            <div className="col-12 col-md-6">
              <Dropdown
                options={countriesList}
                value={contactDetails?.country || ''}
                onChange={(e) => {
                  setContactDetails({ ...contactDetails, country: e.target.value });
                }}
                className="input-shadow w-100 border-0"
                placeholder="Please select a country"
                filter
              />
            </div>
          </div>

          <div className="d-flex align-items-center py-2 flex-wrap">
            <div className="col-12 col-md-6 p-medium">Phone Number</div>
            <div className="col-12 col-md-6">
              <NumberFormat
                className="input-shadow w-100 p-2 border-0 number-format"
                placeholder="Please type in"
                format="(###) ###-####"
                mask="_"
                onValueChange={(e) => {
                  setContactDetails((pre) => ({
                    ...pre,
                    phone: e.value,
                  }));
                }}
                value={contactDetails?.phone || ''}
              />
            </div>
          </div>
          <div className="d-flex align-items-center py-2 flex-wrap">
            <div className="col-12 col-md-6 p-medium">Zip Code</div>
            <div className="col-12 col-md-6">
              <InputText
                value={contactDetails?.zip || ''}
                onChange={(e) => {
                  setContactDetails({ ...contactDetails, zip: e.target.value });
                }}
                className="input-shadow w-100 border-0 p-2"
                placeholder="Please type in"
              />
            </div>
          </div>
        </div>
      </div>
      {showAddContactModal && (
        <InvoiceAddContactFormModal
          visible={showAddContactModal}
          onHide={() => setShowAddContactModal(false)} // Close modal when hiding
          selectedCase={selectedCase}
          isLeadSpecific={isLeadSpecific}
          setBillingContacts={setBillingContacts}
        />
      )}
      {showEnableLawftAccessModal && (
        <EnableLawftAccessModal
          visible={showEnableLawftAccessModal}
          onHide={() => setShowEnableLawftAccessModal(false)} // Close modal when hiding
          selectedCase={selectedCase}
          isLeadSpecific={isLeadSpecific}
          setBillingContacts={setBillingContacts}
          enableLawftAccessBillingContact={enableLawftAccessBillingContact}
          caseDetailsState={caseDetailsState}
        />
      )}
    </>
  );
};

export default InvoiceForForm;
