import React, { useState, useEffect, useCallback, useRef } from 'react';
import debounce from 'lodash.debounce';
import { globalSearchEvent } from 'services/generalServices';
import { groupBy, openLinkInBlank, removeHtmlTags, removePhoneNumberFormatting } from 'utils/utils';
import { useHistory } from 'react-router-dom';
import { Tooltip } from 'primereact/tooltip';
import { contactType } from 'common/dropdownOptions';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import TextSnippetWrapper from 'components/TextSnippetWrapper';
import { useToggle } from 'hooks/useToggle';
import { getFileExtension, getFileType } from 'utils/filesUtils';
import axios from 'axios';

export default function GlobalSearch() {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef(null);
  const showContentRef = useRef(null);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [showContent, toggleContent] = useToggle(false);
  const cancelTokenSource = useRef(null);

  function findType(item) {
    return getFileType(getFileExtension(item?.file_name));
  }

  const fetchData = async (query, cb) => {
    if (cancelTokenSource.current) {
      cancelTokenSource.current.cancel();
    }
    cancelTokenSource.current = axios.CancelToken.source();

    setLoading(true);
    try {
      const res = await globalSearchEvent(query, showContentRef.current, false, true, {
        cancelToken: cancelTokenSource.current.token,
      });
      if (res.data && !showContentRef.current) {
        setResults(
          groupBy(
            res?.data?.map((v) => v?._source),
            'res_type'
          )
        );
      } else if (res.data && showContentRef.current) {
        const data = res.data.documents?.buckets?.map((v) => ({
          ...(v?.top_hit?.hits?.hits?.[0]?._source || {}),
          file_path: v?.top_hit?.hits?.hits?.[0]?._source?.object_key,
          object_id: v?.top_hit?.hits?.hits?.[0]?._source?.document_id,
          type: findType(v?.top_hit?.hits?.hits?.[0]?._source),
        }));
        setResults(data);
      }
      setLoading(false);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Previous request canceled:', error.message);
      } else {
        console.error(error);
        setLoading(false);
      }
    }
  };

  const delayedSearch = useCallback(
    debounce((query) => {
      if (query !== '') {
        fetchData(removePhoneNumberFormatting(query));
      } else {
        if (cancelTokenSource.current) {
          cancelTokenSource.current.cancel();
          cancelTokenSource.current = null;
        }
        setResults({});
        setLoading(false);
      }
    }, 500),
    []
  );

  useEffect(() => {
    setLoading(true);
    setResults({});
    delayedSearch(query);
  }, [query]); //eslint-disable-line

  useEffect(() => {
    showContentRef.current = showContent;
  }, [showContent]); //eslint-disable-line

  const redirectToPage = (pathname, state) => {
    if (!openLinkInBlank(pathname, state)) {
      history?.replace({
        pathname,
        state,
      });
    }
  };

  const goToDetails = (data) => {
    if (data?.res_type === 'case') {
      if (data?.is_lead) {
        redirectToPage(`/leads/${data?.case_id}`);
      } else {
        redirectToPage(`/cases/${data?.case_id}`);
      }
    } else if (data?.res_type === 'message') {
      redirectToPage(`messages/${data?.message_thread_id}`);
    } else if (data?.res_type === 'message-reply') {
      redirectToPage(`messages/${data?.pk?.split('#')?.[1]}`);
    } else if (data?.res_type === 'text_message_thread' || data?.res_type === 'text_message_reply') {
      redirectToPage(`/text-messages`, { text_message: data });
    } else if (data?.res_type === 'file_system_entry' || showContentRef.current) {
      localStorage.setItem('file_sk', data?.sk);
      localStorage.setItem('file_pk', data?.pk);
      localStorage.setItem('viewFile', JSON.stringify(data));
      if (data?.case_id && data?.case_id !== 'undefined') {
        redirectToPage(`/${data?.is_lead ? 'leads' : 'cases'}/${data?.case_id}/files`, { file: data });
      } else {
        redirectToPage(`/file-manager`, { file: data });
      }
    } else if (data?.res_type === 'event') {
      redirectToPage(`/calendar`, data);
    } else if (data?.res_type === 'user') {
      localStorage.setItem('sk', data.sk);
      localStorage.setItem('pk', data.pk);
      if (data?.access_level && data?.access_level !== 'client') {
        redirectToPage(`/settings/team-management/${data?.user_id}`);
      } else if (contactType.includes(data?.contact_type || !data?.access_level || data?.access_level === 'client')) {
        redirectToPage(`/contacts/${data?.contact_id}`);
      }
    } else if (data?.res_type === 'task') {
      redirectToPage(`/tasks`, { viewTask: data });
    } else if (data?.res_type === 'invoice') {
      localStorage.setItem('viewInvoice', true);
      redirectToPage(`/cases/${data?.case_id ? data?.case_id : data?.invoice?.case_id}/billing`, data);
    } else if (data?.res_type === 'payment_request') {
      localStorage.setItem('viewPaymentRequest', true);
      redirectToPage(`/cases/${data?.case_id}/billing`, data);
    } else if (data?.res_type === 'case-note') {
      redirectToPage(`/cases/${data?.case_id}/notes`, { case_note: data });
    }
    close();
  };

  //close search when clicked outside
  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        close();
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  const close = () => {
    setLoading(false);
    setResults({});
    setIsOpen(false);
    setQuery('');
  };
  const onSearchKeyDown = (e) => {
    let code = e.keyCode ? e.keyCode : e.which;
    if (code === 13) {
      delayedSearch(query);
    }
  };

  const loader = () => {
    return (
      <div className="overlay searchbutton">
        <i className="pi pi-spin pi-spinner" style={{ fontSize: '1em' }}></i>
      </div>
    );
  };

  return (
    <div className="search-container" ref={wrapperRef}>
      <span className="p-input-icon-left input-shadow autowidth">
        <TextSnippetWrapper
          type="text"
          className={isOpen ? 'open search expandright input-style' : 'search expandright input-style'}
          id="searchright"
          placeholder="Search"
          value={query}
          onChange={(e) => {
            setQuery(e.target.value || '');
          }}
          onFocus={() => setIsOpen(true)}
          onKeyDown={onSearchKeyDown}
        />
      </span>
      {isOpen && (
        <>
          <i
            className={`icon-files fa-lg cursor-pointer ${showContent ? 'highlight' : ''}`}
            onClick={() => {
              if (isOpen && query) {
                setLoading(true);
              }
              setResults({});
              toggleContent();
              delayedSearch(query);
            }}
          ></i>
          <Tooltip content="Search Content Data" target=".icon-files" position="bottom" showDelay={500} />
        </>
      )}
      {loading ? (
        loader()
      ) : (
        <label
          className="button searchbutton"
          htmlFor="searchright"
          onClick={() => {
            setIsOpen(true);
            if (isOpen && query) {
              delayedSearch(query);
            }
          }}
        >
          <i className="icon-search fa-lg cursor-pointer"></i>
          <Tooltip content="Search" target=".icon-search" position="bottom" showDelay={500} />
        </label>
      )}
      {Object.keys(results || {})?.length > 0 && (
        <div className={isOpen ? 'result open' : 'result'}>
          {!showContentRef.current ? (
            <div className="result-list">
              {Boolean(
                results?.user?.filter(
                  (v) => (v.access_level && v.access_level === 'client') || !v.access_level || contactType.includes(v?.contact_type)
                ).length > 0
              ) && (
                <div className="list">
                  <h1 className="title">Contacts</h1>
                  {results?.user
                    ?.filter(
                      (v) => (v.access_level && v.access_level === 'client') || !v.access_level || contactType.includes(v?.contact_type)
                    )
                    .map((v, i) => (
                      <li key={i} onClick={() => goToDetails(v)}>
                        <h1 className="sub-title text-primary-dark">
                          {v.contact_type === 'person' ? `${v?.first_name} ${v?.last_name}` : v?.title}
                        </h1>
                      </li>
                    ))}
                </div>
              )}

              {Boolean(results?.case?.filter((v) => !Boolean(v?.is_lead)).length > 0) && (
                <div className="list">
                  <h1 className="title">Cases</h1>
                  {results?.case
                    ?.filter((v) => !Boolean(v?.is_lead))
                    .map((v, i) => (
                      <li key={i} onClick={() => goToDetails(v)}>
                        <h1 className="sub-title text-primary-dark">{v?.case_description?.case_name}</h1>
                      </li>
                    ))}
                </div>
              )}

              {Boolean(results?.case?.filter((v) => v?.is_lead)?.length > 0) && (
                <div className="list">
                  <h1 className="title">Leads</h1>
                  {results?.case
                    ?.filter((v) => v?.is_lead)
                    .map((v, i) => (
                      <li key={i} onClick={() => goToDetails(v)}>
                        <h1 className="sub-title text-primary-dark">{v?.case_description?.case_name}</h1>
                      </li>
                    ))}
                </div>
              )}

              {Boolean(results?.file_system_entry?.length > 0) && (
                <div className="list">
                  <h1 className="title">Files</h1>
                  {results?.file_system_entry?.map((v, i) => (
                    <li key={i} onClick={() => goToDetails(v)}>
                      <h1 className="sub-title text-primary-dark">{v?.display_name}</h1>
                    </li>
                  ))}
                </div>
              )}

              {Boolean(results?.user?.filter((v) => v.access_level && v.access_level !== 'client').length > 0) && (
                <div className="list">
                  <h1 className="title">Firm Users</h1>
                  {results?.user
                    ?.filter((v) => v.access_level && v.access_level !== 'client')
                    .map((v, i) => (
                      <li key={i} onClick={() => goToDetails(v)}>
                        <h1 className="sub-title text-primary-dark">
                          {v?.first_name} {v?.last_name}
                        </h1>
                      </li>
                    ))}
                </div>
              )}

              {Boolean(results?.event?.length > 0) && (
                <div className="list">
                  <h1 className="title">Events</h1>
                  {results?.event?.map((v, i) => (
                    <li key={i} onClick={() => goToDetails(v)}>
                      <h1 className="sub-title text-primary-dark">
                        {v?.case_name && v?.case_name !== '-' ? v?.case_name + ': ' : ''} {v?.title}
                      </h1>
                    </li>
                  ))}
                </div>
              )}

              {(Boolean(results?.message?.length > 0) || Boolean(results?.['message-reply']?.length > 0)) && (
                <div className="list">
                  <h1 className="title">Messages</h1>
                  {results?.message?.map((v, i) => (
                    <li key={i} onClick={() => goToDetails(v)}>
                      <h1 className="sub-title text-primary-dark">{v?.subject}</h1>
                    </li>
                  ))}
                  {results?.['message-reply']?.map((v, i) => (
                    <li key={i} onClick={() => goToDetails(v)}>
                      <h1 className="sub-title text-primary-dark">{removeHtmlTags(v?.message)}</h1>
                    </li>
                  ))}
                </div>
              )}
              {(Boolean(results?.text_message_thread?.length > 0) || Boolean(results?.['text_message_reply']?.length > 0)) && (
                <div className="list">
                  <h1 className="title">Text Messages</h1>
                  {results?.text_message_thread?.map((v, i) => (
                    <li key={i} onClick={() => goToDetails(v)}>
                      <h1 className="sub-title text-primary-dark">{v?.body ? v?.body : v?.title}</h1>
                    </li>
                  ))}
                  {results?.text_message_reply?.map((v, i) => (
                    <li key={i} onClick={() => goToDetails(v)}>
                      <h1 className="sub-title text-primary-dark">{v?.body ? v?.body : v?.title}</h1>
                    </li>
                  ))}
                </div>
              )}

              {Boolean(results?.task?.length > 0) && (
                <div className="list">
                  <h1 className="title">Task</h1>
                  {results?.task?.map((v, i) => (
                    <li key={i} onClick={() => goToDetails(v)}>
                      <h1 className="sub-title text-primary-dark ">
                        {v?.case_name ? v?.case_name + ': ' : ''} {v?.task_name}
                      </h1>
                    </li>
                  ))}
                </div>
              )}
              {Boolean(results?.payment_request?.length > 0) && (
                <div className="list">
                  <h1 className="title">Payment Request</h1>
                  {results?.payment_request?.map((v, i) => (
                    <li key={i} onClick={() => goToDetails(v)}>
                      <h1 className="sub-title text-primary-dark">
                        {v?.case_name ? v?.case_name + ': ' : ''} {v?.payment_request_number}
                      </h1>
                    </li>
                  ))}
                </div>
              )}
              {Boolean(results?.invoice?.length > 0) && (
                <div className="list">
                  <h1 className="title">Invoice</h1>
                  {results?.invoice?.map((v, i) => (
                    <li key={i} onClick={() => goToDetails(v)}>
                      <h1 className="sub-title text-primary-dark">
                        {v?.invoice?.case_name ? v?.invoice?.case_name + ': ' : ''} {v?.invoice_number}
                      </h1>
                    </li>
                  ))}
                </div>
              )}
              {Boolean(results?.['case-note']?.length > 0) && (
                <div className="list">
                  <h1 className="title">Case Note</h1>
                  {results?.['case-note']?.map((v, i) => (
                    <li key={i} onClick={() => goToDetails(v)}>
                      <h1 className="sub-title text-primary-dark">{v?.title && v?.title}</h1>
                    </li>
                  ))}
                </div>
              )}
            </div>
          ) : (
            <div className="result-list">
              {Boolean(results?.length > 0) && (
                <div className="list">
                  <h1 className="title">Files</h1>
                  {results?.map((v, i) => (
                    <li key={i} onClick={() => goToDetails(v)}>
                      <h1 className="sub-title text-primary-dark">{decodeURIComponent(v?.file_name)}</h1>
                    </li>
                  ))}
                </div>
              )}
            </div>
          )}
          <hr className="my-1" />
          <Link
            to={`/search?search=${query}${showContentRef.current ? `&showContent=true` : ''}`}
            className="redirection-link d-flex justify-content-center"
            onClick={() => setIsOpen(false)}
          >
            View All
          </Link>
        </div>
      )}
      {Object.keys(results || {})?.length === 0 && query?.length > 0 && loading === false && (
        <div className={isOpen ? 'result open' : 'result'}>
          <div className="list p-0 m-0">
            <h1 className="sub-title m-0">No Data Found</h1>
          </div>
        </div>
      )}
    </div>
  );
}
