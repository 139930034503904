/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { AutoComplete } from 'primereact/autocomplete';
import { useDispatch } from 'react-redux';
import debounce from 'lodash.debounce';

import { useToast } from 'context/ToastContext';
import { createTimeEntry, listActivityTypes, updateTimeEntry, getUserHourRate } from 'services/timerServices';
import { nofifyTimeManager } from 'redux/actions/TimeEntryUpdateActions';
import { toastConstant } from 'constants/toastmessage';
import { totalCountEvent } from 'services/generalServices';
import { getCurrentDateInTimezone, convertToTimezoneWithStartOfDayFormat } from 'utils/utility_functions/timezone';
import { UserDetailsContext } from 'context/userDetailsContext';
import { listCasesAndLeads } from 'services/CaseServices';
import TextSnippetWrapper from 'components/TextSnippetWrapper';
import CaseListDropDown from 'components/CaseListDropDown/CaseListDropDown';
import { Calendar } from 'primereact/calendar';

// import { useCaseAndLeadsList } from 'hooks/useCaseAndLeadList';

export const CreateTimer = (props) => {
  const [selectedCase, setSelectedCase] = useState();
  const [description, setDescription] = useState();
  const [rate, setRate] = useState('');
  const [markAsNonBillable, setMarkAsNonBillable] = useState(false);
  const dispatch = useDispatch();
  const [caseList, setCaseList] = useState([]);
  const [activityTypes, setActivityTypes] = useState();
  const [selectedActivityType, setSelectedActivityType] = useState();
  const isFromTimeManger = props?.isFromTimeManger ?? false;
  const [duration, setDuration] = useState('');
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState();
  const [load, setLoad] = useState(false);
  let [formError, setFormError] = useState({
    case: '',
    duration: '',
    rate: '',
    timeEntryDate: '',
  });
  const [timeEntryDate, setTimeEntryDate] = useState(new Date());

  const { addToast } = useToast();

  const userContext = useContext(UserDetailsContext);

  // const { caseAndLeadsList } = useCaseAndLeadsList();

  useEffect(() => {
    loadActivityType();
    setHoursRate();
  }, []);

  // useEffect(() => {
  //   if (caseAndLeadsList?.case_list && caseAndLeadsList?.case_list?.length) {
  //     let data = caseAndLeadsList?.case_list?.map((val) => {
  //       val.new_case_name =
  //         val && val.case_description && val.case_description.case_name
  //           ? `${val.unique_number ? `${val.unique_number} ` : ''}${val.case_description.case_name}`
  //           : 'TBD';
  //       return val;
  //     });
  //     setCaseList(data);
  //   }
  // }, [caseAndLeadsList?.case_list]);

  function getAllCaseList(is_lead = false, case_id = '', exclude_pi_case = false, keyword = '', limit = 15, is_filter_dropdown = true) {
    listCasesAndLeads(is_lead, case_id, exclude_pi_case, keyword, limit, is_filter_dropdown)
      .then((res) => {
        let tempArray = [];
        res?.data?.case_list?.forEach((item) => {
          tempArray.push({
            label:
              item && item.case_description && item.case_description.case_name
                ? `${item.unique_number ? `${item.unique_number} ` : ''}${item.case_description.case_name}`
                : 'TBD',
            value: item.case_description.case_name,
            id: item?.case_id,
          });
        });
        setCaseList(tempArray);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const setHoursRate = () => {
    getUserHourRate().then((response) => {
      if (response?.data?.rate?.rate) {
        setRate(response?.data?.rate?.rate);
      }
    });
  };

  useEffect(() => {
    if (props.selectedTimer) {
      setDuration(props.selectedTimer?.total_hours);
      setMarkAsNonBillable(!props.selectedTimer.billable);
      setDescription(props.selectedTimer.notes);
      setTimeEntryDate(new Date(props?.selectedTimer?.time_entry_date));
      if (props.selectedTimer.hour_rate) {
        setRate(props.selectedTimer.hour_rate);
      } else {
        setHoursRate();
      }
    }
  }, [props.selectedTimer, props.caseView]);

  useEffect(() => {
    if (props.selectedTimer) {
      let case_id = props.selectedTimer.case_id;
      let case_label = props?.selectedTimer?.case_name;
      let activity_type_id = props.selectedTimer.activity_type_id;

      // if (caseList && caseList.length > 0) {
      //   let selectedCase = caseList.filter((index) => index.case_id === case_id);
      //   setSelectedCase({ ...selectedCase[0] });
      // }
      setSelectedCase({ id: case_id, label: case_label, ...props?.selectedTimer });
      if (activityTypes && activityTypes.length > 0) {
        let selectedActivity = activityTypes.filter((index) => index.activity_type_id === activity_type_id);
        setSelectedActivityType({ ...selectedActivity[0] });
      }
    }
  }, [activityTypes]);

  useEffect(() => {
    if (props.caseView) {
      let case_id = props?.caseDetails?.case_id;
      let case_label = props?.caseDetails?.case_description?.case_name;
      // if (caseList && caseList.length > 0) {
      //   let selectedCase = caseList.filter((index) => index.case_id === case_id);
      //   setSelectedCase({ ...selectedCase[0] });
      // }
      setSelectedCase({ id: case_id, label: case_label, ...props?.caseDetails });
    }
  }, [props.caseView]);

  const fetchData = useCallback((query) => {
    getAllCaseList(false, '', false, query, 15, true);
  }, []);

  const delayedSearch = useCallback(
    debounce((query) => {
      fetchData(query);
    }, 500),
    [fetchData]
  );

  useEffect(() => {
    if (searchValue !== '') {
      delayedSearch(searchValue);
    } else if (searchValue === '' && load) {
      getAllCaseList(false, '', false, searchValue, 15, true);
    }
  }, [searchValue, delayedSearch, load]);

  const searchCase = (e) => {
    let query = e?.query;
    setSearchValue(query);
  };

  const onChangeCase = (e) => {
    // setSelectedCase(e?.value);
    setSelectedCase(e);
    formError.case = '';
    setFormError({ ...formError });
  };

  // console.log(selectedCase, "SC")

  // const selectedCaseTemplate = (option, props) => {
  //   if (option) {
  //     return <div>{option?.case_name}</div>;
  //   }
  //   return <span>{props.placeholder}</span>;
  // };

  const submitTimeEntry = () => {
    if (validateForm()) {
      setLoading(true);
      let data = {
        start_time: getCurrentDateInTimezone(userContext?.userDetails?.timezone),
        // case_id: props.isLeadSpecific ? props?.caseDetails?.case_id : selectedCase?.id,
        case_id: props.isLeadSpecific ? props?.caseDetails?.case_id : props?.caseView ? props?.caseDetails?.case_id : selectedCase?.id,
        // case_name: props.isLeadSpecific
        //   ? props?.caseDetails?.case_description?.case_name
        //   : props?.caseDetails?.case_description?.case_name
        //   ? props?.caseDetails?.case_description?.case_name
        //   : selectedCase?.value,
        case_name: props?.isLeadSpecific
          ? props?.caseDetails?.case_description?.case_name
          : props?.caseView
          ? props?.caseDetails?.case_description?.case_name
          : !props?.caseView && !props?.isLeadSpecific
          ? selectedCase?.case_name
          : selectedCase?.value,
        billable: !markAsNonBillable,
        notes: description,
        hour_rate: `${rate}`,
        time_entry_status: 'OPEN',
        activity_type: selectedActivityType?.activity_type,
        activity_type_id: selectedActivityType?.activity_type_id,
        activity_type_code: selectedActivityType?.activity_type_code,
        total_hours: String(Number(duration)),
        amount: `${duration * rate}`,
      };

      if (props.selectedTimer) {
        let request_data = { ...props.selectedTimer, ...data };
        request_data.time_entry_status = 'OPEN';
        if (!Boolean(props?.is_automated)) {
          request_data.time_entry_date = convertToTimezoneWithStartOfDayFormat(timeEntryDate, userContext?.userDetails?.timezone);
        }
        updateTimeEntry(request_data, props.isLeadSpecific ? props.isLeadSpecific : selectedCase?.is_lead, '', true, true)
          .then((response) => {
            dispatch(nofifyTimeManager(response.data));
            addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.UPDATE_TIME_ENTRY_SUCCESS);
            setLoading(false);
            typeof props.createTimerSuccess === 'function' && props.createTimerSuccess();
          })
          .catch((err) => {
            setLoading(false);
            addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, toastConstant.message.UPDATE_TIME_ENTRY_FAILED);
          });
      } else {
        let date = new Date();
        if (props?.is_automated) {
          data.time_entry_date = convertToTimezoneWithStartOfDayFormat(date, userContext?.userDetails?.timezone);
        } else {
          data.time_entry_date = convertToTimezoneWithStartOfDayFormat(timeEntryDate, userContext?.userDetails?.timezone);
        }
        createTimeEntry(data, props.isLeadSpecific ? props?.isLeadSpecific : selectedCase?.is_lead)
          .then((response) => {
            addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.CREATE_TIME_ENTRY_SUCCESS);

            setLoading(false);

            typeof props.createTimerSuccess === 'function' && props.createTimerSuccess();
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
            addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, toastConstant.message.CREATE_TIME_ENTRY_FAILED);
          });
      }
    }
  };
  const loadActivityType = () => {
    totalCountEvent('activity_type').then((response) => {
      listActivityTypes('', response.data)
        .then((response) => {
          if (response && response.data && response.data?.activity_types.length > 0) {
            response.data.activity_types.forEach((type, idx) => {
              type.activity_label = type.activity_type + ' - ' + type.activity_type_code;
            });

            setActivityTypes([...response.data.activity_types]);
          }
        })
        .catch((err) => {});
    });
  };

  const validateForm = () => {
    let valid = true;
    if (!selectedCase) {
      valid = false;
      formError.case = 'Required';
    }
    if (!duration) {
      valid = false;
      formError.duration = 'Required';
    }
    if (duration && isNaN(duration)) {
      valid = false;
      formError.duration = 'Invalid';
      // let values = duration.split(":");
      // if (values && values.length > 0) {
      //   let hh = values[0];
      //   let mm = values[1];
      //   let ss = values[2];
      //   if (mm > 59 || ss > 59) {
      //     valid = false;
      //     formError.duration = "Invalid";
      //   }
      // }
    }
    if (!markAsNonBillable && (!rate || (rate && rate <= 0))) {
      valid = false;
      formError.rate = 'Required a valid rate';
    }
    if (!timeEntryDate) {
      valid = false;
      formError.timeEntryDate = 'Required';
    }

    setFormError({ ...formError });
    return valid;
  };

  return (
    <>
      <div className="row">
        {!isFromTimeManger && <div className="col-12 mb-3"> {props.selectedTimer ? 'Update Time Entry' : 'Add Time Entry'}</div>}
        {isFromTimeManger && (
          <div className="d-flex justify-content-between align-items-center mt-3 mb-3">
            <div className="modal-heading F-size16 text-bold">{props.header}</div>
            {/* <div>
              <button
                className="p-button p-button-secondary mx-1"
                onClick={submitTimeEntry}
              >
                {props.selectedTimer ? "Update" : "Save"}
              </button>
              <button
                className="p-button p-button-secondary outline mx-1"
                onClick={props.cancelCreateTimer}
              >
                {" "}
                Cancel
              </button>
            </div> */}
          </div>
        )}
      </div>
      <div className="row p-2">
        <div className="col-12 col-sm-4 mb-3 d-flex align-items-center">
          <label className="p-medium">
            Duration
            <span className="text-danger">*</span>
          </label>
        </div>
        <div className="col-12 col-sm-8 mb-3">
          {/* <Timer timer={time} hideActions={true} /> */}
          <InputText
            className="input-shadow w-100 border-0 p-2 p-d-block"
            value={duration}
            onChange={(e) => {
              setDuration(e.target.value);
              formError.duration = '';
              setFormError({ ...formError });
            }}
          />
          <span id="username1-help" className="p-d-block pt-2">
            0.1 = 6 minutes
          </span>
          {/* <InputMask
            className="input-shadow w-100 border-0 p-2"
            placeholder="hh:mm:ss"
            value={duration}
            onChange={(e) => {
              setDuration(e.target.value);
              formError.duration = "";
              setFormError({ ...formError });
            }}
            mask="99:99:99"
          /> */}
          {formError && formError.duration && <span className="text-danger d-block">{formError.duration}</span>}
        </div>
        {!Boolean(props?.is_automated) && (
          <>
            <div className="col-12 col-sm-4 mb-3 d-flex align-items-center">
              <label className="p-medium">Date</label>
              <span className="text-danger">*</span>
            </div>
            <div className="col-12 col-sm-8 mb-3">
              <Calendar
                value={timeEntryDate}
                onChange={(e) => {
                  setTimeEntryDate(e.value);
                  formError.timeEntryDate = '';
                }}
                minDate={new Date()}
                placeholder="Select Date"
                className="input-shadow w-100"
              />
              {formError && formError.timeEntryDate && <span className="text-danger d-block">{formError.timeEntryDate}</span>}
            </div>
          </>
        )}

        {!Boolean(props.caseView) && (
          <>
            <div className="col-12 col-sm-4 mb-3 d-flex align-items-center">
              <label className="p-medium">Case/Lead</label>
              <span className="text-danger">*</span>
            </div>
            <div className="col-12 col-sm-8 mb-3">
              {/* <Dropdown
                placeholder="By Case"
                options={caseList}
                value={selectedCase}
                onChange={onChangeCase}
                optionLabel={'new_case_name'}
                className="w-100"
                disabled={props.caseView}
                filter
                //={(selectedCase ==="" )? false : true}
                valueTemplate={selectedCaseTemplate}
              /> */}
              {/* <AutoComplete
                field="label"
                value={selectedCase}
                suggestions={caseList}
                completeMethod={searchCase}
                onChange={onChangeCase}
                placeholder="Case/Lead Name"
                // selectedItemTemplate={selectedCaseTemplate}
              /> */}
              <CaseListDropDown
                value={selectedCase}
                onChange={(e) => {
                  onChangeCase(e);
                }}
                placeholder="Case/Lead Name"
                noOptionsMessage="No Cases/Leads Found"
                loadOptions={searchCase}
                defaultOptions={caseList}
              />
              {formError && formError.case && <span className="text-danger">Required</span>}
              {/*  {caseError && <span className="text-danger">*Required</span>} */}
            </div>
          </>
        )}

        <div className="col-12 col-md-4 mb-3 d-flex align-items-center">
          <label className="p-medium">Billing Code</label>
        </div>
        <div className="col-12 col-md-8 mb-3">
          <Dropdown
            options={activityTypes}
            value={selectedActivityType}
            //showClear={true}
            // optionLabel={"activity_type"}
            optionLabel={'activity_label'}
            onChange={(e) => {
              setSelectedActivityType(e.value);
            }}
            className="w-100"
            filter
            filterBy="activity_type,activity_type_code"
            filterPlaceholder="Search"
            placeholder="Select Billing Code"
            disabled={props.is_automated}
          />
        </div>

        <div className="col-12 col-sm-4 mb-3">
          <label className="p-medium">Description</label>
        </div>
        <div className="col-12 col-sm-8 mb-3">
          <TextSnippetWrapper
            type="textarea"
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
            className="input-shadow w-100"
          />
        </div>

        <div className="col-12 col-sm-4 mb-3 d-flex align-items-center">
          <label className="p-medium">
            {/* Rate, <span className="text-muted">$/h</span> */}
            Hourly Rate
            <span className="text-danger">{!markAsNonBillable ? '*' : ''}</span>
          </label>
        </div>
        <div className="col-12 col-sm-8 mb-3">
          <InputNumber
            id="rate"
            name="rate"
            placeholder="Rate"
            className="input-shadow w-100"
            value={rate}
            onChange={(e) => {
              if (e.value === null) {
                setRate(0);
              } else {
                setRate(e.value);
              }
              formError.rate = '';
              setFormError({ ...formError });
            }}
            mode="currency"
            currency="USD"
            locale="en-US"
            min={0}
          />
          {formError && formError.rate && <span className="text-danger">{formError.rate}</span>}
        </div>

        <div className="col-12 col-sm-4 mb-3 d-flex align-items-center">
          <label className="p-medium">Total Amount</label>
        </div>
        <div className="col-12 col-sm-8 mb-3">
          <InputNumber
            id="total_amount"
            placeholder="Duration * Rate"
            className="input-shadow w-100"
            value={!isNaN(rate * duration) ? rate * duration : ''}
            mode="currency"
            currency="USD"
            locale="en-US"
            min={0}
            disabled={true}
          />
          {formError && formError.rate && <span className="text-danger">{formError.rate}</span>}
        </div>

        <div className="col-12 my-3 d-flex align-items-center">
          <Checkbox
            checked={markAsNonBillable}
            onChange={(e) => {
              setMarkAsNonBillable(e.target.checked);
              if (e.target.checked) {
                formError.rate = '';
                setFormError({ ...formError });
              }
            }}
          />
          <label className="ms-2 p-medium">Mark as non-billable</label>
        </div>
        {/* <hr className="px-2"></hr>
        <div className="col-12 mt-2">
          <button
            className="p-button p-button-secondary mx-1"
            onClick={submitTimeEntry}
          >
            {props.selectedTimer ? "Update" : "Save"}
          </button>
          <button
            className="p-button p-button-secondary outline mx-1"
            onClick={props.cancelCreateTimer}
          >
            {" "}
            Cancel
          </button>
        </div> */}
        <div className="footer-btn">
          <Button className="p-button-secondary outline mx-2" label="Cancel" onClick={props.cancelCreateTimer}></Button>
          <Button
            className="p-button p-button-secondary"
            label={props.selectedTimer ? 'Update' : 'Save'}
            onClick={submitTimeEntry}
            loading={loading}
          ></Button>
        </div>
      </div>
    </>
  );
};
