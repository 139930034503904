import React from 'react';
import { Button } from 'primereact/button';
import { Badge } from 'primereact/badge';
import ImageComponent from 'shared/ImageComponent';
import { handleDateTimeOffset } from 'utils/utility_functions/timezone';
import '../mailbox.scss';
import { Tag } from 'primereact/tag';
import GlobalLoader from 'components/GlobalLoader/GlobalLoader';

const MailList = ({
  allMails,
  selectedEmailThreadPk,
  setSelectedEmailThreadPkSk,
  handleLoadMore,
  loading,
  hasNext,
  paginationLoading,
  readEmails,
  setReadEmails,
  multiple = false,
  selectedEmails = [],
  setSelectedEmails = () => {},
}) => {
  const toggleEmailSelection = (pk, sk) => {
    setSelectedEmails((prev) => {
      const exists = prev.some((email) => email.pk === pk && email.sk === sk);
      return exists ? prev.filter((email) => email.pk !== pk || email.sk !== sk) : [...prev, { pk, sk }];
    });
  };
  const handleEmailClick = (mail) => {
    setSelectedEmailThreadPkSk({ pk: mail?.pk, sk: mail?.sk });
    setReadEmails((prev) => ({ ...prev, [mail?.pk]: true }));
  };
  if (!allMails?.length) {
    return (
      <div className="text-message-height mailbox">
        <div className="msg-holder pt-0" style={{ overflow: 'hidden auto' }}>
          <div className="d-flex align-items-center justify-content-center">
            <h6 className="text-center" style={{ marginTop: '80px' }}>
              No emails found
            </h6>
          </div>
        </div>
      </div>
    );
  }

  const statusMapping = {
    SUCCESS: 'Mapped By AI',
    BYPASSED: 'Mapping Bypassed',
    FAILED: 'AI Mapping Failed',
    MANUAL_OVERRIDE: 'Mapped By User',
    NO_MATCH_FOUND: 'Unmapped By AI',
    IN_PROGRESS: 'Mapping In Progress',
    QUEUED: 'Mapping Scheduled',
  };

  const getStatusClass = (status) => {
    if (status === 'SUCCESS') return 's-tag'; // Success
    if (status === 'FAILED') return 'da-tag'; // Failure
    if (status === 'IN_PROGRESS') return 'b-tag'; // In Progress
    return 'y-tag'; // All others
  };

  return (
    <>
      <GlobalLoader />
      <div className="text-message-height mailbox">
        {/* Mail List Container */}
        <div className="msg-holder pt-0" style={{ height: 'auto', overflow: 'hidden auto' }}>
          <div className="border-top">
            {allMails?.map((mail) => {
              const isUnread = mail?.is_unread && !readEmails[mail?.pk];
              const isChecked = selectedEmails.some((email) => email.pk === mail.pk && email.sk === mail.sk);
              return (
                <div className="mail-list-outer-box flex-row-reverse" key={mail?.sk + mail?.time}>
                  <button
                    className="w-100 border-bottom p-2 ps-0 pointer mail-list-box"
                    style={{
                      backgroundColor: selectedEmailThreadPk === mail?.pk ? '#f2f2f2' : '#fff',
                      border: 'none',
                      padding: '12px',
                      cursor: 'pointer',
                      textAlign: 'left',
                      display: 'block',
                    }}
                    tabIndex="0"
                    onClick={() => {
                      handleEmailClick(mail);
                    }}
                  >
                    <div className={`d-flex align-items-center`}>
                      <div className="px-2 position-relative">
                        <ImageComponent
                          fileName={mail?.email_from?.user_email?.charAt(0)}
                          style={{ backgroundColor: '#2196F3', color: '#ffffff' }}
                          fileSize="medium.jpg"
                        />
                        {/* {isUnread && <Badge className="mail-list-badge"></Badge>} */}
                        {isUnread && <i className="icon-window-filled mail-list-badge"></i>}
                      </div>
                      <div className={`col-10 ${isUnread ? 'unread-email' : ''}`}>
                        <div
                          style={{
                            marginBottom: '0.3rem',
                          }}
                        >
                          <div className="d-flex justify-content-between align-items-center">
                            <h6 className="text-break text-secondary mb-0 pe-2">
                              {mail?.email_from?.user_name || mail?.email_from?.user_email}
                            </h6>
                            {mail?.ai_reconciliation_status && (
                              <div>
                                <Tag
                                  className={`tag-case mail-ai-tags text-center d-status-tags ${getStatusClass(
                                    mail.ai_reconciliation_status
                                  )}`}
                                  rounded
                                  value={
                                    <>
                                      <i className="bi bi-stars me-2"></i>
                                      {statusMapping[mail.ai_reconciliation_status] || mail.ai_reconciliation_status}
                                    </>
                                  }
                                />
                              </div>
                            )}
                          </div>
                          <span className="pointer text-break text-primary-dark">{mail?.subject}</span>
                        </div>
                        <div className="d-flex justify-content-between w-100">
                          <span className="text-hint black-500">{handleDateTimeOffset(null, mail?.time, 'MM-DD-YYYY')}</span>
                          <span className="text-hint black-500">{handleDateTimeOffset(null, mail?.time, 'hh:mm A')}</span>
                        </div>
                      </div>
                    </div>
                  </button>
                  {multiple && (
                    <input type="checkbox" checked={isChecked} onChange={() => toggleEmailSelection(mail.pk, mail.sk)} className="ms-2" />
                  )}
                </div>
              );
            })}
          </div>
          {loading && (
            <div className="text-center p-3">
              <i className="pi pi-spin pi-spinner" /> Loading more emails...
            </div>
          )}
          {hasNext && (
            <div className="text-center">
              <Button type="button" className="load-more-btn w-100" onClick={handleLoadMore} disabled={!hasNext}>
                Load More
              </Button>
            </div>
          )}
          {paginationLoading && (
            <div className="text-center p-3">
              <i className="pi pi-spin pi-spinner" /> Loading more emails...
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default MailList;
