export const actionTypes = {
  USER_LOGOUT: 'USER_LOGOUT',
  SET_USER_FIRST_NAME: 'SET_USER_FIRST_NAME',
  SET_USER_LAST_NAME: 'SET_USER_LAST_NAME',
  GET_PRACTICE_AREA_REQUEST: 'GET_PRACTICE_AREA_REQUEST',
  GET_PRACTICE_AREA_SUCCESS: 'GET_PRACTICE_AREA_SUCCESS',
  GET_PRACTICE_AREA_ERROR: 'GET_PRACTICE_AREA_ERROR',
  GET_CASE_TAG_REQUEST: 'GET_CASE_TAG_REQUEST',
  GET_CASE_TAG_SUCCESS: 'GET_CASE_TAG_SUCCESS',
  GET_CASE_TAG_ERROR: 'GET_CASE_TAG_ERROR',
  GET_CUSTOM_FIELD_LIST: 'GET_CUSTOM_FIELD_LIST',

  GET_CUSTOM_FIELD_LIST_SUCCESS: 'GET_CUSTOM_FIELD_LIST_SUCCESS',
  GET_CUSTOM_FIELD_LIST_ERROR: 'GET_CUSTOM_FIELD_LIST_ERROR',
  CREATE_CASE_TAG_REQUEST: 'CREATE_CASE_TAG_REQUEST',
  CREATE_CASE_TAG_SUCCESS: 'CREATE_CASE_TAG_SUCCESS',
  CREATE_CASE_TAG_ERROR: 'CREATE_CASE_TAG_ERROR',
  GET_CASE_STATUS_REQUEST: 'GET_CASE_STATUS_REQUEST',
  GET_CASE_STATUS_SUCCESS: 'GET_CASE_STATUS_SUCCESS',
  GET_CASE_STATUS_ERROR: 'GET_CASE_STATUS_ERROR',
  GET_CASE_REQUEST: 'GET_CASE_REQUEST',
  GET_CASE_SUCCESS: 'GET_CASE_SUCCESS',
  GET_CASE_ERROR: 'GET_CASE_ERROR',
  UPDATE_CASE_LIST: 'UPDATE_CASE_LIST',
  CREATE_CASE_REQUEST: 'CREATE_CASE_REQUEST',
  CREATE_CASE_SUCCESS: 'CREATE_CASE_SUCCESS',
  CREATE_CASE_ERROR: 'CREATE_CASE_ERROR',
  GET_CONTACT_REQUEST: 'GET_CONTACT_REQUEST',
  GET_CONTACT_SUCCESS: 'GET_CONTACT_SUCCESS',
  GET_CONTACT_ERROR: 'GET_CONTACT_ERROR',
  CLEAR_CONTACT_DATA: 'CLEAR_CONTACT_DATA',
  CLEAR_CASE_CREATE: 'CLEAR_CASE_CREATE',
  GET_CASE_DETAILS_REQUEST: 'GET_CASE_DETAILS_REQUEST',
  GET_CASE_DETAILS_SUCCESS: 'GET_CASE_DETAILS_SUCCESS',
  GET_CASE_DETAILS_ERROR: 'GET_CASE_DETAILS_ERROR',
  CLEAR_CASE_DETAILS: 'CLEAR_CASE_DETAILS',
  GET_CONFLICT_REQUEST: 'GET_CONFLICT_REQUEST',
  GET_CONFLICT_SUCCESS: 'GET_CONFLICT_SUCCESS',
  GET_CONFLICT_ERROR: 'GET_CONFLICT_ERROR',
  GET_CASE_MATTER_REQUEST: 'GET_CASE_MATTER_REQUEST',
  GET_CASE_MATTER_SUCCESS: 'GET_CASE_MATTER_SUCCESS',
  GET_CASE_MATTER_ERROR: 'GET_CASE_MATTER_ERROR',
  DELETE_CUSTOM_FIELD: 'DELETE_CUSTOM_FIELD',
  DELETE_CUSTOM_FIELD_SUCCESS: 'DELETE_CUSTOM_FIELD_SUCCESS',
  DELETE_CUSTOM_FIELD_ERROR: 'DELETE_CUSTOM_FIELD_ERROR',
  UPDATE_CUSTOM_FIELD: 'UPDATE_CUSTOM_FIELD',
  UPDATE_CUSTOM_FIELD_SUCCESS: 'UPDATE_CUSTOM_FIELD_SUCCESS',
  UPDATE_CUSTOM_FIELD_ERROR: 'UPDATE_CUSTOM_FIELD_ERROR',

  GET_EVENT_LIST_STARTED: 'GET_EVENT_LIST_STARTED',
  GET_EVENT_LIST_SUCCESS: 'GET_EVENT_LIST_SUCCESS',
  GET_EVENT_LIST_FAILURE: 'GET_EVENT_LIST_FAILURE',
  GET_CASE_NOTE_REQUEST: 'GET_CASE_NOTE_REQUEST',
  GET_CASE_NOTE_SUCCESS: 'GET_CASE_NOTE_SUCCESS',
  GET_CASE_NOTE_ERROR: 'GET_CASE_NOTE_ERROR',
  GET_CASE_COURT_REQUEST: 'GET_CASE_COURT_REQUEST',
  GET_CASE_COURT_SUCCESS: 'GET_CASE_COURT_SUCCESS',
  GET_CASE_COURT_ERROR: 'GET_CASE_COURT_ERROR',
  GET_LEAD_STAGE_REQUEST: 'GET_LEAD_STAGE_REQUEST',
  GET_LEAD_STAGE_SUCCESS: 'GET_LEAD_STAGE_SUCCESS',
  GET_LEAD_STAGE_ERROR: 'GET_LEAD_STAGE_ERROR',
  GET_OFFICE_LOCATIONS_REQUEST: 'GET_OFFICE_LOCATIONS_REQUEST',
  GET_OFFICE_LOCATIONS_SUCCESS: 'GET_OFFICE_LOCATIONS_SUCCESS',
  GET_OFFICE_LOCATIONS_ERROR: 'GET_OFFICE_LOCATIONS_ERROR',

  GET_EVENT_TYPE_LIST_STARTED: 'GET_EVENT_TYPE_LIST_STARTED',
  GET_EVENT_TYPE_LIST_SUCCESS: 'GET_EVENT_TYPE_LIST_SUCCESS',
  GET_EVENT_TYPE_LIST_FAILURE: 'GET_EVENT_TYPE_LIST_FAILURE',
  GET_TASK_LIST_REQUEST: 'GET_TASK_LIST_REQUEST',
  GET_TASK_LIST_SUCCESS: 'GET_TASK_LIST_SUCCESS',
  GET_TASK_LIST_ERROR: 'GET_TASK_LIST_ERROR',
  RESET_TASK_LIST: 'RESET_TASK_LIST',
  GET_CLIENT_USER_DETAILS_REQUEST: 'GET_CLIENT_USER_DETAILS_REQUEST',
  GET_CLIENT_USER_DETAILS_SUCCESS: 'GET_CLIENT_USER_DETAILS_SUCCESS',
  GET_CLIENT_USER_DETAILS_ERROR: 'GET_CLIENT_USER_DETAILS_ERROR',

  GET_CALENDAR_SETTINGS_STARTED: 'GET_CALENDAR_SETTINGS_STARTED',
  GET_CALENDAR_SETTINGS_SUCCESS: 'GET_CALENDAR_SETTINGS_SUCCESS',
  GET_CALENDAR_SETTINGS_FAILURE: 'GET_CALENDAR_SETTINGS_FAILURE',

  GET_NOTIFICATIONS_STARTED: 'GET_NOTIFICATIONS_STARTED',
  GET_NOTIFICATIONS_SUCCESS: 'GET_NOTIFICATIONS_SUCCESS',
  GET_NOTIFICATIONS_FAILURE: 'GET_NOTIFICATIONS_FAILURE',

  GET_USER_GROUPS_STARTED: 'GET_USER_GROUPS_STARTED',
  GET_USER_GROUPS_SUCCESS: 'GET_USER_GROUPS_SUCCESS',
  GET_USER_GROUPS_FAILURE: 'GET_USER_GROUPS_FAILURE',

  GET_USER_ROLES_STARTED: 'GET_USER_ROLES_STARTED',
  GET_USER_ROLES_SUCCESS: 'GET_USER_ROLES_SUCCESS',
  GET_USER_ROLES_FAILURE: 'GET_USER_ROLES_FAILURE',
  GET_TASKSTAGE_LIST_REQUEST: 'GET_TASKSTAGE_LIST_REQUEST',
  GET_TASKSTAGE_LIST_SUCCESS: 'GET_TASKSTAGE_LIST_SUCCESS',
  GET_TASKSTAGE_LIST_ERROR: 'GET_TASKSTAGE_LIST_ERROR',
  GET_CASE_LIST_REQUEST: 'GET_CASE_LIST_REQUEST',
  GET_CASE_LIST_SUCCESS: 'GET_CASE_LIST_SUCCESS',
  GET_CASE_LIST_ERROR: 'GET_CASE_LIST_ERROR',
  NEW_TIME_ENTRY_ADDED: 'NEW_TIME_ENTRY_ADDED',

  GET_ACCOUNTS_LIST_STARTED: 'GET_ACCOUNTS_LIST_STARTED',
  GET_ACCOUNTS_LIST_FAILED: 'GET_ACCOUNTS_LIST_FAILED',
  GET_ACCOUNTS_LIST_SUCCESS: 'GET_ACCOUNTS_LIST_SUCCESS',

  GET_EXPENSE_TYPE_LIST_STARTED: 'GET_EXPENSE_TYPE_LIST_STARTED',
  GET_EXPENSE_TYPE_LIST_FAILED: 'GET_EXPENSE_TYPE_LIST_FAILED',
  GET_EXPENSE_TYPE_LIST_SUCCESS: 'GET_EXPENSE_TYPE_LIST_SUCCESS',

  GET_PAYMENT_STRUCTURES_STARTED: 'GET_PAYMENT_STRUCTURES_STARTED',
  GET_PAYMENT_STRUCTURES_SUCCESS: 'GET_PAYMENT_STRUCTURES_SUCCESS',
  GET_PAYMENT_STRUCTURES_FAILED: 'GET_PAYMENT_STRUCTURES_FAILED',

  GET_TAX_STARTED: 'GET_TAX_STARTED',
  GET_TAX_SUCCESS: 'GET_TAX_SUCCESS',
  GET_TAX_FAILED: 'GET_TAX_FAILED',
  GET_AUTOMATION_RULES: 'GET_AUTOMATION_RULES',
  GET_AUTOMATION_RULES_SUCCESS: 'GET_AUTOMATION_RULES_SUCCESS',
  GET_AUTOMATION_RULES_ERROR: 'GET_AUTOMATION_RULES_ERROR',

  GET_PAYMENT_REQUEST_LIST_SUCCESS: 'GET_PAYMENT_REQUEST_LIST_SUCCESS',
  GET_PAYMENT_REQUEST_LIST_FAILED: 'GET_PAYMENT_REQUEST_LIST_FAILED',
  GET_PAYMENT_REQUEST_LIST_STARTED: 'GET_PAYMENT_REQUEST_LIST_STARTED',

  GET_PAYMENT_RECORD_LIST_SUCCESS: 'GET_PAYMENT_RECORD_LIST_SUCCESS',
  GET_PAYMENT_RECORD_LIST_FAILED: 'GET_PAYMENT_RECORD_LIST_FAILED',
  GET_PAYMENT_RECORD_LIST_STARTED: 'GET_PAYMENT_RECORD_LIST_STARTED',

  //settings module - JobTitle menu
  GET_JOB_TITLE_REQUEST: 'GET_JOB_TITLE_REQUEST',
  GET_JOB_TITLE_SUCCESS: 'GET_JOB_TITLE_SUCCESS',
  GET_JOB_TITLE_ERROR: 'GET_JOB_TITLE_ERROR',

  //settings module - GlobalTag menu
  GET_GLOBAL_TAG_REQUEST: 'GET_GLOBAL_TAG_REQUEST',
  GET_GLOBAL_TAG_SUCCESS: 'GET_GLOBAL_TAG_SUCCESS',
  GET_GLOBAL_TAG_ERROR: 'GET_GLOBAL_TAG_ERROR',

  //settings module - BusinessRole menu
  GET_BUSINESS_ROLE_REQUEST: 'GET_BUSINESS_ROLE_REQUEST',
  GET_BUSINESS_ROLE_SUCCESS: 'GET_BUSINESS_ROLE_SUCCESS',
  GET_BUSINESS_ROLE_ERROR: 'GET_BUSINESS_ROLE_ERROR',

  RECORD_AUTOMATION_DATA: 'RECORD_AUTOMATION_DATA',
  RESET_AUTOMATION_DATA: 'RESET_AUTOMATION_DATA',
  RESET_DATA: 'RESET_DATA',
  SET_AUTOMATION_DATA: 'SET_AUTOMATION_DATA',
  ADD_NEW_CARD: 'ADD_NEW_CARD',
  RECORD_ACTION_TYPE: 'RECORD_ACTION_TYPE',
  RECORD_DELAY_OR_ACTION_BODY: 'RECORD_DELAY_OR_ACTION_BODY',
  ACTION_PARAMETERS: 'ACTION_PARAMETERS',
  FOLDER_STRUCTURE: 'FOLDER_STRUCTURE',
  RECORD_FILTER_BODY: 'RECORD_FILTER_BODY',
  DELETE_CARD_AND_DATA_BODY: 'DELETE_CARD_AND_DATA_BODY',
  SET_DATA_FROM_BACK_END: 'SET_DATA_FROM_BACK_END',
  FORM_DATA_IS_CHANGING: 'FORM_DATA_IS_CHANGING',
  CHANGE_TO_FULL_SCREEN: 'CHANGE_TO_FULL_SCREEN',
  HIDE_FULL_SCREEN_VIEW: 'HIDE_FULL_SCREEN_VIEW',
  SAVE_CONTACT_FORM_DATA: 'SAVE_CONTACT_FORM_DATA',
  SAVE_INFORMATION_FORM_DATA: 'SAVE_INFORMATION_FORM_DATA',
  SAVE_BILLING_PREFERNCE_DATA: 'SAVE_BILLING_PREFERNCE_DATA',
  MOVE_TO_CONTACT: 'MOVE_TO_CONTACT',
  MOVE_TO_INFORMATION: 'MOVE_TO_INFORMATION',
  MOVE_TO_BILLING_PREFERENCES: 'MOVE_TO_BILLING_PREFERENCES',
  SEND_LEAD_DATA: 'SEND_LEAD_DATA',
  LEAD_FORM_DATA_COMPLETED: 'LEAD_FORM_DATA_COMPLETED',
  RESET_LEAD_LOCAL_DATA: 'RESET_LEAD_LOCAL_DATA',
  RECORD_FEE_PAYMENT_DATA_FOR_CONVERSION: 'RECORD_FEE_PAYMENT_DATA_FOR_CONVERSION',
  CONVERT_TO_CASE_CLICKED: 'CONVERT_TO_CASE_CLICKED',
  STARTED_BACKWARD_NAVIGATION: 'STARTED_BACKWARD_NAVIGATION',
  STARTED_FORWARD_NAVIGATION: 'STARTED_FORWARD_NAVIGATION',
  SAVE_CURRENT_INDEX: 'SAVE_CURRENT_INDEX',
  UPDATE_LEAD_CLICKED: 'UPDATE_LEAD_CLICKED',

  SHOW_ADD_CASE: 'SHOW_ADD_CASE',
  HIDE_ADD_CASE: 'HIDE_ADD_CASE',

  GET_RELATION_ROLE_REQUEST: 'GET_RELATION_ROLE_REQUEST',
  GET_RELATION_ROLE_SUCCESS: 'GET_RELATION_ROLE_SUCCESS',
  GET_RELATION_ROLE_ERROR: 'GET_RELATION_ROLE_ERROR',

  GET_LEAD_SOURCE_REQUEST: 'GET_LEAD_SOURCE_REQUEST',
  GET_LEAD_SOURCE_SUCCESS: 'GET_LEAD_SOURCE_SUCCESS',
  GET_LEAD_SOURCE_ERROR: 'GET_LEAD_SOURCE_ERROR',

  SHOW_ADD_LEAD: 'SHOW_ADD_LEAD',
  HIDE_ADD_LEAD: 'HIDE_ADD_LEAD',

  SHOW_ADD_EVENT: 'SHOW_ADD_EVENT',
  HIDE_ADD_EVENT: 'HIDE_ADD_EVENT',

  SHOW_ADD_TIMEENTRY: 'SHOW_ADD_TIMEENTRY',
  HIDE_ADD_TIMEENTRY: 'HIDE_ADD_TIMEENTRY',

  SHOW_ADD_MESSAGE: 'SHOW_ADD_MESSAGE',
  HIDE_ADD_MESSAGE: 'HIDE_ADD_MESSAGE',

  SHOW_ADD_TASK: 'SHOW_ADD_TASK',
  HIDE_ADD_TASK: 'HIDE_ADD_TASK',

  UPDATE_PROFILE_IMAGE_START: 'UPDATE_PROFILE_IMAGE_START',
  UPDATE_PROFILE_IMAGE_SUCCESS: 'UPDATE_PROFILE_IMAGE_SUCCESS',
  MOVE_TO_INFORMATION_AFTER_CONTACT_SAVE: 'MOVE_TO_INFORMATION_AFTER_CONTACT_SAVE',

  GET_LEAD_REASON_REQUEST: 'GET_LEAD_REASON_REQUEST',
  GET_LEAD_REASON_SUCCESS: 'GET_LEAD_REASON_SUCCESS',
  GET_LEAD_REASON_ERROR: 'GET_LEAD_REASON_ERROR',

  GET_CASE_OUTCOME_REQUEST: 'GET_CASE_OUTCOME_REQUEST',
  GET_CASE_OUTCOME_SUCCESS: 'GET_CASE_OUTCOME_SUCCESS',
  GET_CASE_OUTCOME_ERROR: 'GET_CASE_OUTCOME_ERROR',

  GET_CASE_REASON_REQUEST: 'GET_CASE_REASON_REQUEST',
  GET_CASE_REASON_SUCCESS: 'GET_CASE_REASON_SUCCESS',
  GET_CASE_REASON_ERROR: 'GET_CASE_REASON_ERROR',

  //active timer
  GET_ACTIVE_TIMER_HOURS: 'GET_ACTIVE_TIMER_HOURS',
};
