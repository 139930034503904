/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { useSelector, useDispatch } from 'react-redux';
import { getUserList } from 'redux/actions/userListAction';
import { createExpense, updateExpense } from 'services/billingServices';
import { useToast } from 'context/ToastContext';
import { toastConstant } from 'constants/toastmessage';
import { listExpenseTypes } from 'redux/actions/ExpenseTypeActions';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { totalCountEvent } from 'services/generalServices';
import { convertToTimezoneWithStartOfDay } from 'utils/utility_functions/timezone';
import { UserDetailsContext } from 'context/userDetailsContext';
// import { useCaseAndLeadsList } from 'hooks/useCaseAndLeadList';
import { ModalHeader } from 'shared/ModalHeader';
import { AutoComplete } from 'primereact/autocomplete';
import { listCasesAndLeads } from 'services/CaseServices';
import debounce from 'lodash.debounce';
import TextSnippetWrapper from 'components/TextSnippetWrapper';
import CaseListDropDown from 'components/CaseListDropDown/CaseListDropDown';
import CustomCalendar from 'components/CustomCalendar/CustomCalendar';
import { sortByKey } from 'utils/utils';

export const CreateExpense = (props) => {
  const [selectedCase, setSelectedCase] = useState();
  const [selectedType, setSelectedType] = useState();
  const [quantity, setQuantity] = useState('');
  const [rate, setRate] = useState('');
  const [description, setDescription] = useState('');
  const [selectedTeamMember, setSelectedTeamMember] = useState();
  // const [nonBillable, setNonBillable] = useState(false);//TODO:NON-BILLABLE
  const [nonBillable, setNonBillable] = useState(false);
  const [selectedDate, setSelectedDate] = useState();
  const [caseList, setCaseList] = useState([]);
  const [caseFullList, setCaseFullList] = useState();
  const [searchValue, setSearchValue] = useState();
  const [load, setLoad] = useState(false);
  let [formError, setFormError] = useState({
    case: '',
    team_member: '',
    rate: '',
    quantity: '',
  });
  let [teamMembers, setTeamMembers] = useState([]);
  const [loading, setLoading] = useState(false);
  const userList = useSelector((state) => state.userList.userList.firm_users);
  const expenseTypes = useSelector((state) => state.expenseTypeList.expense_types);
  const dispatch = useDispatch();
  const { addToast } = useToast();

  // const { caseAndLeadsList } = useCaseAndLeadsList();

  const userContext = useContext(UserDetailsContext);

  useEffect(() => {
    totalCountEvent('user')
      .then((response) => {
        dispatch(getUserList('ACTIVE', response.data, '', 'forward', '', '', false));
      })
      .catch((error) => {});
    totalCountEvent('expense_type').then((response) => {
      dispatch(listExpenseTypes('', response.data));
    });
  }, []);

  // useEffect(() => {
  //   if (caseAndLeadsList && caseAndLeadsList?.case_list?.length) {
  //     let data = caseAndLeadsList?.case_list?.map((val) => {
  //       val.new_case_name =
  //         val && val.case_description && val.case_description.case_name
  //           ? `${val.unique_number ? `${val.unique_number} ` : ''}${val.case_description.case_name}`
  //           : 'TBD';
  //       return val;
  //     });
  //     setCaseList(data);
  //   }
  // }, [caseAndLeadsList]);

  useEffect(() => {
    getAllCaseList();
  }, []);

  useEffect(() => {
    if (userList && userList.length > 0) {
      teamMembers = [];
      userList.forEach((item) => {
        if (item.is_confirmed === true) {
          let name = '';
          if (item.first_name) name += item.first_name;
          if (item.middle_name) name = name + ' ' + item.middle_name;
          if (item.last_name) name = name + ' ' + item.last_name;
          let team_member = {
            name: name,
            ...item,
          };
          teamMembers.push(team_member);
        }
      });
      const sortedTeamMembers = sortByKey(teamMembers, 'first_name');

      setTeamMembers([...sortedTeamMembers]);
    }
  }, [userList]);

  useEffect(() => {
    if (props.modal && !props.selectedExpense) {
      setSelectedCase();
      clearFormError();
      setSelectedType();
      setSelectedTeamMember();
      setQuantity();
      setRate();
      setSelectedDate();
      setDescription();
      setNonBillable(false);
      if (props.caseView) {
        let case_id = props?.caseDetails?.case_id;
        let case_label = props?.caseDetails?.case_description?.case_name;
        // if (caseList && caseList.length > 0) {
        //   let selectedCase = caseList.filter((index) => index.case_id === case_id);
        //   if (props.isLeadSpecific) {
        //     setSelectedCase(props.caseDetails);
        //   } else {
        //     setSelectedCase({ ...selectedCase[0] });
        //   }
        // }
        if (props.isLeadSpecific) {
          setSelectedCase(props.caseDetails);
        } else {
          setSelectedCase({ id: case_id, label: case_label, ...props?.caseDetails });
        }
      }
    } else if (props.selectedExpense) {
      try {
        setSelectedCase();
        clearFormError();
        setSelectedType();
        setSelectedTeamMember();
        setQuantity();
        setRate();
        setSelectedDate();
        setDescription();
        setNonBillable(false);
        setQuantity(props.selectedExpense?.quantity);
        setRate(props.selectedExpense?.rate_per_unit);
        setDescription(props.selectedExpense?.description);
        setNonBillable(!props.selectedExpense.billable);
        if (props.selectedExpense?.expense_date) setSelectedDate(props.selectedExpense?.expense_date);
        let case_id = props.selectedExpense?.case_id;
        let case_label = props?.selectedExpense?.case_name;
        // if (caseList && caseList.length > 0) {
        //   let _case_list = caseList && caseList.filter((index) => index.case_id === case_id);
        //   if (props.isLeadSpecific) {
        //     setSelectedCase(props.caseDetails);
        //   } else {
        //     if (_case_list?.length > 0) {
        //       setSelectedCase(_case_list[0]);
        //     }
        //   }
        // }
        if (props.isLeadSpecific) {
          setSelectedCase(props.caseDetails);
        } else {
          setSelectedCase({ id: case_id, label: case_label, ...props?.selectedExpense });
        }
        let expense_type_id = props.selectedExpense?.expense_type_id;
        let _expense_types = expenseTypes?.expense_type?.filter((index) => index.expense_type_id === expense_type_id);
        if (_expense_types?.length > 0) setSelectedType(_expense_types[0]);

        let team_member_id = props.selectedExpense?.team_member_id;
        let _teamMembers = teamMembers.filter((index) => index.contact_id === team_member_id);
        if (_teamMembers?.length > 0) setSelectedTeamMember(_teamMembers[0]);
      } catch (err) {}
    }
  }, [props.modal, caseList, expenseTypes, teamMembers, props.selectedExpense]);

  const onSubmit = () => {
    if (validateForm()) {
      setLoading(true);
      let data = {
        // case_id: props.isLeadSpecific ? props?.caseDetails?.case_id : selectedCase?.id,
        case_id: props.isLeadSpecific ? props?.caseDetails?.case_id : props?.caseView ? props?.caseDetails?.case_id : selectedCase?.id,
        // case_name: props.isLeadSpecific
        //   ? props?.caseDetails?.case_description?.case_name
        //   : selectedCase?.case_description?.case_name
        //   ? selectedCase?.case_description?.case_name
        //   : selectedCase?.value,
        case_name: props?.isLeadSpecific
          ? props?.caseDetails?.case_description?.case_name
          : props?.caseView
          ? props?.caseDetails?.case_description?.case_name
          : !props?.caseView && !props?.isLeadSpecific
          ? selectedCase?.case_name
          : selectedCase?.value,
        expense_type_id: selectedType?.expense_type_id,
        expense_type: selectedType?.expense_type,
        quantity: quantity,
        rate_per_unit: rate,
        total_amount: quantity * rate,
        expense_date: selectedDate,
        description: description,
        team_member_id: selectedTeamMember?.firm_user_id || selectedTeamMember?.contact_id,
        firm_user_id: selectedTeamMember?.firm_user_id,
        team_member_name: selectedTeamMember?.name,
        billable: !nonBillable,
      };
      if (props.selectedExpense) {
        data = { ...props.selectedExpense, ...data };
        updateExpense(data, props.isLeadSpecific ? props.isLeadSpecific : selectedCase?.is_lead)
          .then((response) => {
            addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.EXPENSE_UPDATED_SUCCESS);
            setLoading(false);
            props.onCreateSuccess();
          })
          .catch((err) => {
            setLoading(false);
            addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, toastConstant.message.EXPENSE_UPDATE_FAILED);
          });
      } else {
        createExpense(data, props.isLeadSpecific ? props.isLeadSpecific : selectedCase?.is_lead)
          .then((response) => {
            addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.EXPENSE_CREATE_SUCCESS);

            setLoading(false);
            props.onCreateSuccess();
          })
          .catch((err) => {
            setLoading(false);
            addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, toastConstant.message.EXPENSE_CREATE_FAILED);
          });
      }
    }
  };

  function getAllCaseList(is_lead = true, case_id = '', exclude_pi_case = false, keyword = '', limit = 15, is_filter_dropdown = true) {
    listCasesAndLeads(is_lead, case_id, exclude_pi_case, keyword, limit, is_filter_dropdown)
      .then((res) => {
        let tempArray = [];
        res?.data?.case_list?.forEach((item) => {
          tempArray.push({
            label:
              item && item.case_description && item.case_description.case_name
                ? `${item.unique_number ? `${item.unique_number} ` : ''}${item.case_description.case_name}`
                : 'TBD',
            value: item.case_description.case_name,
            id: item?.case_id,
          });
        });
        setCaseFullList(tempArray);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const validateForm = () => {
    let valid = true;
    if (!selectedCase) {
      formError.case = 'required';
      valid = false;
    }
    if (!rate) {
      formError.rate = 'required';
      valid = false;
    }
    if (!quantity) {
      formError.quantity = 'required';
      valid = false;
    }
    if (!selectedTeamMember) {
      formError.team_member = 'required';
      valid = false;
    }
    setFormError({ ...formError });
    return valid;
  };

  const clearFormError = () => {
    formError = {
      case: '',
      team_member: '',
      rate: '',
      quantity: '',
    };
    setFormError({ ...formError });
  };

  const searchCase = (e) => {
    let query = e?.query;
    setSearchValue(query);
  };

  const fetchData = useCallback((query) => {
    getAllCaseList(false, '', false, query, 15, true);
  }, []);

  const delayedSearch = useCallback(
    debounce((query) => {
      fetchData(query);
    }, 500),
    [fetchData]
  );

  const assignToItemTemplate = (option) => {
    return (
      <>
        {option?.firm_user_id && <i className="fas fa-user-tie me-2"></i>}
        {option?.name}
      </>
    );
  };

  useEffect(() => {
    if (searchValue !== '') {
      delayedSearch(searchValue);
    } else if (searchValue === '' && load) {
      getAllCaseList(false, '', false, searchValue, 15, true);
    }
  }, [searchValue, delayedSearch, load]);

  useEffect(() => {
    if (props.modal) {
      document.body.style.overflow = 'hidden';
    }
    // Clean up the effect when the component unmounts
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [props.modal]); // to remove the dropdown scroll issue in dialog dropdown

  return (
    <Dialog
      visible={props.modal}
      onHide={(e) => {
        props.setModal(false);
      }}
      className="add-contact-dialog create-msg-popup"
      header={ModalHeader}
      closable={false}
    >
      <div className="d-flex justify-content-between align-items-center mt-3 mb-3">
        <div className="modal-heading F-size16 text-bold">{props.selectedExpense ? 'Update Expense' : 'Add Expense'}</div>
      </div>
      <div className="row mt-3 d-flex align-items-center expense-popup">
        <div className="col-12 col-md-4 p-medium">
          {!props?.caseView && !props?.isLeadSpecific ? 'Case/Lead' : 'Case'}
          <span className="text-danger">*</span>
        </div>
        <div className="col-12 col-md-8">
          {props.isLeadSpecific ? (
            <InputText
              className="input-shadow w-100"
              placeholder="Case/Lead Name"
              value={props.caseDetails?.case_description?.case_name}
              onValueChange={null}
              disabled={props.caseView}
            />
          ) : (
            <>
              {/* <Dropdown
                className="w-100"
                optionLabel={'new_case_name'}
                value={selectedCase}
                placeholder="Select Case Name"
                options={caseList}
                onChange={(e) => {
                  setSelectedCase(e.value);
                  formError.case = '';
                  setFormError({ ...formError });
                }}
                filter
                disabled={props.caseView}
              /> */}
              {/* <AutoComplete
                className="w-100"
                value={selectedCase}
                suggestions={caseFullList}
                completeMethod={searchCase}
                field="label"
                onChange={(e) => {
                  setSelectedCase(e.value);
                  formError.case = '';
                  setFormError({ ...formError });
                }}
                placeholder={!props?.caseView && !props?.isLeadSpecific ? 'Case/Lead Name' : 'Case Name'}
                filter
                disabled={props.caseView}
              /> */}
              <CaseListDropDown
                className="w-100"
                value={selectedCase}
                onChange={(e) => {
                  setSelectedCase(e);
                  formError.case = '';
                  setFormError({ ...formError });
                }}
                placeholder={!props?.caseView && !props?.isLeadSpecific ? 'Case/Lead Name' : 'Case Name'}
                noOptionsMessage="No Cases/Leads Found"
                disabled={props.caseView}
              />
              {formError && formError.case && <span className="text-danger">Required</span>}
            </>
          )}
        </div>
        <div className="col-12 col-md-4 mt-3 p-medium">Type</div>
        <div className="col-12 col-md-8 mt-3">
          <Dropdown
            className="w-100"
            value={selectedType}
            optionLabel={'expense_type'}
            placeholder="Select Expense Type"
            options={expenseTypes.expense_type}
            onChange={(e) => {
              setSelectedType(e.value);
            }}
          />
        </div>
        <div className="col-12 col-md-4 mt-3 p-medium">
          Quantity
          <span className="text-danger">*</span>
        </div>
        <div className="col-12 col-md-8 mt-3">
          <input
            className="input-shadow w-100 border-0 p-2"
            placeholder="Quantity"
            value={quantity}
            onChange={(e) => {
              setQuantity(e.target.value);
              formError.quantity = '';
              setFormError({ ...formError });
            }}
            type="number"
            min={1}
          />
          {formError && formError.quantity && <span className="text-danger">Required</span>}
        </div>
        <div className="col-12 col-md-4 mt-3 p-medium">
          {/* Rate, $/unit */}
          Hourly Rate
          <span className="text-danger">*</span>
        </div>
        <div className="col-12 col-md-8 mt-3">
          <InputNumber
            className="input-shadow w-100"
            placeholder="Rate"
            value={rate}
            onChange={(e) => {
              setRate(e.value);
              formError.rate = '';
              setFormError({ ...formError });
            }}
            mode="currency"
            currency="USD"
            locale="en-US"
            min={1}
          />
          {formError && formError.rate && <span className="text-danger">Required</span>}
        </div>
        <div className="col-12 col-md-4 mt-3 p-medium">Total Amount</div>
        <div className="col-12 col-md-8 mt-3">
          <InputNumber
            className="input-shadow w-100 border-0"
            placeholder="Quantity * Rate"
            min={0}
            disabled={true}
            value={!isNaN(rate * quantity) ? rate * quantity : ''}
            mode="currency"
            currency="USD"
            locale="en-US"
          />
        </div>
        <div className="col-12 col-md-4 mt-3 p-medium">Date</div>
        <div className="col-12 col-md-8 mt-3">
          <CustomCalendar
            className="input-shadow w-100 border-0"
            value={selectedDate}
            onChange={(e) => {
              setSelectedDate(e.value);
            }}
            monthNavigator
            yearNavigator
            yearRange="1920:2030"
          />
        </div>
        <div className="col-12 col-md-4 mt-3 p-medium">Description</div>
        <div className="col-12 col-md-8 mt-3 ">
          <TextSnippetWrapper
            type="textarea"
            className="input-shadow w-100 border-0 p-2"
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
          />
        </div>
        <div className="col-12 col-md-4 mt-3 p-medium">
          Team Member
          <span className="text-danger">*</span>
        </div>
        <div className="col-12 col-md-8 mt-3">
          <Dropdown
            placeholder="Select Team Member"
            className="w-100"
            options={teamMembers}
            onChange={(e) => {
              setSelectedTeamMember(e.value);
              formError.team_member = '';
              setFormError({ ...formError });
            }}
            optionLabel="name"
            filterBy="name"
            value={selectedTeamMember}
            filter
            itemTemplate={assignToItemTemplate}
          />
          {formError && formError.team_member && <span className="text-danger">Required</span>}
        </div>
        <div className="col-4 col-sm-3 col-md-4 mt-3 p-medium">Non - billable</div>
        <div className="col-8 col-sm-9 col-md-8 mt-3">
          <label className="switch">
            <input
              type="checkbox"
              checked={nonBillable}
              name="weekends"
              onChange={(e) => {
                setNonBillable(e.target.checked);
              }}
            />{' '}
            <span className="slider round"></span>
          </label>
        </div>
      </div>

      <div className="footer-btn">
        <Button
          className="p-button-secondary outline mx-2"
          label="Cancel"
          onClick={() => {
            props.setModal(false);
          }}
        ></Button>
        <Button
          className="p-button p-button-secondary"
          label={props.selectedExpense ? 'Update' : 'Save'}
          onClick={onSubmit}
          loading={loading}
        ></Button>
      </div>
    </Dialog>
  );
};
