import LabelName from 'components/UI/LabelName/LabelName';
import { InputSwitch } from 'primereact/inputswitch';
import React, { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { Dropdown } from 'primereact/dropdown';
import { recurringEventFrequency, recurringWeekOccurrence, weekdaysList } from 'constants/dropdownOptions';
import { RequiredValidation } from 'components/UI/RequiredValidation/RequiredValidation';
import { useCalendarContext } from 'modules/calendar/Context/CalendarContext';
import CustomCalendar from 'components/CustomCalendar/CustomCalendar';

function EventRecurring({ watch, control, setValue, errors, resetField }) {
  const { eventDetails, editEventModal } = useCalendarContext();

  const isRecurring = watch('is_recurring');
  const isNthWeekSelected = watch('recurrence_keys.recurrence_frequency') === 'nth_weekday';
  const startTimeWatcher = watch('meeting_start_time');

  useEffect(() => {
    if (!isRecurring) {
      setValue('is_recurring', false);
      resetField('recurrence_keys');
    } else {
      if (editEventModal) {
        if (eventDetails?.is_recurring && isRecurring) {
          setValue('recurrence_keys', eventDetails?.recurrence_keys);
        } else {
          setValue('recurrence_keys.recurrence_limit', startTimeWatcher);
        }
      } else if (isRecurring) {
        setValue('recurrence_keys.recurrence_limit', startTimeWatcher);
      }
    }
  }, [isRecurring]);

  return (
    <>
      <div className="d-flex align-items-center py-2 F-size14 flex-wrap ">
        <div className="col-md-4 col-12">
          <LabelName required={false} htmlFor="is_recurring">
            Recurring Event
          </LabelName>
        </div>
        <div className="col-md-8 col-12 d-flex align-items-center justify-content-start text-capitalize edit-field">
          <div className="w-100 ">
            <Controller
              name="is_recurring"
              control={control}
              render={({ field }) => (
                <InputSwitch name={field.name} checked={field.value} onChange={(e) => field.onChange(e.value)} className="input-swich" />
              )}
            />
          </div>
        </div>
      </div>

      {isRecurring && (
        <>
          <div className="d-flex align-items-center py-2 flex-wrap">
            <div className="col-md-4 col-12">
              <LabelName required={true} htmlFor="recurrence_limit">
                Till Date
              </LabelName>
            </div>
            <div className="col-md-8 col-12">
              <Controller
                name="recurrence_keys.recurrence_limit"
                control={control}
                rules={{
                  required: 'Please select till date',
                  validate: (value) => {
                    return new Date(value) > new Date(startTimeWatcher) ? true : 'Till date should be greater than start date';
                  },
                }}
                render={({ field }) => (
                  <CustomCalendar
                    id={field.name}
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e.value);
                      const tillDate = new Date(e.value);
                      const startTime = new Date(startTimeWatcher);
                      tillDate.setHours(startTime.getHours());
                      tillDate.setMinutes(startTime.getMinutes());
                      tillDate.setSeconds(startTime.getSeconds());
                      setValue('recurrence_keys.recurrence_limit', tillDate);
                    }}
                    minDate={startTimeWatcher}
                    placeholder="Date"
                    monthNavigator
                    yearNavigator
                    yearRange="1950:2050"
                    className="w-100 input-shadow field-icon"
                    showIcon
                  />
                )}
              />
            </div>
            <div className="offset-md-4">
              <RequiredValidation field={`recurrence_keys.recurrence_limit`} errors={errors} />
            </div>
          </div>

          <div className="d-flex align-items-center py-2 flex-wrap">
            <div className="col-md-4 col-12 p-medium">
              <LabelName required={true} htmlFor="recurrence_frequency">
                Schedule
              </LabelName>
            </div>
            <div className="col-md-8 col-12">
              <div className="d-flex align-items-center">
                <Controller
                  name="recurrence_keys.recurrence_frequency"
                  control={control}
                  rules={{ required: 'Please select frequency' }}
                  defaultValue=""
                  render={({ field }) => (
                    <Dropdown
                      id={field.name}
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(e.value);
                      }}
                      options={recurringEventFrequency}
                      optionLabel={'label'}
                      placeholder="Recurring Frequency"
                      className="flex-grow-1"
                      filter
                      resetFilterOnHide={true}
                    />
                  )}
                />
              </div>
            </div>
            <div className="offset-md-4">
              <RequiredValidation field={`recurrence_keys.recurrence_frequency`} errors={errors} />
            </div>
          </div>

          {isNthWeekSelected && (
            <>
              <div className="d-flex align-items-center py-2 flex-wrap">
                <div className="col-md-4 col-12 p-medium">
                  <LabelName required={true} htmlFor="week_occurrence">
                    Week Occurrence
                  </LabelName>
                </div>
                <div className="col-md-8 col-12">
                  <div className="d-flex align-items-center">
                    <Controller
                      name="recurrence_keys.week_occurrence"
                      control={control}
                      rules={{ required: 'Please select week occurrence' }}
                      defaultValue=""
                      render={({ field }) => (
                        <Dropdown
                          id={field.name}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          options={recurringWeekOccurrence}
                          optionLabel={'label'}
                          placeholder="Week Occurrence"
                          className="flex-grow-1"
                          filter
                          resetFilterOnHide={true}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="offset-md-4">
                  <RequiredValidation field={`recurrence_keys.week_occurrence`} errors={errors} />
                </div>
              </div>

              <div className="d-flex align-items-center py-2 flex-wrap">
                <div className="col-md-4 col-12 p-medium">
                  <LabelName required={true} htmlFor="weekday">
                    Weekday
                  </LabelName>
                </div>
                <div className="col-md-8 col-12">
                  <div className="d-flex align-items-center">
                    <Controller
                      name="recurrence_keys.weekday"
                      control={control}
                      rules={{ required: 'Please select weekday' }}
                      defaultValue=""
                      render={({ field }) => (
                        <Dropdown
                          id={field.name}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                          }}
                          options={weekdaysList}
                          optionLabel={'label'}
                          placeholder="Weekday"
                          className="flex-grow-1"
                          filter
                          resetFilterOnHide={true}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="offset-md-4">
                  <RequiredValidation field={`recurrence_keys.weekday`} errors={errors} />
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
}

export default EventRecurring;
