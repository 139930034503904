const { reconcileEmail } = require('services/mailbox/mailboxService');

export const useReconcileEmail = (data) => {
  const changeEmailCase = async (reference_emails, caseId, caseName, isLead) => {
    const dataBody = {
      reference_emails,
      case_id: caseId,
      case_name: caseName,
      is_lead: isLead,
    };
    const response = await reconcileEmail(dataBody);
    return response;
  };
  return { changeEmailCase };
};
